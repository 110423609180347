/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Card,
  notification,
  Typography,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import PageContentHeader from "../../../components/common/PageContentHeader";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import { addBankService } from "../../../services/master-services";

const { Title } = Typography;

/**
 * handleSubmit function handles the form submission.
 * It sends the form data to the server and handles the response.
 * @param {Object} values - Form values
 */

const AddBank = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [form] = Form.useForm(); // Ant Design form instance
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status


  const handleSubmit = async (values) => {
    values.finance_commission = `${values?.finance_commission}%`;
    values.status = values.status === true ? 1 : 0;

    try {
      setIsLoading(true);
      const response = await addBankService(values);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response?.data?.message,
        });
        navigate("/admin/masters/bank");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        {/* Loader component to show loading spinner */}
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title="Add Bank" />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Bank Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      {/* Form item for Bank */}
                      <Form.Item
                        name="name"
                        label="Bank"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your bank"
                                );
                              }
                              if (!/^[a-zA-Z0-9\s()]+$/.test(value)) {
                                return Promise.reject(
                                  "Please input a valid bank"
                                );
                              }
                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Bank cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Bank cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Bank must be between 3 and 30 characters"
                                );
                              }
                              // Check if the string is numeric only
                              if (/^[\d()]+$/.test(value)) {
                                return Promise.reject(
                                  "Bank cannot be only numeric values"
                                );
                              }
                              // 
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Bank"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      {/* Form item for Finance Commission */}
                      <Form.Item
                        name="finance_commission"
                        label="Finance Commission"
                        rules={[
                          {
                            required: true,
                            message: "Please input valid finance commission",
                          },
                          {
                            // Using a regular expression to enforce the rule for no symbols and allowing up to 5 decimal places.
                            pattern: new RegExp(/^(100(?:\.0{1,4})?|0?\d{0,2}(?:\.\d{1,4})?)$/),
                            message: "Please input a valid percentage",
                          },
                        ]}
                        
                      >
                        <InputNumber
                          min={0}
                          type="number"
                          addonAfter="%"
                          placeholder="Finance Commission"
                          className="form-control-ant"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      {/* Form item for In House */}
                      <Form.Item
                        name="in_house"
                        label="In-House"
                        rules={[
                          {
                            required: true,
                            message: "Please select in-house",
                          },
                        ]}
                      >
                        <Radio.Group
                        // onChange={onChange} value={value}
                        >
                          <Radio value={"Yes"}>Yes</Radio>
                          <Radio value={"No"}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  {/* Cancel button */}
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/bank")}
                  >
                    Cancel
                  </Button>
                  {/* Save button */}
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddBank;
