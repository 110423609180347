/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import { Col, Form, Select, notification } from "antd";
import { getCityService, getStateService } from "../../services/master-services";

const CityState = (props) => {
    const { leadViewDetails, form } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [register, setRegister] = useState({
        state_id: 0,
    });

    useEffect(() => {
        getStateData();
    }, [])

    const getCityData = async (state_id) => {
        setIsLoading(true);
        try {
            const cityDataArray = await getCityService(state_id);
            setCityData(cityDataArray?.data?.data);
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getStateData = async () => {
        setIsLoading(true);
        try {
            const stateData = await getStateService();
            setStateData(stateData?.data?.data);
            if (leadViewDetails?.customer?.state.id) {
                getCityData(leadViewDetails?.customer?.state?.id);
            }
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleStateChange = (value) => {
        setRegister((state) => ({
            ...state,
            state_id: value,
        }));
        form.setFieldsValue({
            city_id: null,
        });
        getCityData(value);
    };

    return (
        <>
            <Loader loading={isLoading} />
            <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                <Form.Item
                    name="state_id"
                    label="State"
                    rules={[
                        {
                            required: true,
                            message: 'Please select your state',
                        },
                    ]}
                >
                    <Select
                        placeholder="State"
                        className="form-control-ant"
                        onChange={handleStateChange}
                    >
                        {stateData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={8} sm={24} xs={24}>
                <Form.Item
                    allowClear
                    name="city_id"
                    label="City"
                    rules={[
                        {
                            required: true,
                            message: 'Please select your city',
                        },
                    ]}
                >
                    <Select
                        placeholder="City"
                        className="form-control-ant"
                    >
                        {cityData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        </>
    )
}

export default CityState