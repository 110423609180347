import React from 'react';
import { DotLoader } from 'react-spinners';

function Loader(props) {
  if (props.loading) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 999,
        }}
      >
        <DotLoader color="#abf7b1" />
      </div>
    );
  }
}

export default React.memo(Loader);
