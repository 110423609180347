/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, notification, Tag, DatePicker } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTargetsDataService } from '../../services/master-services';
import Loader from '../common/Loader';
import { API_RESPONSE_CODES, monthData } from '../../utills/constant';
import dayjs from 'dayjs';
import PageContentHeader from '../common/PageContentHeader';
import moment from 'moment';
import { useSelector } from 'react-redux';
import NoDataFound from '../common/NoDataFound';

const ProfileTargets = (props) => {
  const { profileId } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();
  const [form] = Form.useForm();
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const permissions = useSelector((state) => state?.auth?.userData?.permissions);
  const launchDate = useSelector((state) => state?.auth?.userData?.launch_date);
  const hasEditTargetPermission = isAdmin ? true : permissions?.includes('edit-target');
  const hasAddTargetPermission = isAdmin ? true : permissions?.includes('add-target');

  const [isLoading, setIsLoading] = useState(false);
  const [targetData, setTargetData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthId());
  const salesOfficerExists = userData?.role === 'Sales Officer';

  useEffect(() => {
    if (pathname?.includes('target')) {
      getTargetData(id, selectedMonth, selectedYear);
    } else if (profileId) {
      getTargetData(profileId, selectedMonth, selectedYear);
    }
  }, [id, selectedMonth, profileId, selectedYear]);

  function getCurrentMonthId() {
    const currentMonth = new Date().getMonth() + 1;
    const month = monthData?.find((m) => m.id === currentMonth);
    return month?.id || '';
  }
  const route = isAdmin ? '/admin' : '';
  const isExchangeEvaluationTeam = userData?.designation === 'Exchange Evaluation Team';

  const targetLockingDate = userData?.target_locking_date ? moment(userData.target_locking_date) : null;
  const currentDate = moment();

  const isLockingDateValid = targetLockingDate && targetLockingDate.isSameOrAfter(currentDate, 'day');

  //Get Target data service
  const getTargetData = async (id, month, selectedYear) => {
    setIsLoading(true);
    try {
      const target = await getTargetsDataService(id, month, selectedYear);
      if (target?.status === API_RESPONSE_CODES?.OK) {
        setTargetData(target?.data?.data.kpi);
        setUserData(target?.data?.data.user);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMonthChange = (selectedMonthId) => {
    if (selectedMonthId) {
      setSelectedMonth(dayjs(selectedMonthId).month() + 1);
      setSelectedYear(dayjs(selectedMonthId).year());
    } else {
      setSelectedMonth(null);
    }
  };

  const disablePastDates = (current) => {
    return current < dayjs(launchDate, 'DD-MM-YYYY').startOf('month'); // Disable dates before the launch month
  };

  const isFutureDate = (selectedMonth, selectedYear) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return selectedYear > currentYear || (selectedYear === currentYear && selectedMonth > currentMonth);
  };

  const formInitialValues = {
    remember: true,
    month: dayjs(),
  };

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />
        <Form form={form} initialValues={formInitialValues}>
          <PageContentHeader
            className='my-target-header'
            title={userData?.designation ? `My Target (${userData?.designation})` : 'My Target'}
            headerRight={
              <>
                <Form.Item
                  name='month'
                  label='Select Month & Year:'
                  className='select-month-picker'
                  rules={[
                    {
                      required: true,
                      message: 'Please select month',
                    },
                  ]}
                >
                  <DatePicker
                    picker='month'
                    format={'MM/YYYY'}
                    onSelect={handleMonthChange}
                    disabledDate={disablePastDates}
                    placeholder='Select month & year'
                  />
                </Form.Item>
              </>
            }
          />
          {isFutureDate(selectedMonth, selectedYear) && !isExchangeEvaluationTeam && (
            <>
              {pathname.includes('profile') ? null : (
                <div className='text-right mb-3'>
                  {userData?.designation === 'Team Leader' || userData?.designation === "Sales Officer" ? (
                    <>
                      {isLockingDateValid && hasEditTargetPermission && targetData?.length > 0 && (
                        <Button
                          type='primary'
                          className='secondary-button'
                          onClick={() => navigate(`${route}/edit-target/${id}/${selectedMonth}?year=${selectedYear}&role=${salesOfficerExists ? 'true' : 'false'}`)}
                        >
                          {'Edit Target'}
                        </Button>
                      )}
                      {isLockingDateValid && hasAddTargetPermission && targetData?.length < 1 && (
                        <Button
                          type='primary'
                          className='secondary-button'
                          onClick={() => navigate(`${route}/edit-target/${id}/${selectedMonth}?year=${selectedYear}&role=${salesOfficerExists ? 'true' : 'false'}`)}
                        >
                          {'Add Target'}
                        </Button>
                      )}
                    </>
                  ) : ""}
                </div>
              )}
            </>
          )}
          <Card>
            <Row gutter={20} justify='start' className='mb-3'>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6>User Name:</h6>
                  <p className=''>{userData?.full_name}</p>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6>Territory:</h6>
                  <div className='d-flex flex-wrap row-gap-1'>
                    {userData?.territories?.map((item) => {
                      return (
                        <Tag color='blue' key={item} className='white-space-normal'>
                          {item}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6>Area:</h6>
                  <div className='d-flex flex-wrap row-gap-1'>
                    {userData?.areas?.map((item) => {
                      return (
                        <Tag color='red' key={item} className='white-space-normal'>
                          {item}
                        </Tag>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6>Assigned On:</h6>
                  <p className=''>{userData?.assigned_on ? dayjs(userData?.assigned_on).format('DD-MM-YYYY') : '-'}</p>
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <div className='view-fields d-flex align-items-baseline'>
                  <h6>Due Date:</h6>
                  <p className=''>{userData?.due_date ? dayjs(userData?.due_date).format('DD-MM-YYYY') : '-'}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={[40, 40]}>
              {targetData?.length > 0 ? (
                targetData?.map((ele, index) => {
                  return (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                      <div className='user-table-data'>
                        <div className='target-table'>
                          <div className='custom-table'>
                            <table className='table table-bordered view-target-table'>
                              <thead>
                                <tr className='first-head'>
                                  <th colspan='3'>{ele.name}</th>
                                </tr>
                                <tr className='second-head'>
                                  <th className='text-left' style={{ whiteSpace: 'nowrap' }}>
                                    Model Name
                                  </th>
                                  <th className='target-head text-center' style={{ width: '26%' }}>
                                    Target
                                  </th>
                                  <th className='achieved-head text-center' style={{ width: '26%' }}>
                                    Achievement
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {ele?.targets?.map((el, index) => (
                                  <>
                                    <tr>
                                      <td className='text-left'>{el?.model_name}</td>
                                      <td className='text-center'>
                                        {ele?.kpi_id === 10 || ele?.kpi_id === 12
                                          ? el?.target_total_amount
                                          : el?.target_value}
                                      </td>
                                      <td className='text-center'>
                                        {ele?.kpi_id === 10 || ele?.kpi_id === 12
                                          ? el?.achieved_target_total_amount
                                          : el?.achievement_value}
                                      </td>
                                    </tr>
                                  </>
                                ))}
                                <tr className='totals-row'>
                                  <td>Total</td>
                                  <td className='text-center'>{ele?.total_target_value}</td>
                                  <td className='text-center'>{ele?.total_achievement_value}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </Row>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default ProfileTargets;
