/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { Button, Card, Col, DatePicker, Form, notification, Row, Space } from 'antd';
import Title from 'antd/es/skeleton/Title';
import InputSelect from '../../../components/common/InputSelect';
import Loader from '../../../components/common/Loader';
import {
  addTargetMasterService,
  getTargetMasterService,
  getVehicleModelService,
} from '../../../services/master-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import dayjs from 'dayjs';
import TargetField from '../../../components/common/TargetField';
// import NoDataFound from '../../../components/common/NoDataFound';
import { isValidNonZeroNumber } from '../../../utills/helpers';

const TargetMasters = () => {
  const [form] = Form.useForm(); // Ant Design form instance

  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const [modelData, setModelData] = useState([]); // Model data state
  const [date, setDate] = useState(dayjs()); // State to handle date
  const [targetData, setTargetData] = useState([]); // State to check edit mode
  const [modelId, setModelId] = useState(1); // State to manage the model id

  // Default form values
  const formInitialValues = {
    remember: true,
    month: dayjs(),
    model_id: modelId,
  };

  // useEffect to fetch model data on initial component render
  useEffect(() => {
    getModelData();
  }, []);

  // useEffect to populate data in the fields on change of date field
  useEffect(() => {
    getTargetMasterData(modelId, date);
  }, [modelId, date]);

  const clearValidationErrors = () => {
    const fieldsToClearErrors = targetData?.data?.map((ele) => ({
      name: `${ele.slug}`,
      errors: [], // Clear validation errors
    }));

    // Set the fields with no errors
    form.setFields(fieldsToClearErrors);
  };

  // Function to get modal data
  const getModelData = async () => {
    try {
      setIsLoading(true);
      const modalDataArray = await getVehicleModelService(1);
      setModelData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to get modal data
  const getTargetMasterData = async (model, date) => {
    const month = dayjs(date).month() + 1;
    const year = dayjs(date).year();
    try {
      setIsLoading(true);
      const targetMasterData = await getTargetMasterService(month, year, model);
      if (targetMasterData?.status === API_RESPONSE_CODES?.OK) {
        // Dynamically obtaining field names from the 'slug' property
        const fields = targetMasterData?.data?.data?.data?.map((ele) => ele?.slug);

        const formValues = fields.reduce((acc, field, index) => {
          // Using the dynamic field name to set the corresponding value
          if (field) {
            // Ensuring the field name is not undefined or null
            acc[field] = isValidNonZeroNumber(targetMasterData?.data?.data?.data?.at(index)?.parameter_value)
              ? targetMasterData?.data?.data?.data?.at(index)?.parameter_value
              : null;
          }
          return acc;
        }, {});

        form.setFieldsValue(formValues);
      }
      setTargetData(targetMasterData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle date field change
  const handleDateChange = (e) => {
    setDate(e);
    clearValidationErrors();
  };

  // Function to disable past date and time
  const disablePastMonths = (current) => {
    return current && current.isBefore(dayjs().startOf('month'), 'day');
  };

  // Function to handle model change
  const handleModelChange = (e) => {
    setModelId(e);
    clearValidationErrors();
  };

  // Function to handle submit action
  const handleSubmit = async (val) => {
    let monthConverted = dayjs(val.month).month() + 1;
    let yearConverted = dayjs(val.month).year();
    val.month_id = monthConverted;
    val.year_id = yearConverted;
    const payload = {
      month: val.month_id,
      year: val.year_id,
      model_id: modelId,
      kpi: [
        {
          kpi_id: 2,
          title: 'Test Drive',
          parameter_type: 'percentage',
          parameter_value: val.test_drive,
        },
        {
          kpi_id: 3,
          title: 'Home Visit',
          parameter_type: 'percentage',
          parameter_value: val.home_visit,
        },
        {
          kpi_id: 4,
          title: 'Exchange',
          parameter_type: 'percentage',
          parameter_value: val.exchange,
        },
        {
          kpi_id: 5,
          title: 'Booking',
          parameter_type: 'number',
          parameter_value: val.booking,
        },
        {
          kpi_id: 6,
          title: 'Retail',
          parameter_type: 'number',
          parameter_value: val.retail,
        },
        {
          kpi_id: 7,
          title: 'Valuation',
          parameter_type: 'percentage',
          parameter_value: val.valuation,
        },
        {
          kpi_id: 8,
          title: 'Purchase',
          parameter_type: 'number',
          parameter_value: val.purchase,
        },
        {
          kpi_id: 9,
          title: 'Extended Warranty',
          parameter_type: 'percentage',
          parameter_value: val.extended_warranty,
        },
        {
          kpi_id: 10,
          title: '3M / Body Care',
          parameter_type: 'amount',
          parameter_value: val.body_care,
        },
        {
          kpi_id: 11,
          title: 'In House',
          parameter_type: 'percentage',
          parameter_value: val.in_house,
        },
        {
          kpi_id: 12,
          title: 'Accessories',
          parameter_type: 'amount',
          parameter_value: val.accessories,
        },
      ],
    };
    try {
      setIsLoading(true);
      const response = await addTargetMasterService(payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getTargetMasterData(modelId, date);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />
        <PageContentHeader title='Target Master' />
        <div className='personal-detail'>
          <div className='user-left d-flex align-items-center'>
            <Title level={4} className='pe-2 pb-2'>
              Target Master
            </Title>
          </div>
          <Form
            form={form}
            layout='vertical'
            name='add-user-form'
            onFinish={handleSubmit}
            initialValues={formInitialValues}
          >
            <Card>
              <Row gutter={20}>
                <Col xl={6} lg={24} md={12} sm={24} xs={24}>
                  {/* Select Month and year */}
                  <Form.Item
                    name='month'
                    label='Select Month & Year'
                    rules={[
                      {
                        required: true,
                        message: 'Please select month',
                      },
                    ]}
                  >
                    <DatePicker
                      value={date}
                      picker='month'
                      format={'MM-YYYY'}
                      allowClear={false}
                      onChange={handleDateChange}
                      disabledDate={disablePastMonths}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
                {/* Model */}
                <Col xl={6} lg={24} md={12} sm={24} xs={24}>
                  <Form.Item>
                    <InputSelect
                      label='Model'
                      name='model_id'
                      allowClear={false}
                      options={modelData}
                      placeholder='Model'
                      optionName={'model_name'}
                      onChange={handleModelChange}
                      className='form-control-ant'
                      rules={[
                        {
                          required: true,
                          message: 'Please select your car model',
                        },
                      ]}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className='detail-wrapper'>
                {/* Dynamically mapping the target kpis */}
                <Row gutter={20} justify='start'>
                  {/* {targetData?.data?.length > 0 ? (
                    targetData?.data?.map((ele, index) => {
                      return (
                        <TargetField
                          min={0}
                          key={index}
                          label={ele.title}
                          name={`${ele.slug}`}
                          placeholder={ele.title}
                          validation={ele.parameter_type}
                          addonBefore={ele.parameter_type === 'amount' ? '₹' : ''}
                          addonAfter={ele.parameter_type === 'percentage' ? '%' : ''}
                        />
                      );
                    })
                  ) : (
                    <NoDataFound />
                  )} */}
                  {targetData?.data?.map((ele, index) => {
                    if (!ele?.slug) return null; // Handle missing slug
                    return (
                      <TargetField
                        key={index}
                        min={0}
                        label={ele.title}
                        name={`${ele.slug}`}
                        placeholder={ele.title}
                        validation={ele.parameter_type}
                        addonBefore={ele.parameter_type === 'amount' ? '₹' : ''}
                        addonAfter={ele.parameter_type === 'percentage' ? '%' : ''}
                      />
                    );
                  })}
                </Row>
              </div>

              {/* Submit Button */}
              <div className='user-right d-flex align-items-center justify-content-end mt-3'>
                <Space size='middle'>
                  {/* <Button type='primary' className='secondary-button' htmlType='submit'>
                    {targetData?.editable ? 'Update' : 'Submit'}
                  </Button> */}
                  <Button type='primary' className='secondary-button' htmlType='submit' disabled={isLoading}>
                    {targetData?.editable ? 'Update' : 'Submit'}
                  </Button>
                </Space>
              </div>
            </Card>
          </Form>
        </div>
      </div>
    </>
  );
};

export default TargetMasters;
