/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../app/auth/Login';
import setAuthToken from '../axiosConfig';
import ForgotPasswordPage from '../app/auth/ForgotPasswordPage';
import AdminDashboard from '../app/admin/dashboard/AdminDashboard';
import DefaultLayout from '../components/layout/DefaultLayout';
import Users from '../app/admin/users/Users';
import Permissions from '../app/admin/permissions/Permissions';
import Leads from '../app/admin/leads/Leads';
import ModelMaster from '../../src/app/admin/masters/Model';
import ExchangeModelMaster from '../app/admin/masters/ExchangeModel';
import TerritoryMaster from '../app/admin/masters/Territory';
import TransmissionMaster from '../app/admin/masters/Transmission';
import VariantMaster from '../app/admin/masters/Variant';
import VehicleMaster from '../app/admin/masters/Vehicle';
import AreaMaster from '../app/admin/masters/Area';
import BankMaster from '../app/admin/masters/Bank';
import AddVehicle from '../app/admin/masters/AddVehicle';
import AddExchangeModel from '../app/admin/masters/AddExchangeModel';
import AddModel from '../app/admin/masters/AddModel';
import AddArea from '../app/admin/masters/AddArea';
import AddVariant from '../app/admin/masters/AddVariant';
import AddTerritory from '../app/admin/masters/AddTerritory';
import AddTransmission from '../app/admin/masters/AddTransmission';
import AddBank from '../app/admin/masters/AddBank';
import Reports from '../app/admin/reports/Reports';
import UserDetails from '../app/admin/users/UserDetails';
import AddUsers from '../app/admin/users/AddUsers';
import AddTarget from '../app/admin/users/AddTarget';
import AddLeads from '../app/admin/leads/AddLeads';
import ExchangeEvaluation from '../app/admin/leads/Exchange/ExchangeEvaluation';
import ViewLeads from '../app/admin/leads/ViewLeads';
import LeadDetails from '../app/admin/leads/LeadDetails';
import CallLogs from '../app/admin/leads/CallLogs';
import HomeVisit from '../app/admin/leads/HomeVisit';
import ExchangeDetails from '../app/admin/leads/Exchange/ExchangeDetails';
import Profile from '../components/profile/CompanyProfileEdit';
import ErrorPage from '../components/common/ErrorPage';
import SalesDashboard from '../app/sales-team/dashboard/SalesDashboard';
import SalesModalMaster from '../app/sales-team/modal-master/SalesModalMaster';
import ViewExchange from '../app/exchange-evaluation-team/leads/ViewExchange';
import AddQuotation from '../app/exchange-evaluation-team/leads/AddQuotation';
import Retail from '../app/exchange-evaluation-team/leads/Retail';
import ActivityLog from '../app/exchange-evaluation-team/leads/ActivityLog';
import Bookings from '../app/exchange-evaluation-team/leads/Booking/Bookings';
import BookingDetails from '../app/exchange-evaluation-team/leads/Booking/BookingDetails';
import MyLeads from '../app/sales-team/leads/MyLeads';
import AdminProfile from '../components/profile/CompanyProfile';
import ProfileDetailsEdit from '../components/profile/ProfileDetailsEdit';
import DashboardHome from '../app/admin/dashboard/components/DashboardHome';
import DashboardTarget from '../app/admin/dashboard/components/DashboardTarget';
import { useSelector } from 'react-redux';
import ResetPassword from '../app/auth/ResetPassword';
import ChangePassword from '../app/auth/ChangePassword';
import UseScrollToTop from '../components/common/UseScrollToTop';
import ExchangeLeads from '../app/admin/leads/Exchange/ExchangeLeads';
import ViewQuotation from '../app/exchange-evaluation-team/leads/ViewQuotation';
import ExchangeDetailsWithPRocured from '../app/admin/leads/Exchange/ExchangeDetailsWithPRocured';
import ProfileTargets from '../components/profile/ProfileTargets';
import NoDataFound from '../components/common/NoDataFound';
import ReportsComponent from '../app/admin/reports/ReportsComponent';
import DashboardUsers from '../app/admin/dashboard/components/DashboardUsers';
import TargetMasters from '../app/admin/masters/TargetMaster';

function Routers() {
  const isAuthenticated = localStorage?.getItem('authToken');
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const permissions = useSelector((state) => state?.auth?.userData?.permissions);

  useEffect(() => {
    setAuthToken();
  }, [isAuthenticated]);

  UseScrollToTop();

  const adminRoutes = () => {
    return (
      <>
        <Route path='/' element={<DefaultLayout />}>
          <Route index element={<Navigate to='/admin/dashboard' />} />
          <Route path='/admin' element={<AdminDashboard />} />
          <Route path='/admin/dashboard' element={<AdminDashboard />} />
          <Route path='/admin/dashboard/home' element={<DashboardHome />} />
          <Route path='/admin/dashboard/target' element={<DashboardTarget />} />
          <Route path='/admin/users' element={<Users />} />
          <Route path='/admin/users/details/:id' element={<UserDetails />} />
          <Route path='/admin/users/edit/:id' element={<UserDetails />} />
          <Route path='/admin/users/add' element={<AddUsers />} />
          <Route path='/admin/add-target' element={<AddTarget />} />
          <Route path='/admin/edit-target/:id/:monthID' element={<AddTarget />} />
          <Route path='/admin/profile' element={<AdminProfile />} />
          <Route path='/admin/profile/edit' element={<Profile />} />
          <Route path='/admin/target/:id' element={<DashboardUsers />} />
          <Route path='/admin/dashboard-user/:id' element={<DashboardUsers />} />
          <Route path='/admin/profile/details-edit' element={<ProfileDetailsEdit />} />
          <Route path='/admin/permissions' element={<Permissions />} />
          <Route path='/admin/leads' element={<Leads />} />
          <Route path='/admin/leads/add' element={<AddLeads />} />
          <Route path='/admin/leads/edit/:id' element={<AddLeads />} />
          <Route path='/admin/leads/my-leads' element={<MyLeads />} />
          <Route path='/admin/leads/lead-details' element={<LeadDetails />} />
          <Route path='/admin/leads/view/:id' element={<ViewLeads />} />
          <Route path='/admin/leads/call-logs' element={<CallLogs />} />
          <Route path='/admin/leads/home-visit' element={<HomeVisit />} />
          <Route path='/admin/leads/exchange-details' element={<ExchangeDetails />} />
          <Route path='/admin/add-quotation' element={<AddQuotation />} />
          <Route path='/admin/view-quotation/:id' element={<ViewQuotation />} />
          <Route path='/admin/retail' element={<Retail />} />
          <Route path='/admin/view-exchange' element={<ViewExchange />} />
          <Route path='/admin/activity-log' element={<ActivityLog />} />
          <Route path='/admin/bookings/:id' element={<Bookings />} />
          <Route path='/admin/booking-details/:id' element={<BookingDetails />} />
          <Route path='/admin/targets/:id' element={<ProfileTargets />} />
          <Route path='/admin/masters/model' element={<ModelMaster />} />
          <Route path='/admin/masters/area' element={<AreaMaster />} />
          <Route path='/admin/masters/exchange-model' element={<ExchangeModelMaster />} />
          <Route path='/admin/masters/territory' element={<TerritoryMaster />} />
          <Route path='/admin/masters/transmission' element={<TransmissionMaster />} />
          <Route path='/admin/masters/variant' element={<VariantMaster />} />
          <Route path='/admin/masters/vehicle' element={<VehicleMaster />} />
          <Route path='/admin/masters/bank' element={<BankMaster />} />
          <Route path='/admin/masters/vehicle/add' element={<AddVehicle />} />
          <Route path='/admin/masters/vehicle/edit/:id' element={<AddVehicle />} />
          <Route path='/admin/masters/exchange-model/add' element={<AddExchangeModel />} />
          <Route path='/admin/masters/exchange-model/edit/:id' element={<AddExchangeModel />} />
          <Route path='/admin/masters/model/add' element={<AddModel />} />
          <Route path='/admin/masters/variant/add' element={<AddVariant />} />
          <Route path='/admin/masters/transmission/add' element={<AddTransmission />} />
          <Route path='/admin/masters/territory/add' element={<AddTerritory />} />
          <Route path='/admin/masters/area/add' element={<AddArea />} />
          <Route path='/admin/masters/bank/add' element={<AddBank />} />
          <Route path='/admin/masters/bank/edit/:id' element={<AddBank />} />
          <Route path='/admin/masters/target' element={<TargetMasters />} />
          <Route path='/admin/reports' element={<Reports />} />
          <Route path='/admin/reports/over-all-reports' element={<ReportsComponent />} />
          <Route path='/admin/reports/booking-reports' element={<ReportsComponent />} />
          <Route path='/admin/reports/exchange-reports' element={<ReportsComponent />} />
          <Route path='/admin/reports/retail-reports' element={<ReportsComponent />} />
          <Route path='/admin/reports/home-visit-test-drive-reports' element={<ReportsComponent />} />
          <Route path='/admin/change-password' element={<ChangePassword />} />
          <Route path='*' element={<ErrorPage />} />
        </Route>
      </>
    );
  };

  const userRoutes = () => {
    return (
      <Route path='/' element={<DefaultLayout />}>
        <Route index element={<Navigate to='/dashboard' />} />
        <Route path='/users' element={<Users />} />
        <Route
          path='/users/details/:id'
          element={permissions?.includes('view-user') ? <UserDetails /> : <ErrorPage />}
        />
        <Route path='/users/edit/:id' element={permissions?.includes('edit-user') ? <UserDetails /> : <ErrorPage />} />
        <Route path='/users/add' element={permissions?.includes('add-user') ? <AddUsers /> : <ErrorPage />} />
        <Route path='/profile' element={<AdminProfile />} />
        <Route path='/profile/edit' element={<ProfileDetailsEdit />} />
        <Route path='/dashboard' element={<SalesDashboard />} />
        <Route path='/add-quotation' element={<AddQuotation />} />
        <Route path='/leads' element={<Leads />} />
        <Route path='/leads/edit/:id' element={<AddLeads />} />
        <Route path='/leads/exchange-lead' element={<ExchangeLeads />} />
        <Route path='/target/:id' element={<DashboardUsers />} />
        <Route path='/dashboard-user/:id' element={<DashboardUsers />} />
        <Route path='/exchange-evaluation/:id' element={<ExchangeEvaluation />} />
        <Route path='/exchange-evaluation-details/:id' element={<ExchangeDetailsWithPRocured />} />
        <Route path='/leads/add' element={permissions?.includes('add-lead') ? <AddLeads /> : <ErrorPage />} />
        <Route path='/add-target' element={permissions?.includes('add-target') ? <AddTarget /> : <ErrorPage />} />
        <Route path='/targets/:id' element={<ProfileTargets />} />
        <Route path='/bookings/:id' element={<Bookings />} />
        <Route path='/view-quotation/:id' element={<ViewQuotation />} />
        <Route path='/leads/my-leads' element={<MyLeads />} />
        <Route path='/leads/lead-details' element={<LeadDetails />} />
        <Route path='/add-target' element={<AddTarget />} />
        <Route path='/edit-target/:id' element={<AddTarget />} />
        <Route path='/edit-target/:id/:monthID' element={<AddTarget />} />
        <Route
          path='/leads/view/:id'
          element={
            roleType === 'Exchange Evaluation Team' || permissions?.includes('view-lead') ? (
              <ViewLeads />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route path='/booking-details/:id' element={<BookingDetails />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/model-master' element={<SalesModalMaster />} />
        <Route path='*' element={<ErrorPage />} />
        <Route path='/no-result-found' element={<NoDataFound />} />
      </Route>
    );
  };

  return (
    <Routes>
      {isAuthenticated ? (
        <>{isAdmin ? adminRoutes() : userRoutes()}</>
      ) : (
        <>
          <Route path='/' element={<Login />} />
          <Route path='/admin' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPasswordPage />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='*' element={<Navigate to={isAdmin ? '/admin' : '/'} replace />} />
        </>
      )}
    </Routes>
  );
}

export default Routers;
