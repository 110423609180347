import React from 'react';
import Routes from './routes/routes';
import setAuthToken from './axiosConfig';
import axios from 'axios';
import { API_RESPONSE_CODES } from './utills/constant';
import { handleLogout } from './app/auth/slice/authSlice';
import { useDispatch } from 'react-redux';

setAuthToken();

export default function App() {
  const dispatch = useDispatch();

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const { response } = error;
      if (response && response.status === API_RESPONSE_CODES?.UNAUTHORIZED) {
        dispatch(handleLogout());
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <Routes />
    </div>
  );
}
