import React from "react";
import { Steps, Typography } from "antd";
import {
  CarOutlined,
  BookOutlined,
  HomeOutlined,
  BellOutlined,
  PhoneOutlined,
  RetweetOutlined,
  UserAddOutlined,
  FileDoneOutlined,
  PlusSquareOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  ddmmyyFormatter,
  formatTimeToReadableString,
} from "../../../utills/helpers";

const { Title } = Typography;

const ActivityLog = () => {
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);

  const renderIcon = (activity) => {
    switch (activity) {
      case "Lead Created":
        return <UserAddOutlined />;
      case "First Call":
        return <PhoneOutlined />;
      case "Follow up call":
        return <PhoneOutlined />;
      case "Home Visit":
        return <HomeOutlined />;
      case "Test Drive":
        return <CarOutlined />;
      case "Vehicle Booked":
        return <BookOutlined />;
      case "Vehicle Procured":
        return <BellOutlined />;
      case "Vehicle Retailed":
        return <PlusSquareOutlined />;
      case "Exchange":
        return <RetweetOutlined />;
      case "Quotation sent":
        return <FileDoneOutlined />;
      case "Evaluation Completed":
        return <CheckCircleOutlined />;
      default:
        return <CarOutlined />;
    }
  };
  return (
    <>
      <div className="user-data lead-detail">
        <div className="user-left">
          <Title level={4} className="">
            Activity Log
          </Title>
        </div>
        <div className="activity-log mt-4">
          <Steps
            direction="vertical"
            current={leadViewDetails?.activity_logs?.length}
          >
            {leadViewDetails?.activity_logs?.map((activity) => (
              <Steps.Step
                key={activity.id}
                title={
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="">{activity.start_date?ddmmyyFormatter(activity.start_date):"-"}</p>
                  </div>
                }
                description={
                  <>
                    <div>
                      <div className="d-flex justify-content-start">
                        <p className="subtitle">{activity.activity}</p>
                        {activity.activity === "Lead Created" && (
                          <p className="ps-3">
                            {leadViewDetails?.lead_type?.name} Lead
                          </p>
                        )}
                        {activity.activity === "First Call" ||
                        activity.activity === "Follow up call" ? (
                          activity.call_duration && (
                            <p className="ps-3">
                              Call Duration :{" "}
                              {formatTimeToReadableString(
                                activity.call_duration
                              )}
                            </p>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                      {activity.activity === "Home Visit" ||
                      activity.activity === "Test Drive" ? (
                        activity.visit_time && (
                          <p className="sub-subtitle">
                            <span className="active-log-label">Time :</span>{" "}
                            {dayjs(activity.visit_time, "HH:mm").format(
                              "hh:mm A"
                            )}
                          </p>
                        )
                      ) : (
                        <></>
                      )}
                      {activity.notes && (
                        <p className="sub-subtitle">
                          <span className="active-log-label">Notes:</span>{" "}
                          <span className="active-log-value">
                            {activity.notes}
                          </span>
                        </p>
                      )}
                    </div>
                  </>
                }
                icon={renderIcon(activity.activity)}
              />
            ))}
          </Steps>
        </div>
      </div>
    </>
  );
};

export default ActivityLog;
