/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Card,
  Upload,
  notification,
  Image,
  message,
} from "antd";
import { } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import {
  getAdminProfileDetails,
  updateCompanyProfileService,
} from "../../services/auth-service";
import Loader from "../common/Loader";
import {
  getCityService,
  getStateService,
} from "../../services/master-services";
import { API_RESPONSE_CODES, lockingDate } from "../../utills/constant";
import { useNavigate } from "react-router-dom";
import PageContentHeader from "../common/PageContentHeader";

const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;

  const [logo, setLogo] = useState("");
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adminProfileData, setAdminProfileData] = useState();

  useEffect(() => {
    if (adminProfileData?.full_name) {
      form.setFieldsValue({
        name: adminProfileData?.company_details?.name,
        gstNumber: adminProfileData?.company_details?.gst_no,
        designation: adminProfileData?.company_details?.designation,
        contact: adminProfileData?.company_details?.phone_no,
        email: adminProfileData?.company_details?.email,
        logo: adminProfileData?.company_details?.logo_path,
        address: adminProfileData?.company_details?.address,
        postalCode: adminProfileData?.company_details?.postal_code,
        dealershipId: adminProfileData?.company_details?.dealership_id,
        state: adminProfileData?.company_details?.state_id,
        city: adminProfileData?.company_details?.city_id,
        target_locking_date: adminProfileData?.company_details?.target_locking_date,
      });
      setLogo(adminProfileData?.company_details?.logo_path);
    }
  }, [adminProfileData, form]);

  useEffect(() => {
    getAdminProfileData();
  }, []);

  const handleStateChange = (value) => {
    if (value) {
      getCityData(value);
      form.setFieldsValue({
        city: undefined,
      });
    } else {
      form.setFieldsValue({
        city: undefined,
      });
      setCityData([]);
    }
  };

  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
      if (adminProfileData?.company_details?.state_id) {
        getCityData(adminProfileData?.company_details?.state_id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getCityData = async (id) => {
    setIsLoading(true);
    if (id) {
      try {
        const cityData = await getCityService(id);
        setCityData(cityData?.data?.data);
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAdminProfileData = async () => {
    setIsLoading(true);
    try {
      const adminProfileResponse = await getAdminProfileDetails();
      setAdminProfileData(adminProfileResponse?.data?.data);
      getStateData();
      getCityData(adminProfileResponse?.data?.data?.company_details?.state_id);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(`/admin/profile`);
  };

  const validateFile = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/GIF file");
      return Upload.LIST_IGNORE;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("Image must be smaller than 10MB");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleEdit = async (values) => {
    setIsLoading(true);

    const payload = {
      comp_name: values?.name,
      gst_no: values?.gstNumber,
      comp_address: values?.address,
      comp_email: values?.email,
      postal_code: values?.postalCode,
      dealership_id: values?.dealershipId,
      comp_city_id: values?.city,
      comp_state_id: values?.state,
      logo: values?.logo?.file?.originFileObj,
      target_locking_date: values?.target_locking_date ? values?.target_locking_date : "",
    };
    if (payload?.logo === undefined) {
      delete payload?.logo;
    }
    try {
      const updateProfile = await updateCompanyProfileService(payload);
      if (updateProfile.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: updateProfile.data.message,
        });
        navigate("/admin/profile");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        <Loader loading={isLoading} />
        <PageContentHeader title="Edit Company Details" />

        <div className="personal-detail">
          <Card>
            <div className="profile-image-box mb-3">
              <Image
                width={150}
                height={150}
                src={logo ? logo : "/images/avatar-green-bg.png"}
              />
            </div>
            <div className="detail-wrapper pt-3">
              <Form
                layout="vertical"
                name="company_profile_edit"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                form={form}
                onFinish={handleEdit}
              >
                <div className="login-input-field">
                  <Row gutter={20} justify="start">
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject("Please input your name");
                              }
                              if (!/^[A-Za-z\s]+$/.test(value)) {
                                return Promise.reject(
                                  "Please input a valid name"
                                );
                              }
                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Name cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Name cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Name must be between 3 and 30 characters"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          // maxLength={30}
                          placeholder="Name"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="gstNumber"
                        label="GST Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your GST number",
                          },
                          {
                            pattern:
                              /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                            message:
                              "GST number must be a valid 15 character GSTIN",
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.length !== 15) {
                                return Promise.reject("");
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="GST Number"
                          className="form-control-ant"
                        // maxLength={15}
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="logo"
                        label="Logo"
                        rules={[
                          {
                            required: false,
                            message: "Please upload your a logo",
                          },
                        ]}
                      >
                        <Upload
                          beforeUpload={validateFile}
                          maxCount={1}
                          accept=".jpg,.jpeg,.png,.gif"
                          maxFileSize={10 * 1024 * 1024}
                          customRequest={({ onSuccess }) => {
                            onSuccess("ok");
                          }}
                          onChange={(values) => {
                            return setLogo(
                              URL.createObjectURL(values.file.originFileObj)
                            );
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload Logo
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your address"
                                );
                              }

                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Address cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Address cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 30 || value?.length > 100) {
                                return Promise.reject(
                                  "Address must be between 30 and 100 characters"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input placeholder="Address" />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email id",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email id",
                          },
                          {
                            validator: (_, value) => {
                              if (value && /@-/.test(value)) {
                                return Promise.reject(
                                  "Please enter a valid email address"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="state"
                        label="State"
                        rules={[
                          {
                            required: true,
                            message: "Please input your state",
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="State"
                          className="form-control-ant"
                          onChange={handleStateChange}
                        >
                          {stateData?.map((option) => (
                            <Option key={option.id} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="city"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: "Please input your city",
                          },
                        ]}
                      >
                        <Select
                          placeholder="City"
                          allowClear
                          className="form-control-ant"
                        >
                          {cityData?.map((option) => (
                            <Option key={option.id} value={option.id}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="postalCode"
                        label="Postal Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input your postal Code",
                          },
                          {
                            pattern: /^(?!0)\d{6}$/,
                            message:
                              "Please enter a valid 6 digits postal code",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Postal Code"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="dealershipId"
                        label="Dealership ID"
                        rules={[
                          {
                            required: true,
                            message: "Please input your dealership ID",
                          },
                          {
                            pattern: /^[A-Za-z0-9]{5,17}$/,
                            message:
                              "Dealership ID must be between 5 and 17 alphanumeric characters",
                          },
                        ]}
                      >
                        <Input
                          // maxLength={10}
                          placeholder="Dealership ID"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="target_locking_date"
                        label="Target Locking Date of Every Month"
                        rules={[
                          {
                            required: true,
                            message: "Please select any locking date"
                          }
                        ]}
                      >
                        <Select
                          placeholder="Target Locking Date"
                          allowClear
                          className="form-control-ant"
                        >
                          {lockingDate?.map((option) => (
                            <Option key={option.id} value={option.id}>
                              {option.id}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="user-right d-flex align-items-center justify-content-end">
                  <Button
                    style={{ marginLeft: "20px" }}
                    type="primary"
                    ghost
                    danger
                    className="primary-button me-3"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="secondary-button"
                  // onClick={handleEdit}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Profile;
