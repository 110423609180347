import { useLocation } from 'react-router-dom';
import { handleLogIn } from './slice/authSlice';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/common/Loader';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Input, notification } from 'antd';
import { loginService } from '../../services/auth-service';
import { API_RESPONSE_CODES, roleIds } from '../../utills/constant';
import setAuthToken from '../../axiosConfig';
import { v4 as uuidv4 } from 'uuid';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  // const adminRouteCheck = useSelector((state) => state?.auth?.isAdmin);

  useEffect(() => {
    if (location.pathname.includes('admin')) {
      setIsAdmin(true);
    }
  }, [location]);

  const handleLogin = async (values) => {
    let payload = {
      username: values.email_id || values.mobile,
      password: values.password,
      isAdmin: location.pathname.includes('admin') ? true : false
    };
    try {
      if (localStorage?.getItem('authToken')) {
        window.location.reload();
      } else {
        setIsLoading(true);
        const res = await loginService(payload);
        if (res.data.status === API_RESPONSE_CODES.OK) {
          const token = res?.data?.data?.token;
          localStorage?.setItem('authToken', token);

          const loginUUID = uuidv4();
          localStorage?.setItem('loginUUID', loginUUID);

          setAuthToken();
          let userData = { ...res.data.data };
          dispatch(handleLogIn(userData));
          notification.success({
            message: res.data.message,
          });

          let rollId = res.data.data.role_id;
          switch (rollId) {
            case roleIds?.superAdmin:
              navigate('/admin/dashboard');
              break;
            default:
              navigate('/dashboard');
          }
        }
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="form-wrapper">
        <div className="form-main common-middile-section">
          <div className="form-head text-center">
            <img src={'/toyota-logo.png'} alt="#" className="logo" />
            <h4>Login </h4>
          </div>

          <Form
            layout="vertical"
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleLogin}
          >
            <div className="login-input-field">
              {isAdmin ? (
                <Form.Item
                  name="email_id"
                  label="Email ID"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email id',
                    },
                    {
                      type: 'email',
                      message: 'Please enter a valid email id',
                    },
                    {
                      validator: (_, value) => {
                        if (value && /@-/.test(value)) {
                          return Promise.reject(
                            'Please enter a valid email id'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    // prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email ID"
                    className="form-control-ant"
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="mobile"
                  label="Mobile No."
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Please input your mobile');
                        }
                        if (!/^[0-9]*$/.test(value)) {
                          return Promise.reject(
                            'Mobile number must be digits only '
                          );
                        }
                        if (value?.length < 10 || value?.length > 10) {
                          return Promise.reject(
                            'Please enter a valid 10 digit mobile number'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    // prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Mobile No."
                    type="text" // Change type to "text" to allow entering arbitrary characters
                    className="form-control-ant"
                    inputMode="numeric" // Set input mode to "numeric" to show numeric keyboard on mobile devices
                    pattern="[0-9]*" // Set pattern to only allow numeric input
                  />
                </Form.Item>
              )}
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password',
                  },
                  // {
                  //   max: 20,
                  //   message: 'Password cannot be more than 20 characters.',
                  // },
                  {
                    pattern: /^\S+$/,
                    message: 'Password cannot contain white spaces.',
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  maxLength={20}
                  placeholder="Password"
                  className="password"
                />
              </Form.Item>
              <Form.Item className="login-form-forgot mb-3">
                <Link to="/forgot-password">Forgot password?</Link>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Login
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;

