/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Select, Card, Typography, notification, Form } from 'antd';
import LeadDetails from '../leads/LeadDetails';
import CallLogs from '../leads/CallLogs';
import ExchangeDetails from './Exchange/ExchangeDetails';
import HomeVisit from './HomeVisit';
import Quotation from '../../exchange-evaluation-team/leads/Quotation';
import Retail from '../../exchange-evaluation-team/leads/Retail';
import ActivityLog from '../../exchange-evaluation-team/leads/ActivityLog';
import Bookings from '../../exchange-evaluation-team/leads/Booking/Bookings';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { fetchLeadDetailsServices, handleTabChange } from '../../auth/slice/authSlice';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import {
  getLeadService,
  getLeadStatusService,
  updateLeadStatusService,
  updateLeadTypeService,
} from '../../../services/master-services';
import LeadStatusModal from '../../../components/modals/LeadStatusModal';
import BookingDetails from '../../exchange-evaluation-team/leads/Booking/BookingDetails';
import ExchangeDetailsWithPRocured from './Exchange/ExchangeDetailsWithPRocured';
import ViewQuotation from '../../exchange-evaluation-team/leads/ViewQuotation';
import AddQuotation from '../../exchange-evaluation-team/leads/AddQuotation';

const { Title } = Typography;

const ViewLeads = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [statusType, setStatusType] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editBooking, setEditBooking] = useState(false);
  const [leadsTypeData, setLeadsTypeData] = useState([]);
  const [leadStatusData, setLeadStatusData] = useState([]);
  const [bookingDetailsShow, setBookingDetailsShow] = useState(false);
  const [showQuotation, setShowQuotation] = useState(false);
  const [quotationId, setQuotationId] = useState();
  const [showAddQuot, setShowAddQuot] = useState(false);
  // const [activeTabKey, setActiveTabKey] = useState("leadDetails");
  // const [bookingDetailsShow, setBookingDetailsShow] = useState(false);

  const activeTabKey = useSelector((state) => state?.auth?.activeTab);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const isExchangeEvaluationTeam = roleType === 'Exchange Evaluation Team';
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const isReatailed = leadViewDetails?.lead_stage?.name === 'Retailed';
  const isLeadConverted =
    leadViewDetails?.lead_status?.name === 'Converted' ||
    leadViewDetails?.lead_status?.name === 'Lost' ||
    leadViewDetails?.lead_status?.name === 'Dropped';
  const isCheckedStatusAndRetailData = isLeadConverted && leadViewDetails?.retial === null;
  const isCheckedStatusAndBookingData = isLeadConverted && leadViewDetails?.booking === null;
  const isCheckedStatusAndQuotationData = isLeadConverted && leadViewDetails?.quotations?.length == [];
  const isCheckedStatusAndExchangeData =
    isLeadConverted && leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 0;
  const isCheckedStatusAndHomeVisitAndTestDriveData =
    isLeadConverted && leadViewDetails?.home_visit_test_drive?.length == [];
  const isCheckedStatusAndCallLogsData = isLeadConverted && leadViewDetails?.call_logs?.length == [];
  const isLeadDroppedOrLost =
    leadViewDetails?.lead_status?.name === 'Lost' || leadViewDetails?.lead_status?.name === 'Dropped';
  const isBooked =
    leadViewDetails && leadViewDetails?.booking?.id && leadViewDetails?.booking?.status === 'Booked' ? true : false;

  useEffect(() => {
    // eslint-disable-next-line no-mixed-operators
    if (
      (isLeadConverted && isCheckedStatusAndRetailData) ||
      isCheckedStatusAndBookingData ||
      isCheckedStatusAndQuotationData ||
      isCheckedStatusAndExchangeData ||
      isCheckedStatusAndHomeVisitAndTestDriveData ||
      isCheckedStatusAndCallLogsData
    ) {
      dispatch(handleTabChange('leadDetails'));
    }
  }, [
    isCheckedStatusAndRetailData,
    isCheckedStatusAndBookingData,
    isCheckedStatusAndQuotationData,
    isCheckedStatusAndExchangeData,
    isCheckedStatusAndHomeVisitAndTestDriveData,
    isCheckedStatusAndCallLogsData,
  ]);

  useEffect(() => {
    getLeadsData(id);
    getLeadStatusData();
    getLeadsTypeData();
  }, []);

  useEffect(() => {
    getLeadsData(id);
  }, [showAddQuot, showQuotation]);

  useEffect(() => {
    form.setFieldsValue({
      lead_type: leadViewDetails?.lead_type?.name,
      status: leadViewDetails?.lead_status?.name,
    });
  }, [leadViewDetails]);

  const getLeadsData = async (id) => {
    try {
      setIsLoading(true);
      // const res = await getLeadDetailsService(id);
      // setLeadDetailsData(res?.data?.data);
      dispatch(fetchLeadDetailsServices({ id: id }));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadStatusData = async () => {
    try {
      setIsLoading(true);
      const res = await getLeadStatusService();
      setLeadStatusData(res?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadsTypeData = async () => {
    try {
      setIsLoading(true);
      const res = await getLeadService();
      setLeadsTypeData(res?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = async (res) => {
    if (res === '2' || res === '3') {
      setStatusType(res);
      setShowModal(true);
    } else {
      const payload = {
        lead_status_id: res,
        otherDetails: {},
        reasonDetails: [],
        remarks: '',
      };
      setIsLoading(true);
      try {
        const res = await updateLeadStatusService(id, payload);
        notification.success({
          message: res.data.message,
        });
        getLeadsData(id);
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateLeadTypeData = async (res) => {
    const payload = { lead_type_id: res };
    setIsLoading(true);
    try {
      const res = await updateLeadTypeService(id, payload);
      if (res?.status === API_RESPONSE_CODES?.OK) {
        notification.success({
          message: res.data.message,
        });
        getLeadsData(id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const tabListNoTitle = [
    {
      key: 'leadDetails',
      label: 'Lead Details',
    },
    {
      key: 'callLogs',
      label: 'Call Logs',
      disabled: isCheckedStatusAndCallLogsData,
    },
    {
      key: 'homeVist',
      label: 'Home Visit/Test Drive',
      disabled: isCheckedStatusAndHomeVisitAndTestDriveData,
    },
    {
      key: 'exchange',
      label: 'Exchange',
      disabled: isCheckedStatusAndExchangeData,
    },
    {
      key: 'quotation',
      label: 'Quotation',
      disabled: isCheckedStatusAndQuotationData,
    },
    {
      key: 'bookings',
      label: 'Bookings',
      disabled: isCheckedStatusAndBookingData,
    },
    {
      key: 'retail',
      label: 'Retails',
      disabled: !isBooked || isCheckedStatusAndRetailData,
    },
    {
      key: 'activityLog',
      label: 'Activity Log',
    },
  ];

  const contentListNoTitle = {
    leadDetails: <LeadDetails />,
    callLogs: <CallLogs />,
    homeVist: <HomeVisit />,
    exchange:
      leadViewDetails?.exchange_vehicle_detail?.vehicle_procured === 'No' &&
      leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 ? (
        <ExchangeDetailsWithPRocured />
      ) : (
        <ExchangeDetails />
      ),
    quotation: (() => {
      if (showQuotation) {
        return <ViewQuotation setShowQuotation={setShowQuotation} quotationId={quotationId} />;
      } else if (showAddQuot) {
        return <AddQuotation setShowAddQuot={setShowAddQuot} />;
      } else {
        return (
          <Quotation
            setShowAddQuot={setShowAddQuot}
            setQuotationId={setQuotationId}
            setShowQuotation={setShowQuotation}
          />
        );
      }
    })(),
    bookings:
      (leadViewDetails?.booking?.status === 'Booked' ||
        leadViewDetails?.booking?.status === 'Save' ||
        leadViewDetails?.booking?.status === 'Cancelled') &&
      !editBooking ? (
        <BookingDetails
          setEditBooking={setEditBooking}
          setBookingDetailsShow={setBookingDetailsShow}
          {...(isExchangeEvaluationTeam && { isExchangeEvaluationTeam })}
        />
      ) : (
        <Bookings
          editBooking={editBooking}
          setEditBooking={setEditBooking}
          bookingDetailsShow={bookingDetailsShow}
          setBookingDetailsShow={setBookingDetailsShow}
          {...(isExchangeEvaluationTeam && { isExchangeEvaluationTeam })}
        />
      ),
    retail: <Retail isLeadConverted={isLeadConverted} activeTabKey={activeTabKey} />,
    activityLog: <ActivityLog />,
  };

  const onTabChange = (key) => {
    // setActiveTabKey(key);
    dispatch(handleTabChange(key));
    setShowQuotation(false);
    setShowAddQuot(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='user-data'>
        <PageContentHeader
          title='View Leads'
          headerRight={
            <Title level={5} className='text-end mb-0'>
              Lead Stage: {leadViewDetails?.lead_stage?.name}
            </Title>
          }
        />
        <Form form={form}>
          <Card className='lead-stage'>
            <div className='lead-stage lead-stage-top d-flex justify-content-between align-items-center w-100'>
              <div className='model-name-wrapper d-flex align-items-baseline'>
                <h6 className='model-name'>Lead No:</h6>
                <p className='ps-2'>{leadViewDetails?.id}</p>
              </div>

              <div className='d-flex align-items-baseline'>
                <h6 className='pe-2 car-varient'>Lead Type: </h6>
                <div className='flex-fill'>
                  <Form.Item name='lead_type'>
                    <Select
                      disabled={isExchangeEvaluationTeam || isReatailed || isLeadDroppedOrLost}
                      placeholder='Lead Type'
                      onChange={updateLeadTypeData}
                      className='lead-status-select'
                    >
                      {leadsTypeData?.map((option) => (
                        <Select.Option key={option.id} value={option.slug}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className=' d-flex align-items-baseline'>
                <h6 className='pe-2 car-color'>Status:</h6>
                <div className='flex-fill'>
                  <Form.Item name='status'>
                    <Select
                      placeholder='Status'
                      className='lead-status-select'
                      onChange={handleChangeStatus}
                      disabled={isExchangeEvaluationTeam || isReatailed || isLeadDroppedOrLost}
                    >
                      {leadStatusData?.map((option) => (
                        <Select.Option disabled={option.name === 'Converted'} key={option.id} value={option.slug}>
                          {option.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Card>
        </Form>
        {showModal && (
          <LeadStatusModal
            id={id}
            open={showModal}
            statusType={statusType}
            getLeadsData={getLeadsData}
            setIsLoading={setIsLoading}
            setShowModal={setShowModal}
            leadDetailsData={leadViewDetails}
          />
        )}
        <div className='card-tab mt-4'>
          <Card
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey}
            tabBarExtraContent={<a href='#'>More</a>}
            onTabChange={onTabChange}
            tabProps={{
              size: 'middle',
            }}
          >
            {contentListNoTitle[activeTabKey]}
          </Card>
        </div>
      </div>
    </>
  );
};

export default ViewLeads;
