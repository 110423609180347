/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, Switch, notification } from "antd";
import { transformData } from "../../../../utills/helpers";
import LineChart from "./charts/lineChart";
import moment from "moment";
import { getDashboardTargetData } from "../../../../services/dashboard-services";
import Filters from "./filters/CommonFilters";
import Loader from "../../../../components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { saveDashboardHomeFilters } from "../../../auth/slice/authSlice";
import NoDataFound from "../../../../components/common/NoDataFound";

const DashboardTarget = () => {
  const dispatch = useDispatch();
  const [getDashboardTargets, setDashboardTargets] = useState([]);
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [targetSwitchChecked, settargetSwitchChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId: "",
    modelNo: "",
    startDate: "",
    endDate: "",
    month_id: "",
    year_id: "",
    territory_id: [],
    area_id: "",
    exclude_user_ids: []
  });

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const isLabelNotModel = getDashboardTargets?.exchange_targets_table?.label === "Territory";
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const userId = useSelector((state) => state?.auth?.userData?.id);
  // ||
  // getDashboardTargets?.exchange_targets_table?.label === "Team Leader" ||
  // getDashboardTargets?.exchange_targets_table?.label === "Sales Officer";
  const route = isAdmin ? "/admin" : "";

  useEffect(() => {
    getDashboardTargetDetails(filters);
    dispatch(saveDashboardHomeFilters(filters));
  }, [filters]);

  //Api call to get dashboard home data
  const getDashboardTargetDetails = async () => {
    setIsLoading(true);
    try {
      const reqData = {
        userId: filters?.userId ? filters?.userId : "",
        modelId: filters?.modelNo ? filters?.modelNo : "",
        startDate: filters?.startDate
          ? moment(filters?.startDate).format("YYYY-MM-DD")
          : "",
        endDate: filters?.endDate
          ? moment(filters?.endDate).format("YYYY-MM-DD")
          : "",
        month_id: filters?.month_id,
        year_id: filters?.year_id,
        territory_id: filters?.territory_id,
        area_id: filters?.area_id,
      };
      const response = await getDashboardTargetData(reqData);
      setDashboardTargets(response && response?.data && response?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // EW In House Column Chart Data
  // Transform valuation breakup data
  const valuationBreakupHeaders = [
    "Days",
    "Pending Valuation",
    { role: "annotation" },
  ];

  const pendingValuationLineChartData = transformData(
    getDashboardTargets?.valuation_breakup,
    valuationBreakupHeaders
  );

  // Transform variation breakup data
  const variationBreakupHeaders = [
    "Value",
    "Variation",
    { role: "annotation" },
  ];
  const variationLineChartData = transformData(
    getDashboardTargets?.variation_breakup,

    variationBreakupHeaders
  );

  const toggleSwitch = (checked) => {
    setIsSwitchChecked(checked);
  };
  const toggleSwitchTarget = (checked) => {
    settargetSwitchChecked(checked);
  };

  const handleExcludeIds = (excludeIds) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      exclude_user_ids: excludeIds // Set exclude_user_ids with the new value (row)
    }));
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="px-0">
        <Filters onFiltersChange={setFilters} />
        <div className="user-table-head dash-table-head pb-3 d-flex justify-content-between align-items-center">
          <div className="user-left d-flex align-items-center gap-3">
            <h5>Target Table</h5>

            {getDashboardTargets?.targets_table?.target?.length > 0 && (
              <Switch
                size="large"
                unCheckedChildren="0 %"
                onChange={toggleSwitch}
                checked={isSwitchChecked}
                checkedChildren={`${getDashboardTargets?.targets_table?.overall_achieved_target_percentage?.toFixed(
                  2
                )}%`}
              />
            )}
          </div>
        </div>
        <Row gutter={20}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-4">
            <div className="user-table-data">
              <div className="target-table">
                <div className="custom-table">
                  {getDashboardTargets?.targets_table?.target?.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr className="first-head">
                          <th>{getDashboardTargets?.targets_table?.label}</th>
                          <th
                            colSpan={isSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Enquiry
                          </th>
                          <th
                            colSpan={isSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Home Visit
                          </th>
                          <th
                            colSpan={isSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Test Drive
                          </th>
                          <th
                            colSpan={isSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Booking
                          </th>
                          <th
                            colSpan={isSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Retailed
                          </th>
                          <th className="text-center">Lost</th>
                        </tr>
                        <tr className="second-head">
                          <th></th>
                          <th className="target-head text-center">Target</th>
                          {isSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {isSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {isSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {isSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {isSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="text-center">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getDashboardTargets &&
                          getDashboardTargets?.targets_table?.target?.map(
                            (row, index) => {
                              return (
                                <>
                                  <tr
                                    key={index}
                                    className={
                                      row?.so === "Total" ? "totals-row" : ""
                                    }
                                  >
                                    {/* In this code I remove the targets route and add only target route for the correct redirection from target */}
                                    <td>{row.vechical_name}</td>
                                    <td className="text-center">
                                      {!isNaN(row.enquiry_target_value) &&
                                        +row.enquiry_target_value !== 0 ? (
                                        <>
                                          {roleType === "Sales Officer" ?
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${userId}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.enquiry_target_value}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${row?.vehical_id}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.enquiry_target_value}
                                            </a>
                                          }
                                        </>
                                      ) : (
                                        row.enquiry_target_value
                                      )}
                                    </td>
                                    {isSwitchChecked && (
                                      <td className="text-center">
                                        {row.enquiry_achieved_value ? (
                                          <>
                                            {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?key=hot_lead&territory_id=${row?.vehical_id}&target_redirect=true`
                                                    : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.enquiry_achieved_value}
                                              </a> :
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?key=hot_lead&territory_id=${row?.vehical_id}&target_redirect=true`
                                                    : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.enquiry_achieved_value}
                                              </a>
                                            }
                                          </>
                                        ) : (
                                          row.enquiry_achieved_value
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      {!isNaN(row.home_visit_target_value) &&
                                        +row.home_visit_target_value !== 0 ? (
                                        <>
                                          {roleType === "Sales Officer" ?
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${userId}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.home_visit_target_value}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${row?.vehical_id}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.home_visit_target_value}
                                            </a>
                                          }
                                        </>
                                      ) : (
                                        row.home_visit_target_value
                                      )}
                                    </td>
                                    {isSwitchChecked && (
                                      <td className="text-center">
                                        {row.home_visit_achieved_value ? (
                                          <>
                                            {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?&key=hot_lead&&home_visit_completed="true"&territory_id=${row?.vehical_id}&target_redirect=true&home_visit_completed=true`
                                                    : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&home_visit_completed="true"&target_redirect=true&home_visit_completed=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.home_visit_achieved_value}
                                              </a> :
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?&key=hot_lead&&home_visit_completed="true"&territory_id=${row?.vehical_id}&target_redirect=true&home_visit_completed=true`
                                                    : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&home_visit_completed="true"&target_redirect=true&home_visit_completed=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.home_visit_achieved_value}
                                              </a>
                                            }
                                          </>
                                        ) : (
                                          row.home_visit_achieved_value
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      {!isNaN(row.test_drive_target_value) &&
                                        +row.test_drive_target_value !== 0 ? (
                                        <>
                                          {roleType === "Sales Officer" ?
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${userId}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.test_drive_target_value}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${row?.vehical_id}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.test_drive_target_value}
                                            </a>
                                          }

                                        </>
                                      ) : (
                                        row.test_drive_target_value
                                      )}
                                    </td>
                                    {isSwitchChecked && (
                                      <td className="text-center">
                                        {row.test_drive_achieved_value ? (
                                          <>
                                            {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?key=hot_lead&test_drive_completed="true"&territory_id=${row.vehical_id}&target_redirect=true&test_drive_completed=true`
                                                    : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&test_drive_completed="true"&target_redirect=true&test_drive_completed=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.test_drive_achieved_value}
                                              </a> :
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?key=hot_lead&test_drive_completed="true"&territory_id=${row.vehical_id}&target_redirect=true&test_drive_completed=true`
                                                    : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&test_drive_completed="true"&target_redirect=true&test_drive_completed=true`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.test_drive_achieved_value}
                                              </a>
                                            }
                                          </>
                                        ) : (
                                          row.test_drive_achieved_value
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      {!isNaN(row.booking_target_value) &&
                                        +row.booking_target_value !== 0 ? (
                                        <>
                                          {roleType === "Sales Officer" ?
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${userId}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.booking_target_value}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${row?.vehical_id}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.booking_target_value}
                                            </a>
                                          }

                                        </>
                                      ) : (
                                        row.booking_target_value
                                      )}
                                    </td>
                                    {isSwitchChecked && (
                                      <td className="text-center">
                                        {row.booking_achieved_value ? (
                                          <>
                                            {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&kpi_parameter=booking`
                                                    : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&kpi_parameter=booking`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.booking_achieved_value}
                                              </a> :
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&kpi_parameter=booking`
                                                    : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&kpi_parameter=booking`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.booking_achieved_value}
                                              </a>
                                            }
                                          </>
                                        ) : (
                                          row.booking_achieved_value
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      {!isNaN(row.retail_target_value) &&
                                        +row.retail_target_value !== 0 ? (
                                        <>
                                          {roleType === "Sales Officer" ?
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${userId}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.retail_target_value}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                                  : `${route}/target/${row?.vehical_id}`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.retail_target_value}
                                            </a>
                                          }

                                        </>
                                      ) : (
                                        row.retail_target_value
                                      )}
                                    </td>
                                    {isSwitchChecked && (
                                      <td className="text-center">
                                        {row.retail_achieved_value ? (
                                          <>
                                            {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&lead_stage=3`
                                                    : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&lead_stage=3`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.retail_achieved_value}
                                              </a> :
                                              <a
                                                href={
                                                  isLabelNotModel
                                                    ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&lead_stage=3`
                                                    : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&lead_stage=3`
                                                }
                                                onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                              >
                                                {row.retail_achieved_value}
                                              </a>
                                            }
                                          </>
                                        ) : (
                                          row.retail_achieved_value
                                        )}
                                      </td>
                                    )}
                                    <td className="text-center">
                                      {row.lost_count ? (
                                        <>
                                          {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                            <a
                                              href={`${route}/leads?lead_owned=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`}
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.lost_count}
                                            </a> :
                                            <a
                                              href={
                                                isLabelNotModel
                                                  ? `${route}/leads?territory_id=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`
                                                  : `${route}/leads?model_type=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`
                                              }
                                              onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                            >
                                              {row.lost_count}
                                            </a>
                                          }
                                        </>
                                      ) : (
                                        row.lost_count
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            }
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="mb-4">
            <div className="user-table-data mt-2">
              <div className="user-table-head dash-table-head pb-3 d-flex justify-content-between align-items-center">
                <div className="user-left  d-flex align-items-center gap-3">
                  <h5>Exchange Target Table</h5>

                  {getDashboardTargets?.exchange_targets_table?.exchange
                    ?.length > 0 && (
                      <Switch
                        onChange={toggleSwitchTarget}
                        checked={targetSwitchChecked}
                        checkedChildren={`${getDashboardTargets?.exchange_targets_table?.overall_achieved_target_percentage?.toFixed(
                          2
                        )}%`}
                        unCheckedChildren="0 %"
                        size="large"
                      />
                    )}
                </div>
                <div className="user-right">
                  {/* <Space size="middle">
                    <Button icon={<UploadOutlined />} />
                  </Space> */}
                </div>
              </div>
              <div className="target-table">
                <div className="custom-table">
                  {getDashboardTargets?.exchange_targets_table?.exchange
                    ?.length > 0 ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr className="first-head">
                          <th>
                            {getDashboardTargets?.exchange_targets_table?.label
                              ? getDashboardTargets?.exchange_targets_table
                                ?.label
                              : "-"}
                          </th>
                          <th
                            colSpan={targetSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Exchange
                          </th>
                          <th
                            colSpan={targetSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Valuation
                          </th>
                          <th
                            colSpan={targetSwitchChecked ? 2 : null}
                            className="text-center"
                          >
                            Procured
                          </th>
                          <th className="text-center">Lost</th>
                        </tr>
                        <tr className="second-head">
                          <th></th>
                          <th className="target-head text-center">Target</th>
                          {targetSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {targetSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="target-head text-center">Target</th>
                          {targetSwitchChecked && (
                            <th className="achieved-head text-center">
                              Achieved
                            </th>
                          )}
                          <th className="text-center">Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getDashboardTargets &&
                          getDashboardTargets?.exchange_targets_table?.exchange?.map(
                            (row, index) => (
                              <tr
                                key={index}
                                className={
                                  row?.so === "Total" ? "totals-row" : ""
                                }
                              >
                                <td>{row.vechical_name}</td>
                                <td className="text-center">
                                  {!isNaN(row.exchange_target_value) &&
                                    +row.exchange_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.exchange_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.exchange_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.exchange_target_value
                                  )}
                                </td>
                                {targetSwitchChecked && (
                                  <td className="text-center">
                                    {row.exchange_achieved_value ? (
                                      <>
                                        {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&exchange_car="true"&target_redirect=true`
                                                : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&exchange_car="true"&target_redirect=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.exchange_achieved_value}
                                          </a> :
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&exchange_car="true"&target_redirect=true`
                                                : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&exchange_car="true"&target_redirect=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.exchange_achieved_value}
                                          </a>
                                        }
                                      </>
                                    ) : (
                                      row.exchange_achieved_value
                                    )}
                                  </td>
                                )}
                                <td className="text-center">
                                  {/* {row.valuation_target_value} */}
                                  {!isNaN(row.valuation_target_value) &&
                                    +row.valuation_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.valuation_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.valuation_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.valuation_target_value
                                  )}
                                </td>
                                {targetSwitchChecked && (
                                  <td className="text-center">
                                    {row.valuation_achieved_value ? (
                                      <>
                                        {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&evaluation_completed="true"&target_redirect=true`
                                                : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&evaluation_completed="true"&target_redirect=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.valuation_achieved_value}
                                          </a> :
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&evaluation_completed="true"&target_redirect=true`
                                                : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&evaluation_completed="true"&target_redirect=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.valuation_achieved_value}
                                          </a>
                                        }
                                      </>
                                    ) : (
                                      row.valuation_achieved_value
                                    )}
                                  </td>
                                )}
                                <td className="text-center">
                                  {/* {row.purchase_procured_target_value} */}
                                  {!isNaN(row.purchase_procured_target_value) &&
                                    +row.purchase_procured_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.purchase_procured_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/target/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.purchase_procured_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.purchase_procured_target_value
                                  )}
                                </td>
                                {targetSwitchChecked && (
                                  <td className="text-center">
                                    {row.purchase_procured_achieved_value ? (
                                      <>
                                        {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&procured=true`
                                                : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&procured=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.purchase_procured_achieved_value}
                                          </a> :
                                          <a
                                            href={
                                              isLabelNotModel
                                                ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&procured=true`
                                                : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&procured=true`
                                            }
                                            onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                          >
                                            {row.purchase_procured_achieved_value}
                                          </a>
                                        }
                                      </>
                                    ) : (
                                      row.purchase_procured_achieved_value
                                    )}
                                  </td>
                                )}
                                <td className="text-center">
                                  {row.lost_count ? (
                                    <>
                                      {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                        <a
                                          href={`${route}/leads?lead_owned=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`}
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.lost_count}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`
                                              : `${route}/leads?model_type=${row.vehical_id}&lead_status=3&key=hot_lead&target_redirect=true`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.lost_count}
                                        </a>
                                      }
                                    </>
                                  ) : (
                                    row.lost_count
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mb-4">
            <Card className="">
              <div className="user-table-data">
                <div className="lead-line-chart">
                  <LineChart
                    data={pendingValuationLineChartData}
                    chartColors={["#94959B"]}
                    fullRetailData={
                      getDashboardTargets &&
                      getDashboardTargets?.valuation_breakup
                    }
                    heading={"pending_valuation"}
                    exclude_user_ids={getDashboardTargets?.exclude_user_ids}
                  />
                </div>
              </div>
            </Card>
          </Col>
          <Col xl={12} lg={12} md={12} sm={24} xs={24} className="mb-4">
            <Card className="">
              <div className="user-table-data">
                <div className="lead-line-chart">
                  <LineChart
                    data={variationLineChartData}
                    chartColors={["#DD3A3C"]}
                    heading={"variation"}
                    exclude_user_ids={getDashboardTargets?.exclude_user_ids}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DashboardTarget;
