import axios from 'axios';
import { API_BASE_URL } from '../utills/constant';

export const getDesignationsService = async (edit = false) => {
  return await axios.get(`${API_BASE_URL}/master/get-designations?edit_user=${edit}`);
};

export const getRolesService = async (edit = false) => {
  return await axios.get(`${API_BASE_URL}/master/get-roles?edit_user=${edit}`);
};

export const getTerritoriesService = async (state_id, status) => {
  let stateIds = Array.isArray(state_id) ? state_id : [state_id];
  let stateQueryParams = stateIds.map((id, index) => `state_id[${index}]=${id}`).join('&');

  // Convert the status boolean to a string and append it to the query parameters
  let statusQueryParam = status === true ? 'status=true' : 'status=false';

  return await axios.get(`${API_BASE_URL}/master/get-territories?${stateQueryParams}&${statusQueryParam}`);
};
export const getFilterTerritoriesService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-territories`);
};

export const getFilterAreaService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-areas`);
};

export const getLeadFiltersLeadRange = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-lead-filters-date-range`);
};

export const getTerritories = async (state_id, status) => {
  // Assuming state_id is always provided for simplicity
  const statusQueryParam = `status=${status}`;
  return await axios.get(`${API_BASE_URL}/master/get-territories?state_id[${0}]=${state_id}&${statusQueryParam}`);
};

export const getTargetModels = async (month, year, user_id) => {
  return await axios.get(
    `${API_BASE_URL}/master/get-models?month=${month}&year=${year}&user_id=${user_id}`
  );
};

export const getTargetCalculation = async (payload) => {
  if (payload.changed_param) {
    // If the payload includes 'changed_param', send it as a request body
    return await axios.put(`${API_BASE_URL}/master/calculate-target-values`, payload);
  } else {
    // Otherwise, send it as query parameters
    const { month, year, model_id, enquiry_no, kpi_id } = payload;
    return await axios.put(
      `${API_BASE_URL}/master/calculate-target-values?month=${month}&year=${year}&model_id=${model_id}&enquiry_no=${enquiry_no}&kpi_id=${kpi_id}`
    );
  }
};

export const getManageTargetCalculation = async (payload) => {
  const { month, year, model_id, enquiry_no, kpi_id, delete_model_target, changed_param1, edit_target, user_id, edit_mode } = payload;
  const loginUUID = localStorage.getItem('loginUUID');

  // Construct the query string conditionally based on the presence of delete_model_target
  if (delete_model_target) {
    // Payload with delete_model_target
    return await axios.get(
      `${API_BASE_URL}/master/manage-temp-target-values?model_id=${model_id}&enquiry_no=${enquiry_no}&delete_model_target=1&edit_mode=1&uuid=${loginUUID}`
    );
  } else {
    // Regular payload without delete_model_target
    return await axios.get(
      `${API_BASE_URL}/master/manage-temp-target-values?month=${month}&year=${year}&model_id=${model_id}&enquiry_no=${enquiry_no}&kpi_id=${kpi_id}&changed_param1=${changed_param1}&edit_target=${edit_target}&user_id=${user_id}&edit_mode=${edit_mode}&uuid=${loginUUID}`
    );
  }
};

export const deleteTargetTempData = async () => {
  const loginUUID = localStorage.getItem('loginUUID');
  return await axios.delete(
    `${API_BASE_URL}/master/clear-temp-data?uuid=${loginUUID}`
  );
};

export const deleteManageTargetCalculation = async (payload) => {
  const { model_id, enquiry_no, delete_model_target } = payload;
  return await axios.get(
    `${API_BASE_URL}/master/manage-temp-target-values?model_id=${model_id}&enquiry_no=${enquiry_no}&delete_model_target=${delete_model_target}`
  );
};

export const deleteTargetKpis = async (payload) => {
  return await axios.put(
    `${API_BASE_URL}/master/delete-target-values`, payload
  );
};

export const getTargetUserHierachy = async (user_id, isTargetFlow, isEditTargetFlow, target_assignee, month, year) => {
  const loginUUID = localStorage.getItem('loginUUID');
  // Initialize query parameters array
  let queryParams = [];

  queryParams.push(`user_id=${user_id}`);

  if (isEditTargetFlow) {
    queryParams.push(`isEditTargetFlow=${isEditTargetFlow}`);
  }
  if (target_assignee) {
    queryParams.push(`target_assignee=${target_assignee}`);
  }
  if (month) {
    queryParams.push(`month=${month}`);
  }
  if (year) {
    queryParams.push(`year=${year}`);
  }

  queryParams.push(`isTargetFlow=${isTargetFlow}`);
  const url = `${API_BASE_URL}/master/get-user-hierachy?${queryParams.join("&")}&uuid=${loginUUID}`;
  return await axios.get(url);
};

export const getKPIParameters = async (params = {}) => {
  const url = params.user_id ? `${API_BASE_URL}/master/get-kpi-parameters` : `${API_BASE_URL}/master/get-kpi-parameters`;
  return await axios.get(url, { params });
};


export const getStateService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-states`);
};

export const getCityService = async (state_id) => {
  return await axios.get(`${API_BASE_URL}/master/get-cities?state_id=${state_id}`);
};

export const getAreasService = async (state_id, territory_id, status) => {
  let stateIds = Array.isArray(state_id) ? state_id : [state_id];
  let territoryIds = Array.isArray(territory_id) ? territory_id : [territory_id];
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  const stateyQueryParams = stateIds?.map((id, index) => `state_id[${index}]=${id}`)?.join('&');
  const territoryQueryParams = territoryIds?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/get-areas?${stateyQueryParams}&${territoryQueryParams}&${statusQueryParam}`
  );
};

export const getReportingManagerService = async (id, territory_id) => {
  let territoryIds = Array.isArray(territory_id) ? territory_id : [territory_id];
  const territoryQueryParams = territoryIds?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');
  return await axios.get(`${API_BASE_URL}/master/get-reporting-managers?role_id=${id}&${territoryQueryParams}`);
};

export const getTeamMembersService = async (id, territory_id, area_id) => {
  let areaIds = Array.isArray(area_id) ? area_id : [area_id];
  let territoryIds = Array.isArray(territory_id) ? territory_id : [territory_id];

  const areaQueryParams = areaIds?.map((id, index) => `state_id[${index}]=${id}`)?.join('&');
  const territoryQueryParams = territoryIds?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');

  return await axios.get(`${API_BASE_URL}/master/get-team-members?id=${id}&${territoryQueryParams}&${areaQueryParams}`);
};

export const getRMTransferLeadsService = async (territory_id, area_id) => {
  let areaIds = Array.isArray(area_id) ? area_id : [area_id];
  let territoryIds = Array.isArray(territory_id) ? territory_id : [territory_id];

  const areaQueryParams = areaIds?.map((id, index) => `area_id[${index}]=${id}`)?.join('&');
  const territoryQueryParams = territoryIds?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/get-sales-officer-reporting-manager?${territoryQueryParams}&${areaQueryParams}`
  );
};

export const getSOTransferLeadsService = async (territory_id, area_id, reporting_manager_id) => {
  let territoryIds = Array.isArray(territory_id) ? territory_id : [territory_id];
  let areaIds = Array.isArray(area_id) ? area_id : [area_id];
  let RMIds = Array.isArray(reporting_manager_id) ? reporting_manager_id : [reporting_manager_id];

  const territoryQueryParams = territoryIds?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');
  const areaQueryParams = areaIds?.map((id, index) => `area_id[${index}]=${id}`)?.join('&');
  const RMQueryParams = RMIds?.map((id, index) => `reporting_manager_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/get-sales-officer?${territoryQueryParams}&${areaQueryParams}&${RMQueryParams}`
  );
};

export const transferLeadService = async (data) => {
  return await axios.put(`${API_BASE_URL}/lead/transfer-leads`, data);
};

export const getLeadService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-lead-types`);
};

export const getLeadStatusService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-lead-status`);
};

export const getLeadTramission = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-transmissions`);
};

export const getTramission = async (make_id, model_id, variant_id, exchange, status) => {
  let exchangeQueryParam = exchange === true ? 'exchange=true' : 'exchange=false';
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  return await axios.get(
    `${API_BASE_URL}/master/get-transmissions?make_id=${make_id}&model_id=${model_id}&variant_id=${variant_id}&${exchangeQueryParam}&${statusQueryParam}`
  );
};

export const updateLeadStatusService = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/lead/update-status/${id}`, data);
};

export const updateLeadTypeService = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/lead/update-type/${id}`, data);
};

export const getCustomerProfessionsService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-customer-professions`);
};

export const getCustomersTypeService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-customer-types`);
};

export const getEnquirySourcesService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-enquiry-sources`);
};

export const getEnquirySubSourcesService = async (id) => {
  return await axios.get(`${API_BASE_URL}/master/get-sub-enquiry-sources?parent_id=${id}`);
};

export const getLeadStagesService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-lead-stages`);
};

export const getLeadOwnerService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-users`);
};

export const getBankerService = async (status) => {
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  return await axios.get(`${API_BASE_URL}/master/get-bank-details?${statusQueryParam}`);
};

export const getVehicleMakeService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-makes`);
};

export const getInsuranceTypeService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-insurance-types`);
};

export const getInsuranceCostService = async (data) => {
  const { vehicle_model_id, vehicle_variant_id, fuel_type_id, seater_id, color_id, insurance_type_id } = data;
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-insurance-cost?vehicle_model_id=${vehicle_model_id}&vehicle_variant_id=${vehicle_variant_id}&fuel_type_id=${fuel_type_id}&seater_id=${seater_id}&color_id=${color_id}&insurance_type_id=${insurance_type_id}`,
    data
  );
};

export const getRoadTaxCostService = async (data) => {
  const { vehicle_model_id, vehicle_variant_id, fuel_type_id, seater_id, color_id, road_tax_id } = data;
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-road-tax?vehicle_model_id=${vehicle_model_id}&vehicle_variant_id=${vehicle_variant_id}&fuel_type_id=${fuel_type_id}&seater_id=${seater_id}&color_id=${color_id}&road_tax_id=${road_tax_id}`,
    data
  );
};

export const getRoadTaxTypeService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-road-tax-types`);
};

export const getRtoService = async (state_id) => {
  return await axios.get(`${API_BASE_URL}/master/get-rto-locations?state_id=${state_id}`);
};

export const getVehicleModelService = async (make_id) => {
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-models?make_id=${make_id}`);
};

export const deleteVariantService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/variant/${id}`);
};

export const addVariantService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/variant`, data);
};

export const getVehicleVarientService = async (model_id) => {
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-variants?model_id=${model_id}`);
};

export const getVehicleColorService = async (make_id, model_id, variant_id, status) => {
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-color?make_id=${make_id}&model_id=${model_id}&variant_id=${variant_id}&${statusQueryParam}`
  );
};

export const cancelBooking = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/booking/cancel-booking`, data);
};

export const addTarget = async (data) => {
  return await axios.post(`${API_BASE_URL}/target`, data);
};

export const updateTarget = async (data) => {
  return await axios.put(`${API_BASE_URL}/target/update-target`, data);
};

export const getVehicleFuelTypeService = async (make_id, model_id, variant_id, transmission_id, status, exchange) => {
  let exchangeQueryParam = exchange === true ? 'exchange=true' : 'exchange=false';
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  const transmissionId = transmission_id ? `&transmission_id=${transmission_id}` : '';
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-fuel-types?make_id=${make_id}&model_id=${model_id}&variant_id=${variant_id}${transmissionId}&${statusQueryParam}&${exchangeQueryParam}`
  );
};

export const getVehicleSeaterService = async (make_id, model_id, variant_id, status) => {
  let statusQueryParam = status === true ? 'status=true' : 'status=false';
  return await axios.get(
    `${API_BASE_URL}/master/get-vehicle-seater?make_id=${make_id}&model_id=${model_id}&variant_id=${variant_id}&${statusQueryParam}`
  );
};

export const addHomeVisitDrive = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/home-visit-test-drive`, data);
};
export const generateOTPService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/send-otp`, data);
};

export const verifyOTPService = async (otp, phone_no) => {
  return await axios.get(`${API_BASE_URL}/master/verify-otp?&otp=${otp}&phone_no=${phone_no}`);
};
export const resendOTPService = async (phone_no) => {
  return await axios.get(`${API_BASE_URL}/master/resend-otp?&phone_no=${phone_no}`);
};

export const addCallLogsService = async (data) => {
  return axios.post(`${API_BASE_URL}/lead/call-log`, data);
};

export const updateCallLogsService = async (id, data) => {
  return axios.put(`${API_BASE_URL}/lead/call-log/${id}`, data);
};

export const getAllQuotationDataService = async (id) => {
  return axios.get(`${API_BASE_URL}/lead/${id}`);
};

export const getQuotationMasterDataService = async (model_id, variant_id, fuel_type_id, seater_id, color_id) => {
  return await axios.get(
    `${API_BASE_URL}/master/get-model-details?&model_id=${model_id}&variant_id=${variant_id}&fuel_type_id=${fuel_type_id}&seater_id=${seater_id}&color_id=${color_id}`
  );
};

export const getTermsAndConditionService = async (activity) => {
  return await axios.get(`${API_BASE_URL}/master/get-terms-conditions/${activity}`);
};

export const getQuotationByIdService = async (id) => {
  return await axios.get(`${API_BASE_URL}/lead/quotation/${id}`);
};

export const createQuotationService = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/quotation`, data);
};

export const createRetailDetailsService = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/retails`, data);
};

export const getTargetsDataService = async (user_id, month, year) => {
  return await axios.get(`${API_BASE_URL}/target/${user_id}?month=${month}&year=${year}`);
};

export const getAreaTargetsDataService = async (area_id, user_id, model_id, month, year) => {
  const user_idQueryParams = user_id ? `user_id=${user_id}` : '';
  const model_idQueryParams = model_id ? `&model_id=${model_id}` : '';
  const monthQueryParams = month ? `&month=${month}` : '';
  const yearQueryParams = year ? `&year=${year}` : '';
  return await axios.get(
    `${API_BASE_URL}/target/area/${area_id}?${user_idQueryParams}${model_idQueryParams}${monthQueryParams}${yearQueryParams}`
  );
};

export const getTerritoryTargetsDataService = async (territory_id, user_id, model_id, month, year, exclude_user_ids) => {
  const user_idQueryParams = user_id ? `user_id=${user_id}` : '';
  const model_idQueryParams = model_id ? `&model_id=${model_id}` : '';
  const monthQueryParams = month ? `&month=${month}` : '';
  const yearQueryParams = year ? `&year=${year}` : '';
  const excludeIdQueryParams = exclude_user_ids && exclude_user_ids.length > 0
    ? `&${exclude_user_ids.map((id, index) => `exclude_user_ids[${index}]=${id}`).join('&')}`
    : '';
  return await axios.get(
    `${API_BASE_URL}/target/territory/${territory_id}?${user_idQueryParams}${model_idQueryParams}${monthQueryParams}${yearQueryParams}${excludeIdQueryParams}`
  );
};

export const deleteTargetsDataService = async (delete_target_ids) => {
  return await axios.delete(`${API_BASE_URL}/target/delete-targets`, {
    data: {
      delete_target_ids,
    },
  });
};

export const getKPIParameterService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-kpi-parameters`);
};
export const getTargetMasterService = async (month, year, model) => {
  return await axios.get(
    `${API_BASE_URL}/master/get-kpi-base-target-value?model_id=${model}&month=${month}&year=${year}`
  );
};

export const getMasterVariantListService = async (data) => {
  const { per_page, page, search, make_id, model_id } = data;

  const makeIdQueryParams = make_id?.map((id, index) => `make_id[${index}]=${id}`)?.join('&');
  const modelIdQueryParams = model_id?.map((id, index) => `model_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/variant?per_page=${per_page}&page=${page}&search=${search}&${modelIdQueryParams}&${makeIdQueryParams}`
  );
};

export const getMasterModelListService = async (data) => {
  const { per_page, page, search, make_id } = data;

  const makeIdQueryParams = make_id?.map((id, index) => `make_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/model?per_page=${per_page}&page=${page}&search=${search}&${makeIdQueryParams}`
  );
};

export const deleteModelService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/model/${id}`);
};

export const addModelService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/model`, data);
};

export const addTargetMasterService = async (data) => {
  return await axios.put(`${API_BASE_URL}/master/update-kpi-base-target-value`, data);
};

export const getMasterTransmissionListService = async (data) => {
  const { per_page, page, search } = data;
  return await axios.get(`${API_BASE_URL}/master/transmission?per_page=${per_page}&page=${page}&search=${search}`);
};

export const deleteTransmissionService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/transmission/${id}`);
};

export const addTransmissionService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/transmission`, data);
};

export const getMasterTerritoryListService = async (data) => {
  const { per_page, page, search, state } = data;
  const stateIdQueryParams = state?.map((id, index) => `state_id[${index}]=${id}`)?.join('&');
  return await axios.get(
    `${API_BASE_URL}/master/territory?per_page=${per_page}&page=${page}&search=${search}&${stateIdQueryParams}`
  );
};

export const deleteTerritoryService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/territory/${id}`);
};

export const addTerritoryService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/territory`, data);
};

export const getMasterAreaListService = async (data) => {
  const { per_page, page, search, state_id, territory_id } = data;

  const stateIdQueryParams = state_id?.map((id, index) => `state_id[${index}]=${id}`)?.join('&');
  const territoryIdQueryParams = territory_id?.map((id, index) => `territory_id[${index}]=${id}`)?.join('&');
  return await axios.get(
    `${API_BASE_URL}/master/area?per_page=${per_page}&page=${page}&search=${search}&${stateIdQueryParams}&${territoryIdQueryParams}`
  );
};

export const deleteAreaService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/area/${id}`);
};

export const addAreaService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/area`, data);
};

export const getMasterBankListService = async (data) => {
  const { per_page, page, search, in_house } = data;
  return await axios.get(
    `${API_BASE_URL}/master/bank-details?per_page=${per_page}&page=${page}&search=${search}&in_house=${in_house}`
  );
};

export const deleteBankService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/bank-details/${id}`);
};

export const addBankService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/bank-details`, data);
};

export const getMasterVehicleListService = async (data) => {
  const { per_page, page, search, color_id, seater_id, status, fuel_type_id } = data;

  const colorIdQueryParams = color_id?.map((id, index) => `color_id[${index}]=${id}`)?.join('&');
  const seaterIdQueryParams = seater_id?.map((id, index) => `seater_id[${index}]=${id}`)?.join('&');
  const fuelIdQueryParams = fuel_type_id?.map((id, index) => `fuel_type_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/vehicle-details?per_page=${per_page}&page=${page}&search=${search}&status=${status}&${seaterIdQueryParams}&${colorIdQueryParams}&${fuelIdQueryParams}`
  );
};

export const getFuelMasterService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-fuel-types`);
};

export const getColorMasterService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-colors`);
};

export const updateVehicleStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/vehicle-details/${id}`, data);
};

export const updateExchangeVehicleStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/exchange-vehicle-details/${id}`, data);
};

export const updateUserStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/users/${id}`, data);
};

export const updateModelStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/model/${id}`, data);
};

export const updateAreaStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/area/${id}`, data);
};

export const updateBankStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/bank-details/${id}`, data);
};

export const updateTerritoryStatusService = async (id, data) => {
  return await axios.patch(`${API_BASE_URL}/master/territory/${id}`, data);
};

export const addVehcileService = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/vehicle-details`, data);
};

export const updateVehcileService = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/master/vehicle-details/${id}`, data);
};

export const getFuelTypeMasterService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-fuel-types`);
};

export const getSeaterMasterService = async () => {
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-seaters`);
};

export const deleteVehcileService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/vehicle-details/${id}`);
};

export const getVehcileDetailsMasterService = async (id) => {
  return await axios.get(`${API_BASE_URL}/master/vehicle-details/${id}`);
};

export const getVehicleModelMultipleService = async (make_id) => {
  const makeIdQueryParams = make_id?.map((id, index) => `make_id[${index}]=${id}`)?.join('&');
  return await axios.get(`${API_BASE_URL}/master/get-vehicle-multiple-models?${makeIdQueryParams}`);
};

export async function getExchangeVehicleList(filter) {
  try {
    const response = await axios.get(`${API_BASE_URL}/master/exchange-vehicle-details/`, {
      params: {
        page: filter.page,
        per_page: filter.per_page,
        fuel_type_id: filter.fuel_type_id,
        search: filter?.search,
        status: filter?.status,
        make_id: filter?.filters?.make_id,
        transmission_id: filter?.filters?.transmission_id,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Failed to fetch Launchs');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const getExchangeVehicleList1 = async (data) => {
  const { per_page, page, search, make_id, transmission_id, status, fuel_type_id, color_id, seater_id } = data;

  const makeIdQueryParams = make_id?.map((id, index) => `make_id[${index}]=${id}`)?.join('&');
  const transmissionIdQueryParams = transmission_id?.map((id, index) => `transmission_id[${index}]=${id}`)?.join('&');
  const fuelIdQueryParams = fuel_type_id?.map((id, index) => `fuel_type_id[${index}]=${id}`)?.join('&');
  const colorIdQueryParams = color_id?.map((id, index) => `color_id[${index}]=${id}`)?.join('&');
  const seaterIdQueryParams = seater_id?.map((id, index) => `seater_id[${index}]=${id}`)?.join('&');

  return await axios.get(
    `${API_BASE_URL}/master/exchange-vehicle-details?per_page=${per_page}&page=${page}&search=${search}&status=${status}&${transmissionIdQueryParams}&${makeIdQueryParams}&${fuelIdQueryParams}&${colorIdQueryParams}&${seaterIdQueryParams}`
  );
};

export const addExchangeVehicleData = async (data) => {
  return await axios.post(`${API_BASE_URL}/master/exchange-vehicle-details`, data);
};

export const updateExchangeVehicleData = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/master/exchange-vehicle-details/${id}`, data);
};

export const deleteExchangeVehicleData = async (id) => {
  return await axios.delete(`${API_BASE_URL}/master/exchange-vehicle-details/${id}`);
};

export const getOverAllReports = async (start_date, end_date) => {
  return await axios.get(`${API_BASE_URL}/report/get-overall-report?start_date=${start_date}&end_date=${end_date}`);
};

export const getBookingReports = async (start_date, end_date) => {
  return await axios.get(`${API_BASE_URL}/report/get-booking-report?start_date=${start_date}&end_date=${end_date}`);
};
export const getExchangeReports = async (start_date, end_date) => {
  return await axios.get(`${API_BASE_URL}/report/get-exchange-report?start_date=${start_date}&end_date=${end_date}`);
};
export const getRetailReports = async (start_date, end_date) => {
  return await axios.get(`${API_BASE_URL}/report/get-retail-report?start_date=${start_date}&end_date=${end_date}`);
};

export const getHomeVisitTestDriveReports = async (start_date, end_date) => {
  return await axios.get(
    `${API_BASE_URL}/report/get-home-visit-test-drive-report?start_date=${start_date}&end_date=${end_date}`
  );
};

export const getNotificationCount = async (per_page, page) => {
  return await axios.get(`${API_BASE_URL}/users/get-notifications?per_page=${per_page}&page=${page}`);
};

export const readNotifications = async (data) => {
  return await axios.put(`${API_BASE_URL}/users/mark-as-read-notifications`, data);
};
