import React, { useState } from "react";
import { Modal, Checkbox, Input, Form } from "antd";
import UploadButton from "../../../../components/common/UploadButton";

const reasons = [
  "Financial Issues",
  "Waiting Period Very High",
  "Change Of Mind",
  "Lost Of Co Dealer",
  "Lost Of Competition",
];

const CancellationModal = ({
  visible,
  onOk,
  onCancel,
  onReasonsChange,
  onRemarkChange,
  checkedReasons,
  remark,
}) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({
    cancellation_letter: [],
  });

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        // Call onOk with files
        const files = fileList.cancellation_letter?.map(
          (file) => file.originFileObj
        );
        onOk(files);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={
        <>
          Select Reasons for Cancellation <span className="asterisk">*</span>
        </>
      }
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Submit"
      cancelText="Cancel"
      maskClosable={false}
      closeIcon={false}
    >
      <div className="side-modal">
        <Form layout="vertical" form={form}>
          <Form.Item
            className="form-item-inline"
            name="reasons"
            rules={[
              {
                required: true,
                message: "Please select at least one reason",
              },
            ]}
          >
            <Checkbox.Group
              options={reasons}
              onChange={(values) => {
                onReasonsChange(values);
                form.setFieldsValue({ reasons: values });
              }}
              value={checkedReasons}
              className="d-block"
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span className="asterisk">*</span> Remark
              </>
            }
            name="remark"
            rules={[
              {
                required: true,
                message: "Please input your remark",
              },
              {
                max: 300,
                message: "Remark cannot exceed 300 characters",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              value={remark}
              onChange={(e) => {
                onRemarkChange(e);
                form.setFieldsValue({ remark: e.target.value });
              }}
              placeholder="Enter any additional remarks here..."
            />
          </Form.Item>
          <Form.Item
            name="Cancellation Letter"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please upload cancellation letter",
            //   },
            // ]}
          >
            <UploadButton
              name="cancellation_letter"
              fileList={fileList}
              setFileList={setFileList}
              fileType={["image/jpeg", "image/png"]}
              fileSizeLimit={5}
              label={
                <>
                  Cancellation Letter <span className="asterisk">*</span>
                </>
              }
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CancellationModal;
