/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import {
  deleteVariantService,
  getVehicleMakeService,
  getMasterVariantListService,
  getVehicleModelMultipleService,
} from "../../../services/master-services";
import Loader from "../../../components/common/Loader";
import InputSelect from "../../../components/common/InputSelect";
import { API_RESPONSE_CODES } from "../../../utills/constant";

const Variant = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [listData, setListData] = useState([]);
  const [listCount, setListCount] = useState();
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    page: 1,
    search: "",
    status: [],
    make_id: [],
    model_id: [],
    per_page: 10,
  });

  const isMakeFilterSelected = filters?.make_id?.length > 0;

  useEffect(() => {
    getMakeData();
  }, []);

  useEffect(() => {
    getVariantMasterData(filters);
  }, [filters]);

  const getVariantMasterData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterVariantListService(data);
      setListCount(userList?.data?.data?.total_records);
      setListData(userList?.data?.data?.list_data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleMakeService();
      setMakeData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModelData = async (make_id) => {
    try {
      setIsLoading(true);
      const modalDataArray = await getVehicleModelMultipleService(make_id);
      setModelData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleEdit = (record) => {
  //   // navigate(`/admin/masters/variant/edit/${record.id}`);
  // };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteVariantService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getVariantMasterData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMakeChange = (ele) => {
    if (ele?.length > 0) {
      setFilters((state) => ({ ...state, make_id: ele }));
      getModelData(ele);
    } else {
      setFilters((state) => ({ ...state, make_id: [] }));
      setModelData([]);
    }
    form.setFieldsValue({
      model_id: null,
    });
    // setFilters((state) => ({ ...state, model_id: [] }));
  };

  const handleModelChange = (ele) => {
    if (ele) {
      setFilters((state) => ({ ...state, model_id: ele }));
    } else {
      setFilters((state) => ({ ...state, model_id: [] }));
    }
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  // Tables
  const columns = [
    {
      title: "Make",
      dataIndex: "make",
      key: "make",
    },
    {
      title: "Model",
      dataIndex: "model_name",
      key: "model_name",
    },
    {
      title: "Variant",
      dataIndex: "variant_code",
      key: "variant_code",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Space size="small">
          {/* <Tooltip title="Edit Variant">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
            />
          </Tooltip> */}
          <Popconfirm
            title="Are you sure you want to delete this variant?"
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Variant">
              <Button shape="circle" danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-data">
      <Loader loading={isLoading} />
      <PageContentHeader
        title="Variant"
        headerRight={
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="secondary-button"
              onClick={() => navigate("/admin/masters/variant/add")}
            >
              New Variant
            </Button>
          </>
        }
      />

      {/* Filter Option Card */}
      <Card className="filter-card mb-3">
        <Form
          layout="vertical"
          name="normal_search"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className="login-input-field">
            <Row gutter={[16, 16]}>
              {/* Search Field */}
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="search" label="Search (By Model/Variant)">
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder="Search by model, variant"
                  />
                </Form.Item>
              </Col>

              {/* Make Filter */}
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch={true}
                  name="make_id"
                  label="Make"
                  mode="multiple"
                  maxTagCount={2}
                  options={makeData}
                  placeholder="Make"
                  optionName={"title"}
                  onSelect={handleMakeChange}
                  className="form-control-ant"
                />
              </Col>

              {/* Model Filter */}
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  showSearch={true}
                  allowClear
                  label="Model"
                  name="model_id"
                  mode="multiple"
                  maxTagCount={2}
                  options={modelData}
                  placeholder="Model"
                  optionName={"model_name"}
                  onSelect={handleModelChange}
                  className="form-control-ant"
                  // disabled={!isMakeFilterSelected}
                />
              </Col>

              {/* Status Filter */}
              {/* <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name="status_ids" label="Status">
                  <Select
                    allowClear
                    placeholder="Status"
                    className="form-control-ant"
                    // onChange={handleAreaChange}
                    // disabled={register.territory_id === 0}
                  >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </div>
        </Form>
      </Card>

      <div className="user-table-data">
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: "max-content" }}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount, // Replace totalCount with the total count of your data
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Variant;
