import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { deleteDocuments } from '../../services/admin-services';
import { useParams } from 'react-router-dom';
import { fetchLeadDetailsServices } from '../../app/auth/slice/authSlice';
import { useDispatch, useSelector } from 'react-redux';

const UploadButton = ({ label, name, fileList, setFileList, fileType, fileSizeLimit, documentType, className }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const handleFileChange = (info, fieldName) => {
    setFileList({
      ...fileList,
      [fieldName]: info.fileList,
    });
  };

  const validateFile = (file) => {
    const isAllowedType = fileType.includes(file.type);
    if (!isAllowedType) {
      message.error(`You can only upload ${fileType?.join(', ')} file!`);
      return Upload.LIST_IGNORE;
    }
    const isLtSizeLimit = file.size / 1024 / 1024 < fileSizeLimit;
    if (!isLtSizeLimit) {
      message.error(`File must be smaller than ${fileSizeLimit}MB!`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const handleRemove = async (file) => {
    if (!file.lastModifiedDate) {
      try {
        const documentId = file?.uid;
        const reqData = {
          document_id: documentId ? documentId : null,
          document_type: documentType,
          lead_id: id,
        };

        if (leadViewDetails?.documents) {
          const response = await deleteDocuments(reqData);
          message.success(response?.data?.message || 'File removed successfully');
          setFileList({
            ...fileList,
            [name]: fileList[name]?.filter((item) => item.uid !== file.uid),
          });
          dispatch(fetchLeadDetailsServices({ id: id }));
        }
        // else {
        //   message.error(`Failed to remove file. No document ID available.`);
        // }
      } catch (error) {
        message.error(`Failed to remove file: ${error.message}`);
      }
    }
  };

  return (
    <div
      className={`exchange-upload ${label ? 'd-flex align-items-start justify-content-between' : ''} ${
        className ? className : ''
      }`}
    >
      {label ? <p className='label'>{label}</p> : null}
      <Upload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess('ok');
          }, 0);
        }}
        onChange={(info) => handleFileChange(info, name)}
        beforeUpload={validateFile}
        maxCount={1}
        accept={fileType?.join(',')}
        fileList={fileList[name]}
        showUploadList={{ showRemoveIcon: true }}
        onRemove={leadViewDetails?.documents ? handleRemove : ''}
      >
        <Button icon={<UploadOutlined />}></Button>
      </Upload>
    </div>
  );
};

export default UploadButton;
