/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Col, Card, notification } from "antd";
import ColumnChart from "./charts/columnChart";
import moment from "moment";
import { getDashboardVasRetailedData } from "../../../../services/dashboard-services";
import Loader from "../../../../components/common/Loader";
import Filters from "./filters/CommonFilters";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../../../../components/common/NoDataFound";
import { saveDashboardHomeFilters } from "../../../auth/slice/authSlice";

const DashboardVasRetailed = () => {
  const dispatch = useDispatch();
  const [getVasRetailedData, setVasRetailedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accessoriesPerCarChartData, setAccessoriesPerCarChartData] = useState(
    []
  );
  const [filters, setFilters] = useState({
    userId: "",
    modelNo: "",
    startDate: "",
    endDate: "",
    month_id: "",
    year_id: "",
    territory_id: [],
    exclude_user_ids: []
  });

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const route = isAdmin ? "/admin" : "";
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const userId = useSelector((state) => state?.auth?.userData?.id);
  const isLabelNotModel =
    getVasRetailedData?.retailed_targets_table?.label === "Territory";

  useEffect(() => {
    getDashboardVasBookedDetails(filters);
    dispatch(saveDashboardHomeFilters(filters));
  }, [filters]);

  useEffect(() => {
    // Update chart data when getVasBookedData changes
    if (getVasRetailedData?.accessories_3m_vs_inhouse_ew?.length !== 0) {
      const accessoriesPerCarChartData =
        getVasRetailedData.accessories_3m_vs_inhouse_ew?.map((item) => [
          item.model_name,
          item.tentative_accessories_per_car,
          item.body_care_3m_per_car,
        ]);
      accessoriesPerCarChartData?.unshift([
        "Cars",
        "Accessories per Car",
        "3M Per Car",
      ]);

      const ewInHouseChartData =
        getVasRetailedData.accessories_3m_vs_inhouse_ew?.map((item) => [
          item.model_name,
          parseFloat(item.ew_percentage),
          parseFloat(item.in_house_percentage),
        ]);
      ewInHouseChartData?.unshift(["Cars", "EW %", "In House %"]);

      setAccessoriesPerCarChartData(accessoriesPerCarChartData);
      // setEwInHouseChartData(ewInHouseChartData);
    } else {
      // Handle case where accessories_3m_vs_inhouse_ew is empty or undefined
      setAccessoriesPerCarChartData([]);
      // setEwInHouseChartData([]);
    }
  }, [getVasRetailedData?.accessories_3m_vs_inhouse_ew?.length]);

  //Api call to get dashboard home data
  const getDashboardVasBookedDetails = async () => {
    setIsLoading(true);
    try {
      const reqData = {
        userId: filters?.userId ? filters?.userId : "",
        modelId: filters?.modelNo ? filters?.modelNo : "",
        startDate: filters?.startDate
          ? moment(filters?.startDate).format("YYYY-MM-DD")
          : "",
        endDate: filters?.endDate
          ? moment(filters?.endDate).format("YYYY-MM-DD")
          : "",
        month_id: filters?.month_id,
        year_id: filters?.year_id,
        territory_id: filters?.territory_id,
        area_id: filters?.area_id,
      };
      const response = await getDashboardVasRetailedData(reqData);
      setVasRetailedData(response && response?.data && response?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const accessoriesPerCarColumnChartData =
    getVasRetailedData &&
    getVasRetailedData?.accessories_3m_vs_inhouse_ew?.map((item) => [
      item.model_name,
      item.tentative_accessories_per_car,
      item.body_care_3m_per_car,
    ]);
  accessoriesPerCarColumnChartData?.unshift([
    "Cars",
    "Accessories per Car",
    "3M Per Car",
  ]);

  const ewInHouseColumnChartData =
    getVasRetailedData &&
    getVasRetailedData?.accessories_3m_vs_inhouse_ew?.map((item) => [
      item.model_name,
      parseFloat(item.ew_percentage),
      parseFloat(item.in_house_percentage),
    ]);
  ewInHouseColumnChartData?.unshift(["Cars", "EW %", "In House %"]);

  const handleExcludeIds = (excludeIds) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      exclude_user_ids: excludeIds // Set exclude_user_ids with the new value (row)
    }));
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="px-0">
        <Filters onFiltersChange={setFilters} />

        <div className="user-table-head dash-table-head pb-3 d-flex justify-content-between align-items-center">
          <div className="user-left">
            <h5>VAS Target Table</h5>
          </div>
          {/* <div className="user-right">
            <Space size="middle">
              <Button icon={<UploadOutlined />} />
              <Button icon={<FilterFilled />} />
            </Space>
          </div> */}
        </div>
        <Row gutter={[24, 24]}>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <div className="user-table-data">
              <div className="target-table">
                <div className="custom-table">
                  {getVasRetailedData?.retailed_targets_table?.target?.length >
                    0 ? (
                    <table
                      className="table table-bordered table-hover"
                      cellSpacing={0}
                      cellPadding={0}
                      border={0}
                    >
                      <thead>
                        <tr className="first-head">
                          <th>
                            {getVasRetailedData?.retailed_targets_table?.label
                              ? getVasRetailedData?.retailed_targets_table
                                ?.label
                              : "-"}
                          </th>
                          <th colSpan={2} className="text-center">
                            EW
                          </th>
                          <th colSpan={2} className="text-center">
                            Accessories
                          </th>
                          <th colSpan={2} className="text-center">
                            3M
                          </th>
                          <th colSpan={2} className="text-center">
                            In House Fin.
                          </th>
                        </tr>
                        <tr className="second-head">
                          <th></th>
                          <th className="target-head text-center">Target</th>
                          <th className="achieved-head text-center">
                            Achieved
                          </th>
                          <th className="target-head text-center">Target</th>
                          <th className="achieved-head text-center">
                            Achieved
                          </th>
                          <th className="target-head text-center">Target</th>
                          <th className="achieved-head text-center">
                            Achieved
                          </th>
                          <th className="target-head text-center">Target</th>
                          <th className="achieved-head text-center">
                            Achieved
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {getVasRetailedData &&
                          getVasRetailedData?.retailed_targets_table?.target?.map(
                            (row, index) => (
                              <tr
                                key={index}
                                className={
                                  row.so === "Total" ? "totals-row" : ""
                                }
                              >
                                {/* In this code I remove the targets route and add only target route for the correct redirection from target */}
                                <td>{row.vechical_name}</td>
                                <td className="text-center">
                                  {!isNaN(row.extended_warranty_target_value) &&
                                    +row.extended_warranty_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.extended_warranty_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.extended_warranty_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.extended_warranty_target_value
                                  )}
                                </td>
                                <td className="text-center">
                                  {+row.extended_warranty_achieved_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_ew=ew`
                                              : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_ew=ew`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.extended_warranty_achieved_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_ew=ew`
                                              : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_ew=ew`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.extended_warranty_achieved_value}
                                        </a>
                                      }
                                    </>
                                  ) : (
                                    row.extended_warranty_achieved_value
                                  )}
                                </td>
                                <td className="text-center">
                                  {!isNaN(row.accessories_target_value) &&
                                    +row.accessories_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.accessories_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.accessories_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.accessories_target_value
                                  )}
                                </td>
                                <td className="text-center">
                                  {+row.accessories_achieved_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_accessories=accessories`
                                              : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_accessories=accessories`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.accessories_achieved_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_accessories=accessories`
                                              : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_accessories=accessories`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.accessories_achieved_value}
                                        </a>
                                      }
                                    </>
                                  ) : (
                                    row.accessories_achieved_value
                                  )}
                                </td>
                                <td className="text-center">
                                  {!isNaN(row["3m_body_care_target_value"]) &&
                                    +row["3m_body_care_target_value"] !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row["3m_body_care_target_value"]}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row["3m_body_care_target_value"]}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row["3m_body_care_target_value"]
                                  )}
                                </td>
                                <td className="text-center">
                                  {+row["3m_body_care_achieved_value"] !== 0 ? (
                                    <>
                                      {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_body_care=body_care`
                                              : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_body_care=body_care`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row["3m_body_care_achieved_value"]}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_body_care=body_care`
                                              : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_body_care=body_care`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row["3m_body_care_achieved_value"]}
                                        </a>
                                      }
                                    </>
                                  ) : (
                                    row["3m_body_care_achieved_value"]
                                  )}
                                </td>
                                <td className="text-center">
                                  {!isNaN(row.in_house_target_value) &&
                                    +row.in_house_target_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Officer" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${userId}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.in_house_target_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/dashboard-user/${row?.vehical_id}?territory_name=${row?.vechical_name}`
                                              : `${route}/target/${row?.vehical_id}`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.in_house_target_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.in_house_target_value
                                  )}
                                </td>
                                <td className="text-center">
                                  {row.in_house_achieved_value !== 0 ? (
                                    <>
                                      {roleType === "Sales Manager" || roleType === "Team Leader" ?
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_in_house=inhouse`
                                              : `${route}/leads?lead_owned=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_in_house=inhouse`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.in_house_achieved_value}
                                        </a> :
                                        <a
                                          href={
                                            isLabelNotModel
                                              ? `${route}/leads?territory_id=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_in_house=inhouse`
                                              : `${route}/leads?model_type=${row.vehical_id}&key=hot_lead&target_redirect=true&vas_report_redirect_retail="retail"&kpi_parameter_in_house=inhouse`
                                          }
                                          onClick={() => handleExcludeIds(row?.exclude_user_ids)}
                                        >
                                          {row.in_house_achieved_value}
                                        </a>
                                      }

                                    </>
                                  ) : (
                                    row.in_house_achieved_value
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </Col>

          {/* {accessoriesPerCarChartData?.length !== 0 && ( */}
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Card>
              <div className="user-table-data">
                <div className="lead-bar-chart">
                  <ColumnChart
                    heading={"Vas retailed"}
                    data={accessoriesPerCarColumnChartData}
                    chartColors={["#f0b034", "#fae7bf"]}
                  />
                </div>
              </div>
            </Card>
          </Col>
          {/* )} */}

          {/* {ewInHouseChartData?.length !== 0 && ( */}
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <Card>
              <div className="user-table-data">
                <div className="lead-bar-chart">
                  <ColumnChart
                    heading={"Vas retailed"}
                    data={ewInHouseColumnChartData}
                    chartColors={["#dd5de0", "#f7cdf5"]}
                  />
                </div>
              </div>
            </Card>
          </Col>
          {/* )} */}
        </Row>
      </div>
    </>
  );
};

export default DashboardVasRetailed;
