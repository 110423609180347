/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  Table,
  notification,
  Typography,
  Space,
  Tooltip,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../common/Loader";
import { getAdminProfileDetails } from "../../services/auth-service";
import PageContentHeader from "../common/PageContentHeader";
import { useSelector } from "react-redux";
import { roleIds } from "../../utills/constant";
import CommanPreviewImage from "../common/CommanPreviewImage";
import { AimOutlined } from "@ant-design/icons";
import ProfileTargets from "./ProfileTargets";

const { Title } = Typography;

const AdminProfile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [adminProfileData, setAdminProfileData] = useState();

  const role_id = useSelector((state) => state.auth.userData.role_id);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const isExchangeEvaluationTeam = roleType === "Exchange Evaluation Team";
  const route = useSelector((state) => state.auth.route);
  const permissions = useSelector(
    (state) => state?.auth?.userData?.permissions
  );
  const hasViewTargetPermission = isAdmin
    ? true
    : permissions?.includes("view-target");
  useEffect(() => {
    getAdminProfileData();
  }, []);

  const getAdminProfileData = async () => {
    setIsLoading(true);
    try {
      const adminProfileResponse = await getAdminProfileDetails();
      setAdminProfileData(adminProfileResponse?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewTarget = (record) => {
    navigate(isAdmin ? `/admin/targets/${record.id}` : `/targets/${record.id}`);
  };

  const hasTeamDetails = adminProfileData?.team_details?.length > 0;

  // tables
  const columns = [
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Code",
      dataIndex: "emp_code",
      key: "emp_code",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Reporting Manager",
      key: "reporting_manager",
      dataIndex: "reporting_manager",
    },
    {
      title: "Contact",
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "130px",
      className: "action text-left no-wrap",
      render: (text, record) => (
        <Space size="small">
          {hasViewTargetPermission ? (
            <Tooltip title="View Target">
              <Button
                shape="circle"
                icon={<AimOutlined />}
                onClick={() => handleViewTarget(record)}
              />
            </Tooltip>
          ) : (
            "-"
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="user-data">
        <Loader loading={isLoading} />
        <div className="user-table-head">
          {/* customer details */}
          {role_id === roleIds?.superAdmin && (
            <div className="personal-detail">
              <>
                <PageContentHeader title="Company Details" />

                <Card>
                  <div className="detail-wrapper">
                    <div className="profile-image-box mb-3">
                      <CommanPreviewImage
                        width={150}
                        height={150}
                        src={
                          adminProfileData?.company_details?.logo_path
                            ? adminProfileData?.company_details?.logo_path
                            : "/images/avatar-green-bg.png"
                        }
                      />
                    </div>
                    <div className="view-input-field">
                      <Row gutter={20} justify="start" align="start">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Name:</h6>
                            <p className="ps-2">
                              {adminProfileData?.company_details?.name}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>GST Number:</h6>
                            <p className="ps-2 word-break-break-all">
                              {adminProfileData?.company_details?.gst_no}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Address:</h6>
                            <p className="ps-2">
                              {adminProfileData?.company_details?.address}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Email:</h6>
                            <p className="ps-2 word-break-break-all">
                              {adminProfileData?.company_details?.email}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>State:</h6>
                            <p className="ps-2">
                              {adminProfileData?.company_details?.state}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>City:</h6>
                            <p className="ps-2">
                              {adminProfileData?.company_details?.city}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Postal Code:</h6>
                            <p className="ps-2">
                              {adminProfileData?.company_details?.postal_code}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Dealership ID:</h6>
                            <p className="ps-2 word-break-break-all">
                              {adminProfileData?.company_details?.dealership_id}
                            </p>
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Target Locking Date:</h6>
                            <p className="ps-2 word-break-break-all">
                              {adminProfileData?.company_details?.target_locking_date}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="user-right d-flex align-items-center justify-content-end mt-4">
                    <Link to="/admin/profile/edit">
                      <Button type="primary" className="secondary-button">
                        Edit
                      </Button>
                    </Link>
                  </div>
                </Card>
              </>
            </div>
          )}

          {/* Profile Details */}
          <div className="pt-4">
            <div className="user-left text-start">
              <Title level={4}>Profile Details</Title>
            </div>
            <div className="personal-detail">
              <Card>
                <div className="detail-wrapper">
                  <div className="view-input-field">
                    <Row gutter={20} justify="start">
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="view-fields d-flex align-items-baseline">
                          <h6>Name:</h6>
                          <p className="ps-2">{adminProfileData?.full_name}</p>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="view-fields d-flex align-items-baseline">
                          <h6>Employee Code:</h6>
                          <p className="ps-2 word-break-break-all">
                            {adminProfileData?.emp_code}
                          </p>
                        </div>
                      </Col>

                      {!isAdmin && (
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <div className="view-fields d-flex align-items-baseline">
                            <h6>Designation:</h6>
                            <p className="ps-2">
                              {adminProfileData?.designation}
                            </p>
                          </div>
                        </Col>
                      )}
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="view-fields d-flex align-items-baseline">
                          <h6>Role:</h6>
                          <p className="ps-2">{adminProfileData?.role}</p>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="view-fields d-flex align-items-baseline">
                          <h6>Email:</h6>
                          <p className="ps-2 word-break-break-all">
                            {adminProfileData?.email}
                          </p>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                        <div className="view-fields d-flex align-items-baseline">
                          <h6>Contact Number:</h6>
                          <p className="ps-2">{adminProfileData?.phone_no}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>

                <div className="user-right d-flex align-items-center justify-content-end mt-4">
                  <Link
                    to={
                      role_id === roleIds?.superAdmin
                        ? `${route}/profile/details-edit`
                        : `/profile/edit`
                    }
                  >
                    <Button type="primary" className="secondary-button">
                      Edit
                    </Button>
                  </Link>
                </div>
              </Card>
            </div>
          </div>

          {/* Team Details  */}
          {hasTeamDetails && (
            <div className="user-table-data pt-4">
              <div className="user-left text-start">
                <Title level={4}>Team Details</Title>
              </div>
              <div className="responsive-table-container">
                <Table
                  columns={columns}
                  dataSource={
                    adminProfileData?.team_details ??
                    adminProfileData?.team_details
                  }
                  rowKey="id"
                  pagination={false}
                  scroll={{ x: true }}
                />
              </div>
            </div>
          )}

          {/* Target Details */}
          {!isAdmin && !isExchangeEvaluationTeam && (
            <div className="user-table-data pt-4">
              <div className="user-left text-start">
                <Title level={4}>Target Details</Title>
              </div>
              <div className="responsive-table-container">
                <ProfileTargets
                  profileId={adminProfileData && adminProfileData?.id}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminProfile;
