import React from "react";
import dayjs from "dayjs";
import { Badge, Typography, Tag, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import {
  EyeFilled,
  WhatsAppOutlined,
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { handleTabChange } from "../../app/auth/slice/authSlice";
import { useDispatch } from "react-redux";

const { Title } = Typography;
const ExchangeLeadCard = (exchangeLeadData) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = exchangeLeadData?.exchangeLeadData;
  const isLeadDroppedOrLost =
    details?.lead_status_id === 2 || details?.lead_status_id === 3;

  return (
    <>
      <div
        className="card card-lead h-100 card-cursour"
        onClick={() => {
          dispatch(handleTabChange("leadDetails"));
          navigate(`/leads/view/${details?.id}`);
        }}
      >
        <div className="card-header w-100">
          <div className="card-right flex-fill">
            <div className="card-content px-3">
              <div className="badge d-flex justify-content-between align-items-center mb-2 w-100 px-0">
                <Title level={4} className="mb-0">
                  Lead Type: <span>{details?.lead_type?.name}</span>
                </Title>
                <div className="card-tag d-flex align-items-center ms-auto">
                  <EyeFilled
                    onClick={() => {
                      dispatch(handleTabChange("leadDetails"));
                      navigate(`/leads/view/${details?.id}`);
                    }}
                  />
                  {/* Tooltip for dropped or lost reason */}
                  {isLeadDroppedOrLost ? (
                    <Tooltip
                      title={details?.lead_status_remark_detail?.reason_detail?.map(
                        (ele,index) => (
                          <ul key={index}>
                            <li>{ele}</li>
                          </ul>
                        )
                      )}
                    >
                      <Badge
                        count={details?.lead_status?.name}
                        // color="yellow"
                        className={`lead-status-badge ${details?.lead_status?.name} ps-2`}
                      />
                    </Tooltip>
                  ) : (
                    <Badge
                      count={details?.lead_status?.name}
                      // color="yellow"
                      className={`lead-status-badge ${details?.lead_status?.name} ps-2`}
                    />
                  )}
                </div>
              </div>
              {/* <Title level={4} className='ps-1'>HOT</Title> */}
              <div className="leadno d-flex align-items-baseline flex-wrap">
                <h5 className="card-title">Lead No.:</h5>
                <p className="card-subtitle">{details?.id}</p>
                {details?.exchange_car === "Yes" && (
                  <Tag color="gold" className="lead-card-exchange-tag">
                    Exchange Car
                  </Tag>
                )}
              </div>
              <div className="leadno d-flex align-items-baseline flex-wrap">
                <h5 className="card-title">Lead Stage:</h5>
                <p className="card-subtitle">{details?.lead_stage?.name}</p>
              </div>
              <div className="exchange d-flex align-items-baseline">
                <h5 className="card-title">
                  {details?.vehicle_detail?.model?.model_name}:{" "}
                  {details?.vehicle_detail?.fuel_type?.title}
                  {details?.vehicle_detail?.color?.color
                    ? `: ${details?.vehicle_detail?.color?.color}`
                    : null}
                </h5>
              </div>
              <div className="address text-start">
                <h5 className="card-title">
                  {details?.customer?.full_name}, {details?.customer?.phone_no}
                </h5>
              </div>
              <div className="address text-start">
                <h5 className="card-title">
                  Address:
                  <span className="card-title">
                    {" "}
                    {details?.customer?.address}
                  </span>
                </h5>
              </div>
              <div className="added-on d-flex align-items-baseline">
                <h5 className="card-title">Added On:</h5>
                <p className="card-subtitle">
                  {dayjs(details?.created_at).format("DD-MM-YYYY")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center d-md-block">
                <div className="follow-up d-flex align-items-baseline">
                  <h5 className="card-title">Next Follow Up:</h5>
                  <p className="card-subtitle">
                    {dayjs(details?.next_follow_up_date).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="social-icons d-flex align-items-center justify-content-end">
                  <WhatsAppOutlined className="pe-2" />
                  <PhoneOutlined className="pe-2" />
                  <MailOutlined className="pe-2" />
                  <MessageOutlined className="pe-2" />
                  {details?.exchange_vehicle_detail?.is_evaluated === 1 ? (
                    ""
                  ) : (
                    <Badge count="Evaluation" color="" className="" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangeLeadCard;
