import axios from "axios";
import { API_BASE_URL } from "../utills/constant";

export const getAdminUserListingService = async (data) => {
  const { per_page, page, search, designation, territory, area, state } = data;

  const stateQueryParams = state
    ?.map((id, index) => `&state[${index}]=${id}`)
    ?.join("");
  const designationQueryParams = designation
    ?.map((id, index) => `&designation[${index}]=${id}`)
    ?.join("");
  const territoryQueryParams = territory
    ?.map((id, index) => `&territory[${index}]=${id}`)
    ?.join("");
  const areaQueryParams = area
    ?.map((id, index) => `&area[${index}]=${id}`)
    ?.join("");
  const searchParam = search ? `&search=${search}` : ``;

  return await axios.get(
    `${API_BASE_URL}/users?page=${page}&per_page=${per_page}${searchParam}${designationQueryParams}${stateQueryParams}${territoryQueryParams}${areaQueryParams}`
  );
};

export const rolesPermissionsService = async (data) => {
  const { per_page, page, search } = data;
  return await axios.get(
    `${API_BASE_URL}/roles?per_page=${per_page}&page=${page}&search=${search}`
  );
};
// export const getAdminUserListingService = async (data) => {
//   const { per_page, page, search, designation, territory, area } = data;
//   return await axios.get(
//     `${API_BASE_URL}/users?per_page=${per_page}&page=${page}&search=${search}&designation=[${designation}]&territory=${territory}&area=${area}`
//   );
// };
export const getAdminUserDetailsService = async (id) => {
  return await axios.get(`${API_BASE_URL}/users/${id}`);
};
export const deleteAdminUserService = async (id) => {
  return await axios.delete(`${API_BASE_URL}/users/${id}`);
};

export const addNewUserService = async (data) => {
  return await axios.post(`${API_BASE_URL}/users`, data);
};

export const addNewLeadService = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead`, data);
};

export const editLeadService = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/update-lead`, data);
};

export const AddLeadExchangeDetails = async (data) => {
  return await axios.post(
    `${API_BASE_URL}/lead/update-lead-exchange-details`,
    data
  );
};

export const AddExchangeDetails = async (data) => {
  return await axios.post(
    `${API_BASE_URL}/lead/evaluation-vehicle-details`,
    data
  );
};

// export const deleteDocuments = async (data) => {
//   return await axios.delete(`${API_BASE_URL}/lead/delete-document`, data);
// };
export const deleteDocuments = async (data) => {
  return await axios.delete(`${API_BASE_URL}/lead/delete-document`, {
    data: data,
  });
};

export const AddBooking = async (data) => {
  return await axios.post(`${API_BASE_URL}/lead/booking`, data);
};

export const updateAdminUserService = async (id, data) => {
  return await axios.put(`${API_BASE_URL}/users/${id}`, data);
};
export const updatePermissionsService = async (data) => {
  return await axios.put(`${API_BASE_URL}/roles/update-permission`, data);
};

export const getLeadsListingService = async (data) => {
  const {
    per_page,
    page,
    search,
    lead_status,
    lead_type,
    lead_stage,
    model_type,
    fuel_type,
    rto_locations,
    lead_sources,
    variant_type,
    state_id,
    territory_id,
    area_id,
    lead_owned,
    follow_up_date_id,
    graph_metrics,
    date_range,
    start_date,
    end_date,
    test_drive_completed,
    home_visit_completed,
    kpi_parameter,
    procured,
    vas_report_redirect,
    exchange_car,
    evaluation_completed,
    greater_than_one_day,
    greater_than_ten_day,
    greater_than_twenty_day,
    last_month,
    dashboard_redirect,
    live_lead,
    exclude_user_ids,
    target_redirect,
    month,
    year,
  } = data;

  const leadStatusQueryParams = lead_status
    ?.map((id, index) => `&lead_status[${index}]=${id}`)
    ?.join("");
  const leadTypeQueryParams = lead_type
    ?.map((id, index) => `&lead_type[${index}]=${id}`)
    ?.join("");
  const leadStageQueryParams = lead_stage
    ?.map((id, index) => `&lead_stage[${index}]=${id}`)
    ?.join("");
  const fuelTypeQueryParams = fuel_type
    ?.map((id, index) => `&fuel_type[${index}]=${id}`)
    ?.join("");
  const modalTypeQueryParams = model_type
    ?.map((id, index) => `&model_type[${index}]=${id}`)
    ?.join("");
  const rtoLocationsQueryParams = rto_locations
    ?.map((id, index) => `&rto_locations[${index}]=${id}`)
    ?.join("");
  const leadSourcesQueryParams = lead_sources
    ?.map((id, index) => `&lead_sources[${index}]=${id}`)
    ?.join("");
  const variantQueryParams = variant_type
    ?.map((id, index) => `&variant_type[${index}]=${id}`)
    ?.join("");
  let stateIds = Array.isArray(state_id) ? state_id : [state_id];
  const stateQueryParams = stateIds
    ?.map((id, index) => `&state_id[${index}]=${id}`)
    ?.join("");
  const territoryQueryParams = territory_id
    ?.map((id, index) => `&territory_id[${index}]=${id}`)
    ?.join("");
  const areaQueryParams = area_id
    ?.map((id, index) => `&area_id[${index}]=${id}`)
    ?.join("");
  const leadOwnedQueryParams = lead_owned
    ?.map((id, index) => `&lead_owned[${index}]=${id}`)
    ?.join("");

  const followUpDataByQueryParams = follow_up_date_id
    ?.map((id, index) => `&follow_up_date_id[${index}]=${id}`)
    ?.join("");
  const testDriveCompletedParam = test_drive_completed
    ? `&test_drive_completed=${test_drive_completed}`
    : "";
  const homeVisitCompletedParam = home_visit_completed
    ? `&home_visit_completed=${home_visit_completed}`
    : "";
  const kpiParameterQueryParam = kpi_parameter
    ? `&kpi_parameter=${kpi_parameter}`
    : "";
  const procuredQueryParam = procured
    ? `&procured=${procured}`
    : "";
  const vasReportRedirectQueryParam = vas_report_redirect
    ? `&vas_report_redirect=${vas_report_redirect}`
    : "";
  const exchangeCarParam = exchange_car ? `&exchange_car=${exchange_car}` : "";
  const evaluationCompletedParam = evaluation_completed
    ? `&evaluation_completed=${evaluation_completed}`
    : "";
  const graphMetrics = graph_metrics ? `&graph_metrics=${graph_metrics}` : "";
  const dateRangeParam = date_range ? `&date_range=${date_range}` : "";
  const startDateParam = start_date ? `&start_date=${start_date}` : "";
  const endDateParam = end_date ? `&end_date=${end_date}` : "";
  const greaterThanOneDayParam = greater_than_one_day
    ? `&greater_than_one_day=${greater_than_one_day}`
    : "";
  const greaterThanTwentyDayParam = greater_than_twenty_day
    ? `&greater_than_twenty_day=${greater_than_twenty_day}`
    : "";
  const greaterThanTenDayParam = greater_than_ten_day
    ? `&greater_than_ten_day=${greater_than_ten_day}`
    : "";
  const lastMonthParam = last_month ? `&last_month=${last_month}` : "";
  const dashboardRedirectParam = dashboard_redirect
    ? `&dashboard_redirect=${dashboard_redirect}`
    : "";
  const searchParam = search ? `&search=${search}` : "";
  const liveLeadParam = live_lead ? `&live_lead=${live_lead}` : "";
  const excludeIdQueryParams = exclude_user_ids
    ?.map((id, index) => `&exclude_user_ids[${index}]=${id}`)
    ?.join("");
  const targetRedirectQueryParams = target_redirect ? `&target_redirect=true` : "";
  const monthQueryParams = month ? `&month=${month}` : "";
  const yearQueryParams = year ? `&year=${year}` : ""
  // return await axios.get(
  //   `${API_BASE_URL}/lead?per_page=${per_page}&page=${page}${searchParam}${leadTypeQueryParams}${leadStatusQueryParams}${leadStageQueryParams}${modalTypeQueryParams}${fuelTypeQueryParams}${rtoLocationsQueryParams}${leadSourcesQueryParams}${variantQueryParams}${stateQueryParams}${territoryQueryParams}${areaQueryParams}${leadOwnedQueryParams}${followUpDataByQueryParams}${graphMetrics}${dateRangeParam}${startDateParam}${endDateParam}${testDriveCompletedParam}${homeVisitCompletedParam}${exchangeCarParam}${evaluationCompletedParam}${greaterThanOneDayParam}${greaterThanTenDayParam}${greaterThanTwentyDayParam}${lastMonthParam}${dashboardRedirectParam}${liveLeadParam}`
  // );

  const queryParams = `${searchParam}${leadTypeQueryParams}${leadStatusQueryParams}${leadStageQueryParams}${modalTypeQueryParams}${fuelTypeQueryParams}${rtoLocationsQueryParams}${leadSourcesQueryParams}${variantQueryParams}${stateQueryParams}${territoryQueryParams}${areaQueryParams}${leadOwnedQueryParams}${followUpDataByQueryParams}${graphMetrics}${dateRangeParam}${startDateParam}${endDateParam}${testDriveCompletedParam}${homeVisitCompletedParam}${kpiParameterQueryParam}${procuredQueryParam}${vasReportRedirectQueryParam}${exchangeCarParam}${evaluationCompletedParam}${greaterThanOneDayParam}${greaterThanTenDayParam}${greaterThanTwentyDayParam}${lastMonthParam}${dashboardRedirectParam}${liveLeadParam}${excludeIdQueryParams ? excludeIdQueryParams : ""
    }${targetRedirectQueryParams}${monthQueryParams}${yearQueryParams}`;

  return await axios.get(
    `${API_BASE_URL}/lead?per_page=${per_page}&page=${page}${queryParams}`
  );
};
export const getExchangeLeadsList = async (data) => {
  const {
    per_page,
    page,
    search,
    model_type,
    start_date,
    end_date,
    lead_type = [],
    lead_stage = [],
    territory_id = [],
    area_id = [],
    ...filters
  } = data;

  const query = new URLSearchParams({
    per_page,
    page,
    search,
  });

  // Append lead_type and lead_stage as arrays
  lead_type.forEach((type, index) => query.append(`lead_type[${index}]`, type));
  lead_stage.forEach((stage, index) => query.append(`lead_stage[${index}]`, stage));
  territory_id.forEach((territory, index) => query.append(`territory_id[${index}]`, territory));
  area_id.forEach((area, index) => query.append(`area_id[${index}]`, area));

  // Append model_type, start_date, and end_date directly if they exist
  if (model_type.length) {
    model_type.forEach((type, index) =>
      query.append(`model_type[${index}]`, type)
    );
  }

  if (start_date) {
    query.append("start_date", start_date);
  }

  if (end_date) {
    query.append("end_date", end_date);
  }

  // Append other filters if truthy
  Object.entries(filters)?.forEach(([key, value]) => {
    if (value) query.append(key, value); // Changed to append the actual value instead of true
  });

  return await axios.get(`${API_BASE_URL}/lead?${query.toString()}`);
};

// export const getExchangeLeadsList = async (data) => {
//   const {
//     per_page,
//     page,
//     search,
//     // model_type,
//     // start_date,
//     // end_date,
//     lead_type = [],
//     lead_stage = [],
//     ...filters
//   } = data;

//   const query = new URLSearchParams({
//     per_page,
//     page,
//     search,
//   });

//   // Append lead_type and lead_stage as arrays
//   lead_type.forEach((type, index) => query.append(`lead_type[${index}]`, type));
//   lead_stage.forEach((stage, index) =>
//     query.append(`lead_stage[${index}]`, stage)
//   );

//   // Append only the truthy filters
//   Object.entries(filters)?.forEach(([key, value]) => {
//     if (value) query.append(key, true);
//   });

//   return await axios.get(`${API_BASE_URL}/lead?${query.toString()}`);
// };

export const getLeadDetailsService = async (id) => {
  return await axios.get(`${API_BASE_URL}/lead/${id}`);
};

export const getEchangeVehicleDetails = async (id) => {
  return await axios.get(
    `${API_BASE_URL}/master/exchange-vehicle-details/${id}`
  );
};

export const getExistingCustomerService = async (phone_no) => {
  return await axios.get(
    `${API_BASE_URL}/lead/check-existing-customer?phone_no=${phone_no}`
  );
};
