import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getEchangeVehicleDetails,
  getExchangeLeadsList,
  getLeadDetailsService,
} from '../../../services/admin-services';

import {
  getAreasService,
  getColorMasterService,
  getExchangeVehicleList,
  getFuelMasterService,
  getKPIParameters,
  getLeadTramission,
  getManageTargetCalculation,
  getNotificationCount,
  getSeaterMasterService,
  getStateService,
  getTargetCalculation,
  getTargetUserHierachy,
  getTargetsDataService,
  getTerritories,
  getVehicleMakeService,
} from '../../../services/master-services';
import { removeEmptyKeys } from '../../../utills/helpers';
import { getDashboardHomeData } from '../../../services/dashboard-services';

const initialState = {
  userCredentials: {
    username: '',
    password: '',
    roleId: 0,
    role: '',
  },
  userData: {},
  leadDetails: [],
  exchangeMasterDetails: [],
  dashboardDetails: [],
  stateData: [],
  transmissionData: [],
  fuelData: [],
  seaterData: [],
  makeData: [],
  colorData: [],
  exchangeLeads: [],
  exchangeVechicles: [],
  territoriesData: [],
  areasData: [],
  userHierarchyData: [],
  kpiData: [],
  notificationData: [],
  targetData: [],
  calculatedData: [],
  isAuthenticated: false,
  isAdmin: false,
  route: '',
  activeTab: 'leadDetails',
  editBooking: false,
  dashBoardRedirection: {},
};

export const fetchLeadDetailsServices = createAsyncThunk('leads/fetchLeadDetailsServices', async (payload) => {
  const leadDetailsServices = await getLeadDetailsService(payload?.id);
  return leadDetailsServices;
});

export const fetchExchangeVehicleDetails = createAsyncThunk('leads/fetchExchangeVehicleDetails', async (payload) => {
  const exchangeDetails = await getEchangeVehicleDetails(payload?.id);
  return exchangeDetails;
});

export const fetchAllState = createAsyncThunk('leads/fetchAllState', async () => {
  const stateData = await getStateService();
  return stateData;
});

export const fetchLeadsTransmission = createAsyncThunk('leads/fetchLeadsTransmission', async () => {
  const transmission = await getLeadTramission();
  return transmission;
});

export const fetchMasterFuel = createAsyncThunk('leads/fetchMasterFuel', async () => {
  const fuel = await getFuelMasterService();
  return fuel;
});

export const fetchMasterSeater = createAsyncThunk('leads/fetchMasterSeater', async () => {
  const seater = await getSeaterMasterService();
  return seater;
});

export const fetchMasterMake = createAsyncThunk('leads/fetchMasterMake', async () => {
  const make = await getVehicleMakeService();
  return make;
});

export const fetchMasterColor = createAsyncThunk('leads/fetchMasterColor', async () => {
  const color = await getColorMasterService();
  return color;
});

export const fetchExchangeVehicleList = createAsyncThunk('leads/fetchExchangeVehicleList', async (payload) => {
  const queryString = removeEmptyKeys(payload);
  const exchangeData = await getExchangeVehicleList(queryString);
  return exchangeData;
});

export const fetchExchangeLeadList = createAsyncThunk('leads/fetchExchangeLeadList', async (data) => {
  const leads = await getExchangeLeadsList(data);
  return leads;
});

export const fetchDashboardHomeData = createAsyncThunk('leads/fetchDashboardHomeData', async (data) => {
  const dashboard = await getDashboardHomeData(data);
  return dashboard;
});

export const fetchTargetsData = createAsyncThunk('leads/fetchTargetsData', async ({ user_id, month, year }) => {
  const response = await getTargetsDataService(user_id, month, year);
  return response;
});

export const fetchTargetCalculation = createAsyncThunk(
  'leads/fetchTargetCalculation',
  async (payload) => {
    const response = await getTargetCalculation(payload);
    return response; // Assuming you only need the data from the response
  }
);

export const fetchManagerTargetCalculation = createAsyncThunk(
  'leads/fetchManagerTargetCalculation',
  async (payload) => {
    const response = await getManageTargetCalculation(payload);
    return response;
  }
);

export const fetchTerritories = createAsyncThunk('leads/fetchTerritories', async ({ id, status }) => {
  // Destructure the object passed as the argument
  const territories = await getTerritories(id, status);
  return territories; // Assuming you want to return the data part of the response
});

export const fetchAreasDetails = createAsyncThunk(
  'leads/fetchAreasDetails',
  async ({ stateIDs, territoryIDs, status }, { rejectWithValue }) => {
    try {
      const areas = await getAreasService(stateIDs, territoryIDs, status);
      return areas.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTargetUserHierachy = createAsyncThunk('leads/fetchTargetUserHierachy', async ({ id, isTargetFlow, isEditTargetFlow, target_assignee, month, year }) => {
  const UserData = await getTargetUserHierachy(id, isTargetFlow, isEditTargetFlow, target_assignee, month, year);
  return UserData;
});

// export const fetchKpiParameters = createAsyncThunk('leads/fetchKpiParameters', async () => {
//   const KpiData = await getKPIParameters();
//   return KpiData;
// });

export const fetchKpiParameters = createAsyncThunk('leads/fetchKpiParameters', async (params = {}) => {
  const KpiData = await getKPIParameters(params);
  return KpiData;
});


export const fetchNotifications = createAsyncThunk('leads/fetchNotifications', async ({ per_page, page }) => {
  const notifications = await getNotificationCount(per_page, page);
  return notifications;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogIn: (state, action) => {
      state.userData = action.payload;
      state.isAuthenticated = true;
      state.isAdmin = action.payload.role === 'Super Admin' ? true : false;
      state.route = action.payload.role === 'Super Admin' ? '/admin' : '/dashboard';
    },
    setLoginCredentials: (state, action) => {
      state.email = action.payload.email;
      state.mobile = action.payload.mobile;
      state.password = action.payload.password;
    },
    setDashBoardRedirection: (state, action) => {
      state.dashBoardRedirection = {
        userId: action.payload.userId,
        modelNo: action.payload.modelNo,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    },
    handleLogout: (state) => {
      state.userData = {};
      state.isAuthenticated = false;
      localStorage.clear();
    },
    firstLoginFalse: (state) => {
      state.userData.first_login = false;
    },
    handleTabChange: (state, action) => {
      state.activeTab = action.payload;
    },
    handleEditBooking: (state) => {
      state.editBooking = !state.editBooking;
    },
    saveDashboardHomeFilters: (state, action) => {
      state.dashboardHomeFilters = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(fetchLeadDetailsServices.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchLeadDetailsServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.leadDetails = action.payload.data.data;
      })
      .addCase(fetchLeadDetailsServices.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchExchangeVehicleDetails.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchExchangeVehicleDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.exchangeMasterDetails = action.payload.data.data;
      })
      .addCase(fetchExchangeVehicleDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAllState.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchAllState.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.allState = action.payload.data;
      })
      .addCase(fetchAllState.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchLeadsTransmission.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchLeadsTransmission.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.transmissionData = action.payload.data;
      })
      .addCase(fetchLeadsTransmission.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchMasterFuel.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchMasterFuel.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.fuelData = action.payload.data;
      })
      .addCase(fetchMasterFuel.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchMasterSeater.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchMasterSeater.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.seaterData = action.payload.data;
      })
      .addCase(fetchMasterSeater.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchMasterMake.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchMasterMake.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.makeData = action.payload.data;
      })
      .addCase(fetchMasterMake.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchMasterColor.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchMasterColor.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.colorData = action.payload.data;
      })
      .addCase(fetchMasterColor.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchExchangeLeadList.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchExchangeLeadList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.exchangeLeads = action.payload.data;
      })
      .addCase(fetchExchangeLeadList.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchDashboardHomeData.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchDashboardHomeData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.dashboardDetails = action.payload.data;
      })
      .addCase(fetchDashboardHomeData.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchExchangeVehicleList.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchExchangeVehicleList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.exchangeVechicles = action.payload.data;
      })
      .addCase(fetchExchangeVehicleList.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchTerritories.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchTerritories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.territoriesData = action.payload.data.data;
      })
      .addCase(fetchTerritories.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchAreasDetails.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchAreasDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.areasData = action.payload.data;
      })
      .addCase(fetchAreasDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchTargetUserHierachy.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchTargetUserHierachy.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.userHierarchyData = action.payload.data.data;
      })
      .addCase(fetchTargetUserHierachy.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchKpiParameters.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchKpiParameters.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.kpiData = action.payload.data.data;
      })
      .addCase(fetchKpiParameters.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchNotifications.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.notificationData = action.payload.data.data;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchTargetsData.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchTargetsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.targetData = action.payload.data.data;
      })
      .addCase(fetchTargetsData.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      })

      .addCase(fetchManagerTargetCalculation.pending, (state) => {
        state.status = 'loading';
        state.listLoading = true;
      })
      .addCase(fetchManagerTargetCalculation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.listLoading = false;
        state.calculatedData = action?.payload?.data?.data;
      })
      .addCase(fetchManagerTargetCalculation.rejected, (state, action) => {
        state.status = 'failed';
        state.listLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  handleLogIn,
  handleLogout,
  handleTabChange,
  firstLoginFalse,
  setLoginCredentials,
  handleEditBooking,
  setDashBoardRedirection,
  saveDashboardHomeFilters,
} = authSlice.actions;
export default authSlice.reducer;
