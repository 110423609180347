/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { Button, Card, Col, Form, Row, Space, Switch, Table, Select, Tooltip, Popconfirm, notification } from 'antd';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import Loader from '../../../components/common/Loader';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import InputSelect from '../../../components/common/InputSelect';
import {
  deleteExchangeVehicleData,
  getColorMasterService,
  getExchangeVehicleList1,
  getSeaterMasterService,
  updateExchangeVehicleStatusService,
} from '../../../services/master-services';
import { fetchLeadsTransmission, fetchMasterFuel, fetchMasterMake } from '../../auth/slice/authSlice';

const ExchangeModel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [listData, setListData] = useState();
  const [listCount, setListCount] = useState();
  const [colorData, setColorData] = useState([]);
  const [seaterData, setSeaterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fuelData = useSelector((state) => state?.auth?.fuelData?.data);
  const makeData = useSelector((state) => state?.auth?.makeData?.data);
  const transmissionData = useSelector((state) => state?.auth?.transmissionData?.data);
  // const exchangeVehicleDataList = useSelector((state) => state?.auth?.exchangeVechicles?.list_data);

  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: '',
    status: '',
    make_id: [],
    color_id: [],
    seater_id: [],
    fuel_type_id: [],
    transmission_id: [],
  });

  useEffect(() => {
    handleFuel();
    handleMake();
    getColorData();
    getSeaterData();
    handleTransmission();
  }, []);

  useEffect(() => {
    getExchangeVehicleList(filters);
  }, [filters]);

  const handleEdit = (record) => {
    navigate(`/admin/masters/exchange-model/edit/${record.id}`);
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  const handleFuel = () => {
    try {
      dispatch(fetchMasterFuel());
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      // setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleTransmission = () => {
    try {
      dispatch(fetchLeadsTransmission());
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      // setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  };

  const handleMake = () => {
    try {
      dispatch(fetchMasterMake());
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      // setIsLoading(false);
    } finally {
      // setIsLoading(false);
    }
  };

  // Get color master data
  const getColorData = async () => {
    try {
      setIsLoading(true);
      const fuelData = await getColorMasterService();
      if (fuelData.data.status === API_RESPONSE_CODES.OK) {
        setColorData(fuelData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Get seater master data
  const getSeaterData = async () => {
    try {
      setIsLoading(true);
      const seaterData = await getSeaterMasterService();
      if (seaterData.data.status === API_RESPONSE_CODES.OK) {
        setSeaterData(seaterData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusFilterChange = (value) => {
    if (value) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        status: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        status: '',
      }));
    }
  };

  const handleSeaterChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        seater_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        seater_id: [],
      }));
    }
  };

  const handleColorChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        color_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        color_id: [],
      }));
    }
  };

  const getExchangeVehicleList = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getExchangeVehicleList1(data);
      if (userList?.status === API_RESPONSE_CODES.OK) {
        setListCount(userList?.data?.data?.total_records);
        setListData(userList?.data?.data?.list_data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Handler for changing the status
  const handleStatusChange = async (checked, record) => {
    let payload = {
      status: checked ? 1 : 0,
    };
    try {
      setIsLoading(true);
      const response = await updateExchangeVehicleStatusService(record.id, payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const updatedData = listData?.map((item) => (item.id === record.id ? { ...item, status: checked } : item));
        setListData(updatedData);
        getExchangeVehicleList(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteExchangeVehicleData(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getExchangeVehicleList(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFuelChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        // page: 1,
        fuel_type_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        fuel_type_id: [],
      }));
    }
  };

  const handleTransmissionChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        transmission_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        transmission_id: [],
      }));
    }
  };

  const handleMakeChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        make_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        make_id: [],
      }));
    }
  };

  // Tables
  const columns = [
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model_name',
      key: 'model_name',
    },
    {
      title: 'Fuel',
      dataIndex: 'fuel_type',
      key: 'fuel_type',
    },
    {
      title: 'Transmission',
      dataIndex: 'transmission',
      key: 'transmission',
    },
    {
      title: 'Variant',
      dataIndex: 'variant_code',
      key: 'variant_code',
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'Seater',
      dataIndex: 'seater',
      key: 'seater',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '120px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record)}
          checkedChildren='Active'
          unCheckedChildren='Inactive'
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Space size='small'>
          <Tooltip title='Edit Exchange Vehicle'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Popconfirm
            title='Are you sure you want to delete this exchange model?'
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Delete Exchange Vehicle'>
              <Button shape='circle' danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className='user-data'>
      <Loader loading={isLoading} />
      <PageContentHeader
        title='Exchange Vehicle'
        headerRight={
          <>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className='secondary-button'
              onClick={() => navigate('/admin/masters/exchange-model/add')}
            >
              New Exchange Vehicle
            </Button>
          </>
        }
      />

      {/* Filter Option Card */}
      <Card className='filter-card mb-3'>
        <Form
          layout='vertical'
          name='normal_search'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className='login-input-field'>
            <Row gutter={[16, 16]}>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name='search' label='Search (By Model/Variant)'>
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder='Search by model, variant'
                  />
                </Form.Item>
              </Col>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  name='fuel_type_id'
                  placeholder='Fuel'
                  options={fuelData}
                  label='Fuel'
                  optionName={'title'}
                  mode='multiple'
                  maxTagCount={2}
                  onSelect={handleFuelChange}
                  className='form-control-ant'
                  showSearch={true}
                />
              </Col>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  name='transmission_id'
                  placeholder='Transmission'
                  options={transmissionData}
                  label='Transmission'
                  optionName={'name'}
                  mode='multiple'
                  maxTagCount={2}
                  onSelect={handleTransmissionChange}
                  className='form-control-ant'
                  showSearch={true}
                />
              </Col>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  name='make_id'
                  placeholder='Make'
                  options={makeData}
                  label='Make'
                  optionName={'title'}
                  mode='multiple'
                  maxTagCount={2}
                  onSelect={handleMakeChange}
                  className='form-control-ant'
                  showSearch={true}
                />
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='status' label='Status'>
                  <Select
                    placeholder='Status'
                    className='form-control-ant'
                    allowClear
                    onChange={handleStatusFilterChange}
                  >
                    <Select.Option value='1'>Active</Select.Option>
                    <Select.Option value='0'>Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch={true}
                  label='Color'
                  mode='multiple'
                  maxTagCount={2}
                  name='color_id'
                  options={colorData}
                  optionName={'color'}
                  placeholder='Color'
                  onSelect={handleColorChange}
                  className='form-control-ant'
                />
              </Col>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  name='seater_id'
                  placeholder='Seater'
                  options={seaterData}
                  label='Seater'
                  optionName={'seater'}
                  mode='multiple'
                  maxTagCount={2}
                  onSelect={handleSeaterChange}
                  className='form-control-ant'
                  showSearch={true}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </Card>

      <div className='user-table-data'>
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: 'max-content' }}
          rowKey='id'
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount, // Replace totalCount with the total count of your data
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default ExchangeModel;
