import { Modal } from "antd";
import React, { useState } from "react";

const RoleModal = (props) => {
  const { data, setShowRoleModal } = props;
  const [isModalOpen, setIsModalOpen] = useState(data?.show);

  const handleOk = () => {
    setIsModalOpen(false);
    setShowRoleModal((val) => ({ ...val, show: false }));
  };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   setShowRoleModal((val) => ({ ...val, show: false }));
  // };
  return (
    <div>
      <Modal
        title="Please confirm! "
        open={isModalOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        // onCancel={handleCancel}
        closeIcon={false}
      >
        <p>
          This role is already assigned to <b>{data?.data[0]?.full_name}</b>.
          Assigning this role to another user will revoke it from the existing one.
        </p>
      </Modal>
    </div>
  );
};

export default RoleModal;
