import React from 'react';
import { Space, Typography } from 'antd';
const { Title } = Typography;

const PageContentHeader = (props) => {
  const { title, headerRight, headerLeft, className, leftClassNameDisable } = props;

  return (
    <div className={`page-content-header ${className}`}>
      <div className={leftClassNameDisable ? '' : 'page-content-header-left'}>
        <Title level={1} className='title'>
          {title}
        </Title>
        {headerLeft ? <div className='header-left-extra'>{headerLeft}</div> : null}
      </div>
      {headerRight ? (
        <div className='page-content-header-right'>
          <Space size='middle'>{headerRight}</Space>
        </div>
      ) : null}
    </div>
  );
};

export default PageContentHeader;
