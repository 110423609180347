import React from 'react';
import ReportsComponent from './ReportsComponent';

const Reports = () => {
  return (
    <div className='user-data'>
      <ReportsComponent />
    </div>
  );
};

export default Reports;
