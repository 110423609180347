/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Form, Card, notification, message } from 'antd';
import Loader from "../../../components/common/Loader";
import InputSelect from "../../../components/common/InputSelect";
import { addTarget, deleteTargetTempData, getTargetModels, updateTarget } from '../../../services/master-services';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { API_RESPONSE_CODES, Target_TYPE, monthData } from '../../../utills/constant';
import { fetchTargetUserHierachy, fetchKpiParameters, fetchManagerTargetCalculation } from "../../auth/slice/authSlice";
import UpdatedTargets from '../target/updatedTarget';
import { getYears } from '../../../utills/helpers';

const AddTarget = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, monthID } = useParams();
  const { pathname } = location;
  const searchParams = new URLSearchParams(location.search);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const getYearParams = searchParams.get("year");
  const getRoleParams = searchParams.get("role");
  const route = isAdmin ? "/admin" : "";

  const userId = [+id];
  const [isLoading, setIsLoading] = useState(false);
  const [kpiData, setKpiData] = useState([{
    Enquiry: [],
    Test: [],
    Home: [],
    Booking: [],
    Retail: [],
    Valuation: [],
    Exchange: [],
    Purchase: [],
    Extended: [],
    Body: [],
    InHouse: [],
    Accessories: [],
  }]);

  const [selectAllUser, setAllUser] = useState({
    GM: [],
    AGM: [],
    TSM: [],
    SM: [],
    TL: [],
    SO: [],
  });

  const [getUserOne, setGetUserOne] = useState([]);
  const [getUserThird, setGetUserThird] = useState([]);
  const [getUserFivth, setGetUserFivth] = useState([]);
  const [getUserSixth, setGetUserSixth] = useState([]);
  const [getUserFourth, setGetUserFourth] = useState([]);
  const [getUserSecond, setGetUserSecond] = useState([]);
  const [mockDataStore, setMockDataStore] = useState('');
  const [hycrossMockData, setHycrossMockData] = useState('');
  const [showSalesOfficer, setShowSalesOfficer] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const [modalData, setModalData] = useState([]);
  const [showThirdDropdown, setShowThirdDropdown] = useState(false);
  const [showFivthDropdown, setShowFivthDropdown] = useState(false);
  const [showSixthDropdown, setShowSixthDropdown] = useState(false);
  const [showFourthDropdown, setShowFourthDropdown] = useState(false);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);

  const kpiParameters = useSelector((state) => state?.auth?.kpiData);
  const userDetails = useSelector((state) => state?.auth?.userData?.id)
  const selectUserData = useSelector((state) => state?.auth?.userHierarchyData);
  const calculatedData = useSelector((state) => state?.auth?.calculatedData);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const isTeamLeader = roleType === "Team Leader";

  const data = selectUserData?.subordinate_recursive?.map((element) => element?.subordinate_role);
  const data2 = getUserOne?.map((element) => element?.subordinate_role);
  const data3 = getUserSecond?.map((element) => element?.subordinate_role);
  const data4 = getUserThird?.map((element) => element?.subordinate_role);
  const data5 = getUserFourth?.map((element) => element?.subordinate_role);

  useEffect(() => {
    if (id) {
      getKpiParameters(id, monthID, getYearParams);
    }
    else {
      getKpiParameters(null, null, null);
    }
    if (id) {
      getModalData(monthID, getYearParams, id);
    }
    else {
      getModalData(selectedMonth, selectedYear, null);
    }
  }, [id, monthID, getYearParams, selectedMonth, selectedYear]);

  useEffect(() => {
    if (id) {
      // If `id` exists, call the API with `id`, `monthID`, and `getYearParams`
      getTargetUser(userDetails, 1, id, monthID, getYearParams);
    } else {
      // If no `id`, call the API without extra parameters
      getTargetUser(userDetails, 0, null, null, null);
    }
  }, [id, userDetails, monthID, getYearParams]);

  const getModalData = async (selectedMonth, selectedYear, user_id) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getTargetModels(selectedMonth, selectedYear, user_id);
      setModalData(modalDataArray?.data?.data);
      setIsLoading(false);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const firstUserOnchange = (e) => {
    if (roleType === 'Sales Manager') {
      setShowSalesOfficer(e)
    } else {
      setShowSalesOfficer([]);
    }
    form.setFieldsValue({
      'user2': [],
      'user3': [],
      'user4': [],
      'user5': [],
      'user6': [],
    })
    setGetUserOne([]);
    let filteredData = [];
    e.forEach((el) => {
      filteredData = [...filteredData, ...selectUserData?.subordinate_recursive?.filter((element) => element.id === el)]
    })

    let newGetUserOne = [];
    filteredData.forEach((element) => {
      newGetUserOne = [...newGetUserOne, ...element?.subordinate_recursive];
    });
    setGetUserOne(newGetUserOne);
    setShowSecondDropdown(newGetUserOne?.length > 0);
    setShowThirdDropdown(false);
    setShowFourthDropdown(false);
    setShowFivthDropdown(false);
    setShowSixthDropdown(false);
    setAllUser(prev => ({
      ...prev,
      AGM: [],
      TSM: [],
      SM: [],
      TL: [],
      SO: []
    }))
    setAllUser(prev => ({
      ...prev,
      GM: [...e],
    }))
  }

  const secondUserOnChange = (e) => {
    if (roleType === 'GM (General Manager)' ||
      roleType === 'AGM (Assistant General Manager)' ||
      roleType === 'TSM (Territory Sales Manager)') {
      setShowSalesOfficer(e)
    } else {
      setShowSalesOfficer([]);
    }
    form.setFieldsValue({
      'user3': [],
      'user4': [],
      'user5': [],
      'user6': [],
    })
    setGetUserSecond([]);
    let filteredData = [];
    e.forEach((el) => {
      filteredData = [...filteredData, ...getUserOne?.filter((element) => element.id === el)]
    })

    let newGetUserSecond = [];
    filteredData.forEach((element) => {
      newGetUserSecond = [...newGetUserSecond, ...element?.subordinate_recursive];
    });
    setGetUserSecond(newGetUserSecond);
    setShowThirdDropdown(newGetUserSecond?.length > 0);
    setShowFourthDropdown(false);
    setShowFivthDropdown(false);
    setShowSixthDropdown(false);
    setAllUser(prev => ({
      ...prev,
      TSM: [],
      SM: [],
      TL: [],
      SO: []
    }))
    setAllUser(prev => ({
      ...prev,
      AGM: [...e],
    }))
  }

  const thirdUserOnchange = (e) => {
    if (roleType === 'Super Admin' || roleType === 'CEO' || roleType === 'VP Sales') {
      setShowSalesOfficer(e)
    } else {
      setShowSalesOfficer([]);
    }
    form.setFieldsValue({
      'user4': [],
      'user5': [],
      'user6': [],
    })
    setGetUserThird([]);
    let filteredData = [];

    e.forEach((el) => {
      filteredData = [...filteredData, ...getUserSecond?.filter((element) => element.id === el)]
    })

    let newGetUserThird = [];
    filteredData.forEach((element) => {
      newGetUserThird = [...newGetUserThird, ...element?.subordinate_recursive];
    });
    setGetUserThird(newGetUserThird);
    setShowFourthDropdown(newGetUserThird?.length > 0);
    setShowFivthDropdown(false);
    setShowSixthDropdown(false);
    setAllUser(prev => ({
      ...prev,
      SM: [],
      TL: [],
      SO: []
    }))
    setAllUser(prev => ({
      ...prev,
      TSM: [...e],
    }))
  }

  const fourthUserOnchange = (e) => {
    form.setFieldsValue({
      'user5': [],
      'user6': [],
    })
    setGetUserFourth([]);
    let filteredData = [];

    e.forEach((el) => {
      filteredData = [...filteredData, ...getUserThird?.filter((element) => element.id === el)]
    })

    let newGetUserFourth = [];
    filteredData.forEach((element) => {
      newGetUserFourth = [...newGetUserFourth, ...element?.subordinate_recursive];
    });
    setGetUserFourth(newGetUserFourth);
    setShowFivthDropdown(newGetUserFourth?.length > 0);
    setShowSixthDropdown(false);
    setAllUser(prev => ({
      ...prev,
      TL: [],
      SO: []
    }))
    setAllUser(prev => ({
      ...prev,
      SM: [...e],
    }))
  }

  const fifthUserOnchange = (e) => {
    form.setFieldsValue({
      'user6': [],
    })
    setGetUserFivth([]);
    let filteredData = [];

    e.forEach((el) => {
      filteredData = [...filteredData, ...getUserFourth?.filter((element) => element.id === el)]
    })

    let newGetUserFivth = [];
    filteredData.forEach((element) => {
      newGetUserFivth = [...newGetUserFivth, ...element?.subordinate_recursive];
    });
    setGetUserFivth(newGetUserFivth);
    setShowSixthDropdown(newGetUserFivth?.length > 0);
    setAllUser(prev => ({
      ...prev,
      SO: []
    }))

    setAllUser(prev => ({
      ...prev,
      TL: [...e],
    }))
  }

  const sixthUserOnchange = (e) => {
    setGetUserSixth([]);
    let filteredData = [];

    e.forEach((el) => {
      filteredData = [...filteredData, ...getUserFivth?.filter((element) => element.id === el)]
    })

    let newGetUserSixth = [];
    filteredData.forEach((element) => {
      newGetUserSixth = [...newGetUserSixth, ...element?.subordinate_recursive];
    });
    setGetUserSixth(newGetUserSixth);

    setAllUser(prev => ({
      ...prev,
      SO: [...e],
    }))
  }

  const getKpiParameters = async (id, monthID, getYearParams) => {
    setIsLoading(true);
    try {
      const payload = id ? { user_id: id, month: monthID, year: getYearParams } : {};
      await dispatch(fetchKpiParameters(payload));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getTargetUser = async (user_id, isEditTargetFlow, target_assignee, month, year) => {
    setIsLoading(true);
    try {
      // Dispatch the API call with the right parameters
      const response = await dispatch(fetchTargetUserHierachy({
        id: user_id,
        isTargetFlow: 1,
        isEditTargetFlow: isEditTargetFlow,
        target_assignee: target_assignee,
        month: month,
        year: year
      }));
      if (response?.payload?.data?.status === 200) {
        if (id) {
          let payload = { model_id: null, enquiry_no: 0, kpi_id: 0, month: monthID, year: getYearParams, changed_param1: 0, delete_model_target: 0, edit_target: 1, user_id: id, edit_mode: 0 };
          dispatch(fetchManagerTargetCalculation(payload))
        }
        else {
          let payloadData = { model_id: null, enquiry_no: 0, kpi_id: 0, month: selectedMonth, year: selectedYear, changed_param1: 0, delete_model_target: 0, edit_target: 0, user_id: null, edit_mode: 0 };
          dispatch(fetchManagerTargetCalculation(payloadData))
          getModalData(selectedMonth, selectedYear, null);
        }
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  let req = {
    users: [],
    month: null,
    year: null
  }

  const onSubmit = async (values) => {
    setIsLoading(true);
    const isAnyKpiFilled = calculatedData?.enquiry?.some(kpiObject =>
      Object.values(kpiObject).some(kpiArray => kpiArray?.length > 0)
    );
    if (roleType === 'Super Admin' || roleType === 'CEO' || roleType === 'VP Sales') {
      const isUserSelectionValid =
        selectAllUser.GM?.length > 0 &&
        selectAllUser.AGM?.length > 0 &&
        selectAllUser.TSM?.length > 0

      if (!isUserSelectionValid && !id) {
        notification.error({
          message: 'Please select at least one user from each dropdown before submitting',
        });
        setIsLoading(false);
        return;
      }
    } else if (roleType === 'GM (General Manager)' ||
      roleType === 'AGM (Assistant General Manager)' ||
      roleType === 'TSM (Territory Sales Manager)') {

      const isUserSelectionValid =
        selectAllUser.GM?.length > 0 &&
        selectAllUser.AGM?.length > 0

      if (!isUserSelectionValid && !id) {
        notification.error({
          message: 'Please select at least one user from each dropdown before submitting',
        });
        setIsLoading(false);
        return;
      }
    } else {
      const isUserSelectionValid =
        selectAllUser.GM?.length > 0

      if (!isUserSelectionValid && !id) {
        notification.error({
          message: 'Please select at least one user from each dropdown before submitting',
        });
        setIsLoading(false);
        return;
      }
    }

    if (!isAnyKpiFilled) {
      notification.error({
        message: 'Please fill in at least one target for enquiry before submitting',
      });
      setIsLoading(false);
      return;
    }

    let data = [];
    if (!id) {
      // Directly check each property in the sequence
      if (selectAllUser.AGM?.length === 0) {
        data = [...selectAllUser.GM];
      } else if (selectAllUser.TSM?.length === 0) {
        data = [...selectAllUser.AGM];
      } else if (selectAllUser.SM?.length === 0) {
        data = [...selectAllUser.TSM];
      } else if (selectAllUser.TL?.length === 0) {
        data = [...selectAllUser.SM];
      } else if (selectAllUser.SO?.length === 0) {
        data = [...selectAllUser.TL];
      } else {
        data = [...selectAllUser.SO];
      }
    }
    if (id) {
      userId?.forEach((el) => {
        req.users.push({
          user_id: el,
          kpis: [{
            kpi_id: Target_TYPE.Enquiry, targets: calculatedData.enquiry?.length ? [...calculatedData.enquiry?.map((e) => {
              return { ...e, model_id: e.model_id, param2: e.param2, param1: e.param1 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Test, targets: calculatedData.test_drive?.length ? [...calculatedData.test_drive?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Home, targets: calculatedData?.home_visit?.length ? [...calculatedData.home_visit?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Exchange, targets: calculatedData?.exchange?.length ? [...calculatedData.exchange?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Booking, targets: calculatedData?.booking?.length ? [...calculatedData.booking?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Retail, targets: calculatedData?.retail?.length ? [...calculatedData.retail?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Valuation, targets: calculatedData?.valuation?.length ? [...calculatedData.valuation?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Purchase, targets: calculatedData?.purchase?.length ? [...calculatedData.purchase?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Extended, targets: calculatedData?.extended_warranty?.length ? [...calculatedData.extended_warranty?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Body, targets: calculatedData?.body_care?.length ? [...calculatedData.body_care?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.InHouse, targets: calculatedData?.in_house?.length ? [...calculatedData.in_house?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Accessories, targets: calculatedData?.accessories?.length ? [...calculatedData.accessories?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          }
          ]
        })
      })
    } else {
      data?.forEach((el) => {
        req.users.push({
          user_id: el,
          kpis: [{
            kpi_id: Target_TYPE.Enquiry, targets: calculatedData.enquiry?.length ? [...calculatedData.enquiry?.map((e) => {
              return { ...e, model_id: e.model_id, param2: e.param2, param1: e.param1 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Test, targets: calculatedData.test_drive?.length ? [...calculatedData.test_drive?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Home, targets: calculatedData?.home_visit?.length ? [...calculatedData.home_visit?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Exchange, targets: calculatedData?.exchange?.length ? [...calculatedData.exchange?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Booking, targets: calculatedData?.booking?.length ? [...calculatedData.booking?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Retail, targets: calculatedData?.retail?.length ? [...calculatedData.retail?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Valuation, targets: calculatedData?.valuation?.length ? [...calculatedData.valuation?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Purchase, targets: calculatedData?.purchase?.length ? [...calculatedData.purchase?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Extended, targets: calculatedData?.extended_warranty?.length ? [...calculatedData.extended_warranty?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Body, targets: calculatedData?.body_care?.length ? [...calculatedData.body_care?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.InHouse, targets: calculatedData?.in_house?.length ? [...calculatedData.in_house?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          },
          {
            kpi_id: Target_TYPE.Accessories, targets: calculatedData?.accessories?.length ? [...calculatedData.accessories?.map((e) => {
              return { ...e, model_id: e.model_id, param1: e.param1, param2: e.param2 }
            })] : []
          }
          ]
        })
      })
    }
    req.month = id ? monthID : selectedMonth
    req.year = id ? getYearParams : selectedYear

    if (id ? userId?.length > 0 : data?.length > 0) {
      try {
        const data = id ? updateTarget(req) : addTarget(req);
        const response = await data;
        if (
          response.data.status === API_RESPONSE_CODES.OK
        ) {
          notification.success({
            message: response.data.message,
          });
          if (id) {
            navigate(`${route}/targets/${id}`);
          }
          form.resetFields();
          setIsLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
        // form.resetFields();
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      message.error('Please select atleast one user');
      setIsLoading(false);
    }
  };

  const handleDeleteTargetOnMonthYear = async () => {
    try {
      const response = await deleteTargetTempData()
      if (
        response.data.status === API_RESPONSE_CODES.OK
      ) {
        if (id) {
          let payload = { model_id: null, enquiry_no: 0, kpi_id: 0, month: monthID, year: getYearParams, changed_param1: 0, delete_model_target: 0, edit_target: 1, user_id: id, edit_mode: 0 };
          dispatch(fetchManagerTargetCalculation(payload))
        }
        else {
          let payloadData = { model_id: null, enquiry_no: 0, kpi_id: 0, month: selectedMonth, year: selectedYear, changed_param1: 0, delete_model_target: 0, edit_target: 0, user_id: null, edit_mode: 0 };
          dispatch(fetchManagerTargetCalculation(payloadData))
        }
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  }

  const getOrderedMonths = (months) => {
    const currentMonthIndex = new Date().getMonth();
    return [...months?.slice(currentMonthIndex), ...months?.slice(0, currentMonthIndex)];
  };

  const handleYearChange = (selectedYearId) => {
    if (selectedYearId) {
      setSelectedYear(selectedYearId);
    } else {
      setSelectedYear(new Date().getFullYear());
    }
    handleDeleteTargetOnMonthYear();
    form.setFieldsValue({
      enquiry: null,
      totalAmount: null,
      name: '',
    });
  };


  const handleMonthChange = (selectedMonthId) => {
    if (selectedMonthId) {
      setSelectedMonth(selectedMonthId);
    } else {
      setSelectedMonth(new Date().getMonth() + 1);
    }
    handleDeleteTargetOnMonthYear();
    form.setFieldsValue({
      enquiry: null,
      totalAmount: "",
      name: '',
    });
  };

  const years = getYears();
  const currentYear = new Date().getFullYear();
  const filteredYears = years.filter((y) => y.id >= currentYear);
  const orderedMonths = getOrderedMonths(monthData);

  return (
    <>
      <Loader loading={isLoading} />
      <div className="user-data">
        <PageContentHeader title={pathname === '/admin/add-target' || pathname === '/add-target' ? "Add Target" : "Edit Target"} />
        <div className="search-leads">
          <Card>
            <Form
              layout="vertical"
              name="normal_search"
              className="login-form"
              form={form}
              onFinish={onSubmit}
            >
              {pathname === '/admin/add-target' || pathname === '/add-target' ?
                <div className="login-input-field">
                  <Row gutter={16}>
                    {selectUserData?.subordinate_recursive?.length > 0 && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user_id"
                          placeholder={`${selectUserData?.subordinate_role}`}
                          options={selectUserData?.subordinate_recursive}
                          onSelect={firstUserOnchange}
                          label={`${selectUserData?.subordinate_role}`}
                          optionName={'full_name'}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    {showSecondDropdown && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user2"
                          placeholder={`${data[0]}` || 'User'}
                          options={getUserOne}
                          onSelect={secondUserOnChange}
                          label={`${data[0]}`}
                          optionName={'full_name'}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    {showThirdDropdown && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user3"
                          placeholder={`${data2[0]}`}
                          options={getUserSecond}
                          onSelect={thirdUserOnchange}
                          label={`${data2[0]}`}
                          optionName={'full_name'}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    {showFourthDropdown && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user4"
                          placeholder={` ${data3[0]}`}
                          options={getUserThird}
                          onSelect={fourthUserOnchange}
                          label={`${data3[0]}`}
                          optionName={'full_name'}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    {showFivthDropdown && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user5"
                          placeholder={`${data4[0]}`}
                          options={getUserFourth}
                          onSelect={fifthUserOnchange}
                          label={`${data4[0]}`}
                          optionName={'full_name'}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    {showSixthDropdown && (
                      <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                        <InputSelect
                          allowClear
                          name="user6"
                          placeholder={`${data5[0]}`}
                          options={getUserFivth}
                          label={`${data5[0]}`}
                          optionName={'full_name'}
                          onSelect={sixthUserOnchange}
                          className="form-control-ant"
                          mode="multiple"
                          maxTagCount={2}
                          showSearch={true}
                        />
                      </Col>
                    )}
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                      <InputSelect
                        allowClear={false}
                        name="months"
                        placeholder="Select Month"
                        options={orderedMonths}
                        label="Select Month"
                        optionName={'name'}
                        className="form-control-ant"
                        onSelect={handleMonthChange}
                        defaultValue={new Date().getMonth() + 1}
                      />
                    </Col>
                    <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                      <InputSelect
                        allowClear={false}
                        name="years"
                        placeholder="Select Year"
                        options={filteredYears}
                        label="Select Year"
                        optionName={"name"}
                        className="form-control-ant select-year"
                        onSelect={handleYearChange}
                        defaultValue={filteredYears.find((y) => y.id === currentYear)?.id}
                      />
                    </Col>
                  </Row>
                </div> : ""}

              <div className="lead-type-card pt-4">
                {!isTeamLeader && showSalesOfficer?.length == [0] && !id ? "" :
                  <Row gutter={[20, 20]}>
                    {kpiParameters?.map((item, index) => (
                      <Col key={index} xl={8} lg={12} md={24} sm={24} xs={24}>
                        <UpdatedTargets item={item} form={form} id={id} pathname={pathname} getYearParams={getYearParams}
                          setMockDataStore={setMockDataStore} mockDataStore={mockDataStore} setHycrossMockData={setHycrossMockData}
                          hycrossMockData={hycrossMockData} selectedYear={selectedYear} selectedMonth={selectedMonth} isLoading={isLoading}
                          setIsLoading={setIsLoading} setKpiData={setKpiData} kpiData={kpiData} modalData={modalData} getRoleParams={getRoleParams}
                        />
                      </Col>
                    ))}
                  </Row>
                }
                <div className="user-right d-flex align-items-center justify-content-end pt-4">
                  <Button type="primary" ghost danger className="primary-button me-3" onClick={() => window.location.reload()}>
                    Cancel
                  </Button>
                  <Button type="primary" className="secondary-button" htmlType='submit'>
                    {pathname === '/admin/add-target' || pathname === '/add-target' ? 'Add' : 'Update'}
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddTarget;
