// SearchableSelect.tsx

import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectDropdown = ({
  onSearch,
  name,
  label,
  options,
  onSelect,
  defaultValue,
  value,
  isSearchable = false,
  placeholder,
  allowClear = true,
  optionName,
  disable,
  ...selectProps
}) => {
  return (
    <Select
      showSearch={isSearchable}
      allowClear={allowClear}
      defaultValue={defaultValue}
      filterOption={(input, option) => {
        if (
          option?.children !== null &&
          typeof option?.children?.at(0) === "string"
        ) {
          // Use optional chaining and includes() method
          return option.children
            ?.at(0)
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }}
      name={label}
      id={name}
      onSearch={onSearch} // Call the onSearch prop when the user types
      onChange={onSelect}
      placeholder={placeholder} // Use the placeholder prop here
      value={value}
      disabled={disable}
      {...selectProps}
    >
      {options?.map((item) => (
        <Option key={item.id} value={item.id}>
          {/* {`${item[optionName]} (${item?.designation?.title})`} */}
          {item[optionName]}{" "}
          {item?.designation?.title ? `(${item?.designation?.title})` : ""}
        </Option>
      ))}
    </Select>
  );
};

export default SelectDropdown;
