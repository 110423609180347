/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { EditFilled } from '@ant-design/icons';
import { getAdminUserDetailsService, updateAdminUserService } from '../../../services/admin-services';
import { Table, Row, Col, Form, Input, notification, Select, Button, Typography, Card } from 'antd';
import {
  getAreasService,
  getDesignationsService,
  getReportingManagerService,
  getRolesService,
  getStateService,
  getTerritoriesService,
} from '../../../services/master-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { useSelector } from 'react-redux';
import RoleModal from '../../../components/modals/RoleModal';
const { Title } = Typography;

const UserDetails = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { id } = useParams();

  const [userData, setUserData] = useState();
  const [territorySelection, setTerritorySelection] = useState();
  const [areaData, setAreaData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [territoryData, setTerritoryData] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [checkStateChanged, setCheckStateChanged] = useState(false);
  const [reportingManagerData, setReportingManagerData] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState({ show: false, data: {} });
  const [register, setRegister] = useState({
    state_id: 0,
    territory_id: 0,
    role_id: 0,
    reporting_manager_id: 0,
  });

  useEffect(() => {
    getUserListing(id);
    if (pathname.includes('edit')) {
      getStateData();
      getRoleData(true);
      setEditMode(true);
      getDesignationData(true);
    }
  }, [id, pathname]);

  useEffect(() => {
    if (userData?.full_name) {
      form.setFieldsValue({
        email: userData?.email,
        role_id: userData?.role_id,
        emp_code: userData?.emp_code,
        phone_no: userData?.phone_no,
        state_id: userData?.state_id,
        full_name: userData?.full_name,
        designation_id: userData?.designation_id,
        area_ids: userData?.areas?.map((ele) => ele?.id),
        reporting_manager_id: userData?.reporting_manager_id,
        territory_ids: userData?.territories?.map((ele) => ele?.id),
      });
      setTerritorySelection(userData?.role_id)
      getTerritoryData(userData?.state_id);
      getAreaData(
        userData.state_id,
        userData?.territories?.map((ele) => ele?.id)
      );
      setRegister((state) => ({
        ...state,
        state_id: userData?.state_id,
        territory_id: userData?.territories?.map((ele) => ele?.id),
      }));
      setRegister((state) => ({
        ...state,
        reporting_manager_id: userData?.reporting_manager_id,
      }));
      setRegister((state) => ({
        ...state,
        role_id: userData?.role_id,
      }));
    }
  }, [userData]);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const getUserListing = async (id) => {
    setIsLoading(true);
    try {
      const userData = await getAdminUserDetailsService(id);
      setUserData(userData?.data?.data);
      getRMData(userData?.data?.data?.role_id,
        userData?.data?.data?.territories?.map((ele) => ele?.id)
      );
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStateChange = (value) => {
    if (value?.length < 1 || !value) {
      setRegister((state) => ({
        ...state,
        state_id: 0,
        territory_id: 0,
      }));
      form.setFieldsValue({
        area_ids: [],
        territory_ids: [],
      });
      setTerritoryData([]);
      setAreaData([]);
    } else {
      setRegister((state) => ({
        ...state,
        state_id: value,
      }));
      getTerritoryData(value);
      setCheckStateChanged(true);
      form.setFieldsValue({
        area_ids: [],
        territory_ids: [],
      });
      setAreaData([]);
    }
  };

  const getTerritoryData = async (state_id) => {
    setIsLoading(true);
    try {
      const territoryData = await getTerritoriesService(state_id, false);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRoleData = async (edit) => {
    setIsLoading(true);
    try {
      const roleArray = await getRolesService(edit);
      setRoleData(roleArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerritoryChange = (value) => {
    // if (value?.length > 0) {
    setRegister((state) => ({
      ...state,
      territory_id: value,
    }));
    getAreaData(register.state_id, value);
    getRMData(register?.role_id, value);
    // } else {
    //   setRegister((state) => ({
    //     ...state,
    //     territory_id: 0,
    //   }));
    // }
    form.setFieldsValue({
      area_ids: [],
      reporting_manager_id: [],
    });
    setAreaData([]);
    setReportingManagerData([]);
  };

  const getAreaData = async (state_id, territory_id) => {
    setIsLoading(true);
    try {
      const areaData = await getAreasService(state_id, territory_id, false);
      setAreaData(areaData?.data?.data);
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDesignationData = async (edit) => {
    setIsLoading(true);
    try {
      const designationArray = await getDesignationsService(edit);
      setDesignationData(designationArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRMData = async (id, territory_id) => {
    setIsLoading(true);
    try {
      const reportingManagerArray = await getReportingManagerService(id, territory_id);
      setReportingManagerData(reportingManagerArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoleChange = (res) => {
    setTerritorySelection(res)
    if (res) {
      const data = roleData?.filter((option) => option?.id === res);
      const restrictedIds = data[0]?.id === 4 || data[0]?.id === 5 || data[0]?.id === 6;
      setRegister((state) => ({
        ...state,
        role_id: res,
        reporting_manager_id: res,
      }));

      if (data[0]?.full_name && data[0]?.full_name?.length > 0 && !restrictedIds) {
        setShowRoleModal((val) => ({ ...val, show: true, data: data }));
      }
    } else {
      setAreaData([]);
      // setTerritoryData([]);
      setReportingManagerData([]);
      setRegister((state) => ({
        ...state,
        reporting_manager_id: null,
      }));
    }
    setReportingManagerData([]);
    form.setFieldsValue({
      reporting_manager_id: null,
      territory_ids: null,
      area_ids: null,

    });
  };

  const handleEdit = async (values) => {
    setIsLoading(true);
    try {
      const updateUser = await updateAdminUserService(id, values);
      if (updateUser.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: updateUser.data.message,
        });
        navigate(isAdmin ? '/admin/users' : '/users');
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // tables
  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Code',
      dataIndex: 'emp_code',
      key: 'emp_code',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Contact No.',
      dataIndex: 'phone_no',
      key: 'phone_no',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  return (
    <>
      <Loader loading={isLoading} />
      <div className='user-data'>
        <PageContentHeader title={editMode ? 'Edit Users' : 'View Users'} />

        <div className='personal-detail'>
          <Card>
            <div className='user-left d-flex align-items-center'>
              <Title level={4} className='pe-2 mb-0'>
                Personal Details
              </Title>
              <EditFilled />
            </div>
            <Form
              form={form}
              layout='vertical'
              onFinish={handleEdit}
              className='login-form'
              initialValues={{
                remember: true,
              }}
            >
              <div className='detail-wrapper pt-3'>
                <Row gutter={20} justify='start'>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='full_name'
                      label='Name'
                      rules={[
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value) {
                              return Promise.reject('Please input your name');
                            }
                            if (!/^[A-Za-z\s]+$/.test(value)) {
                              return Promise.reject('Please input a valid name');
                            }
                            if (/\s{2,}/.test(value)) {
                              return Promise.reject('Name cannot contain two consecutive spaces');
                            }
                            const trimmedValue = value.trim();
                            if (trimmedValue !== value) {
                              return Promise.reject('Name cannot start or end with spaces');
                            }
                            if (value?.length < 3 || value?.length > 30) {
                              return Promise.reject('Name must be between 3 and 30 characters');
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      {editMode ? (
                        <Input placeholder='Name' className='form-control-ant' />
                      ) : (
                        <p>{userData?.full_name || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='emp_code'
                      label='Employee Code'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your employee code',
                        },
                        {
                          pattern: /^[a-zA-Z0-9]{10,15}$/,
                          message: 'Employee code must be alphanumeric and between 10 to 15 characters',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Input placeholder='Employee Code' className='form-control-ant' />
                      ) : (
                        <p>{userData?.emp_code || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='designation_id'
                      label='Designation'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your desgination',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select allowClear className='form-control-ant' placeholder='Designation'>
                          {designationData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.title}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>{userData?.designation || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>
                  {showRoleModal?.show && <RoleModal setShowRoleModal={setShowRoleModal} data={showRoleModal} />}
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='role_id'
                      label='Role'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your role ',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select allowClear onChange={handleRoleChange} className='form-control-ant' placeholder='Role'>
                          {roleData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>{userData?.role || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='state_id'
                      label='State'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your state',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          allowClear
                          placeholder='State'
                          className='form-control-ant'
                          defaultValue={userData?.state_id}
                          onChange={handleStateChange}
                        >
                          {stateData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>{userData?.state || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='territory_ids'
                      label='Territory'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your territory',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          allowClear
                          disabled={register?.state_id === 0}
                          // defaultValue={userData&&userData?.territories?.map((ele) => ele.name)}
                          mode={territorySelection === 1 || territorySelection === 2 || territorySelection === 3 || territorySelection === 6 ? 'multiple' : null}
                          maxTagCount={2}
                          placeholder='Territory'
                          className='form-control-ant'
                          onChange={handleTerritoryChange}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {territoryData?.map((option) => (
                            <Select.Option key={option?.id} value={option?.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>
                          {userData?.territories?.map((ele, index) =>
                            index === userData.territories?.length - 1 ? ` ${ele?.name}` : ` ${ele?.name}, `
                          ) || '-'}
                        </p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='area_ids'
                      label='Area'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your area',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          allowClear
                          disabled={register?.territory_id === 0}
                          className='form-control-ant'
                          placeholder='Area'
                          mode='multiple'
                          maxTagCount={2}
                        >
                          {areaData?.map((option) => (
                            <Select.Option key={option?.id} value={option?.id}>
                              {option?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>
                          {userData?.areas?.map((ele, index) =>
                            index === userData.areas?.length - 1 ? ` ${ele.name}` : ` ${ele.name}, `
                          ) || '-'}
                        </p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='reporting_manager_id'
                      label='Reporting Manager'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your reporting manager',
                        },
                      ]}
                    >
                      {editMode ? (
                        <Select
                          allowClear
                          className='form-control-ant'
                          placeholder='Reporting Manager'
                          disabled={!register.reporting_manager_id}
                        >
                          {reportingManagerData?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                              {`${option?.full_name ? option?.full_name : ''} ${option?.role ? '(' + option?.role + ')' : ''
                                }`}
                            </Select.Option>
                          ))}
                        </Select>
                      ) : (
                        <p>{userData?.reporting_manager || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='phone_no'
                      label='Contact Number'
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject('Please input your contact number');
                            }
                            if (!/^[0-9]*$/.test(value)) {
                              return Promise.reject('Contact number must be digits only ');
                            }
                            if (value?.length < 10 || value?.length > 10) {
                              return Promise.reject('Please enter a valid 10 digit contact number');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      {editMode ? (
                        <Input placeholder='Contact Number' className='form-control-ant' />
                      ) : (
                        <p>{userData?.phone_no || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name='email'
                      label='Email'
                      rules={[
                        {
                          required: editMode ? true : false,
                          message: 'Please input your email id',
                        },
                        {
                          type: 'email',
                          message: 'Please enter a valid email id',
                        },
                        {
                          validator: (_, value) => {
                            if (value && /@-/.test(value)) {
                              return Promise.reject('Please enter a valid email id');
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      {editMode ? (
                        <Input placeholder='Email' className='form-control-ant' />
                      ) : (
                        <p>{userData?.email || '-'}</p>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              {editMode ? (
                <div className='user-right d-flex align-items-center justify-content-end'>
                  <Button
                    ghost
                    danger
                    type='primary'
                    className='primary-button me-3'
                    onClick={() => navigate(isAdmin ? '/admin/users' : '/users')}
                  >
                    Cancel
                  </Button>
                  <Button type='primary' htmlType='submit' className='secondary-button'>
                    Update
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </Form>
          </Card>
        </div>

        {!editMode && (
          <div className='user-table-data pt-4'>
            {userData?.team_members?.length > 0 && (
              <Card>
                <div className='user-left d-flex align-items-center'>
                  <Title level={4} className='pe-2 mb-0'>
                    Team Details
                  </Title>
                  <EditFilled />
                </div>
                <div className='responsive-table-container pt-3'>
                  <Table
                    columns={columns}
                    dataSource={userData?.team_members ?? userData?.team_members}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </div>
              </Card>
            )}
          </div>
        )}

        {/* <Card className='mt-4'>
        <div className='user-left d-flex align-items-center'>
          <Title level={4} className='pe-2 mb-0'>Target Details</Title>
          <div className="pe-2">
            <EyeFilled />
          </div>
          <EditFilled />
        </div>
      </Card> */}
      </div>
    </>
  );
};

export default UserDetails;
