/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Table, Switch, Button, Card, notification, Radio } from 'antd';
import { EditOutlined, PlusCircleOutlined, EyeOutlined, MinusCircleOutlined } from '@ant-design/icons';
import Loader from '../../../components/common/Loader';
import { rolesPermissionsService, updatePermissionsService } from '../../../services/admin-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import { useNavigate } from 'react-router-dom';
import PageContentHeader from '../../../components/common/PageContentHeader';

const Permissions = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [leadsRoleList, setLeadsRoleList] = useState([]);
  const [usersRoleList, setUsersRoleList] = useState([]);
  const [targetRoleList, setTargetRoleList] = useState([]);
  const [rolePermissions, setRolePermissions] = useState({});

  const [selectedOption, setSelectedOption] = useState('Lead');

  const plainOptions = ['Lead', 'User', 'Target'];

  useEffect(() => {
    getRoleData();
  }, []);

  const getRoleData = async () => {
    setIsLoading(true);
    try {
      const response = await rolesPermissionsService({
        page: 1,
        per_page: 10,
        search: '',
      });
      if (response?.status === API_RESPONSE_CODES?.OK) {
        setLeadsRoleList(
          response?.data?.data?.list_data?.filter(
            (role) => role?.name !== 'Super Admin' && role?.name !== 'Exchange Evaluation Team'
          )
        );
        setUsersRoleList(
          response?.data?.data?.list_data?.filter((role) => role?.name === 'CEO' || role?.name === 'VP Sales')
        );
        setTargetRoleList(
          response?.data?.data?.list_data?.filter(
            (role) =>
              role?.name !== 'Sales Officer' &&
              role?.name !== 'Super Admin' &&
              role?.name !== 'Exchange Evaluation Team'
          )
        );
        const initialRolePermissions = response?.data?.data?.list_data?.reduce((acc, role) => {
          acc[role?.id] = {
            role_id: role?.id,
            permissions: role?.permissions?.map((permission) => permission.name),
          };
          return acc;
        }, {});
        initialRolePermissions && setRolePermissions(initialRolePermissions);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updatePermission = async (data) => {
    setIsLoading(true);
    try {
      const response = await updatePermissionsService(data);
      if (response?.status === API_RESPONSE_CODES?.OK) {
        notification.success({ message: response?.data?.message });
        getRoleData();
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSwitchChange = (role_id, action, checked) => {
    setRolePermissions((prevRolePermissions) => {
      const updatedPermissions = { ...prevRolePermissions };

      const permission = `${action.toLowerCase()}-${selectedOption?.toLowerCase()}`;

      // Skip user-related permissions for roles other than 2 and 3
      if (
        selectedOption === 'User' &&
        ![2, 3].includes(role_id) &&
        ['add-user', 'edit-user', 'delete-user', 'view-user'].includes(permission)
      ) {
        return;
      }

      if (updatedPermissions[role_id]) {
        if (checked) {
          if (!updatedPermissions[role_id].permissions.includes(permission)) {
            updatedPermissions[role_id].permissions.push(permission);
          }
        } else {
          // Remove the permission only if it exists in the list
          if (updatedPermissions[role_id].permissions.includes(permission)) {
            updatedPermissions[role_id].permissions = updatedPermissions[role_id].permissions.filter(
              (p) => p !== permission
            );
          }
        }
      } else {
        updatedPermissions[role_id] = {
          role_id: role_id,
          permissions: [permission],
        };
      }

      return updatedPermissions;
    });
  };

  const isPermissionChecked = (role_id, action) => {

    // Default behavior for when allSelected is not true or role_id is not in the specified range
    return [selectedOption].some((option) =>
      rolePermissions[role_id]?.permissions.includes(`${action.toLowerCase()}-${option.toLowerCase()}`)
    );
  };

  const handleSave = () => {
    // const check_all_permissions = allSelected ? 1 : 0;
    const payload = {
      roles_permissions: Object.values(rolePermissions),
      all_permissions: 0,
    };
    // payload.roles_permissions = payload.roles_permissions.filter(
    //   (role) => role.permissions.length > 0
    // );
    return updatePermission(payload);
  };

  const columns = [
    {
      title: 'Roles',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: (
        <span className='d-flex align-items-center justify-content-center gap-2'>
          Add <PlusCircleOutlined />
        </span>
      ),
      key: 'add',
      dataIndex: 'add',
      className: 'text-center',
      render: (text, record) => {
        const isChecked = isPermissionChecked(record.id, 'add');
        return (
          <Switch
            size='medium'
            checked={isChecked}
            onChange={(checked) => handleSwitchChange(record.id, 'add', checked)}
          />
        );
      },
    },
    {
      title: (
        <span className='d-flex align-items-center justify-content-center gap-2'>
          Edit <EditOutlined />
        </span>
      ),
      key: 'edit',
      dataIndex: 'edit',
      className: 'text-center',
      render: (text, record) => {
        const isChecked = isPermissionChecked(record.id, 'edit');
        return (
          <Switch
            size='medium'
            checked={isChecked}
            onChange={(checked) => handleSwitchChange(record.id, 'edit', checked)}
          />
        );
      },
    },
    {
      title: (
        <span className='d-flex align-items-center justify-content-center gap-2'>
          Delete <MinusCircleOutlined />
        </span>
      ),
      dataIndex: 'delete',
      className: 'text-center',
      key: 'delete',
      render: (text, record) => {
        const isChecked = isPermissionChecked(record.id, 'delete');
        return (
          <Switch
            size='medium'
            checked={isChecked}
            onChange={(checked) => handleSwitchChange(record.id, 'delete', checked)}
          />
        );
      },
    },
    {
      title: (
        <span className='d-flex align-items-center justify-content-center gap-2'>
          View <EyeOutlined />
        </span>
      ),
      dataIndex: 'view',
      key: 'view',
      className: 'text-center',
      render: (text, record) => {
        const isChecked = isPermissionChecked(record.id, 'view');
        return (
          <Switch
            size='medium'
            checked={isChecked}
            onChange={(checked) => handleSwitchChange(record.id, 'view', checked)}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='user-data'>
        <Loader loading={isLoading} />
        <PageContentHeader title='Update Permissions' />
        <div className='personal-detail'>
          <Card>
            <div className='user-table-data text-start'>
              {/* <Checkbox onChange={onCheckAllChange} checked={selectedOption?.length === plainOptions?.length}>
                All
              </Checkbox> */}
              {/* <Checkbox.Group options={plainOptions} value={selectedOption} onChange={onOptionChange} /> */}
              <Radio.Group options={plainOptions} value={selectedOption} onChange={onOptionChange} />
              <div className='responsive-table-container mt-3'>
                <Table
                  columns={columns}
                  dataSource={
                    selectedOption === 'User'
                      ? usersRoleList
                      : selectedOption === 'Target'
                      ? targetRoleList
                      : leadsRoleList
                  }
                  pagination={false}
                />
              </div>
            </div>
            <div className='user-right d-flex align-items-center justify-content-end mt-4'>
              <Button
                ghost
                style={{ marginLeft: '20px' }}
                danger
                type='primary'
                className='primary-button '
                onClick={() => navigate('/admin/dashboard')}
              >
                Cancel
              </Button>
              <Button
                type='primary'
                className='secondary-button ms-3'
                onClick={handleSave}
                disabled={!selectedOption?.length}
              >
                Save
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Permissions;
