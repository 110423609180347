import React, { useState, useEffect } from "react";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import Loader from "../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import {
  deleteTerritoryService,
  getMasterTerritoryListService,
  getStateService,
  updateTerritoryStatusService,
} from "../../../services/master-services";
import InputSelect from "../../../components/common/InputSelect";

const initialListData = [
  {
    id: 1,
    territory: "Territory 1",
    status: true,
  },
  {
    id: 2,
    territory: "Territory 2",
    status: false,
  },
  {
    id: 3,
    territory: "Territory 3",
    status: true,
  },
];
const Territory = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes

  const [form] = Form.useForm(); // Ant Design form instance
  const [listCount, setListCount] = useState(); // State to store list count
  const [stateData, setStateData] = useState([]); // State to store state master data
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const [listData, setListData] = useState(initialListData); // State to store list data
  const [filters, setFilters] = useState({
    // State to store Filters
    page: 1,
    state: [],
    search: "",
    per_page: 10,
  });

  //Use effect to get state master data
  useEffect(() => {
    getStateData();
  }, []);

  //Use effect to get territory  master data
  useEffect(() => {
    getTerritoryData(filters);
  }, [filters]);

  //function to get state master data
  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to get territory master data
  const getTerritoryData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterTerritoryListService(data);
      setListCount(userList?.data?.data?.total_records);
      setListData(userList?.data?.data?.list_data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to handle delete
  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteTerritoryService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getTerritoryData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to handle search
  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  //function to handle state change
  const onStateChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        state: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        state: [],
      }));
    }
  };

  // Handler for changing the status
  const handleStatusChange = async (checked, record) => {
    try {
      setIsLoading(true);
      let payload = {
        status: checked ? 1 : 0,
      };
      const response = await updateTerritoryStatusService(record.id, payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const updatedData = listData.map((item) =>
          item.id === record.id ? { ...item, status: checked } : item
        );
        setListData(updatedData);
        getTerritoryData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Tables
  const columns = [
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Territory",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Space size="small">
          <Tooltip title="Delete Territory">
            <Popconfirm
              title="Are you sure you want to delete this territory?"
              onConfirm={() => handleDelete(record.id)}
              okButtonProps={{
                loading: false,
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                // onClick={() => handleDelete(record)}
              />
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-data">
      <Loader loading={isLoading} />
      <PageContentHeader
        title="Territory"
        headerRight={
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="secondary-button"
              onClick={() => navigate("/admin/masters/territory/add")}
            >
              New Territory
            </Button>
          </>
        }
      />
      {/* Filter Option Card */}
      <Card className="filter-card mb-3">
        <Form
          layout="vertical"
          name="normal_search"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className="login-input-field">
            <Row gutter={[16, 16]}>
              {/* Territory Input Filter */}
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="search" label="Search (By Territory)">
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder="Search by territory"
                  />
                </Form.Item>
              </Col>
              {/* State Filter */}
              <Col xl={7} lg={8} md={8} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  label="State"
                  name="state_id"
                  mode="multiple"
                  showSearch={true}
                  maxTagCount={2}
                  options={stateData}
                  placeholder="State"
                  optionName={"name"}
                  onSelect={onStateChange}
                  className="form-control-ant"
                  rules={[
                    {
                      required: false,
                      message: "Please select your state",
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </Card>

      <div className="user-table-data">
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: "max-content" }}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount,
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Territory;
