/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Col, DatePicker, Form, notification } from "antd";
import InputSelect from "../../../../../components/common/InputSelect";
import {
  getTramission,
  getVehicleFuelTypeService,
  getVehicleMakeService,
  getVehicleModelService,
  getVehicleVarientService,
} from "../../../../../services/master-services";

const ExchangeDetailsDropdown = (props) => {
  const { leadViewDetails, form, setIsLoading, disable } = props;
  const [makeData, setMakeData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [excFuelData, setExcFuelData] = useState([]);
  const [varientData, setVarientData] = useState([]);
  const [excTransmissionData, setExcTransmissionData] = useState([]);
  const [excVehicleIds, setExcVehicleIds] = useState({
    make_id: "",
    model_id: "",
    variant_id: "",
    transmission_id: "",
  });

  useEffect(() => {
    getMakeData();
  }, []);

  const getFuelData = async (make_id, model_id, variant_id, transmission_id) => {
    setIsLoading(true);
    try {
      const fuelData = await getVehicleFuelTypeService(
        make_id,
        model_id,
        variant_id,
        transmission_id,
        false,
        true
      );
      setExcFuelData(fuelData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeadsTransmission = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const transmissionData = await getTramission(
        make_id,
        model_id,
        variant_id,
        true,
        false
      )
      setExcTransmissionData(transmissionData?.data?.data);
      // dispatch(fetchLeadsTransmission(make_id, model_id, variant_id));
      if (leadViewDetails?.exchange_vehicle_detail?.fuel_type_id) {
        getFuelData(
          leadViewDetails?.exchange_vehicle_detail?.make_id,
          leadViewDetails?.exchange_vehicle_detail?.model?.id,
          leadViewDetails?.exchange_vehicle_detail?.variant?.id,
          leadViewDetails?.exchange_vehicle_detail?.transmission?.id
        );
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id);
      setVarientData(varientDataArray?.data?.data);
      if (leadViewDetails?.exchange_vehicle_detail?.transmission?.id) {
        handleLeadsTransmission(
          leadViewDetails?.exchange_vehicle_detail?.make_id,
          leadViewDetails?.exchange_vehicle_detail?.model?.id,
          leadViewDetails?.exchange_vehicle_detail?.variant?.id
        );
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(value);
      setModalData(modalDataArray?.data?.data);
      if (leadViewDetails?.exchange_vehicle_detail?.variant?.id) {
        getVarientData(leadViewDetails?.exchange_vehicle_detail?.variant?.id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleMakeService();
      setMakeData(modalDataArray?.data?.data);
      if (leadViewDetails?.exchange_vehicle_detail?.model?.id) {
        getModalData(leadViewDetails?.exchange_vehicle_detail?.model?.id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcMakeChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      make_id: value,
    }));
    form.setFieldsValue({
      exchange_vehicle_model_id: null,
      exchange_vehicle_variant_id: null,
      exchange_fuel_type_id: null,
      exchange_transmission_id: null,
    });
    getModalData(value);
  };

  const handleExcModalChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      model_id: value,
    }));
    form.setFieldsValue({
      exchange_vehicle_variant_id: null,
      exchange_fuel_type_id: null,
      exchange_transmission_id: null,
    });
    getVarientData(value);
  };

  const handleExcVariantChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      variant_id: value,
      // model_id: excVehicleIds?.model_id
    }));
    form.setFieldsValue({
      exchange_fuel_type_id: null,
      exchange_transmission_id: null,
    });
    handleLeadsTransmission(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
    // getFuelData(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
  };

  const handleExcTransmissionChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      transmission_id: value,
    }));
    form.setFieldsValue({
      exchange_fuel_type_id: null,
    });
    getFuelData(excVehicleIds?.make_id, excVehicleIds?.model_id, excVehicleIds?.variant_id, value);
  };

  return (
    <>
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          rules={[
            {
              required: true,
              message: "Please select make",
            },
          ]}
          name="exchange_make_id"
          placeholder="Make"
          options={makeData}
          label="Make"
          onSelect={(val) => {
            handleExcMakeChange(val);
          }}
          disabled={disable === false ? true : false}
          optionName={"title"}
          showSearch
        />
      </Col>

      {/* Modal Id  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          rules={[
            {
              required: true,
              message: "Please select your car model",
            },
          ]}
          name="exchange_vehicle_model_id"
          placeholder="Model"
          options={modalData}
          label="Model"
          optionName={"model_name"}
          onSelect={(val) => {
            handleExcModalChange(val);
          }}
          disabled={disable === false ? true : false}
          showSearch
        />
      </Col>

      {/* Car Variant  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          rules={[
            {
              required: true,
              message: "Please select your car variant",
            },
          ]}
          name="exchange_vehicle_variant_id"
          placeholder="Car Variant"
          options={varientData}
          label="Car Variant"
          optionName={"variant_code"}
          onSelect={(val) => {
            handleExcVariantChange(val);
          }}
          disabled={disable === false ? true : false}
          showSearch
        />
      </Col>
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          rules={[
            {
              required: true,
              message: "Please select your car transmission",
            },
          ]}
          disabled={disable === false ? true : false}
          name="exchange_transmission_id"
          placeholder="Transmission"
          options={excTransmissionData}
          onSelect={(val) => {
            handleExcTransmissionChange(val);
          }}
          label="Transmission"
          optionName={"name"}
          showSearch
        />
      </Col>
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          disabled={disable === false ? true : false}
          name="exchange_fuel_type_id"
          placeholder="Fuel"
          rules={[
            {
              required: true,
              message: "Please select your car fuel type",
            },
          ]}
          options={excFuelData}
          label="Fuel"
          optionName={"title"}
          showSearch
        />
      </Col>
      <Col xl={4} md={8} sm={24} xs={24}>
        <Form.Item
          name="year"
          label="Year"
          rules={[
            {
              required: true,
              message: "Please select year",
            },
          ]}
        >
          <DatePicker
            picker="year"
            placeholder="Select Year"
            disabledDate={(current) => {
              return (
                current && dayjs(current).isAfter(dayjs().endOf("year"), "day")
              );
            }}
            disabled={disable === false ? true : false}
          />
        </Form.Item>
      </Col>

    </>
  );
};

export default ExchangeDetailsDropdown;
