/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  notification,
  Typography,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import PageContentHeader from "../../../components/common/PageContentHeader";
import { addTransmissionService } from "../../../services/master-services";
import { API_RESPONSE_CODES } from "../../../utills/constant";

const { Title } = Typography;

/**
 * handleSubmit function handles the form submission.
 * It sends the form data to the server and handles the response.
 * @param {Object} values - Form values
 */

const AddTransmission = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [form] = Form.useForm(); // Ant Design form instance
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await addTransmissionService(values);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response?.data?.message,
        });
        navigate("/admin/masters/transmission");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        {/* Loader component to show loading spinner */}
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title="Add Transmission" />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Transmission Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="name"
                        label="Transmission"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your car transmission"
                                );
                              }

                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Transmission cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Transmission cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Transmission must be between 3 and 30 characters"
                                );
                              }
                              if (!/^[a-zA-Z0-9\s()]+$/.test(value)) {
                                return Promise.reject(
                                  "Transmission can only contain alphanumeric characters"
                                );
                              }
                               // Check if the string is numeric only
                               if (/^[\d()]+$/.test(value)) {
                                return Promise.reject(
                                  "Transmission cannot be only numeric values"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Transmission"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  {/* <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Switch
                          defaultChecked
                          onChange={statusChange}
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                        />
                      </Form.Item>
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  {/* Cancel button */}
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/transmission")}
                  >
                    Cancel
                  </Button>
                  {/* Save button */}
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddTransmission;
