import { Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PasswordChangeModal = (props) => {
  const { open } = props;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(open);
  const route = useSelector((state) => state.auth.route);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const handleOk = () => {
    navigate(isAdmin ? `${route}/change-password` : '/change-password');
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Please change your password first! "
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={false}
      >
        <p>
          You need to update your password because this is the first time you
          are signing in.
        </p>
      </Modal>
    </div>
  );
};

export default PasswordChangeModal;
