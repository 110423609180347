/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Form, Input, Card, notification } from "antd";
import {
  getAdminProfileDetails,
  updateProfileDetailsService,
} from "../../services/auth-service";
import Loader from "../common/Loader";
import PageContentHeader from "../common/PageContentHeader";
import { API_RESPONSE_CODES, roleIds } from "../../utills/constant";
import { getDesignationsService } from "../../services/master-services";

const Profile = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [adminProfileData, setAdminProfileData] = useState();
  const [designationData, setDesignationData] = useState([]);

  const role_id = useSelector((state) => state?.auth?.userData?.role_id);
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  useEffect(() => {
    getDesignationData();
    getAdminProfileData();
  }, []);
  const [form] = Form.useForm();

  useEffect(() => {
    if (adminProfileData?.full_name) {
      form.setFieldsValue({
        full_name: adminProfileData?.full_name,
        emp_code: adminProfileData?.emp_code,
        designation_id: adminProfileData?.designation_id,
        phone_no: adminProfileData?.phone_no,
        email: adminProfileData?.email,
      });
    }
  }, [adminProfileData, designationData]);

  const getAdminProfileData = async () => {
    setIsLoading(true);
    try {
      const adminProfileResponse = await getAdminProfileDetails();
      setAdminProfileData(adminProfileResponse?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (values) => {
    setIsLoading(true);
    try {
      const updateProfile = await updateProfileDetailsService(values);
      if (updateProfile.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: updateProfile.data.message,
        });
        navigate(
          role_id === roleIds?.superAdmin ? `/admin/profile` : `/profile`
        );
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDesignationData = async () => {
    setIsLoading(true);
    try {
      const designationArray = await getDesignationsService();
      setDesignationData(designationArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(isAdmin ? `/admin/profile` : `/profile`);
  };

  return (
    <>
      <div className="user-data">
        <Loader loading={isLoading} />
        <PageContentHeader title="Edit Profile Details" />

        <div className="personal-detail">
          <Card>
            <div className="detail-wrapper">
              <Form
                layout="vertical"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                form={form}
                onFinish={handleEdit}
              >
                <div className="login-input-field">
                  <Row gutter={20} justify="start">
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="full_name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject("Please input your name");
                              }
                              if (!/^[A-Za-z\s]+$/.test(value)) {
                                return Promise.reject(
                                  "Please input a valid name"
                                );
                              }
                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Name cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Name cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Name must be between 3 and 30 characters"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          placeholder="Name"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="emp_code"
                        label="Employee Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input your employee code",
                          },
                          {
                            pattern: /^[a-zA-Z0-9]{10,15}$/,
                            message:
                              "Employee code must be alphanumeric and between 10 to 15 characters",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Employee Code"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email id",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email id",
                          },
                          {
                            validator: (_, value) => {
                              if (value && /@-/.test(value)) {
                                return Promise.reject(
                                  "Please enter a valid email id"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                    {!isAdmin && <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="designation_id"
                        label="Designation"
                        rules={[
                          {
                            required: true,
                            message: "Please input your desgination",
                          },
                        ]}
                      >
                        <p className="ant-input p-2">
                          {adminProfileData?.designation}
                        </p>
                      </Form.Item>
                    </Col>}
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <Form.Item
                        name="phone_no"
                        label="Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            validator: (_, value) => {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your contact number"
                                );
                              }
                              if (!/^[0-9]*$/.test(value)) {
                                return Promise.reject(
                                  "Contact number must be digits only "
                                );
                              }
                              if (value?.length < 10 || value?.length > 10) {
                                return Promise.reject(
                                  "Please enter a valid 10 digit contact number"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          placeholder="Contact Number"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="user-right d-flex align-items-center justify-content-end">
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button me-3"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="secondary-button"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Profile;
