import React, { useState } from 'react';
import { Card } from 'antd';
import DashboardHome from './components/DashboardHome';
import DashboardTarget from './components/DashboardTarget';
import DashboardVasBooked from './components/DashboardVasBooked';
import DashboardVasRetailed from './components/DashboardVasRetailed';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { useSelector } from 'react-redux';

const AdminDashboard = () => {
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const isExchangeEvaluationTeam = roleType === "Exchange Evaluation Team";

  const tabListNoTitle = [
    {
      key: 'home',
      label: 'Home',
    },
    isExchangeEvaluationTeam ? "" : (
      {
        key: 'targets',
        label: 'Targets',
      }),
    isExchangeEvaluationTeam ? "" : (
      {
        key: 'vasBooked',
        label: 'VAS Report(Booked)',
      }
    ),
    isExchangeEvaluationTeam ? "" : (
      {
        key: 'vasReport',
        label: 'VAS Report(Retailed)',
      }
    )
  ];

  const contentListNoTitle = {
    home: <DashboardHome />,
    targets: < DashboardTarget />,
    vasBooked: <DashboardVasBooked />,
    vasReport: <DashboardVasRetailed />,
  };
  const [activeTabKey, setActiveTabKey] = useState('home');

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };
  return (
    <>
      <div className='user-data'>
        <PageContentHeader title="Dashboard" />
        <div className='card-tab'>
          <Card
            tabList={tabListNoTitle}
            activeTabKey={activeTabKey}
            tabBarExtraContent={<a href="#">More</a>}
            onTabChange={onTabChange}
            tabProps={{
              size: 'middle',
            }}
          >
            {contentListNoTitle[activeTabKey]}
          </Card>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
