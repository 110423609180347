import React from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LineChart = (props) => {
  const { data, chartColors, fullRetailData, heading, exclude_user_ids } = props;
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const route = isAdmin ? "/admin" : "";
  const options = {
    chartArea: { width: "92%", left: "8%" },
    legend: {
      position: "top",
      labeledValueText: "both",
      textStyle: { fontSize: 13 },
      alignment: "center",
    },
    hAxis: {
      minValue: 0,
      minorGridlines: {
        color: "transparent", // Optional: Hides minor vertical gridlines if present
      },
    },
    vAxis: {
      // title: 'Day',
      minValue: 0,
      minorGridlines: {
        color: "transparent", // Optional: Hides minor vertical gridlines if present
      },
    },

    intervals: { style: "area" },
    colors: chartColors ? chartColors : ["#94959b"],
    pointSize: 5, // Size of the point circle
    pointShape: "circle", // Shape of the point
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 16,
        margin: 40,
        auraColor: "none",
        color: "#5370d5",
        fontWeight: "700",
      },
      stem: {
        length: 20, // Adjust the stem length as needed
      },
      highContrast: true, // Ensure high contrast for the annotation text
    },
    series: {
      0: { annotations: { stem: { length: 10, color: "transparent" } } }, // Example to adjust stem length for specific series
    },
  };
  const isTargetPage =
    data?.at(0)?.at(1) === "Pending Valuation" ||
    data?.at(0)?.at(1) === "Variation";

  const TargetChartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        let str = "> 60 Days & < 120 Days";
        if (selection.length === 0) return;
        const item = selection[0];
        if (item.row !== null) {
          // Assuming your data includes headers as the first row
          const checkStr = data?.[item?.row + 1]?.at(0) === str;
          let duration;
          if (heading === "pending_valuation") {
            duration = checkStr
              ? "60-120"
              : data?.[item?.row + 1]?.at(0)?.split(" ")?.at(0);
          } else if (heading === "variation") {
            duration = data?.[item?.row + 1]?.at(0)
            // ?.replace("<", "");
          }
          if (duration) {
            navigate(
              `${route}/leads?metrics=${heading}&graph_duration=${duration}&key=hot_lead&target_redirect=true&exclude_user_ids=${exclude_user_ids}`
            );
          }
        }
      },
    },
  ];

  const HomeChartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;
        const item = selection[0];
        if (item.row !== null) {
          // Assuming your data includes headers as the first row
          const xValue = data[item.row + 1]?.[0]; // +1 to account for header row in data
          const getModelId = fullRetailData
            ?.filter((ele) => ele?.model_name === xValue)
            ?.at(0)?.model_id;
          if (getModelId) {
            navigate(
              `${route}/leads?lead_stage=3&model_type=${getModelId}&key=hot_lead&last_month="true"`
            );
          }
        }
      },
    },
  ];

  return (
    <div>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
        chartEvents={isTargetPage ? TargetChartEvents : HomeChartEvents}
        key={data?.length}
        className="column-chart-cursor-pointer"
      />
    </div>
  );
};
export default LineChart;
