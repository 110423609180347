import React, { useEffect, useState } from 'react';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { Button, Card, Col, Form, Popconfirm, Row, Select, Space, Switch, Table, Tooltip, notification } from 'antd';
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Search from 'antd/es/input/Search';
import {
  deleteVehcileService,
  getColorMasterService,
  getFuelMasterService,
  getMasterVehicleListService,
  updateVehicleStatusService,
} from '../../../services/master-services';
import Loader from '../../../components/common/Loader';
import { formatIndianCurrency } from '../../../utills/helpers';
import InputSelect from '../../../components/common/InputSelect';
import { API_RESPONSE_CODES } from '../../../utills/constant';

const Vehicle = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // State to manage list data
  const [listCount, setListCount] = useState();
  const [fuelData, setFuelData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState();
  const [filters, setFilters] = useState({
    page: 1,
    search: '',
    status: '',
    per_page: 10,
    color_id: [],
    seater_id: [],
    fuel_type_id: [],
  });

  useEffect(() => {
    getFuelData();
    getColorData();
  }, []);

  useEffect(() => {
    getVehicleMasterData(filters);
  }, [filters]);

  // Get vehicle listing master data
  const getVehicleMasterData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterVehicleListService(data);
      if (userList?.status === API_RESPONSE_CODES.OK) {
        setListCount(userList?.data?.data?.total_records);
        setListData(userList?.data?.data?.list_data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Get fuel master data
  const getFuelData = async () => {
    setIsLoading(true);
    try {
      const fuelData = await getFuelMasterService();
      if (fuelData.data.status === API_RESPONSE_CODES.OK) {
        setFuelData(fuelData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Get color master data
  const getColorData = async () => {
    try {
      setIsLoading(true);
      const fuelData = await getColorMasterService();
      if (fuelData.data.status === API_RESPONSE_CODES.OK) {
        setColorData(fuelData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update Status of vehicle from listing
  const handleStatusChange = async (checked, record) => {
    try {
      setIsLoading(true);

      let payload = {
        status: checked ? 1 : 0,
      };
      const response = await updateVehicleStatusService(record.id, payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const updatedData = listData.map((item) => (item.id === record.id ? { ...item, status: checked } : item));
        setListData(updatedData);
        getVehicleMasterData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (record) => {
    navigate(`/admin/masters/vehicle/edit/${record.id}`);
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteVehcileService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getVehicleMasterData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFuelChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        fuel_type_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        fuel_type_id: [],
      }));
    }
  };

  const handleColorChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        color_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        color_id: [],
      }));
    }
  };

  const handleStatusFilterChange = (value) => {
    if (value) {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        status: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        page: 1,
        status: '',
      }));
    }
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  // Tables
  const columns = [
    {
      title: 'Model',
      dataIndex: 'model_name',
      key: 'model_name',
      width: 200,
    },
    {
      title: 'Variant',
      dataIndex: 'variant_code',
      key: 'variant_code',
      width: 200,
    },
    {
      title: 'Fuel Type',
      dataIndex: 'fuel_type',
      key: 'fuel_type',
      width: 200,
    },
    {
      title: 'Seater',
      dataIndex: 'seater',
      key: 'seater',
      width: 200,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record)}
          checkedChildren='Active'
          unCheckedChildren='Inactive'
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Space size='small'>
          <Tooltip title='Edit Vehicle'>
            <Button shape='circle' icon={<EditOutlined />} onClick={() => handleEdit(record)} />
          </Tooltip>
          <Popconfirm
            title='Are you sure you want to delete this vehicle?'
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText='Yes'
            cancelText='No'
          >
            <Tooltip title='Delete Vehicle'>
              <Button shape='circle' danger icon={<DeleteOutlined />} />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className='user-data'>
      <Loader loading={isLoading} />
      <PageContentHeader
        title='Vehicle'
        headerRight={
          <>
            <Button
              type='primary'
              icon={<PlusOutlined />}
              className='secondary-button'
              onClick={() => navigate('/admin/masters/vehicle/add')}
            >
              New Vehicle
            </Button>
          </>
        }
      />

      {/* Filter Option Card */}
      <Card className='filter-card mb-3'>
        <Form
          layout='vertical'
          name='normal_search'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className='login-input-field'>
            <Row gutter={[16, 16]}>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name='search' label='Search (By Model/Variant)'>
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder='Search by model, variant'
                  />
                </Form.Item>
              </Col>

              {/* Fuel Filter */}
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch={true}
                  label='Fuel Type'
                  mode='multiple'
                  maxTagCount={2}
                  options={fuelData}
                  name='fuel_type_id'
                  optionName={'title'}
                  placeholder='Fuel Type'
                  onSelect={handleFuelChange}
                  className='form-control-ant'
                />
              </Col>

              {/* Color Filter */}
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch={true}
                  label='Color'
                  mode='multiple'
                  maxTagCount={2}
                  name='color_id'
                  options={colorData}
                  optionName={'color'}
                  placeholder='Color'
                  onSelect={handleColorChange}
                  className='form-control-ant'
                />
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='status_ids' label='Status'>
                  <Select
                    allowClear
                    placeholder='Status'
                    className='form-control-ant'
                    onChange={handleStatusFilterChange}
                    // disabled={register.territory_id === 0}
                  >
                    <Select.Option value='1'>Active</Select.Option>
                    <Select.Option value='0'>Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Card>

      <div className='user-table-data'>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Row gutter={20} justify='start' style={{ paddingLeft: '4.2%' }}>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Ex Showroom:</h6>
                      <p className='value flex-1'>
                        {record.ex_showroom_price ? formatIndianCurrency(record.ex_showroom_price) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>TCS:</h6>
                      <p className='value flex-1'>{record.tcs ? formatIndianCurrency(record.tcs) : '-'}</p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Road Tax (Pvt):</h6>
                      <p className='value flex-1'>
                        {record.road_tax_pvt ? formatIndianCurrency(record.road_tax_pvt) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Road Tax (Taxi):</h6>
                      <p className='value flex-1'>
                        {record.road_tax_taxi ? formatIndianCurrency(record.road_tax_taxi) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Hypothecation:</h6>
                      <p className='value flex-1'>
                        {record.hypothecation ? formatIndianCurrency(record.hypothecation) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Fastag:</h6>
                      <p className='value flex-1'>{record.fastag ? formatIndianCurrency(record.fastag) : '-'}</p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Insurance Add On:</h6>
                      <p className='value flex-1'>
                        {record.insurance_add_on ? formatIndianCurrency(record.insurance_add_on) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Insurance Premium:</h6>
                      <p className='value flex-1'>
                        {record.insurance_premium ? formatIndianCurrency(record.insurance_premium) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Insurance Taxi:</h6>
                      <p className='value flex-1'>
                        {record.insurance_taxi ? formatIndianCurrency(record.insurance_taxi) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Extended Warranty:</h6>
                      <p className='value flex-1'>{record.ew ? formatIndianCurrency(record.ew) : '-'}</p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Accessories:</h6>
                      <p className='value flex-1'>
                        {record.tentative_accessories ? formatIndianCurrency(record.tentative_accessories) : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>VAS:</h6>
                      <p className='value flex-1'>{record.vas ? formatIndianCurrency(record.vas) : '-'}</p>
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label flex-1'>Other Logistics Charges:</h6>
                      <p className='value flex-1'>
                        {record.logistic_charges ? formatIndianCurrency(record.logistic_charges) : '-'}
                      </p>
                    </div>
                  </Col>
                </Row>
              );
            },
            rowExpandable: (record) => record.model !== 'Not Expandable',
          }}
          dataSource={listData}
          scroll={{ x: 'max-content' }}
          rowKey='id'
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount, // Replace totalCount with the total count of your data
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Vehicle;
