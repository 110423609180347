/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  notification,
  Typography,
  Space,
  Switch,
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  addVehcileService,
  getColorMasterService,
  getFuelTypeMasterService,
  getVehcileDetailsMasterService,
  getVehicleMakeService,
  getVehicleModelService,
  getVehicleVarientService,
  updateVehcileService,
} from "../../../services/master-services";
import InputSelect from "../../../components/common/InputSelect";
import { priceValidationFunctionWithAsterisk } from "../../../utills/helpers";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterSeater } from "../../auth/slice/authSlice";

/**
 * handleSubmit function handles the form submission.
 * It sends the form data to the server and handles the response.
 * @param {Object} values - Form values
 */

const { Title } = Typography;

const AddVehicle = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = location;
  const { id } = useParams();

  const [makeData, setMakeData] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  // const [seatData, setSeatData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [varientData, setVarientData] = useState([]);

  const seatData = useSelector((state) => state?.auth?.seaterData?.data);

  const [filters, setFilters] = useState({
    make_id: "",
    vehicle_model_id: "",
    vehicle_variant_id: "",
    fuel_type_id: "",
    seater_id: "",
    color_id: "",
    status: "",
  });

  useEffect(() => {
    if (pathname.includes("edit")) {
      setEditMode(true);
      getVehicleData(id);
    }
    getMakeData();
    getFuelData();
    getSeatData();
    getColorData();
  }, []);

  useEffect(() => {
    const toyotaId = makeData?.at(0)?.id;
    form.setFieldsValue({
      make_id: toyotaId,
    });
    getModalData(toyotaId);
  }, [makeData]);

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleMakeService();
      if (modalDataArray.data.status === API_RESPONSE_CODES.OK) {
        setMakeData(modalDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [tcsValue, setTcsValue] = useState(0);

  const handleExShowroomChange = (e) => {
    const exShowroomPrice = parseFloat(e.target.value) || 0;
    
    // Check if the ex-showroom price is greater than 10 lakhs (1,000,000)
    if (exShowroomPrice > 1000000) {
      const calculatedTCS = (exShowroomPrice * 1) / 100; // 1% of ex showroom price
      setTcsValue(calculatedTCS); // Update the TCS value and round to 2 decimals
      form.setFieldsValue({ tcs: calculatedTCS }); // Set the TCS field value in the form
    } else {
      setTcsValue(0); // Set TCS to 0 for values 10 lakhs or less
      form.setFieldsValue({ tcs: 0 }); // Update the TCS field in the form
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const addModalDataArray = await getVehicleModelService(value);
      if (addModalDataArray.data.status === API_RESPONSE_CODES.OK) {
        setModalData(addModalDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id);
      if (varientDataArray.data.status === API_RESPONSE_CODES.OK) {
        setVarientData(varientDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getColorData = async () => {
    try {
      setIsLoading(true);
      const colorData = await getColorMasterService();
      if (colorData.status === API_RESPONSE_CODES.OK) {
        setColorData(colorData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSeatData = () => {
    setIsLoading(true);
    try {
      dispatch(fetchMasterSeater());
      setIsLoading(false);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const getFuelData = async () => {
    try {
      setIsLoading(true);
      const fuelData = await getFuelTypeMasterService();
      if (fuelData.data.status === API_RESPONSE_CODES.OK) {
        setFuelData(fuelData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVehicleData = async (id) => {
    try {
      setIsLoading(true);
      const vehicleData = await getVehcileDetailsMasterService(id);
      const data = vehicleData?.data?.data;
      if (vehicleData.data.status === API_RESPONSE_CODES.OK) {
        getModalData(data?.make_id);
        getVarientData(data?.vehicle_model_id);
        setFilters((state) => ({
          ...state,
          make_id: data?.make_id,
          vehicle_model_id: data?.vehicle_model_id,
          vehicle_variant_id: data?.vehicle_variant_id,
          fuel_type_id: data?.fuel_type_id,
          seater_id: data?.seater_id,
          color_id: data?.color_id,
        }));
        form.setFieldsValue({
          make_id: data?.make?.id,
          vehicle_model_id: data?.model?.id,
          vehicle_variant_id: data?.vehicle_variant_id,
          fuel_type_id: data?.fuel_type_id,
          seater_id: data?.seater_id,
          color_id: data?.color_id,
          ex_showroom_price: data?.ex_showroom_price,
          tcs: data?.tcs,
          road_tax_pvt: data?.road_tax_pvt,
          road_tax_taxi: data?.road_tax_taxi,
          hypothecation: data?.hypothecation,
          fastag: data?.fastag,
          insurance_add_on: data?.insurance_add_on,
          insurance_premium: data?.insurance_premium,
          insurance_taxi: data?.insurance_taxi,
          ew: data?.ew,
          tentative_accessories: data?.tentative_accessories,
          vas: data?.vas,
          logistic_charges: data?.logistic_charges,
          status: data?.status,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMakeChange = (ele) => {
    form.setFieldsValue({
      vehicle_model_id: null,
      vehicle_variant_id: null,
      fuel_type_id: null,
      seater_id: null,
      color_id: null,
    });
    if (ele) {
      getModalData(ele);
      setFilters((state) => ({
        ...state,
        make_id: ele,
        vehicle_model_id: "",
        vehicle_variant_id: "",
        fuel_type_id: "",
        seater_id: "",
        color_id: "",
      }));
    } else {
      setFilters((state) => ({
        ...state,
        make_id: "",
        vehicle_model_id: "",
        vehicle_variant_id: "",
        fuel_type_id: "",
        seater_id: "",
        color_id: "",
      }));
      setModalData([]);
      setVarientData([]);
    }
  };

  const handleModelChange = (ele) => {
    form.setFieldsValue({
      // vehicle_model_id: null,
      vehicle_variant_id: null,
      fuel_type_id: null,
      seater_id: null,
      color_id: null,
    });
    if (ele) {
      getVarientData(ele);
      setFilters((state) => ({ ...state, vehicle_model_id: ele }));
    } else {
      setFilters((state) => ({ ...state, vehicle_model_id: "" }));
      setVarientData([]);
    }
  };

  const handleFuelChange = (ele) => {
    if (ele) {
      setFilters((state) => ({ ...state, fuel_type_id: ele }));
    } else {
      setFilters((state) => ({ ...state, fuel_type_id: "" }));
    }
  };

  const handleVariantChange = (ele) => {
    if (ele) {
      // getVarientData(ele);
      setFilters((state) => ({ ...state, vehicle_variant_id: ele }));
    } else {
      setFilters((state) => ({ ...state, vehicle_variant_id: "" }));
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const service = editMode
        ? updateVehcileService(id, values)
        : addVehcileService(values);
      const response = await service;
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response?.data?.message,
        });
        navigate("/admin/masters/vehicle");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title={editMode ? "Edit Vehicle" : "Add Vehicle"} />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Vehicle Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  {/* Make Filter */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        name="make_id"
                        disable={true}
                        label="Make"
                        options={makeData}
                        placeholder="Make"
                        optionName={"title"}
                        onSelect={handleMakeChange}
                        className="form-control-ant"
                        rules={[
                          {
                            required: false,
                            message: "Please select car make",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Model Filter */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        name="vehicle_model_id"
                        label="Model"
                        options={modalData}
                        placeholder="Model"
                        optionName={"model_name"}
                        onSelect={handleModelChange}
                        className="form-control-ant"
                        // disable={!filters.make_id}
                        rules={[
                          {
                            required: true,
                            message: "Please select your car model",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Variant Filter */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        label="Variant"
                        placeholder="Variant"
                        options={varientData}
                        name="vehicle_variant_id"
                        optionName={"variant_code"}
                        onSelect={handleVariantChange}
                        className="form-control-ant"
                        // disable={!filters.vehicle_model_id}
                        rules={[
                          {
                            required: true,
                            message: "Please select your car variant",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Fuel Type */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        label="Fuel Type"
                        options={fuelData}
                        name="fuel_type_id"
                        optionName={"title"}
                        placeholder="Fuel Type"
                        onSelect={handleFuelChange}
                        className="form-control-ant"
                        rules={[
                          {
                            required: true,
                            message: "Please select your car fuel type",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Seater Filter */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        label="Seater"
                        options={seatData}
                        name="seater_id"
                        optionName={"seater"}
                        placeholder="Seater"
                        // onSelect={handleFuelChange}
                        className="form-control-ant"
                        rules={[
                          {
                            required: true,
                            message: "Please select your car seater",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Color Filter */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <InputSelect
                        allowClear
                        label="Color"
                        name="color_id"
                        options={colorData}
                        optionName={"color"}
                        placeholder="Color"
                        // onSelect={handleFuelChange}
                        className="form-control-ant"
                        rules={[
                          {
                            required: true,
                            message: "Please select your car color",
                          },
                        ]}
                      />
                    </div>
                  </Col>

                  {/* Ex Showroom field  */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="ex_showroom_price"
                        label="Ex Showroom"
                        rules={priceValidationFunctionWithAsterisk(
                          "ex showroom price"
                        )}
                      >
                        <Input
                          placeholder="Ex Showroom"
                          className="form-control-ant"
                          onChange={handleExShowroomChange}
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* TCS Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="tcs"
                        label="TCS"
                        rules={priceValidationFunctionWithAsterisk("tcs")}
                      >
                        <Input placeholder="TCS" className="form-control-ant" value={tcsValue}
                          readOnly />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Road Tax (PVT) */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="road_tax_pvt"
                        label="Road Tax (Pvt)"
                        rules={priceValidationFunctionWithAsterisk(
                          "road tax (Pvt)"
                        )}
                      >
                        <Input
                          placeholder="Road Tax (Pvt)"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Road Tax (Taxi) */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="road_tax_taxi"
                        label="Road Tax (Taxi)"
                        rules={priceValidationFunctionWithAsterisk(
                          "road tax (Taxi)"
                        )}
                      >
                        <Input
                          placeholder="Road Tax (Taxi)"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Hypothecation Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="hypothecation"
                        label="Hypothecation"
                        rules={priceValidationFunctionWithAsterisk(
                          "hypothecation"
                        )}
                      >
                        <Input
                          placeholder="Hypothecation"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Fastag Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="fastag"
                        label="Fastag"
                        rules={priceValidationFunctionWithAsterisk("fastag")}
                      >
                        <Input
                          placeholder="Fastag"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Insurance Add On Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="insurance_add_on"
                        label="Insurance Add On"
                        rules={priceValidationFunctionWithAsterisk(
                          "insurance add on"
                        )}
                      >
                        <Input
                          placeholder="Insurance Add On"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Insurance Premium Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="insurance_premium"
                        label="Insurance Premium"
                        rules={priceValidationFunctionWithAsterisk(
                          "insurance premium"
                        )}
                      >
                        <Input
                          placeholder="Insurance Premium"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Insurance Taxi Field  */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="insurance_taxi"
                        label="Insurance Taxi"
                        rules={priceValidationFunctionWithAsterisk(
                          "insurance taxi"
                        )}
                      >
                        <Input
                          placeholder="Insurance Taxi"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Extended Warranty Field  */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="ew"
                        label="Extended Warranty"
                        rules={priceValidationFunctionWithAsterisk(
                          "extended warranty"
                        )}
                      >
                        <Input
                          placeholder="Extended Warranty"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Accessories Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="tentative_accessories"
                        label="Accessories"
                        rules={priceValidationFunctionWithAsterisk(
                          "accessories"
                        )}
                      >
                        <Input
                          placeholder="Accessories"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* VAS Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="vas"
                        label="VAS"
                        rules={priceValidationFunctionWithAsterisk("vas")}
                      >
                        <Input placeholder="VAS" className="form-control-ant" />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Other Logistics Charges Field */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="logistic_charges"
                        label="Other Logistics Charges"
                        rules={priceValidationFunctionWithAsterisk(
                          "other logistics charges"
                        )}
                      >
                        <Input
                          placeholder="Other Logistics Charges"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* Status Switch */}
                  {/* <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          {
                            required: false,
                            message: "Please provide status",
                          },
                        ]}
                      >
                        <Switch
                          defaultChecked={false}
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                        />
                      </Form.Item>
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  {/* Cancel button */}
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/vehicle")}
                  >
                    Cancel
                  </Button>
                  {/* Save button */}
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    {editMode ? "Update" : "Save"}
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddVehicle;
