import React, { useEffect, useState } from 'react';
import {
  PieChartOutlined,
  UsergroupAddOutlined,
  ProductOutlined,
  SolutionOutlined,
  CarOutlined,
  EnvironmentOutlined,
  UserOutlined,
  InteractionOutlined,
  BankOutlined,
  BorderOuterOutlined,
  FileTextOutlined,
  DashboardOutlined,
  ClusterOutlined,
  CodepenOutlined,
  AimOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import PageHeader from '../../../src/components/common/Header';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { Content, Sider } = Layout;
const contentStyle = {
  background: '#e9f0ee',
  minHeight: 'calc(100vh - 54px)',
};

const DefaultLayout = () => {
  const isScreenSmall = () => window.innerWidth <= 1024;

  const [isMobileView, setIsMobileView] = useState(isScreenSmall());
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const location = useLocation();

  const getPath = (pathname) => {
    const paths = pathname?.split('/')?.filter(Boolean);
    if (paths.includes('masters') || paths?.includes('reports')) {
      return `/${paths[0]}/${paths[1]}/${paths[2]}`;
    }
    if (paths.includes('admin')) {
      return `/${paths[0]}/${paths[1]}`;
    }
    if (!paths.includes('admin')) {
      return paths[0];
    }
    return pathname;
  };

  useEffect(() => {
    const path = getPath(location.pathname);
    const paths = path?.split('/')?.filter(Boolean);
    const basePath = paths?.length >= 2 ? `/${paths[0]}/${paths[1]}` : path;
    setSelectedKeys([path]);
    setOpenKeys([basePath]);
  }, [location.pathname]);

  const handleMainContentClick = () => {
    setIsMobileView(true);
  };

  const handleMenuClick = () => {
    setIsMobileView(!isMobileView);
  };

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isScreenSmall());
      setCollapsed(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const permissions = useSelector((state) => state?.auth?.userData?.permissions);

  const hasLeadPermission =
    // permissions?.includes("add-lead") ||
    permissions?.includes('view-lead');
  // permissions?.includes("edit-lead") ||
  // permissions?.includes("delete-lead");

  const hasUserPermission =
    // permissions?.includes("add-user") ||
    permissions?.includes('view-user');
  // permissions?.includes("edit-user") ||
  // permissions?.includes("delete-user");

  const hasTargetPermission = permissions?.includes('add-target');
  // permissions?.includes("view-target")||;
  // permissions?.includes("edit-target") ||
  // permissions?.includes("delete-target");

  const adminSideBarOptions = [
    {
      key: '/admin/dashboard',
      icon: <PieChartOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/dashboard'>
          Dashboard
        </Link>
      ),
    },
    {
      key: '/admin/users',
      icon: <UsergroupAddOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/users'>
          Users
        </Link>
      ),
    },
    {
      key: '/admin/permissions',
      icon: <ProductOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/permissions'>
          Permissions
        </Link>
      ),
    },
    {
      key: '/admin/leads',
      icon: <SolutionOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/leads'>
          Leads
        </Link>
      ),
    },
    {
      key: '/admin/add-target',
      icon: <ProductOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/add-target'>
          Targets
        </Link>
      ),
    },
    {
      key: '/admin/masters',
      icon: <UserOutlined />,
      label: 'Masters',
      children: [
        {
          key: '/admin/masters/target',
          icon: <AimOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/target'>
              Target
            </Link>
          ),
        },
        {
          key: '/admin/masters/model',
          icon: <CodepenOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/model'>
              Model
            </Link>
          ),
        },
        {
          key: '/admin/masters/variant',
          icon: <ClusterOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/variant'>
              Variant
            </Link>
          ),
        },
        {
          key: '/admin/masters/exchange-model',
          icon: <InteractionOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/exchange-model'>
              Exchange Vehicle
            </Link>
          ),
        },
        {
          key: '/admin/masters/vehicle',
          icon: <CarOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/vehicle'>
              Vehicle
            </Link>
          ),
        },

        {
          key: '/admin/masters/transmission',
          icon: <DashboardOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/transmission'>
              Transmission
            </Link>
          ),
        },
        {
          key: '/admin/masters/territory',
          icon: <EnvironmentOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/territory'>
              Territory
            </Link>
          ),
        },
        {
          key: '/admin/masters/area',
          icon: <BorderOuterOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/area'>
              Area
            </Link>
          ),
        },
        {
          key: '/admin/masters/bank',
          icon: <BankOutlined />,
          label: (
            <Link className='text-decoration-none' to='/admin/masters/bank'>
              Bank
            </Link>
          ),
        },
      ],
    },
    {
      key: '/admin/reports',
      icon: <FileTextOutlined />,
      label: (
        <Link className='text-decoration-none' to='/admin/reports'>
          Reports
        </Link>
      ),
    },
  ];

  const usersSideBarOptions = [
    {
      key: 'dashboard',
      icon: <PieChartOutlined />,
      label: (
        <Link className='text-decoration-none' to='/dashboard'>
          Dashboard
        </Link>
      ),
    },
    hasUserPermission && {
      key: 'users',
      icon: <UsergroupAddOutlined />,
      label: (
        <Link className='text-decoration-none' to='/users'>
          Users
        </Link>
      ),
    },
    hasLeadPermission && {
      key: 'leads',
      icon: <SolutionOutlined />,
      label: (
        <Link className='text-decoration-none' to='/leads'>
          Leads
        </Link>
      ),
    },
    roleType === 'Exchange Evaluation Team' && {
      key: 'leads',
      icon: <SolutionOutlined />,
      label: (
        <Link className='text-decoration-none' to='/leads/exchange-lead'>
          Leads
        </Link>
      ),
    },
    hasTargetPermission && {
      key: 'add-target',
      icon: <ProductOutlined />,
      label: (
        <Link className='text-decoration-none' to='/add-target'>
          Target
        </Link>
      ),
    },
  ];

  let sideBarMenu = isAdmin ? adminSideBarOptions : usersSideBarOptions;

  return (
    <Layout>
      <PageHeader
        userType={roleType}
        collapsed={collapsed}
        mobileView={isMobileView}
        handleMobileMenuToggle={() => {
          setIsMobileView(!isMobileView);
        }}
        toggleCollapsed={() => {
          setCollapsed(!collapsed);
        }}
        pageHeaderClick={() => handleMainContentClick()}
      />
      <Layout className='layout-with-sidebar'>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={() => setCollapsed(!collapsed)}
          width={225}
          style={{
            background: colorBgContainer,
          }}
          className={`custom-sidebar ${isMobileView ? 'hide' : 'show'}`}
        >
          <Menu
            mode='inline'
            style={{
              height: '100%',
              borderRight: 0,
            }}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            onClick={() => handleMenuClick()}
            items={sideBarMenu}
          />
        </Sider>
        <Content style={contentStyle} onClick={() => handleMainContentClick()}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
