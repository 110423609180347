// SearchableSelect.tsx

import React from "react";
import { Form } from "antd";
import SelectDropdown from "../SelectDropdown";

const InputSelect = ({
  name,
  onSearch,
  label,
  rules,
  options,
  onSelect,
  defaultValue,
  isSearchable = false,
  placeholder,
  extra,
  optionName,
  disable,
  allowClear = true,
  ...selectProps
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      extra={extra}
      {...selectProps}
    >
      <SelectDropdown
        options={options}
        name={label}
        id={name}
        disable={disable}
        showSearch={isSearchable}
        allowClear={allowClear}
        defaultValue={defaultValue}
        onSearch={onSearch}
        onChange={onSelect}
        optionName={optionName}
        placeholder={placeholder}
        {...selectProps}
      />
    </Form.Item>
  );
};

export default InputSelect;
