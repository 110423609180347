import React, { useState } from "react";
import { Col, Row, Form, Button, Typography } from "antd";
import { useSelector } from "react-redux";
import ExchangeEvaluation from "./ExchangeEvaluation";
import dayjs from "dayjs";
import { formatIndianCurrency } from "../../../../utills/helpers";

const { Title } = Typography;

const ExchaneEvaluationDetails = (props) => {
  const { leadViewDetails } = props;
  const [key, setKey] = useState("");
  const roleType = useSelector((state) => state?.auth?.userData?.role);

  const getDocumentInfo = (type) => {
    return leadViewDetails?.documents?.find(
      (doc) => doc.document_type === type
    );
  };

  const renderDocumentLink = (type, label) => {
    const docInfo = getDocumentInfo(type);
    return docInfo ? (
      <Form.Item
        name={`${type.toLowerCase()}_image`}
        label={label}
        layout="horizontal"
        className="form-item-horizontal"
      >
        <a
          href={docInfo.document_path}
          target="_blank"
          rel="noopener noreferrer"
        >
          {docInfo.document_file_name}
        </a>
      </Form.Item>
    ) : (
      <Form.Item
        name={`${type.toLowerCase()}_image`}
        label={label}
        layout="horizontal"
        className="form-item-horizontal"
      >
        <span>No file available</span>
      </Form.Item>
    );
  };
  const handleKey = (e) => {
    setKey(e);
  };

  return (
    <>
      {key === "" ? (
        <div className="user-data lead-detail">
          <div className="personal-detail">
            <div className="detail-wrapper">
              <div className="view-input-field">
                <Form layout="vertical">
                  <div className="d-flex justify-content-between align-items-center">
                    <Title level={4} className="mb-3">
                      Exchange Details
                    </Title>
                  </div>
                  <Row gutter={20} justify="start" className="mb-4">
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Make:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.make?.title
                            ? leadViewDetails?.exchange_vehicle_detail?.make
                                ?.title
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Model:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.model
                            ?.model_name
                            ? leadViewDetails?.exchange_vehicle_detail?.model
                                ?.model_name
                            : "-"}
                        </p>
                      </div>
                    </Col>

                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Transmmission:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail
                            ?.transmission?.name
                            ? leadViewDetails?.exchange_vehicle_detail
                                ?.transmission?.name
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Fuel:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.fuel_type
                            ?.title
                            ? leadViewDetails?.exchange_vehicle_detail
                                ?.fuel_type?.title
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Year:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.car_year
                            ? leadViewDetails?.exchange_vehicle_detail?.car_year
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Odometer:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.odometer
                            ? leadViewDetails?.exchange_vehicle_detail?.odometer
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Expected Price:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail
                            ?.expected_price
                            ? formatIndianCurrency(
                                leadViewDetails?.exchange_vehicle_detail
                                  ?.expected_price
                              )
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Insurance Validity:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail
                            ?.insurance_validity
                            ? dayjs(
                                leadViewDetails?.exchange_vehicle_detail
                                  ?.insurance_validity
                              ).format("DD/MM/YYYY")
                            : "-"}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                      <div className="view-fields d-flex align-items-baseline">
                        <h6>Remark:</h6>
                        <p className="">
                          {leadViewDetails?.exchange_vehicle_detail?.remarks
                            ? leadViewDetails?.exchange_vehicle_detail?.remarks
                            : "-"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div>
                    <Row gutter={[40, 0]} align="start" className="mb-3">
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Title level={4} className="mb-3">
                          Uploads & Attachment
                        </Title>
                        <Row gutter={[16, 0]} align="middle" className="mb-3">
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="flex-fill image-upload">
                              {renderDocumentLink("Front", "Front Image")}
                            </div>
                          </Col>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="flex-fill image-upload">
                              {renderDocumentLink("Back", "Back Image")}
                            </div>
                          </Col>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="flex-fill image-upload">
                              {renderDocumentLink("Side", "Side Image")}
                            </div>
                          </Col>
                          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <div className="flex-fill image-upload">
                              {renderDocumentLink("Inside", "Inside Image")}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
            <div className="user-right d-flex align-items-center justify-content-end mt-4">
              {leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 ||
              leadViewDetails?.exchange_vehicle_detail?.sent_for_evaluation ===
                0 ||
              roleType !== "Exchange Evaluation Team" ? (
                ""
              ) : (
                <Button
                  type="primary"
                  className="secondary-button"
                  onClick={() => handleKey("start")}
                >
                  Start an Evaluation
                </Button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ExchangeEvaluation />
      )}
    </>
  );
};

export default ExchaneEvaluationDetails;
