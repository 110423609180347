import { Image } from "antd";
import React from "react";

const CommanPreviewImage = (props) => {
  const { width, height, src } = props;
  return (
    <div>
      <Image width={width} height={height} src={src} />
    </div>
  );
};

export default CommanPreviewImage;
