/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
  notification,
} from "antd";
import Loader from "../../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../../utills/constant";
import UploadButton from "../../../../components/common/UploadButton";
import { AddExchangeDetails } from "../../../../services/admin-services";
import { fetchLeadDetailsServices } from "../../../auth/slice/authSlice";
import ExchangeDetailsDropdown from "./ExchangeD/ExchangeDetailsDropdown";
import InputSelect from "../../../../components/common/InputSelect";
import { getBankerService } from "../../../../services/master-services";
import ExchangeDetailsWithPRocured from "./ExchangeDetailsWithPRocured";
import PageContentHeader from "../../../../components/common/PageContentHeader";
import {
  notRequiredPriceValidationFunction,
  priceValidationFunction,
} from "../../../../utills/helpers";

const { Title } = Typography;
const ExchangeEvaluation = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [bankerList, setBankerList] = useState([]);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [fileList, setFileList] = useState({
    copy_of_rc: [],
    back_image: [],
    side_image: [],
    front_image: [],
    inside_image: [],
    bank_statement: [],
    copy_of_insurance: [],
    transfer_document: [],
    copy_of_customer_agreement: [],
  });

  const [fileList1, setFileList1] = useState({
    bank_statement: [],
    transfer_document: [],
  })

  const dateFormat = "DD/MM/YYYY";
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const [total, setTotal] = useState(
    leadViewDetails?.exchange_vehicle_detail?.total_refurishment_cost
  );
  const setUploadComponentData = (imagesData) => {
    const formattedFileLists = {
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
      copy_of_rc: [],
      copy_of_insurance: [],
      copy_of_customer_agreement: [],
    };

    imagesData?.forEach((doc) => {
      const file = {
        uid: doc.id,
        name: doc.document_file_name,
        status: "done",
        url: doc.document_path,
      };

      switch (doc.document_type) {
        case "Front":
          formattedFileLists.front_image.push(file);
          break;
        case "Back":
          formattedFileLists.back_image.push(file);
          break;
        case "Side":
          formattedFileLists.side_image.push(file);
          break;
        case "Inside":
          formattedFileLists.inside_image.push(file);
          break;
        case "Transfer":
          formattedFileLists.transfer_document.push(file);
          break;
        case "Bank Statement":
          formattedFileLists.bank_statement.push(file);
          break;
        case "RC":
          formattedFileLists.copy_of_rc.push(file);
          break;
        case "Insurance Policy":
          formattedFileLists.copy_of_insurance.push(file);
          break;
        case "Customer Agreement":
          formattedFileLists.copy_of_customer_agreement.push(file);
          break;
        default:
          break;
      }
    });

    setFileList(formattedFileLists);
  };

  const setBankAndTransferDoc = (exchangeVehicleDetails) => {
    const bankAndTransferDoc = {
      transfer_document: [],
      bank_statement: [],
    };

    const transferDocFile = {
      uid: exchangeVehicleDetails?.id,
      name: exchangeVehicleDetails?.transfer_document_file_name,
      status: "done",
      url: exchangeVehicleDetails?.transfer_document_file_path,
    };

    const bankStatementFile = {
      uid: exchangeVehicleDetails?.id,
      name: exchangeVehicleDetails?.bank_statement_file_name,
      status: "done",
      url: exchangeVehicleDetails?.bank_statement_file_path,
    };
    // Check for existence and push the documents
    if (exchangeVehicleDetails?.transfer_document_file_path) {
      bankAndTransferDoc.transfer_document.push(transferDocFile);
    }

    if (exchangeVehicleDetails?.bank_statement_file_path) {
      bankAndTransferDoc.bank_statement.push(bankStatementFile);
    }
    setFileList1(bankAndTransferDoc)

    return bankAndTransferDoc;
  };


  useEffect(() => {
    getBankData();
    form.setFieldsValue({
      ...leadViewDetails,
      tools: leadViewDetails?.exchange_vehicle_detail?.tools,
      remarks: leadViewDetails?.exchange_vehicle_detail?.remarks,
      bank_id: leadViewDetails?.exchange_vehicle_detail?.bank_id,
      odometer: leadViewDetails?.exchange_vehicle_detail?.odometer,
      year: leadViewDetails?.exchange_vehicle_detail?.car_year
        ? dayjs(leadViewDetails?.exchange_vehicle_detail?.car_year)
        : "",
      spare_keys: leadViewDetails?.exchange_vehicle_detail?.spare_keys,
      exchange_make_id: leadViewDetails?.exchange_vehicle_detail?.make_id,
      spare_wheels: leadViewDetails?.exchange_vehicle_detail?.spare_wheels,
      expected_price: leadViewDetails?.exchange_vehicle_detail?.expected_price,
      foreclose_loan: leadViewDetails?.exchange_vehicle_detail?.foreclose_loan,
      back_tyre_left: leadViewDetails?.exchange_vehicle_detail?.back_tyre_left,
      back_tyre_right:
        leadViewDetails?.exchange_vehicle_detail?.back_tyre_right,
      front_tyre_left:
        leadViewDetails?.exchange_vehicle_detail?.front_tyre_left,
      front_tyre_right:
        leadViewDetails?.exchange_vehicle_detail?.front_tyre_right,
      left_body_damage:
        leadViewDetails?.exchange_vehicle_detail?.left_body_damage,
      rear_body_damage:
        leadViewDetails?.exchange_vehicle_detail?.rear_body_damage,
      broker_valuation:
        leadViewDetails?.exchange_vehicle_detail?.broker_valuation,
      underbody_damage:
        leadViewDetails?.exchange_vehicle_detail?.underbody_damage,
      rust_body_damage:
        leadViewDetails?.exchange_vehicle_detail?.rust_body_damage,
      outstanding_loan:
        leadViewDetails?.exchange_vehicle_detail?.outstanding_loan,
      exchange_fuel_type_id:
        leadViewDetails?.exchange_vehicle_detail?.fuel_type_id,
      exchange_vehicle_model_id:
        leadViewDetails?.exchange_vehicle_detail?.model?.id,
      front_body_damage:
        leadViewDetails?.exchange_vehicle_detail?.front_body_damage,
      right_body_damage:
        leadViewDetails?.exchange_vehicle_detail?.right_body_damage,
      obv_purchase_value:
        leadViewDetails?.exchange_vehicle_detail?.obv_purchase_value,
      exchange_vehicle_variant_id:
        leadViewDetails?.exchange_vehicle_detail?.variant?.id,
      dealership_valuation:
        leadViewDetails?.exchange_vehicle_detail?.dealership_valuation,
      exchange_transmission_id:
        leadViewDetails?.exchange_vehicle_detail?.transmission?.id,
      insurance_validity: leadViewDetails?.exchange_vehicle_detail
        ?.insurance_validity
        ? dayjs(leadViewDetails?.exchange_vehicle_detail?.insurance_validity)
        : "",
      rear_refurishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.rear_refurishment_cost,
      rust_refurishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.rust_refurishment_cost,
      expected_selling_price:
        leadViewDetails?.exchange_vehicle_detail?.expected_selling_price,
      other_refurishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.other_refurishment_cost,
      total_refurishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.total_refurishment_cost,
      left_refurbishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.left_refurbishment_cost,
      rear_refurbishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.rear_refurbishment_cost,
      front_refurbishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.front_refurbishment_cost,
      right_refurbishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.right_refurbishment_cost,
      underbody_refurishment_cost:
        leadViewDetails?.exchange_vehicle_detail?.underbody_refurishment_cost,
      foreclosure_amount_paid_by_dealer:
        leadViewDetails?.exchange_vehicle_detail
          ?.foreclosure_amount_paid_by_dealer,
      value_offered: leadViewDetails?.exchange_vehicle_detail?.value_offered,
    });
    setUploadComponentData(leadViewDetails?.documents);
    setBankAndTransferDoc(
      leadViewDetails?.exchange_vehicle_detail
    );
  }, []);

  const getBankData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getBankerService(true);
      setBankerList(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const convertUrlToBlob = async (url) => {
    if (!url) return null;
    try {
      const response = await fetch(url);
      return await response.blob();
    } catch (error) {
      return null;
    }
  };

  const calculateTotal = (values) => {
    const fields = [
      "front_refurbishment_cost",
      "right_refurbishment_cost",
      "left_refurbishment_cost",
      "rear_refurishment_cost",
      "underbody_refurishment_cost",
      "rust_refurishment_cost",
      "other_refurishment_cost",
    ];

    const totalSum = fields.reduce((sum, field) => {
      const value = parseFloat(values[field]) || 0;
      return sum + value;
    }, 0);

    setTotal(totalSum);
  };

  useEffect(() => {
    form.setFieldsValue({ total_refurishment_cost: total });
  }, [total, form]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("lead_id", id);
    formData.append(
      "vehicle_procured",
      leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 && leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1
        ? "Yes"
        : "No"
    );
    formData.append("tools", values?.tools ? values?.tools : "");
    formData.append("remarks", values?.remarks ? values?.remarks : "");
    formData.append("bank_id", values?.bank_id ? values?.bank_id : "");
    formData.append("odometer", values?.odometer ? values?.odometer : "");
    formData.append("spare_keys", values?.spare_keys ? values?.spare_keys : "");
    formData.append(
      "year",
      values?.year ? dayjs(values.year).format("YYYY") : null
    );
    formData.append(
      "spare_wheels",
      values?.spare_wheels ? values?.spare_wheels : ""
    );
    formData.append(
      "expected_price",
      values?.expected_price ? values?.expected_price : ""
    );
    formData.append(
      "foreclose_loan",
      values?.foreclose_loan ? values?.foreclose_loan : ""
    );
    formData.append(
      "back_tyre_left",
      values?.back_tyre_left ? values?.back_tyre_left : ""
    );
    formData.append(
      "back_tyre_right",
      values?.back_tyre_right ? values?.back_tyre_right : ""
    );
    formData.append(
      "front_tyre_left",
      values?.front_tyre_left ? values?.front_tyre_left : ""
    );
    formData.append(
      "exchange_make_id",
      values?.exchange_make_id ? values?.exchange_make_id : ""
    );
    formData.append(
      "front_tyre_right",
      values?.front_tyre_right ? values?.front_tyre_right : ""
    );
    formData.append(
      "broker_valuation",
      values?.broker_valuation ? values?.broker_valuation : ""
    );
    formData.append(
      "left_body_damage",
      values?.left_body_damage ? values?.left_body_damage : ""
    );
    formData.append(
      "rear_body_damage",
      values?.rear_body_damage ? values?.rear_body_damage : ""
    );
    formData.append(
      "underbody_damage",
      values?.underbody_damage ? values?.underbody_damage : ""
    );
    formData.append(
      "rust_body_damage",
      values?.rust_body_damage ? values?.rust_body_damage : ""
    );
    formData.append(
      "outstanding_loan",
      values?.outstanding_loan ? values?.outstanding_loan : ""
    );
    formData.append(
      "front_body_damage",
      values?.front_body_damage ? values?.front_body_damage : ""
    );
    formData.append(
      "right_body_damage",
      values?.right_body_damage ? values?.right_body_damage : ""
    );
    formData.append(
      "obv_purchase_value",
      values?.obv_purchase_value ? values?.obv_purchase_value : ""
    );
    formData.append(
      "dealership_valuation",
      values?.dealership_valuation ? values?.dealership_valuation : ""
    );
    formData.append(
      "exchange_fuel_type_id",
      values?.exchange_fuel_type_id ? values?.exchange_fuel_type_id : ""
    );
    formData.append(
      "rear_refurishment_cost",
      values?.rear_refurishment_cost ? values?.rear_refurishment_cost : ""
    );
    formData.append(
      "rust_refurishment_cost",
      values?.rust_refurishment_cost ? values?.rust_refurishment_cost : ""
    );
    formData.append(
      "value_offered",
      values?.value_offered ? values?.value_offered : ""
    );
    formData.append(
      "other_refurishment_cost",
      values?.other_refurishment_cost ? values?.other_refurishment_cost : ""
    );
    formData.append(
      "total_refurishment_cost",
      values?.total_refurishment_cost ? values?.total_refurishment_cost : ""
    );
    formData.append(
      "left_refurbishment_cost",
      values?.left_refurbishment_cost ? values?.left_refurbishment_cost : ""
    );
    formData.append(
      "exchange_transmission_id",
      values?.exchange_transmission_id ? values?.exchange_transmission_id : ""
    );
    formData.append(
      "front_refurbishment_cost",
      values?.front_refurbishment_cost ? values?.front_refurbishment_cost : ""
    );
    formData.append(
      "right_refurbishment_cost",
      values?.right_refurbishment_cost ? values?.right_refurbishment_cost : ""
    );
    formData.append(
      "exchange_vehicle_model_id",
      values?.exchange_vehicle_model_id ? values?.exchange_vehicle_model_id : ""
    );
    formData.append(
      "underbody_refurishment_cost",
      values?.underbody_refurishment_cost
        ? values?.underbody_refurishment_cost
        : ""
    );
    formData.append(
      "exchange_vehicle_variant_id",
      values?.exchange_vehicle_variant_id
        ? values?.exchange_vehicle_variant_id
        : ""
    );
    formData.append(
      "foreclosure_amount_paid_by_dealer",
      values?.foreclosure_amount_paid_by_dealer
        ? values?.foreclosure_amount_paid_by_dealer
        : ""
    );

    formData.append(
      "insurance_validity",
      values?.insurance_validity
        ? dayjs(values?.insurance_validity).format("YYYY-MM-DD")
        : null
    );

    const appendFile = async (key, files) => {
      if (files?.length > 0) {
        const file =
          files[0].originFileObj || (await convertUrlToBlob(files[0].url));
        formData.append(key, file || "");
      } else {
        formData.append(key, "");
      }
    };
    await appendFile("back_image", fileList.back_image);
    await appendFile("side_image", fileList.side_image);
    await appendFile("front_image", fileList.front_image);
    await appendFile("inside_image", fileList.inside_image);
    await appendFile("bank_statement", fileList1.bank_statement);
    await appendFile("transfer_document", fileList1.transfer_document);

    if (
      leadViewDetails?.exchange_vehicle_detail?.vehicle_procured === "No" &&
      leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1
    ) {
      await appendFile("copy_of_rc", fileList.copy_of_rc);
      await appendFile("copy_of_insurance", fileList.copy_of_insurance);
      await appendFile(
        "copy_of_customer_agreement",
        fileList.copy_of_customer_agreement
      );
    }

    try {
      const response = await AddExchangeDetails(formData);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response.data.message,
        });
        const res = await dispatch(fetchLeadDetailsServices({ id: id }));
        if (
          res?.payload?.data?.data?.exchange_vehicle_detail?.is_evaluated ===
          1 &&
          res?.payload?.data?.data?.exchange_vehicle_detail
            ?.vehicle_procured === "No"
        ) {
          // navigate(`/exchange-evaluation-details/${id}`);
          setShowDetailsPage(true);
        } else {
          setShowDetailsPage(true);
        }
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList({
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
      copy_of_rc: [],
      copy_of_insurance: [],
      copy_of_customer_agreement: [],
      bank_statement: [],
      transfer_document: [],
    });
  };

  return (
    <>
      {showDetailsPage ? (
        <ExchangeDetailsWithPRocured />
      ) : (
        <>
          <Loader loading={isLoading} />
          <div className="user-data lead-detail">
            <Form
              layout="vertical"
              onFinish={onSubmit}
              form={form}
              onValuesChange={(_, values) => calculateTotal(values)}
            >
              {/* <Card className="mt-3"> */}
              {leadViewDetails?.exchange_vehicle_detail?.vehicle_procured ===
                "No" &&
                leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 ? (
                <div>
                  <PageContentHeader
                    title={leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1 ? "Procurement Details" : ""}
                    headerRight={
                      <Button
                        type="primary"
                        className="secondary-button"
                        onClick={() => setShowDetailsPage(true)}
                      >
                        Evaluation Details
                      </Button>
                    }
                  />
                  {leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1 && (
                    <Row gutter={20}>
                      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item label="Copy of RC" name="copy_of_rc">
                          <UploadButton
                            name="copy_of_rc"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                        <Form.Item
                          label="Copy of Customer Agreement"
                          name="copy_of_customer_agreement"
                        >
                          <UploadButton
                            name="copy_of_customer_agreement"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            documentType="copy_of_customer_agreement"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                        <Form.Item
                          label="Copy of Insurance"
                          name="copy_of_insurance"
                        >
                          <UploadButton
                            name="copy_of_insurance"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          // documentType="copy_of_insurance"
                          // id={id}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </div>
              ) : (
                ""
              )}
              {/* <div className="h-line">&nbsp;</div> */}
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <Title level={4} className="mb-3">
                    Exchange Details
                  </Title>
                </div>
                <Row gutter={[16, 0]} className="mb-3">
                  <ExchangeDetailsDropdown
                    setIsLoading={setIsLoading}
                    form={form}
                    leadViewDetails={leadViewDetails}
                  />
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Odometer"
                      name="odometer"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(
                                new Error("Please input your odometer details")
                              );
                            } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                              return Promise.reject(
                                new Error("The input must be numeric")
                              );
                            } else if (value?.length < 2) {
                              return Promise.reject(
                                new Error(
                                  "The input must be at least 2 digits long"
                                )
                              );
                            } else if (value?.length > 10) {
                              return Promise.reject(
                                new Error(
                                  "Odometer reading cannot be more than 10 digits"
                                )
                              );
                            } else if (/\s/.test(value)) {
                              return Promise.reject(
                                new Error(
                                  "The input must not contain whitespace"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Odometer"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Expected Price"
                      name="expected_price"
                      rules={[
                        {
                          required: true,
                          message: "Please input your expected price",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              // If the field is empty, it's considered valid
                              return Promise.resolve();
                            }
                            if (!/^\d{1,10}(\.\d{1,2})?$/.test(value)) {
                              return Promise.reject(
                                "Please enter a valid Expected price less than 10 digits"
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Expected Price"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Insurance Validity"
                      name="insurance_validity"
                      rules={[
                        {
                          required: true,
                          message: "Please input insurance validity",
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="h-line">&nbsp;</div>
              <div className="exchange-horizontal-label">
                <Row gutter={[40, 0]} align="start" className="mb-3">
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Tyre Status {/*<span className="asterisk">*</span> */}
                    </Title>
                    <Row gutter={[16, 0]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_tyre_left"
                          label="Front Tyre Left"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter front tyre left")
                                  );
                                } else if (!/^\d+$/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "Please enter a valid front tyre left"
                                    )
                                  );
                                } else if (value?.length > 10) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must be no more than 10 digits long"
                                    )
                                  );
                                } else if (/\s/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must not contain whitespace"
                                    )
                                  );
                                } else if (value > 100) {
                                  return Promise.reject(
                                    "The input must be less than or equal to 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Front Tyre Left"
                            className="form-control-ant"
                            suffix="%"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_tyre_right"
                          label="Front Tyre Right"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter front tyre right")
                                  );
                                } else if (!/^\d+$/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "Please enter a valid front tyre right"
                                    )
                                  );
                                } else if (value?.length > 10) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must be no more than 10 digits long"
                                    )
                                  );
                                } else if (/\s/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must not contain whitespace"
                                    )
                                  );
                                } else if (value > 100) {
                                  return Promise.reject(
                                    "The input must be less than or equal to 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Front Tyre Right"
                            className="form-control-ant"
                            suffix="%"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="back_tyre_left"
                          label="Back Tyre Left"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter back tyre left")
                                  );
                                } else if (!/^\d+$/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "Please enter a valid back tyre left"
                                    )
                                  );
                                } else if (value?.length > 10) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must be no more than 10 digits long"
                                    )
                                  );
                                } else if (/\s/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must not contain whitespace"
                                    )
                                  );
                                } else if (value > 100) {
                                  return Promise.reject(
                                    "The input must be less than or equal to 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Back Tyre Left"
                            className="form-control-ant"
                            suffix="%"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="back_tyre_right"
                          label="Back Tyre Right"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              validator: (_, value) => {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter back tyre right")
                                  );
                                } else if (!/^\d+$/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "Please enter a valid back tyre right"
                                    )
                                  );
                                } else if (value?.length > 10) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must be no more than 10 digits long"
                                    )
                                  );
                                } else if (/\s/.test(value)) {
                                  return Promise.reject(
                                    new Error(
                                      "The input must not contain whitespace"
                                    )
                                  );
                                } else if (value > 100) {
                                  return Promise.reject(
                                    "The input must be less than or equal to 100"
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            placeholder="Back Tyre Right"
                            className="form-control-ant"
                            suffix="%"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Title level={4} className="mb-3">
                      Valuation {/* <span className="asterisk">*</span> */}
                    </Title>
                    <Row gutter={[16, 0]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="obv_purchase_value"
                          label={
                            <>
                              OBV Purchase Value{" "}
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction("OBV Purchase Value")}
                        >
                          <Input
                            placeholder="OBV Purchase Value"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="broker_valuation"
                          label={
                            <>
                              Broker Valuation{" "}
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction("Broker Valuation")}
                        >
                          <Input
                            placeholder="Broker Valuation"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Body Damage {/* <span className="asterisk">*</span> */}
                    </Title>
                    <Row gutter={[16, 0]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_body_damage"
                          label="Front"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Front"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="right_body_damage"
                          label="Right"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Right"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="left_body_damage"
                          label="Left"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Left"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="rear_body_damage"
                          label="Rear"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Rear"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="underbody_damage"
                          label="Underbody"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Underbody"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="rust_body_damage"
                          label="Rust"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select the damage status",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Rust"
                          >
                            <Select.Option value="Normal">Normal</Select.Option>
                            <Select.Option value="Minor">Minor</Select.Option>
                            <Select.Option value="Major">Major</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Refurbishment Cost{" "}
                      {/*<span className="asterisk">*</span> */}
                    </Title>
                    <Row gutter={[16, 0]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_refurbishment_cost"
                          label={
                            <>
                              Front
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Front refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Front"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="right_refurbishment_cost"
                          label={
                            <>
                              Right
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Right refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Right"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="left_refurbishment_cost"
                          label={
                            <>
                              Left
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Left refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Left"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="rear_refurishment_cost"
                          label={
                            <>
                              Rear
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Rear refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Rear"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="underbody_refurishment_cost"
                          label={
                            <>
                              Underbody
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Underbody refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Underbody"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="rust_refurishment_cost"
                          label={
                            <>
                              Rust
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Rust refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Rust"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="other_refurishment_cost"
                          label={
                            <>
                              Other
                              <span className="asterisk inline-block ml-1">
                                *
                              </span>
                            </>
                          }
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={priceValidationFunction(
                            "Other refurbishment cost"
                          )}
                        >
                          <Input
                            placeholder="Other"
                            className="form-control-ant"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="total_refurishment_cost"
                          label="Total"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Total cost",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Total"
                            className="form-control-ant"
                            value={total}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="h-line">&nbsp;</div>
              <div className="mb-3">
                <Title level={4} className="mb-3">
                  Foreclosure Formalities
                </Title>
                <Row gutter={[16, 0]} align="start" className="mb-3">
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      name="bank_id"
                      placeholder="Select Bank"
                      options={bankerList}
                      label="Hypothecation by Bank"
                      optionName={"name"}
                      className="form-control-ant"
                      showSearch
                    />
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name="outstanding_loan"
                      label="Outstanding Loan"
                      rules={notRequiredPriceValidationFunction(
                        "Outstanding Loan"
                      )}
                    >
                      <Input
                        placeholder="Outstanding Loan"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name="foreclose_loan"
                      label="Will Dealership Foreclose Loan?"
                    >
                      <Select className="form-control-ant" placeholder="Yes/No">
                        <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                    <Form.Item
                      name="bank_statement"
                      label="Bank Statement Copy"
                    >
                      <UploadButton
                        name="bank_statement"
                        fileList={fileList1}
                        setFileList={setFileList1}
                        fileType={["image/jpeg", "image/png"]}
                        fileSizeLimit={5}
                        documentType="bank_statement"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="foreclosure_amount_paid_by_dealer"
                      label="Foreclosure Amount to be Paid by Dealership"
                      rules={notRequiredPriceValidationFunction(
                        "Foreclosure Amount to be Paid by Dealership"
                      )}
                    >
                      <Input
                        placeholder="Foreclosure Amount to be Paid by Dealership"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="h-line">&nbsp;</div>
              <div>
                <Row gutter={[40, 0]} align="start" className="mb-3">
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Other
                    </Title>
                    <Row gutter={[16, 0]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="transfer_document"
                          label="Transfer Document"
                          layout="horizontal"
                          className="form-item-horizontal"
                        >
                          <UploadButton
                            name="transfer_document"
                            fileList={fileList1}
                            setFileList={setFileList1}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            documentType="transfer_document"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="spare_keys"
                          label="Spare Keys"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select spare keys",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Spare Keys"
                          >
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="spare_wheels"
                          label="Spare Wheels"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select spare wheels",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Spare Wheels"
                          >
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="tools"
                          label="Tools"
                          layout="horizontal"
                          className="form-item-horizontal"
                          rules={[
                            {
                              required: true,
                              message: "Please select tools",
                            },
                          ]}
                        >
                          <Select
                            className="form-control-ant"
                            placeholder="Tools"
                          >
                            <Select.Option value="Yes">Yes</Select.Option>
                            <Select.Option value="No">No</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Image Upload
                    </Title>
                    <Row gutter={[16, 12]} align="middle" className="mb-3">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_image"
                          label="Front Image"
                          layout="horizontal"
                          className="form-item-horizontal mb-0"
                        >
                          <UploadButton
                            name="front_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="back_image"
                          label="Back Image"
                          layout="horizontal"
                          className="form-item-horizontal mb-0"
                        >
                          <UploadButton
                            name="back_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="side_image"
                          label="Side Image"
                          layout="horizontal"
                          className="form-item-horizontal mb-0"
                        >
                          <UploadButton
                            name="side_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="inside_image"
                          label="Inside Image"
                          layout="horizontal"
                          className="form-item-horizontal mb-0"
                        >
                          <UploadButton
                            name="inside_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Remark <span className="asterisk">*</span>
                    </Title>
                    <Form.Item
                      name="remarks"
                      label=""
                      rules={[
                        {
                          validator: (_, value) => {
                            const trimmedValue = value.trim();
                            if (!value) {
                              return Promise.reject(
                                new Error("Please input your remark")
                              );
                            } else if (value?.length > 300) {
                              return Promise.reject(
                                new Error(
                                  "Remark must be at most 300 characters"
                                )
                              );
                            } else if (/\s{2,}/.test(value)) {
                              return Promise.reject(
                                "Remark cannot contain two consecutive spaces"
                              );
                            } else if (trimmedValue !== value) {
                              return Promise.reject(
                                "Remark cannot start or end with spaces"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Remark" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="value_offered"
                      label={
                        <>
                          Value Offered
                          <span className="asterisk inline-block ml-1">*</span>
                        </>
                      }
                      layout="horizontal"
                      className="form-item-horizontal"
                      rules={priceValidationFunction("Value Offered")}
                    >
                      <Input
                        placeholder="Value Offered"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-4">
                {leadViewDetails?.exchange_vehicle_detail?.vehicle_procured ===
                  "No" &&
                  leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 &&
                  leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1
                  ? (
                    <Button
                      type="primary"
                      className="secondary-button"
                      htmlType="submit"
                    >
                      Done
                    </Button>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        ghost
                        danger
                        className="primary-button me-3"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        className="secondary-button"
                        htmlType="submit"
                      >
                        Save
                      </Button>
                    </>
                  )}
              </div>
              {/* </Card> */}
            </Form>
          </div>
        </>
      )}
    </>
  );
};

export default ExchangeEvaluation;
