/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Loader from '../../../components/common/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { ddmmyyFormatter, formatIndianCurrency } from '../../../utills/helpers';
import { Button, Row, Col, Typography, notification } from 'antd';
import CommanPreviewImage from '../../../components/common/CommanPreviewImage';

const LeadDetails = () => {
  const { Title } = Typography;
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const leadDetailsData = useSelector((state) => state?.auth?.leadDetails);
  const permissions = useSelector((state) => state?.auth?.userData?.permissions);
  const permissionToEdit = isAdmin || permissions?.includes('edit-lead');
  const isLeadConverted =
    leadDetailsData?.lead_status?.name === 'Converted' ||
    leadDetailsData?.lead_status?.name === 'Lost' ||
    leadDetailsData?.lead_status?.name === 'Dropped';

  const reason_detail =
    leadDetailsData?.lead_status_remark_detail?.reason_detail?.length > 0
      ? leadDetailsData?.lead_status_remark_detail?.reason_detail
      : [];

  useEffect(() => {
    getLeadsData(id);
  }, []);

  const getLeadsData = async () => {
    setIsLoading(true);
    try {
      // const stateData = await getLeadDetailsService(id);
      // const stateData = useSelector((state) => state?.auth?.leadDetails);
      // setLeadDetailsData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditForm = () => {
    navigate(isAdmin ? `/admin/leads/edit/${id}` : `/leads/edit/${id}`);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='user-data lead-detail'>
        <div className='user-table-head d-flex justify-content-between align-items-center'>
          {/* customer details */}
          <div className='personal-detail'>
            <div className='user-left text-start'>
              <Title level={4} className=''>
                Customer Details
              </Title>
            </div>
            <div className='detail-wrapper pt-2'>
              <div className='view-input-field'>
                <Row gutter={20} justify='start'>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Name:</h6>
                      <p className='value'>{leadDetailsData?.customer?.full_name}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Age:</h6>
                      <p className='value'>
                        {leadDetailsData?.customer?.age ? `${leadDetailsData?.customer?.age} years` : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Profession:</h6>
                      <p className='value'>
                        {leadDetailsData?.customer?.profession?.title
                          ? leadDetailsData?.customer?.profession?.title
                          : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Email:</h6>
                      <p className='value'>{leadDetailsData?.customer?.email}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Contact No:</h6>
                      <p className='value'>{leadDetailsData?.customer?.phone_no}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Address:</h6>
                      <p className='value'>{leadDetailsData?.customer?.address}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>City:</h6>
                      <p className='value'>{leadDetailsData?.customer?.city?.name}</p>
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Territory:</h6>
                      <p className='value'>{leadDetailsData?.customer?.territory?.name}</p>
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Area:</h6>
                      <p className='value'>{leadDetailsData?.customer?.area?.name}</p>
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>State:</h6>
                      <p className='value'>{leadDetailsData?.customer?.state?.name}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>RTO Location:</h6>
                      <p className='value'>
                        {leadDetailsData?.customer?.rto_location?.name
                          ? leadDetailsData?.customer?.rto_location?.name
                          : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Customer Type:</h6>
                      <p className='value'>
                        {leadDetailsData?.customer?.customer_type?.name
                          ? leadDetailsData?.customer?.customer_type?.name
                          : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Lead Source:</h6>
                      <p className='value'>{leadDetailsData?.enquiry_source?.source}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Exchange Car:</h6>
                      <p className='value'>{leadDetailsData?.exchange_car}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Expected Delivery Date:</h6>
                      <p className='value'>
                        {leadDetailsData?.expected_delivery_date
                          ? ddmmyyFormatter(leadDetailsData?.expected_delivery_date)
                          : '-'}
                      </p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Banker:</h6>
                      <p className='value'>{leadDetailsData?.bank_detail?.name}</p>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Choice of Vehicle Number:</h6>
                      <p className='value'>
                        {leadDetailsData?.choice_vehicle_no ? leadDetailsData?.choice_vehicle_no : '-'}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {/* vehicle details */}
            <div className='user-left text-start pt-3'>
              <Title level={4} className=''>
                Vehicle Details
              </Title>
            </div>
            <div className='detail-wrapper pt-2'>
              <Row gutter={20} justify='start'>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <div className='view-fields d-flex align-items-baseline'>
                    <h6 className='label'>Model:</h6>
                    <p className='value'>{leadDetailsData?.vehicle_detail?.model?.model_name}</p>
                  </div>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <div className='view-fields d-flex align-items-baseline'>
                    <h6 className='label'>Car Variant:</h6>
                    <p className='value'>{leadDetailsData?.vehicle_detail?.variant?.variant_code}</p>
                  </div>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <div className='view-fields d-flex align-items-baseline'>
                    <h6 className='label'>Color:</h6>
                    <p className='value'>
                      {leadDetailsData?.vehicle_detail?.color?.color
                        ? leadDetailsData?.vehicle_detail?.color?.color
                        : '-'}
                    </p>
                  </div>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <div className='view-fields d-flex align-items-baseline'>
                    <h6 className='label'>Fuel:</h6>
                    <p className='value'>{leadDetailsData?.vehicle_detail?.fuel_type?.title}</p>
                  </div>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                  <div className='view-fields d-flex align-items-baseline'>
                    <h6 className='label'>Seater:</h6>
                    <p className='value'>{leadDetailsData?.vehicle_detail?.seater?.seater}</p>
                  </div>
                </Col>
                {leadDetailsData?.vehicle_remarks && (
                  <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <div className='view-fields d-flex align-items-baseline'>
                      <h6 className='label'>Remark 1:</h6>
                      <p className='value'>{leadDetailsData?.vehicle_remarks}</p>
                    </div>
                  </Col>
                )}
              </Row>
            </div>

            {/* exchange details */}
            {leadDetailsData?.exchange_car === 'Yes' && (
              <>
                <div className='user-left text-start pt-3'>
                  <Title level={4} className=''>
                    Exchange Details
                  </Title>
                </div>
                <div className='detail-wrapper pt-2'>
                  <Row gutter={20} justify='start'>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Make:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.make?.title
                            ? leadDetailsData?.exchange_vehicle_detail?.make?.title
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Model:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.model?.model_name
                            ? leadDetailsData?.exchange_vehicle_detail?.model?.model_name
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Car Variant:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.variant?.variant_code
                            ? leadDetailsData?.exchange_vehicle_detail?.variant?.variant_code
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Transmission:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.transmission?.name
                            ? leadDetailsData?.exchange_vehicle_detail?.transmission?.name
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Fuel:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.fuel_type?.title
                            ? leadDetailsData?.exchange_vehicle_detail?.fuel_type?.title
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Year:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.car_year
                            ? leadDetailsData?.exchange_vehicle_detail?.car_year
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Odometer:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.odometer
                            ? leadDetailsData?.exchange_vehicle_detail?.odometer
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Expected Price:</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.expected_price
                            ? formatIndianCurrency(leadDetailsData?.exchange_vehicle_detail?.expected_price)
                            : '-'}
                        </p>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Insurance Validity</h6>
                        <p className='value'>
                          {leadDetailsData?.exchange_vehicle_detail?.insurance_validity
                            ? dayjs(leadDetailsData?.exchange_vehicle_detail?.insurance_validity).format('DD/MM/YYYY')
                            : '-'}
                        </p>
                      </div>
                    </Col>

                    {leadDetailsData?.exchange_vehicle_detail?.remarks && (
                      <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                        <div className='view-fields d-flex align-items-baseline'>
                          <h6 className='label'>Remark 2:</h6>
                          <p className='value'>
                            {leadDetailsData?.exchange_vehicle_detail?.remarks
                              ? leadDetailsData?.exchange_vehicle_detail?.remarks
                              : '-'}
                          </p>
                        </div>
                      </Col>
                    )}

                    {leadDetailsData?.documents?.map((ele, index) => {
                      return ele?.document_type === 'Front' ||
                        ele?.document_type === 'Back' ||
                        ele?.document_type === 'Inside' ||
                        ele?.document_type === 'Side' ? (
                        <Col xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                          <div className='view-fields d-flex align-items-baseline'>
                            <h6 className='label'>{ele.document_type} Image:</h6>
                            <div className='uploaded-img mb-3'>
                              <CommanPreviewImage
                                width={90}
                                height={90}
                                src={ele?.document_type ? ele?.document_path : '/images/avatar-green-bg.png'}
                              />
                            </div>
                          </div>
                        </Col>
                      ) : (
                        ''
                      );
                    })}
                  </Row>
                </div>
              </>
            )}

            {/* Lead Status Details  */}
            <>
              <div className='user-left text-start pt-3'>
                <Title level={4} className=''>
                  Lead Status Details
                </Title>
              </div>
              <div className='detail-wrapper pt-2'>
                <Row gutter={20} justify='start'>
                  {leadDetailsData?.lead_status?.name && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Lead Status:</h6>
                        <p className='value'>
                          {leadDetailsData?.lead_status?.name ? leadDetailsData?.lead_status?.name : '-'}
                        </p>
                      </div>
                    </Col>
                  )}
                  {leadDetailsData?.lead_status_remark_detail?.remark && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Remark:</h6>
                        <p className='value'>
                          {leadDetailsData?.lead_status_remark_detail?.remark
                            ? leadDetailsData?.lead_status_remark_detail?.remark
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  )}

                  {leadDetailsData?.lead_status_remark_detail?.reason?.name_of_competition && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Name of the competition:</h6>
                        <p className='value'>
                          {leadDetailsData?.lead_status_remark_detail?.reason?.name_of_competition
                            ? leadDetailsData?.lead_status_remark_detail?.reason?.name_of_competition
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  )}
                  {leadDetailsData?.lead_status_remark_detail?.reason?.name_of_the_co_dealer && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Name of the Co Dealer:</h6>
                        <p className='value'>
                          {leadDetailsData?.lead_status_remark_detail?.reason?.name_of_the_co_dealer
                            ? leadDetailsData?.lead_status_remark_detail?.reason?.name_of_the_co_dealer
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  )}
                  {leadDetailsData?.lead_status_remark_detail?.reason?.competition_model && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Competition model:</h6>
                        <p className='value'>
                          {leadDetailsData?.lead_status_remark_detail?.reason?.competition_model
                            ? leadDetailsData?.lead_status_remark_detail?.reason?.competition_model
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  )}
                  {reason_detail?.length > 0 && (
                    <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                      <div className='view-fields d-flex align-items-baseline'>
                        <h6 className='label'>Reason:</h6>
                        <p className='value'>
                          {reason_detail?.length > 0
                            ? reason_detail?.map((ele, index) => (
                                <ul key={index} className='ps-3'>
                                  <li>{ele}</li>
                                </ul>
                              ))
                            : '-'}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </>
            {permissionToEdit && !isLeadConverted ? (
              <div className='user-right d-flex align-items-center justify-content-end mt-4'>
                <Button type='primary' className='secondary-button' onClick={() => handleEditForm()}>
                  Edit
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadDetails;
