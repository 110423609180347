import React from "react";
import dayjs from "dayjs";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { EyeFilled } from "@ant-design/icons";

const { Title } = Typography;
const QuotationCard = (props) => {
  const { item, setShowQuotation,setQuotationId } = props;

  const leadDetails = useSelector((state) => state?.auth?.leadDetails);

  return (
    <>
      <div
        className="card quotation-card cursor-pointer"
        onClick={() => {
          setShowQuotation(true)
          setQuotationId(item?.id)
        }}
      >
        <div className="card-header d-flex justify-content-between align-items-center">
          <div className="card-left d-flex justify-content-between align-items-top">
            <Title level={4} className="">
              Quote No. <span>{item?.quotation_no}</span>
            </Title>
          </div>
          <div className="card-right">
            <EyeFilled
              onClick={() => {
                setShowQuotation(true)
              }}
            />
          </div>
        </div>
        <div className="card-content">
          {/* <div className='leadno d-flex align-items-baseline'>
            <h5 className="card-title">Lead No. :</h5>
            <p className="card-subtitle">2345</p>
          </div> */}
          <div className="exchange d-flex align-items-baseline">
            <h5 className="card-title">{item?.model?.model_name} - </h5>
            <h5 className="card-title">{item?.fuel_type?.title} - </h5>
            <h5 className="card-title">{item?.color?.color} - </h5>
            <h5 className="card-title">{item?.variant?.variant_code} </h5>
          </div>
          <div className="address text-start">
            <h5 className="card-title">
              {leadDetails?.customer?.full_name}{" "}
              <span className="card-title">
                {" "}
                {leadDetails?.customer?.address}
              </span>
            </h5>
          </div>
          {leadDetails?.exchange_car && (
            <div className="added-on d-flex align-items-baseline">
              <p className="card-subtitleyellow ps-0">Exchange Car</p>
            </div>
          )}
          <div className="follow-up d-lg-flex d-block align-items-baseline justify-content-between">
            <div className="follow-up d-flex align-items-baseline">
              <h5 className="card-title">Issue Date :</h5>
              <p className="card-subtitle">
                {dayjs(item?.issue_date).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="follow-up d-flex align-items-baseline">
              <h5 className="card-title">Valid Up TO :</h5>
              <p className="card-subtitle">
                {dayjs(item?.valid_upto).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationCard;
