import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EyeFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Checkbox, Badge, Typography, Tag, Tooltip } from "antd";
import Loader from "../common/Loader";
import { handleTabChange } from "../../app/auth/slice/authSlice";
import { ddmmyyFormatter } from "../../utills/helpers";
const { Title } = Typography;

const UserCard = (props) => {
  const {
    data,
    isLoading,
    setTransferLeadIds,
    transferLeadIds,
    dashboardRedirect,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const permissions = useSelector((state) => state.auth.userData.permissions);
  const isLeadDroppedOrLost =
    data?.lead_status_id === 2 || data?.lead_status_id === 3;

  const hasViewLeadPermission = isAdmin
    ? true
    : permissions?.includes("view-lead");
  const isDashboardRedirect = dashboardRedirect?.length > 0;
  const details = data;

  const handleCheckboxChange = (id) => {
    setTransferLeadIds((currentIds) => {
      const isIdPresent = currentIds?.includes(id);
      if (isIdPresent) {
        return currentIds?.filter((existingId) => existingId !== id);
      } else {
        return [...currentIds, id];
      }
    });
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="card card-lead h-100 card-cursour">
        <div className="card-header d-flex justify-content-start align-items-baseline w-100">
          <div className="card-left">
            {isAdmin && !isDashboardRedirect && (
              <Checkbox
                onClick={() => handleCheckboxChange(details?.id)}
                checked={transferLeadIds?.includes(details?.id)}
              />
            )}
          </div>

          <div
            className="card-right flex-fill"
            onClick={() => {
              dispatch(handleTabChange("leadDetails"));
              navigate(
                isAdmin
                  ? `/admin/leads/view/${details?.id}`
                  : `/leads/view/${details?.id}`
              );
            }}
          >
            <div className="card-content pe-0">
              <div className="badge d-flex justify-content-between align-items-center mb-2 w-100 px-0">
                <Title level={4} className="mb-0">
                  Lead Type: <span>{details?.lead_type?.name}</span>
                </Title>
                <div className="card-tag d-flex align-items-center ms-auto">
                  {hasViewLeadPermission && (
                    <EyeFilled
                      onClick={() => {
                        dispatch(handleTabChange("leadDetails"));
                        navigate(
                          isAdmin
                            ? `/admin/leads/view/${details?.id}`
                            : `/leads/view/${details?.id}`
                        );
                      }}
                    />
                  )}
                  {/* Tooltip for dropped or lost reason */}
                  {isLeadDroppedOrLost ? (
                    <Tooltip
                      title={data?.lead_status_remark_detail?.reason_detail?.map(
                        (ele,index) => (
                          <ul key={index}>
                            <li>{ele}</li>
                          </ul>
                        )
                      )}
                    >
                      <Badge
                        count={details?.lead_status?.name}
                        // color="yellow"
                        className={`lead-status-badge ${details?.lead_status?.name} ps-2`}
                      />
                    </Tooltip>
                  ) : (
                    <Badge
                      count={details?.lead_status?.name}
                      // color="yellow"
                      className={`lead-status-badge ${details?.lead_status?.name} ps-2`}
                    />
                  )}
                </div>
              </div>
              {/* <Title level={4} className='ps-1'>HOT</Title> */}
              <div className="leadno d-flex align-items-baseline flex-wrap">
                <h5 className="card-title">Lead No.:</h5>
                <p className="card-subtitle">{details?.id}</p>
                {details?.exchange_car === "Yes" && (
                  <Tag color="gold" className="lead-card-exchange-tag">
                    Exchange Car
                  </Tag>
                )}
              </div>
              <div className="leadno d-flex align-items-baseline flex-wrap">
                <h5 className="card-title">Lead Stage:</h5>
                <p className="card-subtitle">{details?.lead_stage?.name}</p>
              </div>
              <div className="leadno d-flex align-items-baseline flex-wrap">
                <h5 className="card-title">Lead Owner:</h5>
                <p className="card-subtitle">
                  {details?.lead_assignee?.full_name}
                </p>
              </div>

              <div className="exchange d-flex align-items-baseline">
                <h5 className="card-title">
                  {details?.vehicle_detail?.model?.model_name}-
                  {details?.vehicle_detail?.fuel_type?.title}-
                  {details?.vehicle_detail?.color?.color
                    ? `${details?.vehicle_detail?.color?.color}`
                    : null}
                </h5>
              </div>

              <div className="address text-start">
                <h5 className="card-title">
                  {details?.customer?.full_name}, {details?.customer?.phone_no}
                </h5>
              </div>
              <div className="address text-start">
                <h5 className="card-title">
                  Address:
                  <span className="card-title">
                    {" "}
                    {details?.customer?.address}
                  </span>
                </h5>
              </div>

              <div className="added-on d-flex align-items-baseline">
                <h5 className="card-title">Added On:</h5>
                <p className="card-subtitle">
                  {details?.created_at
                    ? ddmmyyFormatter(details?.created_at)
                    : "-"}
                  {/* {formatDate(details?.created_at)} */}
                </p>
              </div>
              <div className="follow-up d-flex align-items-baseline">
                <h5 className="card-title">Next Follow Up:</h5>
                <p className="card-subtitle">
                  {details?.next_follow_up_date
                    ? ddmmyyFormatter(details?.next_follow_up_date)
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserCard;
