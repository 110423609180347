import React from 'react';
import AdminDashboard from '../../admin/dashboard/AdminDashboard';

const SalesDashboard = () => {
  return (
    <div>
      <AdminDashboard />
    </div>
  );
};

export default SalesDashboard;
