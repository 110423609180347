import React from 'react';
import { Col, Form, Input } from 'antd';
import {
  numberValidationFunction,
  percentageValidationFunction,
  pricevalidationwithAsterisklength7,
} from '../../utills/helpers';

const TargetField = ({ label, name, min, addonAfter, placeholder, addonBefore, validation }) => {
  return (
    <>
      <Col xl={4} lg={6} md={6} sm={24} xs={24}>
        <div className='login-input-field'>
          <Form.Item
            name={name}
            label={label}
            rules={
              //To handle validation depending on the field type
              validation === 'percentage'
                ? percentageValidationFunction(label.toLowerCase())
                : validation === 'amount'
                  ? pricevalidationwithAsterisklength7(label.toLowerCase())
                  : numberValidationFunction(label.toLowerCase())
            }
          >
            <Input
              min={min}
              addonAfter={addonAfter}
              addonBefore={addonBefore}
              placeholder={placeholder}
              className='form-control-ant'
              style={{ width: '100%' }}
            />
          </Form.Item>
        </div>
      </Col>
    </>
  );
};

export default TargetField;
