/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import InputSelect from '../../../../components/common/InputSelect';
import {
  getVehicleColorService,
  getVehicleFuelTypeService,
  getVehicleModelService,
  getVehicleSeaterService,
  getVehicleVarientService,
} from '../../../../services/master-services';

const BookingDropdowns = (props) => {
  const { leadViewDetails, form, setIsLoading, excVehicleIds, setExcVehicleIds } = props;


  const [seatData, setSeatData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [varientData, setVarientData] = useState([]);
  const [excFuelData, setExcFuelData] = useState([]);


  useEffect(() => {
    getModalData(leadViewDetails?.vehicle_detail?.model?.make_id);
  }, []);

  const getFuelData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const fuelData = await getVehicleFuelTypeService(make_id, model_id, variant_id);
      setExcFuelData(fuelData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getColorData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const colorData = await getVehicleColorService(make_id, model_id, variant_id);
      setColorData(colorData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id);
      setVarientData(varientDataArray?.data?.data);
      if (leadViewDetails?.vehicle_detail?.fuel_type_id) {
        getFuelData(
          leadViewDetails?.vehicle_detail?.model?.make_id,
          leadViewDetails?.vehicle_detail?.model?.id,
          leadViewDetails?.vehicle_detail?.variant?.id
        );
      }
      // if (leadViewDetails?.vehicle_detail?.color_id) {
      getColorData(
        leadViewDetails?.vehicle_detail?.model?.make_id,
        leadViewDetails?.vehicle_detail?.model?.id,
        leadViewDetails?.vehicle_detail?.variant?.id
      );
      // }
      if (leadViewDetails?.vehicle_detail?.seater_id) {
        getSeatData(
          leadViewDetails?.vehicle_detail?.model?.make_id,
          leadViewDetails?.vehicle_detail?.model?.id,
          leadViewDetails?.vehicle_detail?.variant?.id
        );
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(value);
      setModalData(modalDataArray?.data?.data);
      if (leadViewDetails?.vehicle_detail?.variant?.id) {
        getVarientData(leadViewDetails?.vehicle_detail?.model?.id);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getSeatData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const seatData = await getVehicleSeaterService(make_id, model_id, variant_id);
      setSeatData(seatData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcModalChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      model_id: value,
    }));
    form.setFieldsValue({
      vehicle_variant_id: '',
      fuel_type_id: '',
      color_id: '',
      seater_id: '',
    });
    getVarientData(value);
  };

  const handleExcVariantChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      variant_id: value,
    }));
    form.setFieldsValue({
      fuel_type_id: '',
    });
    getColorData(leadViewDetails?.vehicle_detail?.model?.make_id, excVehicleIds?.model_id, value);
  };

  const handleColorChange = (val) => {
    setExcVehicleIds((state) => ({
      ...state,
      color_type_id: val,
    }));
    getFuelData(
      leadViewDetails?.vehicle_detail?.model?.make_id,
      excVehicleIds?.model_id,
      excVehicleIds?.vehicle_variant_id
    );
  };

  const handleFuelChange = (val) => {
    // setMasterData([]);
    setExcVehicleIds((state) => ({
      ...state,
      color_type_id: val,
    }));
    getSeatData(
      leadViewDetails?.vehicle_detail?.model?.make_id,
      excVehicleIds?.model_id,
      excVehicleIds?.vehicle_variant_id
    );
  };

  return (
    <>
      <div className='price-detail-wrap d-flex align-items-baseline'>
        <h6>
          Model <span className='asterisk'>*</span>
        </h6>
        <div className='lead-input'>
          <InputSelect
            rules={[
              {
                required: true,
                message: 'Please select your car model',
              },
            ]}
            name='vehicle_model_id'
            placeholder='Model'
            options={modalData}
            // label="Model"
            optionName={'model_name'}
            onSelect={(val) => {
              handleExcModalChange(val);
            }}
            showSearch
          // filterOptions={filterOption}
          />
        </div>
      </div>
      <div className='price-detail-wrap d-flex align-items-baseline'>
        <h6>
          Variant <span className='asterisk'>*</span>
        </h6>
        <div className='lead-input'>
          <InputSelect
            rules={[
              {
                required: true,
                message: 'Please select your car variant',
              },
            ]}
            name='vehicle_variant_id'
            placeholder='Car Variant'
            options={varientData}
            // label="Variant"
            optionName={'variant_code'}
            onSelect={(val) => {
              handleExcVariantChange(val);
            }}
            showSearch
          />
        </div>
      </div>
      <div className='price-detail-wrap d-flex align-items-baseline'>
        <h6>
          Color <span className='asterisk'>*</span>
        </h6>
        <div className='lead-input'>
          <InputSelect
            rules={[
              {
                required: true,
                message: 'Please select your car color',
              },
            ]}
            name='color_id'
            placeholder='Color'
            options={colorData}
            // label="Color"
            optionName={'color'}
            onSelect={(val) => {
              handleColorChange(val);
            }}
            showSearch
          // filterOptions={filterOption}
          />
        </div>
      </div>
      <div className='price-detail-wrap d-flex align-items-baseline'>
        <h6>
          Fuel <span className='asterisk'>*</span>
        </h6>
        <div className='lead-input'>
          <InputSelect
            rules={[
              {
                required: true,
                message: 'Please select your car fuel type',
              },
            ]}
            name='fuel_type_id'
            placeholder='Fuel'
            options={excFuelData}
            // label="Fuel"
            onSelect={(val) => {
              handleFuelChange(val);
            }}
            optionName={'title'}
            showSearch
          />
        </div>
      </div>
      <div className='price-detail-wrap d-flex align-items-baseline'>
        <h6>
          Seater <span className='asterisk'>*</span>
        </h6>
        <div className='lead-input'>
          <InputSelect
            rules={[
              {
                required: true,
                message: 'Please select seats',
              },
            ]}
            name='seater_id'
            placeholder='Seater'
            options={seatData}
            // label="Seater"
            optionName={'seater'}
            onChange={(val) => {
              // setMasterData([]);
              setExcVehicleIds((state) => ({
                ...state,
                seater_id: val,
              }));
            }}
            showSearch
          />
        </div>
      </div>
    </>
  );
};

export default BookingDropdowns;
