import axios from 'axios';
import { API_BASE_URL } from '../utills/constant';

export const loginService = async (data) => {
  // await axios.post(`${API_BASE_URL}/authentication/login`, {
  //   ...data,
  // });
  return await axios.post(`${API_BASE_URL}/authentication/login`, {
    ...data,
  });
};

export const forgotPasswordService = async (data) => {
  return await axios.post(`${API_BASE_URL}/authentication/forgot-password`, {
    ...data,
  });
};
export const resetPasswordService = async (data) => {
  return await axios.post(`${API_BASE_URL}/authentication/reset-password`, {
    ...data,
  });
};

export const changePasswordService = async (data) => {
  return await axios.post(`${API_BASE_URL}/authentication/change-password`, {
    ...data,
  });
};

export const validateTokenService = async (token) => {
  return await axios.get(`${API_BASE_URL}/authentication/validate-token`, {
    params: { token },
  });
};

export const logoutService = async () => {
  const loginUUID = localStorage.getItem('loginUUID');
  return await axios.post(`${API_BASE_URL}/authentication/logout?uuid=${loginUUID}`);
};

export const getAdminProfileDetails = async () => {
  return await axios.get(`${API_BASE_URL}/authentication/get-profile-details`);
};

export const updateCompanyProfileService = async (data) => {
  const formData = new FormData();

  // Loop through all keys in the data object and append them to FormData
  for (const key in data) {
    // Check if the value is a File object
    if (data[key] instanceof File) {
      // Append the File object itself
      formData.append(key, data[key]);
    } else {
      // Append other types of values directly
      formData.append(key, data[key]);
    }
  }

  return await axios.post(
    `${API_BASE_URL}/authentication/update-company-profile-details`,
    formData
  );
};

export const updateProfileDetailsService = async (data) => {
  return await axios.post(
    `${API_BASE_URL}/authentication/update-profile-details`,
    data
  );
};
