/* eslint-disable react-hooks/exhaustive-deps */
// import { useRouteError } from "react-router-dom";

import React,{ useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Result, Card } from "antd";

export default function ErrorPage() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // useEffect to handle authentication
  useEffect(() => {
    if (!isAuthenticated && !localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <div id="error-page" className="user-data">
      <div className="personal-detail">
        <Card>
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            // extra={<Button type="primary">Back Home</Button>}
          />
        </Card>
      </div>
    </div>
  );
}
