/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, notification } from 'antd';
import InputSelect from '../../../components/common/InputSelect';
import {
  getVehicleColorService,
  getVehicleFuelTypeService,
  getVehicleModelService,
  getVehicleSeaterService,
  getVehicleVarientService,
} from '../../../services/master-services';

const QuotationVehicleDetails = (props) => {
  const { form, setIsLoading, excVehicleIds, setExcVehicleIds, setMasterData } = props;

  const leadDetails = useSelector((state) => state?.auth?.leadDetails);

  const [fuelData, setFuelData] = useState([]);
  const [seatData, setSeatData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [varientData, setVarientData] = useState([]);

  useEffect(() => {
    getModalData(leadDetails?.vehicle_detail?.model?.make_id);
    form.setFieldsValue({
      vehicle_model_id: leadDetails?.vehicle_detail?.vehicle_model_id,
      vehicle_variant_id: leadDetails?.vehicle_detail?.vehicle_variant_id,
      color_id: leadDetails?.vehicle_detail?.color_id,
      fuel_type_id: leadDetails?.vehicle_detail?.fuel_type_id,
      seater_id: leadDetails?.vehicle_detail?.seater_id,
    });
  }, []);

  const getModalData = async (value) => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(value);
      if (leadDetails?.vehicle_detail?.model?.id) {
        getVarientData(leadDetails?.vehicle_detail?.model?.id);
      }
      setModalData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getVarientData = async (model_id) => {
    setIsLoading(true);
    try {
      const varientDataArray = await getVehicleVarientService(model_id);
      setVarientData(varientDataArray?.data?.data);
      if (leadDetails?.vehicle_detail?.model?.id) {
        getColorData(
          leadDetails?.vehicle_detail?.model?.make_id,
          leadDetails?.vehicle_detail?.model?.id,
          leadDetails?.vehicle_detail?.vehicle_variant_id
        );
        getFuelData(
          leadDetails?.vehicle_detail?.model?.make_id,
          leadDetails?.vehicle_detail?.model?.id,
          leadDetails?.vehicle_detail?.vehicle_variant_id
        );
        getSeatData(
          leadDetails?.vehicle_detail?.model?.make_id,
          leadDetails?.vehicle_detail?.model?.id,
          leadDetails?.vehicle_detail?.vehicle_variant_id
        );
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getFuelData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const fuelData = await getVehicleFuelTypeService(make_id, model_id, variant_id);
      setFuelData(fuelData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getColorData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const colorData = await getVehicleColorService(make_id, model_id, variant_id);
      setColorData(colorData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSeatData = async (make_id, model_id, variant_id) => {
    setIsLoading(true);
    try {
      const seatData = await getVehicleSeaterService(make_id, model_id, variant_id);
      setSeatData(seatData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcModalChange = async (value) => {
    setExcVehicleIds((state) => ({
      ...state,
      model_id: value,
      vehicle_variant_id: undefined,
      color_type_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    }));
    form.setFieldsValue({
      vehicle_variant_id: undefined,
      color_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    });
    if (value === undefined) {
      setFuelData([]);
      setSeatData([]);
      setColorData([]);
      setVarientData([]);
      setMasterData([]);
    } else {
      getVarientData(value);
    }
  };

  const handleExcVariantChange = async (value) => {
   
    setExcVehicleIds((state) => ({
      ...state,
      vehicle_variant_id: value,
      color_type_id: undefined,
      fuel_type_id: undefined,
      seater_id: undefined,
    }));

    form.setFieldsValue({
      color_id: null,
      fuel_type_id: null,
      seater_id: null,
    });
    if (value === undefined) {
      setFuelData([]);
      setSeatData([]);
      setColorData([]);
      setMasterData([]);
    } else {
      getFuelData(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
      getColorData(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
      getSeatData(excVehicleIds?.make_id, excVehicleIds?.model_id, value);
    }
  };

  const handleColorChange = (val) => {
    setMasterData([]);
    setExcVehicleIds((state) => ({
      ...state,
      color_type_id: val,
    }));
  };

  return (
    <>
      {/* Modal Id  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          allowClear={false}
          rules={[
            {
              required: true,
              message: 'Please select model',
            },
          ]}
          name='vehicle_model_id'
          placeholder='Model'
          options={modalData}
          label='Model:'
          optionName={'model_name'}
          onSelect={(val) => {
            handleExcModalChange(val);
          }}
          showSearch
          // filterOptions={filterOption}
        />
      </Col>

      {/* Car Variant  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          allowClear={false}
          rules={[
            {
              required: true,
              message: 'Please select variant',
            },
          ]}
          name='vehicle_variant_id'
          placeholder='Car Variant'
          options={varientData}
          label='Car Variant:'
          optionName={'variant_code'}
          onSelect={(val) => {
            handleExcVariantChange(val);
          }}
          showSearch
          // filterOptions={filterOption}
        />
      </Col>

      {/* Vehicle Color  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          allowClear={false}
          rules={[
            {
              required: true,
              message: 'Please select color',
            },
          ]}
          name='color_id'
          placeholder='Color'
          options={colorData}
          label='Color:'
          optionName={'color'}
          onSelect={(val) => {
            handleColorChange(val);
          }}
          showSearch
          // filterOptions={filterOption}
        />
      </Col>

      {/* Fuel */}
      <Col xl={4} lg={6} md={8} sm={24} xs={24}>
        <InputSelect
          allowClear={false}
          rules={[
            {
              required: true,
              message: 'Please select fuel',
            },
          ]}
          name='fuel_type_id'
          placeholder='Fuel'
          options={fuelData}
          label='Fuel:'
          optionName={'title'}
          onChange={(val) => {
            setMasterData([]);
            setExcVehicleIds((state) => ({
              ...state,
              fuel_type_id: val,
            }));
          }}
          showSearch
        />
      </Col>

      {/* Seater Id  */}
      <Col xl={4} md={8} sm={24} xs={24}>
        <InputSelect
          allowClear={false}
          rules={[
            {
              required: true,
              message: 'Please select seats',
            },
          ]}
          name='seater_id'
          placeholder='Seater'
          options={seatData}
          label='Seater:'
          optionName={'seater'}
          onChange={(val) => {
            setMasterData([]);
            setExcVehicleIds((state) => ({
              ...state,
              seater_id: val,
            }));
          }}
          showSearch
        />
      </Col>
    </>
  );
};

export default QuotationVehicleDetails;
