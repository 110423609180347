/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  notification,
  Typography,
  Space,
  Switch,
} from "antd";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  addAreaService,
  getStateService,
  getTerritoriesService,
} from "../../../services/master-services";
import InputSelect from "../../../components/common/InputSelect";
import { API_RESPONSE_CODES } from "../../../utills/constant";
const { Title } = Typography;

const AddArea = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [form] = Form.useForm(); // Ant Design form instance
  const [stateData, setStateData] = useState([]); // state to store state data
  const [isLoading, setIsLoading] = useState(false); // state to manage loading status
  const [territoryData, setTerritoryData] = useState([]); // state to territory state data
  const [filters, setFilters] = useState({
    state_id: "",
    area_id: "",
    territory_id: "",
  }); // state to store filter data

  // useEffect to get state master data
  useEffect(() => {
    getStateData();
  }, []);

  //const to check state data
  const isStatePresent = filters?.state_id;

  //function to get state master data
  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // function to get territory master data
  const getTerritoryData = async (state_id) => {
    // const findStates = stateData?.filter((ele) => state_id.includes(ele.slug));
    // const findState_id = findStates?.map((ele) => ele.id);
    try {
      setIsLoading(true);
      const territoryData = await getTerritoriesService(state_id, true);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // function to handle state change
  const handleStateChange = (value) => {
    if (value) {
      setFilters((prev) => ({
        ...prev,
        state_id: value,
      }));
      getTerritoryData(value);
    } else {
      setFilters((prev) => ({
        ...prev,
        state_id: "",
      }));
      setTerritoryData([]);
    }
    setFilters((prev) => ({
      ...prev,
      territory_id: "",
    }));
    form.setFieldsValue({
      territory_id: null,
      name: null,
    });
  };

  // function to handle territory change
  const handleTerritoryChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        territory_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        territory_id: "",
      }));
    }
    form.setFieldsValue({
      name: null,
    });
  };

  // function to handle submit
  const handleSubmit = async (values) => {
    values.status = values.status === true ? 1 : 0;
    try {
      setIsLoading(true);
      const response = await addAreaService(values);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response?.data?.message,
        });
        navigate("/admin/masters/area");
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        <Loader loading={isLoading} />
        <PageContentHeader title="Add Area" />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Area Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  {/* State Filter */}
                  <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      label="State"
                      name="state_id"
                      options={stateData}
                      placeholder="State"
                      optionName={"name"}
                      onSelect={handleStateChange}
                      className="form-control-ant"
                      rules={[
                        {
                          required: true,
                          message: "Please select your state",
                        },
                      ]}
                    />
                  </Col>

                  {/* Territory Filter */}
                  <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      label="Territory"
                      name="territory_id"
                      optionName={"name"}
                      placeholder="Territory"
                      options={territoryData}
                      // disable={!isStatePresent}
                      className="form-control-ant"
                      onSelect={handleTerritoryChange}
                      rules={[
                        {
                          required: true,
                          message: "Please select your territory",
                        },
                      ]}
                    />
                  </Col>

                  {/* Area */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="name"
                        label="Area"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject("Please input your area");
                              }

                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Area cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Area cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Area must be between 3 and 30 characters"
                                );
                              }
                              if (!/^[a-zA-Z0-9\s()]+$/.test(value)) {
                                return Promise.reject(
                                  "Area can only contain alphanumeric characters"
                                );
                              }
                              // Check if the string is numeric only
                              if (/^[\d()]+$/.test(value)) {
                                return Promise.reject(
                                  "Area cannot be only numeric values"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Area"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/area")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddArea;
