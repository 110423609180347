/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  notification,
  Typography,
  Space,
} from "antd";
import {
  addModelService,
  getVehicleMakeService,
} from "../../../services/master-services";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import InputSelect from "../../../components/common/InputSelect";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import PageContentHeader from "../../../components/common/PageContentHeader";

const { Title } = Typography;
/**
 * handleSubmit function handles the form submission.
 * It sends the form data to the server and handles the response.
 * @param {Object} values - Form values
 */

const AddModel = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [form] = Form.useForm(); // Ant Design form instance
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const [makeData, setMakeData] = useState([]);

  useEffect(() => {
    getMakeData();
  }, []);

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const makeDataArray = await getVehicleMakeService();
      if (makeDataArray.data.status === API_RESPONSE_CODES.OK) {
        setMakeData(makeDataArray?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true); // Set loading state to true
      // Simulate a successful API response
      const response = await addModelService(values);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response.data.message,
        });
      }
      navigate("/admin/masters/model");
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="user-data">
        {/* Loader component to show loading spinner */}
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title="Add Model" />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Model Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  {/* Make Filter */}
                  <Col xl={6} md={8} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      name="make_id"
                      label="Make"
                      options={makeData}
                      placeholder="Make"
                      optionName={"title"}
                      className="form-control-ant"
                      rules={[
                        {
                          required: true,
                          message: "Please select car make",
                        },
                      ]}
                    />
                  </Col>

                  {/* Model Name */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        label="Model"
                        name="model_name"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your car model"
                                );
                              }

                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Model cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Model cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Model must be between 3 and 30 characters"
                                );
                              }
                              if (!/^[a-zA-Z0-9\s()]+$/.test(value)) {
                                return Promise.reject(
                                  "Model can only contain alphanumeric characters"
                                );
                              }
                              // Check if the string is numeric only
                              if (/^[\d()]+$/.test(value)) {
                                return Promise.reject(
                                  "Model cannot be only numeric values"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Model"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  {/* <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[
                          {
                            required: false,
                            message: "",
                          },
                        ]}
                      >
                        <Switch
                          defaultChecked
                          onChange={statusChange}
                          checkedChildren="Active"
                          unCheckedChildren="Inactive"
                        />
                      </Form.Item>
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/model")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddModel;
