export function comparePasswords(password, passwordConfirmation) {
    // Convert both passwords to lowercase for case-insensitive comparison
    var passwordLower = password.toLowerCase();
    var passwordConfirmationLower = passwordConfirmation.toLowerCase();

    // Compare the passwords
    if (passwordLower === passwordConfirmationLower) {
        return true; // Passwords match
    } else {
        return false; // Passwords do not match
    }
}