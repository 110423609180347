/* eslint-disable no-case-declarations */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Input, Card, notification, Button, Modal, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PlusCircleOutlined, MinusCircleOutlined, AimOutlined } from '@ant-design/icons';
import { fetchManagerTargetCalculation } from '../../auth/slice/authSlice';
import { Target_TYPE } from '../../../utills/constant';
import Loader from '../../../components/common/Loader';
import InputSelect from '../../../components/common/InputSelect';

const UpdatedTargets = (props) => {
    const { form, item, id, getYearParams, setKpiData,
        selectedYear, selectedMonth, isLoading, setIsLoading, modalData, getRoleParams
    } = props;

    const { monthID } = useParams();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        option: '',
        amount: 0,
        totalAmount: 0,
        name: '',
    });
    const [dataList, setDataList] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(new Set());
    const calculatedData = useSelector((state) => state?.auth?.calculatedData);
    const roleType = useSelector((state) => state?.auth?.userData?.role);
    const isTeamLeader = roleType === "Team Leader";

    const getEnquiryValue = calculatedData?.enquiry?.map((el) => el)

    const handleShowModal = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Model Name',
            dataIndex: 'model_name',
            key: 'model_name',
        },
        {
            title: 'Total Targets',
            dataIndex: 'total_targets',
            key: 'total_targets',
        },
        {
            title: 'Distributed Targets',
            dataIndex: 'target_distributed',
            key: 'target_distributed',
        },
        {
            title: 'Remaining Targets',
            dataIndex: 'remaining_target',
            key: 'remaining_target',
        },
    ];

    const handleInputChangePrevious = (e) => {
        const { name, value } = e.target;

        // let numericValue = parseFloat(value);
        // const isDecimal = numericValue !== parseInt(value, 10);
        // if (numericValue <= 0 || isDecimal) {
        //     numericValue = "";
        // }
        // else {
        //     let inputType = "Number";
        //     if (inputType === "Number") {
        //         numericValue =
        //             numericValue?.toString()?.length > 3
        //                 ? parseFloat(value?.toString()?.slice(0, 3))
        //                 : numericValue;
        //     }
        // }
        // let adjustedValue = numericValue?.toString();

        // setFormData(prevKpiData => ({
        //     ...prevKpiData,
        //     ...formData,
        //     [name]: adjustedValue,
        // }))

        // form.setFieldsValue({
        //     amount: adjustedValue,
        //     totalAmount: 0,
        //     name: '',
        // })

        const numericValue = parseInt(value, 10);
        const isThreeDigits = value?.length <= 3;

        // If input is empty or not a number, reset to empty string
        if (value === '' || isNaN(numericValue) || numericValue < 0 || !isThreeDigits) {
            setFormData(prevKpiData => ({
                ...prevKpiData,
                [name]: '',
            }));
            form.setFieldsValue({
                [name]: '',
            });
            return;
        }

        // If the input is a valid positive whole number, update the state
        setFormData(prevKpiData => ({
            ...prevKpiData,
            [name]: numericValue,
        }));

        form.setFieldsValue({
            [name]: numericValue,
        });
    };

    const handleSelectChange = (value) => {
        setFormData({
            option: '',
            amount: 0,
            totalAmount: 0,
            name: '',
        });
        if (!value) {
            setFormData({
                option: '',
                amount: 0,
                totalAmount: 0,
                name: '',
            });
            setSelectedOptions(new Set());
            return;
        }
        const selectedModelId = value?.value;
        const selectedModelName = value?.label?.join(' ')?.trim();
        const kpiData = calculatedData?.enquiry?.find(kpi => kpi.model_id === selectedModelId);

        if (kpiData) {
            const isModelAlreadyExists = kpiData.model_name === selectedModelName;

            if (isModelAlreadyExists) {
                notification.info({
                    description: 'Same model is already there, please choose another one.',
                });
                return;
            }
        }
        setFormData({ option: value?.value });

    };

    const getTargetCalulations = async (model_id, enquiry_no, kpi_id, selectedMonth, selectedYear, changed_param1 = 0, delete_model_target = 0, edit_target = 0, user_id, edit_mode = 0, withLoading = false) => {
        if (withLoading) {
            setIsLoading(true);
        }
        try {
            if (withLoading) {
                setIsLoading(true);
            }
            let payload = { model_id, enquiry_no, kpi_id, month: selectedMonth, year: selectedYear, changed_param1, edit_target, user_id, edit_mode };

            if (delete_model_target) {
                payload = { model_id, enquiry_no, delete_model_target: 1 };
            }

            // Dispatch the action with the constructed payload
            await dispatch(fetchManagerTargetCalculation(payload));
            setIsLoading(false);
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
            if (withLoading) {
                setIsLoading(false);
            }
        }
        finally {
            if (withLoading) {
                setIsLoading(false);
            }
        }
    };

    const handleInputChange = (e, data) => {
        const { value } = e.target;
        let numericValue = parseInt(value, 10);

        if (isNaN(numericValue) || numericValue <= 0) {
            numericValue = 0;
        } else {
            let inputType;
            if (item.id === Target_TYPE?.Enquiry) {
                inputType = "Number";
            } else if (
                item.id === Target_TYPE?.Test ||
                item.id === Target_TYPE?.Home ||
                item.id === Target_TYPE?.Exchange ||
                item.id === Target_TYPE?.Booking ||
                item.id === Target_TYPE?.Retail ||
                item.id === Target_TYPE?.Valuation ||
                item.id === Target_TYPE?.Purchase ||
                item.id === Target_TYPE?.Extended ||
                item.id === Target_TYPE?.InHouse) {
                inputType = "Percentage";
            } else {
                inputType = "Amount";
            }

            switch (inputType) {
                case "Number":
                    numericValue =
                        numericValue?.toString()?.length > 3
                            ? parseInt(value.slice(0, 3), 10)
                            : numericValue;
                    break;

                case "Percentage":
                    numericValue = numericValue > 100 ? 100 : numericValue;
                    break;

                case "Amount":
                    let stringValue = numericValue.toString();
                    let parts = stringValue?.split(".");
                    if (parts[0].length > 7) {
                        parts[0] = parts[0]?.slice(0, 7);
                    }
                    numericValue = parts?.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];
                    break;

                default:
                    break;
            }
        }

        // Update the state with the adjusted value
        setInputValues(prev => ({
            ...prev,
            [data.model_id]: numericValue
        }));

        // Call the target calculation API with the updated value
        const matchedEnquiry = getEnquiryValue?.find(el => el.model_id === data?.model_id);
        const withLoading = false;
        if (id) {
            getTargetCalulations(
                data?.model_id,
                matchedEnquiry?.param1,
                item?.id,
                monthID,
                getYearParams,
                numericValue,
                0,
                1,
                id,
                1,
                withLoading
            );
        } else {
            getTargetCalulations(
                data?.model_id,
                matchedEnquiry?.param1,
                item?.id,
                selectedMonth,
                selectedYear,
                numericValue,
                0,
                0,
                0,
                0,
                withLoading
            );
        }
    };
    


    const handleAdd = () => {

        const selectedOption = modalData?.find(el => formData.option === el?.id);
        if (selectedOption) {
            formData.name = selectedOption.model_name;
        }

        if (formData.option && formData.totalAmount) {
            const withLoading = true;
            if (id) {
                getTargetCalulations(
                    formData.option,
                    formData.totalAmount,
                    item?.id,
                    monthID,
                    getYearParams,
                    "",
                    0,
                    1,
                    id,
                    1,
                    withLoading
                );
            } else {
                getTargetCalulations(
                    formData.option,
                    formData.totalAmount,
                    item?.id,
                    selectedMonth,
                    selectedYear,
                    "",
                    0,
                    0,
                    0,
                    0,
                    withLoading
                );
            }

            setDataList([...dataList, formData]);
            setSelectedOptions(new Set([...selectedOptions, formData.option]));
        }
        setFormData({ option: null, amount: '', totalAmount: '', name: '' });
        form.setFieldsValue({
            enquiry: null,
            amount: 0,
            totalAmount: 0,
            name: '',
        })
    };

    const handleRemove = (index, item, data) => {
        const updatedDataList = dataList?.filter((_, i) => i !== index);
        setDataList(updatedDataList);
        switch (item?.id) {
            case 1:
                setKpiData(prevKpiData => ({
                    ...prevKpiData,
                    Enquiry: updatedDataList,
                }));
                break;
            default:
                break;
        }
        getTargetCalulations(
            data?.model_id,
            data?.param1,
            null,
            null,
            null,
            null,
            1
        );

        const removedOption = dataList[index]?.option;
        const newSelectedOptions = new Set([...selectedOptions]);
        newSelectedOptions.delete(removedOption);
        setSelectedOptions(newSelectedOptions);
    };

    // const filteredOptions = modalData?.filter(option => !selectedOptions?.has(option.id));


    return (
        <>
            <Loader loading={isLoading} />
            <Card>
                <div className="container">
                    <h3>{item?.title}
                        {isTeamLeader || getRoleParams === 'true' ?
                            <span style={{ marginLeft: '5px' }} >
                                <Button shape='circle' icon={<AimOutlined />} onClick={handleShowModal} />
                            </span> :
                            ""}
                    </h3>
                    <div className={`form-section target-card-inline-form ${item?.id === Target_TYPE.Enquiry ? "col-count-3" : "col-count-4"}`}>
                        <div className="input-section">
                            {item?.id === Target_TYPE.Enquiry ?
                                <InputSelect
                                    allowClear={false}
                                    name='enquiry'
                                    placeholder="Select Model"
                                    options={modalData}
                                    label="Select Model"
                                    onSelect={handleSelectChange}
                                    optionName={'model_name'}
                                    labelInValue
                                /> : ""
                            }
                        </div>
                        <div className="input-section">
                            {item?.id === Target_TYPE.Enquiry ?
                                <>
                                    <label className="" title="Percentage">Add Number</label>
                                    <Input
                                        title='Total Amount'
                                        type="text"
                                        name="totalAmount"
                                        value={formData.totalAmount}
                                        onChange={handleInputChangePrevious}
                                        placeholder="Add Number"
                                        label="Percentage"
                                        readOnly={item?.id === Target_TYPE.Enquiry ? false : true} />
                                </> : ""
                            }

                        </div>
                        {item?.id === Target_TYPE.Enquiry ?
                            <div className="input-section input-section-last">
                                <PlusCircleOutlined onClick={handleAdd} />
                            </div> : ""
                        }
                    </div>
                    <div className="list-section">
                        <div className="custom-table">
                            <table
                                className="table table-bordered table-hover"
                                cellSpacing={0}
                                cellPadding={0}
                                border={0}
                            >
                                <thead>
                                    <tr>
                                        <th className="text-left">Model</th>
                                        {item?.id === Target_TYPE.Enquiry ? "" : <th>{item?.id === Target_TYPE.Body | item?.id === Target_TYPE.Accessories ? "Amount" : "Percentage"}</th>}
                                        <th>{item?.id === Target_TYPE.Body | item?.id === Target_TYPE.Accessories ? "Total Amount" : "Number"}</th>
                                        {item?.id === Target_TYPE.Enquiry && <th>&nbsp;</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <>
                                        {Target_TYPE?.Enquiry === item?.id && calculatedData && calculatedData?.enquiry && calculatedData?.enquiry?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="enquiry_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        // onBlur={(e) => handleInputBlur(e, data)}
                                                        placeholder="Number"
                                                        maxLength={3}
                                                    />
                                                </td>
                                                <td className="text-center"><MinusCircleOutlined className="danger-text" onClick={() => handleRemove(index, item?.id, data)} /></td>
                                            </tr>
                                        ))}
                                        {Target_TYPE?.Test === item?.id && calculatedData && calculatedData?.test_drive && calculatedData?.test_drive?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="test_drive_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))}
                                        {Target_TYPE?.Home === item?.id && calculatedData && calculatedData?.home_visit && calculatedData?.home_visit?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="home_visit_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Exchange === item.id && calculatedData?.exchange?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="exchange_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Booking === item.id && calculatedData?.booking?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="booking_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Retail === item.id && calculatedData?.retail?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="retail_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Valuation === item.id && calculatedData?.valuation?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="valuation_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Purchase === item.id && calculatedData?.purchase?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="purchase_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }

                                        {Target_TYPE?.Extended === item.id && calculatedData?.extended_warranty?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="extended_warranty_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Body === item.id && calculatedData?.body_care?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="body_care_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Amount"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.InHouse === item.id && calculatedData?.in_house?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="in_house_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Percentage"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                        {Target_TYPE?.Accessories === item.id && calculatedData?.accessories?.map((data, index) => (
                                            <tr key={index}>
                                                <td>{data?.model_name}</td>
                                                <td className="text-center">
                                                    <Input
                                                        type="text"
                                                        name="accessories_number"
                                                        value={inputValues[data?.model_id] ?? data?.param1}
                                                        onChange={(e) => handleInputChange(e, data)}
                                                        placeholder="Amount"
                                                    />
                                                </td>
                                                <td className="text-center">{data?.param2}</td>
                                            </tr>
                                        ))
                                        }
                                    </>

                                    {calculatedData.enquiry?.length > 0 ? dataList?.map(() => (
                                        ""
                                    )) : (
                                        <>
                                            <tr>
                                                <td colSpan={4} className="text-center">
                                                    <div className="ant-list-empty-text"><div className="css-dev-only-do-not-override-1b0bdye ant-empty ant-empty-normal">
                                                        <div className="ant-empty-image"><svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fillRule="evenodd"><ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fillRule="nonzero" stroke="#d9d9d9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path></g></g></svg></div><div className="ant-empty-description">No Data Found</div>
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal
                    title="Target Balance Details"
                    visible={isModalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    {/* Display target_balance data in a table */}
                    <Table
                        dataSource={item?.target_balance || []}
                        columns={columns}
                        rowKey="model_name"
                        pagination={false}
                    />
                </Modal>
            </Card >
        </>
    );
};

export default UpdatedTargets;
