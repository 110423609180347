import { Checkbox, Col, Form, Input, Modal, Row, notification } from "antd";
import React, { useState } from "react";
import { updateLeadStatusService } from "../../services/master-services";
import { API_RESPONSE_CODES } from "../../utills/constant";

const LeadStatusModal = (props) => {
  const [form] = Form.useForm();
  const {
    open,
    setShowModal,
    statusType,
    getLeadsData,
    id,
    setIsLoading,
    leadDetailsData,
  } = props;

  const [checkReason, setCheckReason] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(open);

  const handleOk = async () => {
    let finalPayload;
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      // form.resetFields();
      const reasonDetails = [];
      const otherDetails = {};

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          if (typeof values[key] === "boolean" && values[key]) {
            reasonDetails?.push(key?.replace(/_/g, " "));
          } else if (typeof values[key] === "string" && key !== "remark") {
            otherDetails[key.replace(/_/g, "_")] = values[key];
          }
        }
      }

      finalPayload = {
        lead_status_id: statusType,
        otherDetails: otherDetails,
        reasonDetails: reasonDetails,
        remark: values.remark,
      };
      if (finalPayload?.reasonDetails?.length < 1) {
        setCheckReason(true);
      } else {
        setCheckReason(false);
        const res = await updateLeadStatusService(id, finalPayload);

        if (res?.status === API_RESPONSE_CODES?.OK) {
          notification.success({
            message: res.data.message,
          });
          getLeadsData(id);
          setIsModalOpen(false);
          setShowModal(false);
        }
      }
    } catch (error) {
      if (error.errorFields) {
        return;
      } else {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setIsModalOpen(false);
    form.setFieldsValue({
      status: leadDetailsData?.lead_status?.name,
    });
    getLeadsData(id);
  };

  return (
    <div>
      <Modal
        title={
          <>
            Select Reason <span className="asterisk">*</span>
          </>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={false}
        maskClosable={false}
      >
        {statusType === "2" ? (
          <Row gutter={20} justify="start">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="side-modal">
                <Form form={form} layout="vertical" className="login-form">
                  <Form.Item
                    initialValue={false}
                    layout="inline"w
                    valuePropName="checked"
                    className="form-item-inline"
                    name="Not_satisfied_with_features"
                  >
                    <Checkbox>Not satisfied with the features</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Not_satisfied_with_experience"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Not satisfied with sales experience</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Finance_not_approved"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Finance not approved</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Out_of_budget"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Out of budget</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="High_discount_expected"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>High Discount Expected</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Long_waiting_period"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Long waiting period</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Postponement_of_purchase"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Postponement of purchase</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Not_responding"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Not responding</Checkbox>
                  </Form.Item>

                  {checkReason && (
                    <p style={{ color: "red", fontSize: "13px" }}>
                      Please select atleast one reason
                    </p>
                  )}

                  <Form.Item
                    label={
                      <p>
                        Remark <span className="asterisk">*</span>
                      </p>
                    }
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please input your remark",
                      },
                      {
                        max: 300,
                        message:
                          "Remark cannot exceed 300 characters",
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        ) : statusType === "3" ? (
          <Row gutter={20} justify="start">
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <div className="side-modal">
                <Form form={form} layout="vertical" className="login-form">
                  <Form.Item
                    initialValue={false}
                    name="Lost_to_co_dealer"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Lost to Co-Dealer</Checkbox>
                  </Form.Item>
                  <Form.Item
                    initialValue={false}
                    name="Lost_same_segment_competition"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Lost same segment competition</Checkbox>
                  </Form.Item>

                  <Form.Item
                    initialValue={false}
                    name="Lost_different_segment_competition"
                    layout="inline"
                    valuePropName="checked"
                    className="form-item-inline"
                  >
                    <Checkbox>Lost different segment competition</Checkbox>
                  </Form.Item>
                  {checkReason && (
                    <p style={{ color: "red", fontSize: "13px" }}>
                      Please select atleast one reason
                    </p>
                  )}
                  <br />
                  <Form.Item
                    name="name_of_the_co_dealer"
                    label="Name of the Co-Dealer"
                    layout="inline"
                    className="form-item-inline"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="name_of_competition"
                    label="Name of the competition"
                    layout="inline"
                    className="form-item-inline"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="competition_model"
                    label="Competition model"
                    layout="inline"
                    className="form-item-inline"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={
                      <p>
                        Remark <span className="asterisk">*</span>
                      </p>
                    }
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please input your remark",
                      },
                      {
                        max: 300,
                        message:
                          'Remark cannot exceed 300 characters',
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Modal>
    </div>
  );
};

export default LeadStatusModal;
