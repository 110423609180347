/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Modal, Row, Col, Form, notification } from "antd";
import Loader from "../common/Loader";
import {
  getAreasService,
  getRMTransferLeadsService,
  getSOTransferLeadsService,
  transferLeadService,
  updateUserStatusService,
} from "../../services/master-services";
import InputSelect from "../common/InputSelect";
import { useSelector } from "react-redux";
import { API_RESPONSE_CODES } from "../../utills/constant";
import { useNavigate } from "react-router-dom";

const ModalComponent = (props) => {
  const {
    filters,
    isModalOpen,
    territoryData,
    setIsModalOpen,
    transferLeadIds,
    setTransferLeadIds,
    selectedLeadsLength,
    userTransferParams,
    leadsInitialState,
    setFilters
  } = props;

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const userId = useSelector((state) => state?.auth?.userData?.id);

  const [areaData, setAreaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesOfficerData, setSalesOfficerData] = useState([]);
  const [reportingManagerData, setReportingManagerData] = useState([]);
  const [register, setRegister] = useState({
    id: userId,
    area_id: [],
    territory_id: [],
    sales_officer_id: "",
    reporting_manager_id: [],
  });

  const isAreaDataAvailable = areaData?.length === 0;
  const isSODataAvailable = salesOfficerData?.length === 0;
  const isRMDataAvailable = reportingManagerData?.length === 0;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setTransferLeadIds([]);
    setRegister((state) => ({
      ...state,
      id: userId,
      area_id: [],
      territory_id: [],
      sales_officer_id: "",
      reporting_manager_id: [],
    }));
    form.setFieldsValue({
      area_id: null,
      territory_id: null,
      sales_officer_id: null,
      reporting_manager_id: null,
    });
    setAreaData([]);
    setSalesOfficerData([]);
    setReportingManagerData([]);
    setIsModalOpen(false);
    if (userTransferParams) {
      navigate('/admin/leads')
      setFilters({
        ...filters,
        ...leadsInitialState,
      });
    }

  };

  const getAreaData = async (state_id, territory_id) => {
    setIsLoading(true);
    try {
      const response = await getAreasService(state_id, territory_id);
      setAreaData(response?.data?.data);
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getReportingManagerData = async (id, territory_id, area_id) => {
    setIsLoading(true);
    try {
      const response = await getRMTransferLeadsService(
        id,
        territory_id,
        area_id
      );
      setReportingManagerData(response?.data?.data);
    } catch (error) {
      setReportingManagerData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getSalesOfficerData = async (
    territory_id,
    area_id,
    reporting_manager_id
  ) => {
    setIsLoading(true);
    try {
      const response = await getSOTransferLeadsService(
        territory_id,
        area_id,
        reporting_manager_id
      );
      setSalesOfficerData(response?.data?.data);
    } catch (error) {
      setSalesOfficerData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerritoryChange = (id) => {
    if (id?.length > 0) {
      getAreaData(5, id);
      setRegister((state) => ({ ...state, territory_id: id }));
    } else {
      setAreaData([]);
    }
    setSalesOfficerData([]);
    setReportingManagerData([]);
    form.setFieldsValue({
      area_id: null,
      sales_officer_id: null,
      reporting_manager_id: null,
    });
  };

  const handleAreaChange = (area_id) => {
    if (area_id?.length > 0) {
      getReportingManagerData(register.territory_id, area_id);
      setRegister((state) => ({ ...state, area_id: area_id }));
    } else {
      setReportingManagerData([]);
    }
    setSalesOfficerData([]);
    form.setFieldsValue({
      reporting_manager_id: null,
      sales_officer_id: null,
    });
  };

  const handleRMChange = (id) => {
    if (id?.length > 0) {
      setRegister((state) => ({ ...state, reporting_manager_id: id }));
      getSalesOfficerData(register?.territory_id, register?.area_id, id);
    } else {
      setSalesOfficerData([]);
    }
    form.setFieldsValue({
      sales_officer_id: null,
    });
  };

  const handleSalesOfficerChange = (id) => {
    setRegister((state) => ({ ...state, sales_officer_id: id }));
  };

  const handleTransferLead = async () => {
    const payload = {
      lead_ids: transferLeadIds,
      user_id: register?.sales_officer_id,
    };
    setIsLoading(true);
    try {
      const response = await transferLeadService(payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: response.data.message,
        });
        handleCancel();
        // getLeadListing(filters);
        let statusPayload = {
          status: 0,
        };
        if (userTransferParams) {
          await updateUserStatusService(userTransferParams, statusPayload);
          navigate('/admin/users');
        }
      }

    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {/* <Loader loading={isLoading} /> */}
      <Modal
        title={
          <div className="modal-head">
            <p className="lead-selected-count">
              {selectedLeadsLength}{" "}
              {selectedLeadsLength === 1 ? "lead" : "leads"} selected
            </p>
            <h5 className="text-center">Lead Transfer</h5>
          </div>
        }
        footer={null}
        onOk={handleOk}
        maskClosable={false}
        visible={isModalOpen}
        className="lead-modal"
        onCancel={handleCancel}
      >
        <Loader loading={isLoading} />
        <Form
          form={form}
          layout="vertical"
          name="normal_search"
          className="login-form pt-2"
          onFinish={handleTransferLead}
          initialValues={{
            remember: true,
          }}
        >
          <div className="login-input-field">
            <Row gutter={[16, 0]}>
              {/* Select Territory  */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch
                  mode="multiple"
                  maxTagCount={2}
                  optionName={"name"}
                  name="territory_id"
                  options={territoryData}
                  label="Select Territory"
                  className="form-control-ant"
                  placeholder="Select Territory"
                  onSelect={handleTerritoryChange}
                  rules={[
                    {
                      required: true,
                      message: "Please select territory",
                    },
                  ]}
                />
              </Col>

              {/* Select Area */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <InputSelect
                  showSearch
                  allowClear
                  name="area_id"
                  mode="multiple"
                  maxTagCount={2}
                  options={areaData}
                  label="Select Area"
                  optionName={"name"}
                  placeholder="Select Area"
                  className="form-control-ant"
                  onSelect={handleAreaChange}
                  disable={isAreaDataAvailable}
                  rules={[
                    {
                      required: true,
                      message: "Please select area",
                    },
                  ]}
                />
              </Col>

              {/* Select Sales Manager */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch
                  mode="multiple"
                  maxTagCount={2}
                  optionName={"full_name"}
                  onSelect={handleRMChange}
                  name="reporting_manager_id"
                  disable={isRMDataAvailable}
                  className="form-control-ant"
                  label="Select Sales Manager"
                  options={reportingManagerData}
                  placeholder="Select Sales Manager"
                  rules={[
                    {
                      required: true,
                      message: "Please select sales manager",
                    },
                  ]}
                />
              </Col>

              {/* Select Sales Officer */}
              <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  showSearch
                  name="sales_officer_id"
                  optionName={"full_name"}
                  options={salesOfficerData}
                  disable={isSODataAvailable}
                  label="Select Sales Officer"
                  className="form-control-ant"
                  placeholder="Select Sales Officer"
                  onSelect={handleSalesOfficerChange}
                  rules={[
                    {
                      required: true,
                      message: "Please select sales officer",
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <div className="modal-button text-right">
              <Button
                type="primary"
                htmlType="submit"
                className="secondary-button"
              >
                Transfer
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalComponent;
