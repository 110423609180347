import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { firstLoginFalse } from './slice/authSlice';
import Loader from '../../components/common/Loader';
import { comparePasswords } from '../../utills/apiUrl';
import { API_RESPONSE_CODES } from '../../utills/constant';
import { changePasswordService } from '../../services/auth-service';
import PageContentHeader from '../../components/common/PageContentHeader';
import { Button, Card, Col, Form, Input, Row, Space, notification } from 'antd';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const route = useSelector((state) => state.auth.route);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [form] = Form.useForm();

  const handleChangePassword = async (values) => {
    let compareCurrentAndNewPassword = comparePasswords(values?.current_password, values?.new_password);

    let compareNewPasswords = comparePasswords(values?.new_password, values?.new_password_confirmation);

    if (!compareCurrentAndNewPassword && compareNewPasswords) {
      try {
        setIsLoading(true);
        const res = await changePasswordService(values);

        if (res.status === API_RESPONSE_CODES.OK) {
          dispatch(firstLoginFalse(false));
          notification.success({
            message: res.data.message,
          });
          form.resetFields();
          navigate(isAdmin ? `${route}/dashboard` : `/dashboard`);
        }
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    } else if (compareCurrentAndNewPassword) {
      notification.error({
        message: 'New password should not be same as the old password.',
      });
    } else if (!compareNewPasswords) {
      notification.error({
        message: 'New Password and Confirm New Password should be same.',
      });
    }
  };

  const handleCancel = () => {
    window.history.back();
  };
  return (
    <div className='user-data'>
      <Loader loading={isLoading} />
      <PageContentHeader title='Change Password' />
      <Card>
        <Form
          name='normal_login'
          form={form}
          initialValues={{
            remember: true,
          }}
          layout='vertical'
          onFinish={handleChangePassword}
        >
          <div className='login-input-field'>
            <Row gutter={[16, 0]}>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='current_password'
                  label='Current Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please input your current password',
                    },
                    {
                      pattern: /^\S+$/,
                      message: 'Password cannot contain white spaces.',
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password cannot be less than 8 characters.',
                    // },
                  ]}
                >
                  <Input.Password
                    // prefix={<LockOutlined className="site-form-item-icon" />}
                    type=' current_password'
                    maxLength={20}
                    placeholder='Current Password'
                    className='password'
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='new_password'
                  label='New Password'
                  dependencies={['current_password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please input your new password',
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password cannot be less than 8 characters.',
                    // },
                    {
                      pattern: /^\S+$/,
                      message: 'Password cannot contain white spaces.',
                    },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        'New Password should contain at least 8 characters,one uppercase letter, one lowercase letter, one number, and one special character.',
                    },

                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('current_password') !== value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('New password cannot be the same as the current password.'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    // prefix={<LockOutlined className="site-form-item-icon" />}
                    type=' new_password'
                    maxLength={20}
                    placeholder='New Password'
                    className='password'
                  />
                </Form.Item>
              </Col>
              <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  name='new_password_confirmation'
                  label='Confirm New Password'
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your new password',
                    },
                    {
                      pattern: /^\S+$/,
                      message: 'Password cannot contain white spaces.',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('new_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords do not match.'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    // prefix={<LockOutlined className="site-form-item-icon" />}
                    type='new_password_confirmation'
                    placeholder='Confirm New Password'
                    maxLength={20}
                    className='password'
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className='user-right d-flex align-items-center justify-content-end'>
              <Space size='middle'>
                <Button type='primary' ghost danger className='primary-button' onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type='primary' className='secondary-button' htmlType='submit'>
                  Confirm
                </Button>
              </Space>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
