/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  DatePicker,
  Typography,
  notification,
} from "antd";
import Loader from "../../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../../utills/constant";
import ExchaneEvaluationDetails from "./ExchangeEvaluationDetails";
import UploadButton from "../../../../components/common/UploadButton";
import { fetchLeadDetailsServices } from "../../../auth/slice/authSlice";
import ExchangeDetailsDropdown from "./ExchangeD/ExchangeDetailsDropdown";
import { AddLeadExchangeDetails } from "../../../../services/admin-services";
import ExchangeDetailsWithPRocured from "./ExchangeDetailsWithPRocured";

const { Title } = Typography;

const ExchangeDetails = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const dateFormat = "DD/MM/YYYY";
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState({
    front_image: [],
    back_image: [],
    side_image: [],
    inside_image: [],
  });
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const isLeadConverted = leadViewDetails?.lead_status?.name === "Converted";

  useEffect(() => {
    form.setFieldsValue({
      ...leadViewDetails,
      remark2: leadViewDetails?.exchange_vehicle_detail?.remarks,
      odometer: leadViewDetails?.exchange_vehicle_detail?.odometer,
      year: dayjs(leadViewDetails?.exchange_vehicle_detail?.car_year),
      exchange_make_id: leadViewDetails?.exchange_vehicle_detail?.make_id,
      expected_price: leadViewDetails?.exchange_vehicle_detail?.expected_price,
      exchange_fuel_type_id:
        leadViewDetails?.exchange_vehicle_detail?.fuel_type_id,
      exchange_vehicle_model_id:
        leadViewDetails?.exchange_vehicle_detail?.model?.id,
      exchange_vehicle_variant_id:
        leadViewDetails?.exchange_vehicle_detail?.variant?.id,
      exchange_transmission_id:
        leadViewDetails?.exchange_vehicle_detail?.transmission?.id,
      insurance_validity: leadViewDetails?.exchange_vehicle_detail
        ?.insurance_validity
        ? dayjs(leadViewDetails?.exchange_vehicle_detail?.insurance_validity)
        : "",
    });
    setUploadComponentData(leadViewDetails?.documents);
  }, []);

  const setUploadComponentData = (imagesData) => {
    const formattedFileLists = {
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
    };

    imagesData?.forEach((doc) => {
      const file = {
        uid: doc.id,
        name: doc.document_file_name,
        status: "done",
        url: doc.document_path,
      };

      switch (doc.document_type) {
        case "Front":
          formattedFileLists.front_image.push(file);
          break;
        case "Back":
          formattedFileLists.back_image.push(file);
          break;
        case "Side":
          formattedFileLists.side_image.push(file);
          break;
        case "Inside":
          formattedFileLists.inside_image.push(file);
          break;
        default:
          break;
      }
    });

    setFileList(formattedFileLists);
  };

  const convertUrlToBlob = async (url) => {
    if (!url) return null;
    try {
      const response = await fetch(url);
      return await response.blob();
    } catch (error) {
      return null;
    }
  };

  const handleStatusTracker = (key) => {
    form.setFieldsValue({ sent_for_evaluation: key });
  };

  const handleCancel = () => {
    form.resetFields();
    setFileList({
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("id", id);
    formData.append(
      "sent_for_evaluation",
      form.getFieldValue("sent_for_evaluation")
    );
    formData.append("remark2", values?.remark2 ? values?.remark2 : "");
    formData.append("odometer", values?.odometer ? values?.odometer : "");
    formData.append(
      "vehicle_details_id",
      leadViewDetails?.vehicle_detail?.id
        ? leadViewDetails?.vehicle_detail?.id
        : ""
    );
    formData.append(
      "year",
      values?.year ? dayjs(values.year).format("YYYY") : null
    );
    formData.append(
      "expected_price",
      values?.expected_price ? values?.expected_price : 0
    );
    formData.append(
      "exchange_make_id",
      values?.exchange_make_id ? values?.exchange_make_id : ""
    );
    formData.append(
      "exchange_fuel_type_id",
      values?.exchange_fuel_type_id ? values?.exchange_fuel_type_id : ""
    );
    formData.append(
      "exchange_transmission_id",
      values?.exchange_transmission_id ? values?.exchange_transmission_id : ""
    );
    formData.append(
      "exchange_vehicle_model_id",
      values?.exchange_vehicle_model_id ? values?.exchange_vehicle_model_id : ""
    );
    formData.append(
      "exchange_vehicle_variant_id",
      values?.exchange_vehicle_variant_id
        ? values?.exchange_vehicle_variant_id
        : ""
    );
    formData.append(
      "insurance_validity",
      values?.insurance_validity
        ? dayjs(values?.insurance_validity).format("YYYY-MM-DD")
        : null
    );

    const appendFile = async (key, files) => {
      if (files?.length > 0) {
        const file =
          files[0].originFileObj || (await convertUrlToBlob(files[0].url));
        formData.append(key, file || "");
      } else {
        formData.append(key, "");
      }
    };
    await appendFile("front_image", fileList.front_image);
    await appendFile("back_image", fileList.back_image);
    await appendFile("side_image", fileList.side_image);
    await appendFile("inside_image", fileList.inside_image);
    try {
      const response = await AddLeadExchangeDetails(formData);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response.data.message,
        });
        dispatch(fetchLeadDetailsServices({ id: id }));
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {roleType != "Exchange Evaluation Team" &&
      leadViewDetails?.exchange_vehicle_detail?.sent_for_evaluation === 0 ? (
        <>
          <Loader loading={isLoading} />
          <div className="user-data lead-detail">
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="">
                Exchange Details
              </Title>
            </div>
            <Form layout="vertical" form={form} onFinish={onSubmit}>
              <div className="mt-2 ps-md-0 ">
                <Row gutter={[16, 0]} align="start" className="mb-3">
                  <ExchangeDetailsDropdown
                    setIsLoading={setIsLoading}
                    form={form}
                    leadViewDetails={leadViewDetails}
                  />
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Odometer"
                      name="odometer"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject(
                                new Error("Please input your odometer details")
                              );
                            } else if (!/^\d+$/.test(value)) {
                              return Promise.reject(
                                new Error("The input must be numeric")
                              );
                            } else if (value?.length < 2) {
                              return Promise.reject(
                                new Error(
                                  "The input must be at least 2 digits long"
                                )
                              );
                            } else if (value?.length > 10) {
                              return Promise.reject(
                                new Error(
                                  "Odometer reading cannot be more than 10 digits"
                                )
                              );
                            } else if (/\s/.test(value)) {
                              return Promise.reject(
                                new Error(
                                  "The input must not contain whitespace"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Odometer"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Expected Price"
                      name="expected_price"
                      rules={[
                        {
                          required: true, 
                          message: "Please input your expected price",
                        },
                        () => ({
                          validator(_, value) {
                            if (!value) {
                              // If the field is empty, it's considered valid
                              return Promise.resolve();
                            }
                            if (!/^\d{1,10}$/.test(value)) {
                              return Promise.reject(
                                "Expected price cannot be more than 10 digits"
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Expected Price"
                        className="form-control-ant"
                      />
                    </Form.Item>
                  </Col>
                  <Col xl={4} md={8} sm={24} xs={24}>
                    <Form.Item
                      label="Insurance Validity"
                      name="insurance_validity"
                      rules={[
                        {
                          required: true,
                          message: "Please input insurance validity",
                        },
                      ]}
                    >
                      <DatePicker
                        format={dateFormat}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div>
                <Row gutter={[40, 0]} align="start" className="mb-3">
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="remark2"
                      label="Remark"
                      placeholder="Remark"
                      rules={[
                        {
                          required: true,
                          message: "Please input your remark",
                        },
                        {
                          max: 300,
                          message: "Remark must be at most 300 characters",
                        }
                      ]}
                    >
                      <Input.TextArea placeholder="Remark" />
                    </Form.Item>
                  </Col>
                  <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                    <Title level={4} className="mb-3">
                      Image Upload
                    </Title>
                    <Row gutter={[16, 16]} align="middle">
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="front_image"
                          // label="Front Image"
                          className=" mb-0"
                        >
                          <UploadButton
                            name="front_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            label="Front Image"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="back_image"
                          // label="Back Image"
                          className=" mb-0"
                        >
                          <UploadButton
                            name="back_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            label="Back Image"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="side_image"
                          // label="Side Image"
                          layout="horizontal"
                          className=" mb-0"
                        >
                          <UploadButton
                            name="side_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            label="Side Image"
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                        <Form.Item
                          name="inside_image"
                          // label="Inside Image"
                          layout="horizontal"
                          className=" mb-0"
                        >
                          <UploadButton
                            name="inside_image"
                            fileList={fileList}
                            setFileList={setFileList}
                            fileType={["image/jpeg", "image/png"]}
                            fileSizeLimit={5}
                            label="Inside Image"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="user-right d-flex align-items-center justify-content-end mt-4">
                <Button
                  type="primary"
                  ghost
                  danger
                  className="primary-button me-3"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  className="secondary-button me-3"
                  htmlType="submit"
                  onClick={() => handleStatusTracker(0)}
                >
                  Save
                </Button>
                <Button
                  type="primary"
                  className="secondary-button"
                  htmlType="submit"
                  onClick={() => handleStatusTracker(1)}
                >
                  Send For Evaluation
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : leadViewDetails?.exchange_vehicle_detail?.vehicle_procured ===
          "Yes" &&
        leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 && !isLeadConverted? (
        <ExchangeDetailsWithPRocured />
      ) : (
        <ExchaneEvaluationDetails leadViewDetails={leadViewDetails} id={id} />
      )}
    </>
  );
};

export default ExchangeDetails;
