/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  message,
  Upload,
  Typography,
  TimePicker,
  notification,
  Card,
} from 'antd';
import { PhoneFilled, UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Loader from '../../../components/common/Loader';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import { addCallLogsService, updateCallLogsService } from '../../../services/master-services';
import { useParams } from 'react-router-dom';
import { fetchLeadDetailsServices } from '../../auth/slice/authSlice';
import NoDataFound from '../../../components/common/NoDataFound';
import { ddmmyyFormatter } from '../../../utills/helpers';

const { TextArea } = Input;
const { Title } = Typography;

const CallLogs = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [editId, setEditId] = useState();
  const [fileList, setFileList] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const hasCallLogsData = leadViewDetails?.call_logs?.length > 0;
  const isExchangeEvaluationTeam = roleType === 'Exchange Evaluation Team';
  const isLeadConverted =
    leadViewDetails?.lead_status?.name === 'Converted' ||
    leadViewDetails?.lead_status?.name === 'Lost' ||
    leadViewDetails?.lead_status?.name === 'Dropped';

  useEffect(() => {
    form.setFieldsValue({
      ...leadViewDetails,
      follow_up_date: leadViewDetails?.next_follow_up_date ? dayjs(leadViewDetails?.next_follow_up_date) : null,
    });
  }, []);

  const validateFile = (file) => {
    const isAudio =
      file.type === 'audio/mpeg' ||
      file.type === 'audio/wav' ||
      file.type === 'audio/aac' ||
      file.type === 'audio/flac' ||
      file.type === 'audio/vnd.dlna.adts' ||
      file.type === 'audio/x-m4a' ||
      file.type === 'audio/m4a' ||
      file.type === 'audio/amr-wb';

    if (!isAudio) {
      message.error('You can only upload MP3, WAV, AAC, M4A, FLAC, AWB files');
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const handleFileChange = (info, field) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);

    setFileList((prev) => ({
      ...prev,
      [field]: newFileList,
    }));
  };

  const handleEditFunction = (value) => {
    setEditId(value);
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
    setEditMode(true);
    const logToEdit = leadViewDetails?.call_logs[value];
    const dateForTime = dayjs().format('YYYY-MM-DD');
    const dateTimeString = `${dateForTime}T${logToEdit?.call_time}`;
    form.setFieldsValue({
      phone_no: logToEdit?.phone_no,
      alt_phone_no: logToEdit?.alt_phone_no,
      follow_up_date: logToEdit?.follow_up_date ? dayjs(logToEdit?.follow_up_date) : null,
      scheduled_visit: logToEdit?.scheduled_visit ? dayjs(logToEdit?.scheduled_visit) : null,
      call_date: logToEdit?.call_date ? dayjs(logToEdit?.call_date) : null,
      call_time: logToEdit?.call_time ? dayjs(dateTimeString) : null,
      call_duration: logToEdit?.call_duration ? dayjs(logToEdit?.call_duration, 'HH:mm:ss') : null,
      notes: logToEdit?.notes,
    });
  };

  const handleCancel = () => {
    if (editMode) {
      setEditMode(false);
      form.resetFields();
    } else {
      form.resetFields();
    }
  };

  //Form submit function
  const onFinish = async (values) => {
    delete values.recording;
    values.lead_id = +id;
    values.phone_no = leadViewDetails?.customer?.phone_no;
    values.alt_phone_no = leadViewDetails?.customer?.alt_phone_no;
    values.call_time = values.call_time ? dayjs(values.call_time).format('HH:mm:ss') : '';
    values.call_duration = values.call_duration ? dayjs(values.call_duration).format('HH:mm:ss') : '';
    values.call_date = values.call_date ? dayjs(values.call_date).format('YYYY/MM/DD') : '';
    values.follow_up_date = values.follow_up_date ? dayjs(values.follow_up_date).format('YYYY/MM/DD') : '';

    try {
      setIsLoading(true);
      let response;
      if (editMode) {
        Object.keys(fileList).forEach((key) => {
          if (fileList[key]?.length > 0) {
            values.recording = fileList.audio_file[0].originFileObj;
          }
        });
        for (let key in values) {
          if (values[key] !== undefined) {
            values.key = values[key];
          }
        }
        const editIDVal = leadViewDetails?.call_logs[editId]?.id;

        response = await updateCallLogsService(+editIDVal, values);
      } else {
        const formData = new FormData();

        Object.keys(fileList).forEach((key) => {
          if (fileList[key]?.length > 0) {
            formData.append('recording', fileList.audio_file[0].originFileObj);
          }
        });
        for (let key in values) {
          if (values[key] !== undefined) {
            formData.append(key, values[key]);
          }
        }
        response = await addCallLogsService(formData);
      }
      setIsLoading(true);
      if (response.data.status === API_RESPONSE_CODES.OK || response.data.status === API_RESPONSE_CODES.CREATED) {
        notification.success({
          message: response.data.message,
        });
        setEditMode(false);
        form.resetFields();
        dispatch(fetchLeadDetailsServices({ id: id }));
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //To check if selected date is today's date
  const isToday = selectedDate && selectedDate.isSame(dayjs(), 'day');

  // Function to disable the future time if current date is selected
  const disableFutureTime = () => {
    if (isToday) {
      return {
        disabledHours: () => Array.from(new Array(24), (_, index) => index).slice(dayjs().hour() + 1),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === dayjs().hour()) {
            return Array.from(new Array(60), (_, index) => index).slice(dayjs().minute() + 1);
          }
          return [];
        },
        disabledSeconds: () => [],
      };
    }
    return {};
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className='user-data lead-detail'>
        {!isExchangeEvaluationTeam && !isLeadConverted ? (
          <>
            <Title level={4} className=''>
              Call Logs
            </Title>
            <div className='personal-detail pt-2'>
              <div className='detail-wrapper' id='callLogFormTop'>
                <div className='view-input-field'>
                  <Form layout='vertical' form={form} onFinish={onFinish}>
                    <Row gutter={20} justify='start'>
                      <>
                        {/* Contact Number */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100 my-0'>
                            <h6>Contact Number:</h6>
                            <Form.Item name='phone_no' className='lead-input-wrapper mb-1'>
                              <p className='py-2'>
                                {leadViewDetails?.customer?.phone_no ? (
                                  <>
                                    {leadViewDetails?.customer?.phone_no}
                                    <a href={`tel:${leadViewDetails.customer.phone_no}`} className='ps-2'>
                                      <PhoneFilled style={{ cursor: 'pointer' }} />
                                    </a>
                                  </>
                                ) : (
                                  'N/A'
                                )}
                              </p>
                            </Form.Item>
                          </div>
                        </Col>

                        {/* Alt Contact Number */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100 my-0'>
                            <h6>Alt Contact Number:</h6>
                            <Form.Item name='alt_phone_no' className='lead-input-wrapper mb-1'>
                              <p className='py-2'>
                                {leadViewDetails?.customer?.alt_phone_no ? (
                                  <>
                                    {leadViewDetails?.customer?.alt_phone_no}
                                    <a href={`tel:${leadViewDetails?.customer?.alt_phone_no}`} className='ps-2'>
                                      <PhoneFilled style={{ cursor: 'pointer' }} />
                                    </a>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </p>
                            </Form.Item>
                          </div>
                        </Col>

                        {/* Recording */}
                        {!editMode && (
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>
                                Attach Recording:
                              </h6>
                              <Form.Item
                                // label="Attach Recording:"
                                className='lead-input-wrapper mb-0'
                                name='recording'
                              // rules={[
                              //   {
                              //     required: editMode ? false : true,
                              //     message: 'Please upload your file',
                              //   },
                              // ]}
                              >
                                <div className='exchange-upload pt-0'>
                                  <Upload
                                    maxCount={1}
                                    accept='.mp3,.wav,.aac,.flac,.m4a,.awb'
                                    beforeUpload={validateFile}
                                    customRequest={({ onSuccess }) => {
                                      onSuccess('ok');
                                    }}
                                    onChange={(info) =>
                                      handleFileChange(info, "audio_file")
                                    }
                                  // fileList={fileList?.front_image}
                                  >
                                    <Button icon={<UploadOutlined />}></Button>
                                  </Upload>
                                </div>
                              </Form.Item>
                            </div>
                          </Col>
                        )}

                        {/* Call Date */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100'>
                            <h6>Call Date:</h6>
                            <div className='lead-input-wrapper'>
                              <Form.Item name='call_date'>
                                <DatePicker
                                  className='ps-2'
                                  format='DD/MM/YYYY'
                                  disabledDate={(current) => current && current > dayjs().endOf('day')}
                                  onChange={(value) => setSelectedDate(value)}
                                  placeholder='Select Call Date'
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        {/* Call Time */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100'>
                            <h6>Call Time:</h6>
                            <div className='lead-input-wrapper'>
                              <Form.Item
                                name='call_time'
                                rules={[
                                  {
                                    required: false,
                                    message: 'Please select call time',
                                  },
                                ]}
                              >
                                <TimePicker
                                  use12Hours
                                  className='ps-2'
                                  format='h:mm a'
                                  placeholder='Select Time'
                                  disabledTime={disableFutureTime}
                                  inputReadOnly={true}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        {/* Call Duration */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100'>
                            <h6>Call Duration:</h6>
                            <div className='lead-input-wrapper'>
                              <Form.Item name='call_duration'>
                                <TimePicker showNow={false} inputReadOnly={true} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        {/* Next Follow Up Date */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-baseline w-100'>
                            <h6>Next Follow Up Date:</h6>
                            <div className='lead-input-wrapper'>
                              <Form.Item name='follow_up_date'>
                                <DatePicker
                                  className='ps-2'
                                  format='DD/MM/YYYY'
                                  disabledDate={(current) => {
                                    return (
                                      current < dayjs().startOf("day") ||
                                      current >
                                      dayjs().add(10, "day").endOf("day")
                                    );
                                  }}
                                  placeholder='Select Date'
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        {/* Notes */}
                        <Col xl={12} md={24} sm={24} xs={24}>
                          <div className='view-fields d-flex align-items-center w-100'>
                            <h6>
                              Notes:
                              <span className='asterisk'>*</span>
                            </h6>
                            <div className='lead-input-wrapper'>
                              <Form.Item
                                // label="Notes"
                                name='notes'
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter notes',
                                  },
                                  {
                                    max: 300,
                                    message: 'Maximum length of 300 words allowed',
                                  },
                                ]}
                              >
                                <TextArea placeholder='Notes' />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                      </>
                    </Row>
                    {/* Submit and Cancel button */}
                    <div className='user-right d-flex align-items-center justify-content-end mt-2'>
                      <Button
                        type='primary'
                        ghost
                        danger
                        className='primary-button me-4'
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </Button>

                      <Button type='primary' htmlType='submit' className='secondary-button'>
                        {editMode ? 'Update' : 'Save'}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className='h-line mb-2 pt-3'>&nbsp;</div>
          </>
        ) : (
          <></>
        )}
        {/* CALL HISTORY */}
        <div className='personal-detail pt-2'>
          {hasCallLogsData ? (
            <>
              <div className='user-left d-flex justify-content-between align-items-center'>
                <Title level={4} className='pe-3'>
                  Call History
                </Title>
              </div>
              {leadViewDetails?.call_logs?.map((logs, index) => (
                <Card className='lead-stage mt-2' key={index}>
                  {!isExchangeEvaluationTeam && !isLeadConverted ? (
                    <div className='user-right d-flex align-items-center justify-content-end'>
                      <Button type='primary' className='secondary-button' onClick={() => handleEditFunction(index)}>
                        Edit
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className='detail-wrapper'>
                    <div className='view-input-field'>
                      <Form layout='vertical'>
                        <Row gutter={20} justify='start'>
                          {/* Contact Number */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Contact Number:</h6>
                              <p className=''>{logs.phone_no ? logs.phone_no : '-'}</p>
                            </div>
                          </Col>
                          {/* Call Date */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Call Date:</h6>
                              <p className=''>{logs?.call_date ? ddmmyyFormatter(logs?.call_date) : '-'}</p>
                            </div>
                          </Col>
                          {/* Call Time */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Call Time:</h6>
                              <p className="">
                                {logs?.call_time
                                  ? dayjs(logs?.call_time, "HH:mm").format(
                                    "hh:mm A"
                                  )
                                  : "-"}
                              </p>
                            </div>
                          </Col>
                          {/* Call Duration */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Call Duration:</h6>
                              <p className=''>{logs?.call_duration ? logs?.call_duration : '-'} </p>
                            </div>
                          </Col>
                          {/* Follow up date */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Follow Up Date:</h6>
                              <p className=''>{logs?.follow_up_date ? ddmmyyFormatter(logs?.follow_up_date) : '-'}</p>
                            </div>
                          </Col>
                          {/* Call Recording */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Call Recording:</h6>
                              <a href={logs?.recording_path ? `${logs?.recording_path}` : `#`}>
                                {logs?.recording_file_name ? logs?.recording_file_name : '-'}
                              </a>
                            </div>
                          </Col>
                          {/* Notes */}
                          <Col xl={12} md={24} sm={24} xs={24}>
                            <div className='view-fields d-flex align-items-baseline w-100'>
                              <h6>Notes:</h6>
                              <p className='flex-1'>{logs?.notes ? logs?.notes : '-'}</p>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </div>
                </Card>
              ))}
            </>
          ) : (
            <NoDataFound />
          )}
        </div>
      </div>
    </>
  );
};

export default CallLogs;
