/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Col, Form, Row, notification, Tag } from "antd";
import Loader from "../../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../../utills/constant";
import { getTargetsDataService, getTerritoryTargetsDataService } from "../../../../services/master-services";
import PageContentHeader from "../../../../components/common/PageContentHeader";

const DashboardUsers = () => {
    const { id } = useParams();
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [targetData, setTargetData] = useState([]);
    const [userData, setUserData] = useState([]);

    const dashboardData = useSelector(
        (state) => state?.auth?.dashboardHomeFilters
    );
    const userID = dashboardData?.userId ? dashboardData?.userId : "";
    const modelID = dashboardData?.modelNo ? dashboardData?.modelNo : "";
    const monthID = dashboardData?.month_id ? dashboardData?.month_id : "";
    const year = dashboardData?.year_id ? dashboardData?.year_id : "";
    const exclude_user_ids = dashboardData?.exclude_user_ids ? dashboardData?.exclude_user_ids : "";
    const roleType = useSelector((state) => state?.auth?.userData?.role);

    useEffect(() => {
        if (roleType === "Sales Officer" || roleType === "Sales Manager" || roleType === "Team Leader") {
            getTargetData(id, monthID, year)
        } else {
            getTargetTerritioryData(id, userID, modelID, monthID, year, exclude_user_ids);
        }
    }, [])

    // const getTargetAreaData = async (id, userID, modelID, monthID, year) => {
    //     setIsLoading(true);
    //     try {
    //         const target = await getAreaTargetsDataService(id, userID, modelID, monthID, year);
    //         if (target?.status === API_RESPONSE_CODES?.OK) {
    //             setTargetData(target?.data?.data?.kpi);
    //             setUserData(target?.data?.data?.user);
    //         }
    //     } catch (error) {
    //         notification.error({
    //             description: error.response?.data?.message ?? error?.message,
    //         });
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };

    const getTargetTerritioryData = async (id, userID, modelID, monthID, year, exclude_user_ids) => {
        setIsLoading(true);
        try {
            const target = await getTerritoryTargetsDataService(id, userID, modelID, monthID, year, exclude_user_ids);
            if (target?.status === API_RESPONSE_CODES?.OK) {
                setTargetData(target?.data?.data?.kpi);
                setUserData(target?.data?.data?.user);
            }
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const getTargetData = async (id, month, selectedYear) => {
        setIsLoading(true);
        try {
            const target = await getTargetsDataService(id, month, selectedYear);
            if (target?.status === API_RESPONSE_CODES?.OK) {
                setTargetData(target?.data?.data.kpi);
                setUserData(target?.data?.data.user);
            }
        } catch (error) {
            notification.error({
                description: error.response?.data?.message ?? error?.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <div className="user-data">
                <Loader loading={isLoading} />
                <Form form={form}>
                    <PageContentHeader
                        title={
                            userData?.designation
                                ? `Target`
                                : "Target"
                        }
                        className="my-target-header"
                    />
                    <Card>
                        <Row gutter={20} justify="start" className="mb-3">
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <div className="view-fields d-flex align-items-baseline">
                                    <h6>Territory:</h6>
                                    <div className="d-flex flex-wrap row-gap-1">
                                        <Tag
                                            color="blue"
                                            key={userData?.territories}
                                            className="white-space-normal"
                                        >
                                            {userData?.territories}
                                        </Tag>
                                    </div>
                                </div>
                            </Col>
                            {userData?.full_name &&
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <div className="view-fields d-flex align-items-baseline">
                                        <h6>User Name:</h6>
                                        <p className=''>{userData?.full_name}</p>
                                    </div>
                                </Col>
                            }
                            {userData?.role &&
                                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                    <div className="view-fields d-flex align-items-baseline">
                                        <h6>Role:</h6>
                                        <p className=''>{userData?.role}</p>
                                    </div>
                                </Col>
                            }
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                <div className="view-fields d-flex align-items-baseline">
                                    <h6>Due Date:</h6>
                                    <p className="">
                                        {userData?.due_date
                                            ? dayjs(userData?.due_date).format("DD-MM-YYYY")
                                            : "-"}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[40, 40]}>
                            {targetData?.length > 0
                                ? targetData?.map((ele, index) => {
                                    return (
                                        <Col xl={12} lg={12} md={24} sm={24} xs={24} key={index}>
                                            <div className="user-table-data">
                                                <div className="target-table">
                                                    <div className="custom-table">
                                                        <table className="table table-bordered view-target-table">
                                                            <thead>
                                                                <tr className="first-head">
                                                                    <th colspan="3">{ele.name}</th>
                                                                </tr>
                                                                <tr className="second-head">
                                                                    <th
                                                                        className="text-left"
                                                                        style={{ whiteSpace: "nowrap" }}
                                                                    >
                                                                        Model Name
                                                                    </th>
                                                                    <th
                                                                        className="target-head text-center"
                                                                        style={{ width: "26%" }}
                                                                    >
                                                                        Target
                                                                    </th>
                                                                    <th
                                                                        className="achieved-head text-center"
                                                                        style={{ width: "26%" }}
                                                                    >
                                                                        Achievement
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ele?.targets?.map((el, index) => (
                                                                    <>
                                                                        <tr>
                                                                            <td className="text-left">
                                                                                {el?.model_name}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {ele?.kpi_id === 10 ||
                                                                                    ele?.kpi_id === 12
                                                                                    ? el?.target_total_amount
                                                                                    : el?.target_value}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {ele?.kpi_id === 10 ||
                                                                                    ele?.kpi_id === 12
                                                                                    ? el?.achieved_target_total_amount
                                                                                    : el?.achievement_value}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))}
                                                                <tr className="totals-row">
                                                                    <td>Total</td>
                                                                    <td className="text-center">
                                                                        {ele?.total_target_value}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {ele?.total_achievement_value}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })
                                : null}
                        </Row>
                    </Card>
                </Form>
            </div>
        </>
    );
};

export default DashboardUsers;
