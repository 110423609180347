import React from 'react'


const ViewExchange = () => {
  return (
    <>
    dhgfgf
    </>
  )
}

export default ViewExchange