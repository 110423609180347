import React, { useState, useEffect } from "react";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import {
  deleteTransmissionService,
  getMasterTransmissionListService,
} from "../../../services/master-services";
import Loader from "../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../utills/constant";

const Transmission = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [listCount, setListCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [listData, setListData] = useState();
  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  useEffect(() => {
    getTransmissionData(filters);
  }, [filters]);

  const getTransmissionData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterTransmissionListService(data);
      setListCount(userList?.data?.data?.total_records);
      setListData(userList?.data?.data?.list_data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteTransmissionService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getTransmissionData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page:1,
    }));
  };

  // Tables
  const columns = [
    {
      title: "Transmission",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Space size="small">
          <Popconfirm
            title="Are you sure you want to delete this transmission?"
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Transmission">
              <Button
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                // onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-data">
      <Loader loading={isLoading} />
      <PageContentHeader
        title="Transmission"
        headerRight={
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="secondary-button"
              onClick={() => navigate("/admin/masters/transmission/add")}
            >
              New Transmission
            </Button>
          </>
        }
      />

      {/* Filter Option Card */}
      <Card className="filter-card mb-3">
        <Form
          layout="vertical"
          name="normal_search"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className="login-input-field">
            <Row gutter={[16, 16]}>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="search" label="Search (By Transmission)">
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder="Search by transmission"
                  />
                </Form.Item>
              </Col>

              {/* <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name="status_ids" label="Status">
                  <Select
                    placeholder="Status"
                    className="form-control-ant"
                    // onChange={handleAreaChange}
                    // disabled={register.territory_id === 0}
                  >
                    <Select.Option value="active">Active</Select.Option>
                    <Select.Option value="inactive">Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
          </div>
        </Form>
      </Card>

      <div className="user-table-data">
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: "max-content" }}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount, // Replace totalCount with the total count of your data
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Transmission;
