/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "./Loader";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../app/auth/slice/authSlice";
import { logoutService } from "../../services/auth-service";
import { Avatar, Dropdown, Space, Image, Typography, Button, Badge, List, Drawer, notification } from "antd";
import { API_RESPONSE_CODES } from "../../utills/constant";
import PasswordChangeModal from "../modals/PasswordChangeModal";
import {
  UserOutlined,
  DownOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  LockOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { getNotificationCount, readNotifications } from "../../services/master-services";

const { Title } = Typography;

const PageHeader = (props) => {
  const {
    collapsed,
    toggleCollapsed,
    mobileView,
    handleMobileMenuToggle,
    pageHeaderClick,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const route = useSelector((state) => state.auth.route);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const roleName = useSelector((state) => state.auth.userData?.full_name);
  // const roleType = useSelector((state) => state.auth.userData.role);

  const firstLoginCheck = useSelector(
    (state) => state.auth.userData.first_login
  );

  const handleLogoutFunction = async () => {
    try {
      setIsLoading(true);
      const res = await logoutService();
      if (res.status === API_RESPONSE_CODES?.OK) {
        dispatch(handleLogout());
        navigate(isAdmin ? "/admin" : "/");
      }
    } catch (error) {
      dispatch(handleLogout());
      navigate(isAdmin ? "/admin" : "/");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenuClick = () => {
    setDropdownVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchNotifications(10, currentPage);
  }, [currentPage]);

  const fetchNotifications = async (perPage, page, forceFetch = false) => {
    if (!forceFetch && !hasMore) return;
    try {
      const response = await getNotificationCount(perPage, page);
      const fetchedNotifications = response?.data?.data?.notification_data;
      if (fetchedNotifications?.length < perPage) {
        setHasMore(false);
      }
      setNotificationCount(response?.data?.data?.unread_notification);
      if (page === 1) {
        setNotifications(fetchedNotifications);
      } else if (page >= currentPage) {
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          ...fetchedNotifications?.filter((item) => !prevNotifications?.find((n) => n.id === item.id))
        ]); // Load next page data
      } else {
        setNotifications((prevNotifications) => [
          ...fetchedNotifications?.filter((item) => !prevNotifications?.find((n) => n.id === item.id)),
          ...prevNotifications
        ]); // Load previous page data
      }
    } catch (error) {
      console.error("Error fetching notifications", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (e.target.scrollTop === 0 && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const handleReadAllNotifications = async () => {
    const reqData = {
      "mark_as_read": true,
    }
    try {
      const response = await readNotifications(reqData);
      if (
        response.data.status === API_RESPONSE_CODES?.OK ||
        response.data.status === API_RESPONSE_CODES?.CREATED
      ) {
        fetchNotifications(10, currentPage, true);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadNotifications = async (id) => {
    const reqData = {
      "mark_as_read": false,
      "notification_id": id ? id : null
    }
    try {
      const response = await readNotifications(reqData);
      if (
        response.data.status === API_RESPONSE_CODES?.OK ||
        response.data.status === API_RESPONSE_CODES?.CREATED
      ) {
        await fetchNotifications(10, currentPage, true);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const items = [
    {
      icon: <UserOutlined />,
      label: (
        <Link
          className="text-decoration-none"
          to={isAdmin ? `admin/profile` : `/profile`}
        >
          Profile
        </Link>
      ),
      key: "profile",
      onClick: handleMenuClick,
    },
    {
      icon: <LockOutlined />,
      label: (
        <Link
          className="text-decoration-none"
          to={isAdmin ? `${route}/change-password` : `/change-password`}
        >
          Change Password
        </Link>
      ),
      key: "change-password",
      onClick: handleMenuClick,
    },
    {
      icon: <LogoutOutlined />,
      label: "Log out",
      key: "logout",
      onClick: handleLogoutFunction,
    },
  ];

  const allNotificationsRead = notifications?.every((notification) => notification.read_at)

  return (
    <div className="page-header fixed d-flex align-items-center">
      <Loader loading={isLoading} />
      <div className="header-left d-flex align-items-center">
        <div className="header-logo">
          <Link to={isAdmin ? "/admin/dashboard" : "/dashboard"}>
            <Space size="middle">
              <Image
                src={"/toyota-logo.png"}
                alt="Header Image"
                preview={false}
              />
              <div className="header-heading">TOYOTA</div>
            </Space>
          </Link>
        </div>
        {firstLoginCheck ? (
          <PasswordChangeModal open={firstLoginCheck} />
        ) : (
          <></>
        )}
        <Button
          type="text"
          icon={mobileView ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={handleMobileMenuToggle}
          className="menu-trigger-button d-hide"
        />
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={toggleCollapsed}
          className="menu-trigger-button m-hide"
        />
      </div>
      <div className="welcome">
        <Title level={4} className="pe-2">
          Welcome to JD Toyota
        </Title>
      </div>
      <div
        className="user-profile d-flex align-items-center ms-auto"
        onClick={pageHeaderClick}
      >
        <Badge count={notificationCount} overflowCount={999} className="mr-2 cursor-pointer" onClick={showDrawer}>
          <Button shape="circle" size="small" onClick={showDrawer} icon={<BellOutlined />} />
        </Badge>
        <Dropdown
          menu={{ items }}
          className="header-drop"
          overlayClassName="user-profile-header"
          open={dropdownVisible}
          onOpenChange={handleVisibleChange}
          trigger={["click"]}
        >
          <a>
            <Avatar
              size="small"
              icon={<UserOutlined />}
              style={{ backgroundColor: "#0a9551" }}
              className="profile-logo"
            />
            <Space>
              <span className="user-name"> {roleName}</span>
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
        >
          {!allNotificationsRead && (
            <Button
              type="primary"
              className="secondary-button"
              size="small"
              onClick={handleReadAllNotifications}
              style={{ marginBottom: 16 }}
            >
              Read All
            </Button>
          )}
          <List
            itemLayout="horizontal"
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item key={item.id} className={item.read_at ? "read" : "unread"} onClick={() => handleReadNotifications(item.id)}>
                <List.Item.Meta title={item.data} />
                <p className="time">{item.created_at_human}</p>
              </List.Item>
            )}
            onScroll={handleScroll}
            className="notification-list"
            style={{ maxHeight: '500px', overflow: 'auto' }}
          />
        </Drawer>
      </div>
    </div>
  );
};



export default PageHeader;
