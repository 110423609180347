/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Typography } from 'antd';
import ExchangeDetailsDropdown from './ExchangeD/ExchangeDetailsDropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ExchangeEvaluation from './ExchangeEvaluation';
import { ddmmyyFormatter, formatIndianCurrency } from '../../../../utills/helpers';
import PageContentHeader from '../../../../components/common/PageContentHeader';

const { Title } = Typography;
const ExchangeDetailsWithPRocured = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [fileList, setFileList] = useState({
    back_image: [],
    side_image: [],
    front_image: [],
    inside_image: [],
    bank_statement: [],
    transfer_documents: [],
  });

  const dateFormat = 'DD/MM/YYYY';
  const roleType = useSelector((state) => state?.auth?.userData?.role);
  const leadViewDetails = useSelector((state) => state?.auth?.leadDetails);

  const setUploadComponentData = (imagesData) => {
    const formattedFileLists = {
      front_image: [],
      back_image: [],
      side_image: [],
      inside_image: [],
      transfer_documents: [],
      bank_statement: [],
      copy_of_rc: [],
      copy_of_insurance: [],
      copy_of_customer_agreement: [],
    };

    imagesData?.forEach((doc) => {
      const file = {
        uid: doc.id,
        name: doc.document_file_name,
        status: 'done',
        url: doc.document_path,
      };

      switch (doc.document_type) {
        case 'Front':
          formattedFileLists.front_image.push(file);
          break;
        case 'Back':
          formattedFileLists.back_image.push(file);
          break;
        case 'Side':
          formattedFileLists.side_image.push(file);
          break;
        case 'Inside':
          formattedFileLists.inside_image.push(file);
          break;
        case 'Transfer':
          formattedFileLists.transfer_documents.push(file);
          break;
        case 'Bank Statement':
          formattedFileLists.bank_statement.push(file);
          break;
        case 'RC':
          formattedFileLists.copy_of_rc.push(file);
          break;
        case 'Insurance Policy':
          formattedFileLists.copy_of_insurance.push(file);
          break;
        case 'Customer Agreement':
          formattedFileLists.copy_of_customer_agreement.push(file);
          break;
        default:
          break;
      }
    });

    setFileList(formattedFileLists);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...leadViewDetails,
      year: dayjs(leadViewDetails?.exchange_vehicle_detail?.car_year),
      exchange_make_id: leadViewDetails?.exchange_vehicle_detail?.make?.title,
      exchange_fuel_type_id: leadViewDetails?.exchange_vehicle_detail?.fuel_type?.title,
      exchange_vehicle_model_id: leadViewDetails?.exchange_vehicle_detail?.model?.model_name,
      exchange_transmission_id: leadViewDetails?.exchange_vehicle_detail?.transmission?.name,
      exchange_vehicle_variant_id: leadViewDetails?.exchange_vehicle_detail?.variant?.variant_code,
    });
    setUploadComponentData(leadViewDetails?.documents);
  }, []);

  const getDocumentInfo = (type) => {
    return leadViewDetails?.documents?.find((doc) => doc.document_type === type);
  };

  const renderDocumentLink = (type, label) => {
    const docInfo = getDocumentInfo(type);
    return docInfo ? (
      <Form.Item
        name={`${type.toLowerCase()}_image`}
        label={`${label}:`}
        layout='vertical'
        className='form-item-horizontal'
      >
        <a href={docInfo.document_path} target='_blank' rel='noopener noreferrer'>
          {docInfo.document_file_name}
        </a>
      </Form.Item>
    ) : (
      <Form.Item
        name={`${type.toLowerCase()}_image`}
        label={`${label}:`}
        layout='vertical'
        className='form-item-horizontal'
      >
        <span>No file available</span>
      </Form.Item>
    );
  };

  return (
    <>
      {showDetailsPage ? (
        <ExchangeEvaluation />
      ) : (
        <div className='user-data lead-detail exchange-form-detail'>
          <Form layout='vertical' form={form}>
            <div>
              {/* <PageContentHeader
                title="Exchange Details"
                headerRight={
                  leadViewDetails?.exchange_vehicle_detail?.vehicle_procured === "No" &&
                    leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1 &&
                    roleType === "Exchange Evaluation Team" ? (
                    <Button
                      type="primary"
                      className="secondary-button mb-3"
                      onClick={() => setShowDetailsPage(true)}
                    >
                      Mark As Procured
                    </Button>
                  ) : null
                }
              /> */}
              <PageContentHeader
                title='Exchange Details'
                headerRight={
                  <>
                    {leadViewDetails?.exchange_vehicle_detail?.vehicle_procured === 'No' &&
                    leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked === 1 &&
                    roleType === 'Exchange Evaluation Team' ? (
                      <Button type='primary' className='secondary-button mb-3' onClick={() => setShowDetailsPage(true)}>
                        Mark As Procured
                      </Button>
                    ) : null}
                    {roleType === 'Exchange Evaluation Team' &&
                      leadViewDetails?.exchange_vehicle_detail?.is_vehicle_booked !== 1 && (
                        <Button type='default' className='mb-3 ml-3' onClick={() => setShowDetailsPage(true)}>
                          Edit Evaluation
                        </Button>
                      )}
                  </>
                }
              />
              {leadViewDetails?.exchange_vehicle_detail?.vehicle_procured === 'Yes' &&
              leadViewDetails?.exchange_vehicle_detail?.is_evaluated === 1 ? (
                <div className=''>
                  <Title level={4} className='mb-3'>
                    Procurement Details
                  </Title>
                  <Row gutter={20} justify='start' align='start'>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <div className='flex-fill'>{renderDocumentLink('RC', 'Copy Of RC')}</div>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={24} xs={24}>
                      <div className='flex-fill'>
                        {renderDocumentLink('Customer Agreement', 'Copy Of Customer Agreement')}
                      </div>
                    </Col>
                    <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                      <div className='flex-fill'>{renderDocumentLink('Insurance Policy', 'Copy Of Insurance')}</div>
                    </Col>
                  </Row>
                </div>
              ) : (
                ''
              )}
              <Row gutter={20} justify='start'>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='Make' label='Make:'>
                    {leadViewDetails?.exchange_vehicle_detail?.make?.title}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='Model' label='Model:'>
                    {leadViewDetails?.exchange_vehicle_detail?.model?.model_name}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='carVariant' label='Car Variant:'>
                    {leadViewDetails?.exchange_vehicle_detail?.variant?.variant_code}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='transmission' label='Transmission:'>
                    {leadViewDetails?.exchange_vehicle_detail?.transmission?.name}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='fuel' label='Fuel:'>
                    {leadViewDetails?.exchange_vehicle_detail?.fuel_type?.title}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='year' label='Year:'>
                    {leadViewDetails?.exchange_vehicle_detail?.car_year}
                  </Form.Item>
                </Col>

                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='odometer' label='Odometer:'>
                    {leadViewDetails?.exchange_vehicle_detail?.odometer}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='expected_price' label='Expected Price:'>
                    {formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.expected_price)}
                  </Form.Item>
                </Col>
                <Col xl={4} md={8} sm={24} xs={24}>
                  <Form.Item name='insurance_validity' label='Insurance Validity:'>
                    {leadViewDetails?.exchange_vehicle_detail?.insurance_validity
                      ? ddmmyyFormatter(leadViewDetails?.exchange_vehicle_detail?.insurance_validity)
                      : '-'}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className='h-line'>&nbsp;</div>
            <div>
              <Row gutter={[40, 0]} align='start' className='mb-3'>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Tyre Status
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='front_tyre_left'
                        label='Front Tyre Left:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.front_tyre_left
                          ? `${leadViewDetails?.exchange_vehicle_detail?.front_tyre_left} %`
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='front_tyre_right'
                        label='Front Tyre Right:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.front_tyre_right
                          ? `${leadViewDetails?.exchange_vehicle_detail?.front_tyre_right} %`
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='back_tyre_left'
                        label='Back Tyre Left:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.back_tyre_left
                          ? `${leadViewDetails?.exchange_vehicle_detail?.back_tyre_left} %`
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='back_tyre_right'
                        label='Back Tyre Right:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.back_tyre_right
                          ? `${leadViewDetails?.exchange_vehicle_detail?.back_tyre_right} %`
                          : '-'}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Title level={4} className='mb-3'>
                    Valuation
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='obv_purchase_value'
                        label='OBV Purchase Value:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.obv_purchase_value
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.obv_purchase_value)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='broker_valuation'
                        label='Broker Valuation:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.broker_valuation
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.broker_valuation)
                          : '-'}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Body Damage
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='front_body_damage'
                        label='Front:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.front_body_damage}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='right_body_damage'
                        label='Right:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.right_body_damage}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='left_body_damage'
                        label='Left:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.left_body_damage}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='rear_body_damage'
                        label='Rear:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.rear_body_damage}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='underbody_damage'
                        label='Underbody:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.underbody_damage}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='rust_body_damage'
                        label='Rust:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.rust_body_damage}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Refurbishment Cost
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='front_refurbishment_cost'
                        label='Front:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.front_refurbishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.front_refurbishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='right_refurbishment_cost'
                        label='Right:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.right_refurbishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.right_refurbishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='left_refurbishment_cost'
                        label='Left:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.left_refurbishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.left_refurbishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='rear_refurishment_cost'
                        label='Rear:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.rear_refurishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.rear_refurishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='underbody_refurishment_cost'
                        label='Underbody:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.underbody_refurishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.underbody_refurishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='rust_refurishment_cost'
                        label='Rust:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.rust_refurishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.rust_refurishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='other_refurishment_cost'
                        label='Other:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.other_refurishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.other_refurishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='total_refurishment_cost'
                        label='Total:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.total_refurishment_cost
                          ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.total_refurishment_cost)
                          : '-'}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='h-line'>&nbsp;</div>
            <div className='mb-3'>
              <Title level={4} className='mb-3'>
                Foreclosure Formalities
              </Title>
              <Row gutter={[16, 0]} align='start' className='mb-3'>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  <Form.Item name='bank_id' label='Hypothecation by Bank:'>
                    {leadViewDetails?.exchange_vehicle_detail?.bank_detail?.name
                      ? leadViewDetails?.exchange_vehicle_detail?.bank_detail?.name
                      : '-'}
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  {/* {renderDocumentLink('Bank Statement', 'Bank Statement Copy')} */}
                  <Form.Item name='bank_statement_copy' label='Bank Statement Copy:'>
                    {leadViewDetails?.exchange_vehicle_detail?.bank_statement_file_name ? (
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${leadViewDetails?.exchange_vehicle_detail?.bank_statement_file_path}`}
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.bank_statement_file_name}
                      </a>
                    ) : (
                      <span>No file available</span>
                    )}
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  <Form.Item name='outstanding_loan' label='Outstanding Loan:'>
                    {leadViewDetails?.exchange_vehicle_detail?.outstanding_loan
                      ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.outstanding_loan)
                      : '-'}
                  </Form.Item>
                </Col>
                <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                  <Form.Item name='foreclose_loan' label='Will Dealership Foreclose Loan?'>
                    {leadViewDetails?.exchange_vehicle_detail?.foreclose_loan
                      ? leadViewDetails?.exchange_vehicle_detail?.foreclose_loan
                      : '-'}
                  </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                  <Form.Item
                    name='foreclosure_amount_paid_by_dealer'
                    label='Foreclosure Amount to be Paid by Dealership:'
                  >
                    {leadViewDetails?.exchange_vehicle_detail?.foreclosure_amount_paid_by_dealer
                      ? formatIndianCurrency(
                          leadViewDetails?.exchange_vehicle_detail?.foreclosure_amount_paid_by_dealer
                        )
                      : '-'}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className='h-line'>&nbsp;</div>
            <div>
              <Row gutter={[40, 0]} align='start' className='mb-3'>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Other
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='transfer_document'
                        label='Transfer Document:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {/* {renderDocumentLink("Transfer", "Transfer Document")} */}
                        {leadViewDetails?.exchange_vehicle_detail?.transfer_document_file_name ? (
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`${leadViewDetails?.exchange_vehicle_detail?.transfer_document_file_path}`}
                          >
                            {leadViewDetails?.exchange_vehicle_detail?.transfer_document_file_name}
                          </a>
                        ) : (
                          <span>No file available</span>
                        )}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='spare_keys'
                        label='Spare Keys:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.spare_keys}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item
                        name='spare_wheels'
                        label='Spare Wheels:'
                        layout='horizontal'
                        className='form-item-horizontal'
                      >
                        {leadViewDetails?.exchange_vehicle_detail?.spare_wheels}
                      </Form.Item>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Form.Item name='tools' label='Tools:' layout='horizontal' className='form-item-horizontal'>
                        {leadViewDetails?.exchange_vehicle_detail?.tools}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Image Upload
                  </Title>
                  <Row gutter={[16, 0]} align='middle' className='mb-3'>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div className=''>{renderDocumentLink('Front', 'Front Image')}</div>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div className=''>{renderDocumentLink('Back', 'Back Image')}</div>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div className=''>{renderDocumentLink('Side', 'Side Image')}</div>
                    </Col>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <div className=''>{renderDocumentLink('Inside', 'Inside Image')}</div>
                    </Col>
                  </Row>
                </Col>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Title level={4} className='mb-3'>
                    Remark
                  </Title>
                  <Form.Item
                    name='remarks'
                    label=''
                    rules={[
                      {
                        required: false,
                        message: 'Please input remark',
                      },
                      {
                        max: 300,
                        message: 'Remark cannot exceed 300 characters',
                      },
                    ]}
                  >
                    {leadViewDetails?.exchange_vehicle_detail?.remarks}
                  </Form.Item>
                </Col>
                <Col xl={8} lg={12} md={24} sm={24} xs={24}>
                  <Form.Item
                    name='value_offered'
                    label='Value Offered:'
                    layout='horizontal'
                    className='form-item-horizontal'
                  >
                    {leadViewDetails?.exchange_vehicle_detail?.value_offered
                      ? formatIndianCurrency(leadViewDetails?.exchange_vehicle_detail?.value_offered)
                      : '-'}
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default ExchangeDetailsWithPRocured;
