/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Radio,
  Typography,
  notification,
  Card,
  Select,
  Space,
  Tag,
  Button,
} from "antd";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../components/common/Loader";
import { fetchExchangeLeadList } from "../../../auth/slice/authSlice";
import ExchangeLeadCard from "../../../../components/card/ExchangeLeadCard";
import NoDataFound from "../../../../components/common/NoDataFound";
import {
  getFilterAreaService,
  getFilterTerritoriesService,
  getLeadOwnerService,
  getLeadService,
  getLeadStagesService,
  getVehicleModelService,
} from "../../../../services/master-services";
import { useLocation, useNavigate } from "react-router-dom";
import { ddmmyyFormatter } from "../../../../utills/helpers";
import { API_RESPONSE_CODES } from "../../../../utills/constant";

const { Title } = Typography;

const ExchangeLeads = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const hotKeyId = searchParams.get("key");
  const typeParamId =
    searchParams.get("lead_type") === "10" ? "" : searchParams.get("lead_type");
  const stageParamId = searchParams.get("lead_stage");
  const needProcurment =
    searchParams.get("need_procured") === `"true"` ? true : false;
  const needEvaluation =
    searchParams.get("need_evaluation") === `"true"` ? true : false;

  const exchangeLeads = useSelector(
    (state) => state?.auth?.exchangeLeads?.data?.list_data
  );
  const dashboardData = useSelector(
    (state) => state?.auth?.dashboardHomeFilters
  );
  const startDateId = dashboardData?.startDate;
  const endDateId = dashboardData?.endDate;
  const modelType = dashboardData?.modelNo;

  const [tagNames, setTagnames] = useState({});
  const [areaData, setAreaData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ownerData, setOwnerData] = useState([]);
  const [leadsTypeData, setLeadsTypeData] = useState([]);
  const [leadStageData, setLeadStageData] = useState([]);
  const [territoryData, setTerritoryData] = useState([]);
  const [radioData, setRadioData] = useState("all");
  const [modalData, setModalData] = useState([]);

  const [filters, setFilters] = useState({
    page: 1,
    per_page: 10,
    search: "",
    procured: false,
    evaluation_completed: false,
    lead_stage: stageParamId ? [stageParamId] : [],
    lead_type: typeParamId ? [typeParamId] : [],
    need_evaluation: needEvaluation ? needEvaluation : false,
    need_procured: needProcurment ? needProcurment : false,
    start_date: hotKeyId && startDateId ? startDateId : null,
    end_date: hotKeyId && endDateId ? endDateId : null,
    model_type: hotKeyId && modelType ? [modelType] : [],
    territory_id: [],
    area_id: [],
  });

  const [register, setRegister] = useState({
    state_id: 0,
    territory_id: 0,
  });

  const hasExchangeLeadsData = exchangeLeads?.length > 0;

  useEffect(() => {
    getLeadsTypeData();
    getLeadStagesData();
    getTerritoryData();
    getOwnerData();
    getModalData();
    getAreaData();
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setRadioData(value);
    setFilters((prev) => ({
      ...prev,
      need_evaluation: value === "need_evaluation",
      evaluation_completed: value === "evaluation_completed",
      need_procured: value === "need_procured",
      procured: value === "procured",
      page: 1,
    }));
  };

  useEffect(() => {
    getExchangeLeadListing(filters);
  }, [filters]);

  useEffect(() => {
    if (hotKeyId && typeParamId) {
      const lead_type =
        typeParamId === "10"
          ? "Live"
          : leadsTypeData?.filter((ele) => ele.id === +typeParamId)?.at(0)
            ?.name;
      setTagnames((state) => ({
        ...state,
        "Lead Type": lead_type,
      }));
    }
    if (hotKeyId && stageParamId) {
      let val = leadStageData
        ?.filter((ele) => ele.id === +stageParamId)
        ?.at(0)?.name;
      setTagnames((state) => ({
        ...state,
        "Lead Stage": val,
      }));
    }
    if (hotKeyId && needProcurment) {
      setTagnames((state) => ({
        ...state,
        Procurement: "Pending",
      }));
    }
    if (hotKeyId && needEvaluation) {
      setTagnames((state) => ({
        ...state,
        Evaluation: "Pending",
      }));
    }
    if (hotKeyId && startDateId) {
      setTagnames((state) => ({
        ...state,
        "From Date": ddmmyyFormatter(startDateId),
      }));
    }
    if (hotKeyId && endDateId) {
      setTagnames((state) => ({
        ...state,
        "To Date": ddmmyyFormatter(endDateId),
      }));
    }
    if (hotKeyId && modelType) {
      const model = modalData
        ?.filter((ele) => ele.id === +modelType)
        ?.at(0)?.model_name;
      setTagnames((state) => ({ ...state, Model: model }));
    }
  }, [
    typeParamId,
    stageParamId,
    leadsTypeData,
    needProcurment,
    needEvaluation,
  ]);

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };
  const getModalData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleModelService(1);
      setModalData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const getExchangeLeadListing = async (data) => {
    setIsLoading(true);
    try {
      await dispatch(fetchExchangeLeadList(data));
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadsTypeData = async () => {
    setIsLoading(true);
    try {
      const leadsDataArray = await getLeadService();
      setLeadsTypeData(leadsDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getLeadStagesData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getLeadStagesService();
      setLeadStageData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleButtonClick = () => {
    navigate(`/leads/exchange-lead`);
    setFilters({
      ...filters,
      page: 1,
      per_page: 10,
      search: "",
      procured: false,
      area_id: [],
      territory_id: [],
      lead_stage: [],
      lead_type: [],
      evaluation_completed: false,
      need_evaluation: false,
      need_procured: false,
      start_date: "",
      end_date: "",
      model_type: [],
    });
  };

  const getOwnerData = async () => {
    setIsLoading(true);
    try {
      const ownerData = await getLeadOwnerService();
      if (ownerData?.status === API_RESPONSE_CODES?.OK) {
        setOwnerData(ownerData?.data?.data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTerritoryData = async () => {
    setIsLoading(true);
    try {
      const territoryData = await getFilterTerritoriesService();
      if (territoryData?.status === API_RESPONSE_CODES?.OK) {
        setTerritoryData(territoryData?.data?.data);
      }
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAreaData = async () => {
    setIsLoading(true);
    try {
      const areaData = await getFilterAreaService();
      if (areaData?.status === API_RESPONSE_CODES?.OK) {
        setAreaData(areaData?.data?.data);
      }
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerritoryChange = (value) => {
    setFilters((state) => ({
      ...state,
      page: 1,
      territory_id: value,
    }));

    setRegister((state) => ({
      ...state,
      territory_id: value,
    }));
    getAreaData(register.state_id, value);
  };
  return (
    <>
      <Loader loading={isLoading} />
      <div className="user-data">
        <div className="user-left justify-content-between d-flex align-items-center">
          <Title level={4} className="">
            My Leads
          </Title>
        </div>

        {hotKeyId ? (
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-3">
            <Space size={"middle"} wrap>
              {Object.entries(tagNames)?.map(([key, value]) => (
                <Space key={key}>
                  {/* Adding a key for each pair for React's rendering */}
                  <span className="tag-label">{key}:</span>
                  <Tag color="green">{value}</Tag>
                </Space>
              ))}
            </Space>

            <Button
              type="primary"
              className="secondary-button"
              onClick={handleButtonClick}
            >
              Go to Leads List
            </Button>
          </div>
        ) : (
          <>
            <div className="search-leads pb-4">
              <Search
                allowClear
                onSearch={onSearch}
                placeholder="Search by Customer and Contact Number"
              />
            </div>
            <Radio.Group onChange={handleChange} value={radioData}>
              <Radio value={"all"}>All</Radio>
              <Radio value={"need_evaluation"}>Evaluation Needed</Radio>
              <Radio value={"evaluation_completed"}>Evaluation Completed</Radio>
              <Radio value={"need_procured"}>Procurement Needed</Radio>
              <Radio value={"procured"}>Procured</Radio>
            </Radio.Group>
            <Row className="mt-3" gutter={16}>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode="multiple"
                  maxTagCount={2}
                  placeholder="Lead Type"
                  className="form-control-ant"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(ele) =>
                    setFilters((state) => ({
                      ...state,
                      page: 1,
                      lead_type: ele,
                    }))
                  }
                >
                  {leadsTypeData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode="multiple"
                  maxTagCount={2}
                  placeholder="Lead Stage"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  className="form-control-ant"
                  onChange={(ele) =>
                    setFilters((state) => ({
                      ...state,
                      page: 1,
                      lead_stage: ele,
                    }))
                  }
                >
                  {leadStageData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode='multiple'
                  maxTagCount={2}
                  placeholder='Model Type'
                  className='form-control-ant'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(ele) =>
                    setFilters((state) => ({
                      ...state,
                      page: 1,
                      model_type: ele,
                    }))
                  }
                >
                  {modalData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option?.model_name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode='multiple'
                  maxTagCount={2}
                  placeholder='Territory'
                  className='form-control-ant'
                  onChange={handleTerritoryChange}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {territoryData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode='multiple'
                  maxTagCount={2}
                  placeholder='Area'
                  className='form-control-ant'
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(ele) =>
                    setFilters((state) => ({
                      ...state,
                      page: 1,
                      area_id: ele,
                    }))
                  }
                >
                  {areaData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Select
                  allowClear
                  mode='multiple'
                  maxTagCount={2}
                  placeholder='Lead Owner'
                  className='form-control-ant'
                  filterOption={(input, option) =>
                    option.children?.join(' ')?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(ele) =>
                    setFilters((state) => ({
                      ...state,
                      page: 1,
                      lead_owned: ele,
                    }))
                  }
                >
                  {ownerData?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option?.full_name}
                      {option?.title ? ` (${option?.title})` : ''}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        )}
        <div className="lead-type-card pt-4">
          <Row gutter={[20, 20]}>
            {hasExchangeLeadsData ? (
              exchangeLeads?.map((item) => (
                <Col key={item.id} xl={24} lg={24} md={24} sm={24} xs={24}>
                  <ExchangeLeadCard exchangeLeadData={item} />
                </Col>
              ))
            ) : (
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card>
                  <div className="user-table-data">
                    <NoDataFound />
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default ExchangeLeads;
