/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Tabs,
  Button,
  Table,
  Space,
  Popconfirm,
  notification,
  Tag,
  Form,
  Select,
  Card,
  Row,
  Col,
  Tooltip,
  Switch,
  Modal,
} from 'antd';
import { PlusOutlined, EyeOutlined, DeleteOutlined, EditOutlined, AimOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/common/Loader';
import { getAdminUserListingService } from '../../../services/admin-services';
import { deleteAdminUserService } from '../../../services/admin-services';
import { API_RESPONSE_CODES } from '../../../utills/constant';
import Search from 'antd/es/input/Search';
import {
  getAreasService,
  getDesignationsService,
  getStateService,
  getTerritoriesService,
  updateUserStatusService,
} from '../../../services/master-services';
import PageContentHeader from '../../../components/common/PageContentHeader';
import { useSelector } from 'react-redux';

const Users = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [listData, setListData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [listCount, setListCount] = useState();
  const [stateData, setStateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [territoryData, setTerritoryData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [designationData, setDesignationData] = useState([]);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [currentTags, setCurrentTags] = useState({ heading: '', data: [] });
  const [register, setRegister] = useState({
    state_id: 0,
    territory_id: 0,
    area_id: 0,
  });
  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: '',
    state: [],
    area: [],
    territory: [],
    designation: [],
  });

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const permissions = useSelector((state) => state?.auth?.userData?.permissions);

  const hasAddUserPermission = isAdmin ? true : permissions?.includes('add-user');

  const hasViewUserPermission = isAdmin ? true : permissions?.includes('view-user');

  const hasEditUserPermission = isAdmin ? true : permissions?.includes('edit-user');

  const hasDeleteUserPermission = isAdmin ? true : permissions?.includes('delete-user');

  const hasViewTargetPermission = isAdmin ? true : permissions?.includes('view-target');

  const route = isAdmin ? '/admin' : '';

  useEffect(() => {
    getUserListing(filters);
  }, [filters]);

  useEffect(() => {
    getStateData();
    getDesignationData();
  }, []);

  const handleStateChange = (value) => {
    if (value?.length < 1) {
      setRegister((val) => ({
        ...val,
        state_id: 0,
        territory_id: 0,
      }));
      setFilters((val) => ({
        ...val,
        state: [],
        area: [],
        territory: [],
        page: 1,
      }));
      form.setFieldsValue({
        territory_ids: [],
        area_ids: [],
      });
    } else {
      setRegister((val) => ({
        ...val,
        state_id: value,
      }));
      setFilters((val) => ({
        ...val,
        state: value,
        page: 1,
      }));
      getTerritoryData(value);
    }
  };

  const handleAreaChange = (value) => {
    setFilters((val) => ({
      ...val,
      area: value,
      page: 1,
    }));
  };

  const handleTerritoryChange = (value) => {
    if (value?.length < 1) {
      setRegister((state) => ({
        ...state,
        territory_id: 0,
      }));
      setFilters((val) => ({
        ...val,
        territory: [],
        page: 1,
      }));
      form.setFieldsValue({
        area_ids: [],
      });
    } else {
      setRegister((state) => ({
        ...state,
        territory_id: value,
      }));
      setFilters((val) => ({
        ...val,
        territory: value,
        page: 1,
      }));
      getAreaData(register.state_id, value);
    }
  };

  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTerritoryData = async (state_id) => {
    const findStates = stateData?.filter((ele) => state_id.includes(ele.slug));
    const findState_id = findStates?.map((ele) => ele.id);

    setIsLoading(true);
    try {
      const territoryData = await getTerritoriesService(findState_id);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAreaData = async (state_id, territory_id) => {
    const findStates = stateData?.filter((ele) => state_id.includes(ele.slug));
    const findState_id = findStates?.map((ele) => ele.id);
    const findTerritories = territoryData?.filter((ele) => territory_id.includes(ele.slug));
    const findterritory_id = findTerritories?.map((ele) => ele.id);
    setIsLoading(true);
    try {
      const areaData = await getAreasService(findState_id, findterritory_id);
      setAreaData(areaData?.data?.data);
    } catch (error) {
      setAreaData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getDesignationData = async () => {
    setIsLoading(true);
    try {
      const designationArray = await getDesignationsService(true);
      setDesignationData(designationArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getUserListing = async (data) => {
    setIsLoading(true);
    try {
      const userList = await getAdminUserListingService(data);
      if (userList?.status === API_RESPONSE_CODES?.OK) {
        setListCount(userList?.data?.data?.total_records);
        setListData(userList?.data?.data?.list_data);
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (record) => {
    navigate(isAdmin ? `/admin/users/edit/${record.id}` : `/users/edit/${record.id}`);
  };

  const handleViewTarget = (record) => {
    navigate(isAdmin ? `/admin/targets/${record.id}` : `/targets/${record.id}`);
  };

  const handleDelete = async (key) => {
    setIsLoading(true);
    try {
      const deleteUser = await deleteAdminUserService(key);
      if (deleteUser.data.status === API_RESPONSE_CODES.OK) {
        getUserListing(filters);
        notification.success({
          message: deleteUser.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleView = (record) => {
    navigate(isAdmin ? `/admin/users/details/${record.id}` : `/users/details/${record.id}`);
  };

  const handleDesignationChange = (values) => {
    setFilters((prev) => ({ ...prev, designation: values, page: 1, }));
  };

  const handleStatusChange = async (checked, record) => {
    if (!checked && record.lead_count > 0) {
      setCurrentRecord(record);
      setIsModalVisible(true);
    } else {
      try {
        setIsLoading(true);
        let payload = {
          status: checked ? 1 : 0,
        };
        const response = await updateUserStatusService(record.id, payload);
        if (response.data.status === API_RESPONSE_CODES.OK) {
          notification.success({
            message: response.data.message,
          });

          getUserListing(filters);
        }
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    navigate(`${route}/leads?transfer=${currentRecord?.id}`);
  };

  const handleShowMore = (headings, tags) => {
    setCurrentTags((state) => ({
      ...state,
      heading: headings,
      data: tags,
    }));
    setIsModalVisible1(true);
  };

  // Tables
  const columns = [
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Code',
      dataIndex: 'emp_code',
      key: 'emp_code',
    },
    {
      title: 'Designation',
      dataIndex: 'designation_title',
      key: 'designation_title',
    },
    {
      title: 'Territory',
      key: 'territories',
      dataIndex: 'territories',
      render: (territory) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {territory?.slice(0, 3)?.map((tag) => (
            <Tag key={tag.id} color='blue' style={{ marginBottom: '8px' }}>
              {tag.name}
            </Tag>
          ))}
          {territory?.length > 3 && <a onClick={() => handleShowMore('Territories', territory)}>...Show more</a>}
        </div>
      ),
    },
    {
      title: 'Area',
      key: 'areas',
      dataIndex: 'areas',
      render: (area) => (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {area?.slice(0, 3)?.map((tag) => (
            <Tag key={tag.id} color='red' style={{ marginBottom: '8px' }}>
              {tag.name}
            </Tag>
          ))}
          {area?.length > 3 && <a onClick={() => handleShowMore('Areas', area)}>...Show more</a>}
        </div>
      ),
    },
    {
      title: 'Reporting Manager',
      dataIndex: 'reporting_manager_full_name',
      key: 'reporting_manager_full_name',
    },
    {
      title: 'Contact Number',
      dataIndex: 'phone_no',
      key: 'phone_no',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'status',
      width: '100px',
      className: 'action text-center no-wrap',
      render: (text, record) => (
        <Space size='small'>
          {hasViewUserPermission && (
            <Tooltip title='View User'>
              <Button shape='circle' icon={<EyeOutlined />} onClick={() => handleView(record)} />
            </Tooltip>
          )}
          {hasViewTargetPermission && (
            <Tooltip title='View Target'>
              <Button shape='circle' icon={<AimOutlined />} onClick={() => handleViewTarget(record)} />
            </Tooltip>
          )}

          {hasEditUserPermission && (
            <Tooltip title='Edit User'>
              <Button shape='circle' icon={<EditOutlined />} onClick={() => handleEdit(record)} />
            </Tooltip>
          )}
          {hasDeleteUserPermission && (
            <Popconfirm
              title='Are you sure you want to delete this user?'
              onConfirm={() => handleDelete(record.id)}
              okButtonProps={{
                loading: false,
              }}
              okText='Yes'
              cancelText='No'
            >
              <Tooltip title='Delete User'>
                <Button shape='circle' danger icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          )}
          {hasEditUserPermission && (
            <Switch
              checked={record.status}
              onChange={(checked) => handleStatusChange(checked, record)}
              checkedChildren='Active'
              unCheckedChildren='Inactive'
            />
          )}
        </Space>
      ),
    },
  ];

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
      page: 1,
    }));
  };

  //All tabs item
  const items = [
    {
      key: '1',
      label: 'All',
      children: '',
    },
  ];

  return (
    <div className='user-data'>
      <Loader loading={isLoading} />
      <PageContentHeader
        title='Users'
        // headerLeft={<Input addonBefore={<SearchOutlined />} size="small" placeholder='Search' />}
        headerRight={
          <>
            {hasAddUserPermission && (
              <Button
                type='primary'
                icon={<PlusOutlined />}
                className='secondary-button'
                onClick={() => navigate(isAdmin ? '/admin/users/add' : '/users/add')}
              >
                New User
              </Button>
            )}
          </>
        }
      />

      <Card className='filter-card'>
        <Form
          layout='vertical'
          name='normal_search'
          className='login-form'
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className='login-input-field'>
            <Row gutter={[16, 16]}>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item
                  name='search'
                  label='Search (by Name/Designation/Code)'
                // extra="(by Name/Code/RM/Contact/Email)"
                >
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder='Search by name, designation or code'
                  />
                </Form.Item>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='designation_id' label='Designation'>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount={2}
                    placeholder='Designation'
                    className='form-control-ant'
                    onChange={(values) => handleDesignationChange(values)}
                  >
                    {designationData?.map((option) => (
                      <Select.Option key={option.id} value={option.slug}>
                        {option.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='state_id' label='State'>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount={2}
                    placeholder='State'
                    className='form-control-ant'
                    onChange={handleStateChange}
                  >
                    {stateData?.map((option) => (
                      <Select.Option key={option.id} value={option.slug}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='territory_ids' label='Territory'>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount={2}
                    placeholder='Territory'
                    className='form-control-ant'
                    onChange={handleTerritoryChange}
                    disabled={register.state_id === 0}
                  >
                    {territoryData?.map((option) => (
                      <Select.Option key={option.id} value={option.slug}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                <Form.Item name='area_ids' label='Area'>
                  <Select
                    allowClear
                    mode='multiple'
                    maxTagCount={2}
                    placeholder='Area'
                    className='form-control-ant'
                    onChange={handleAreaChange}
                    disabled={register.territory_id === 0}
                  >
                    {areaData?.map((option) => (
                      <Select.Option key={option.id} value={option.slug}>
                        {option?.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Card>
      <div className='user-table-data'>
        <Tabs defaultActiveKey='1' items={items} />
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: true }}
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount,
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>

      {/* Confirm Lead Transfer Modal */}
      <Modal
        title='Confirmation'
        visible={isModalVisible}
        onOk={handleConfirm}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button key='submit' type='primary' onClick={handleConfirm}>
            Go to Lead Transfer
          </Button>,
        ]}
      >
        <p>There are lead(s) assigned to this user so please transfer them first.</p>
      </Modal>

      {/* Show all Area & Territory Modal */}
      <Modal
        closeIcon={false}
        maskClosable={false}
        open={isModalVisible1}
        title={`All ${currentTags?.heading}`}
        onOk={() => setIsModalVisible1(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {currentTags?.data?.map((tag) => (
            <Tag key={tag.id} color={currentTags.heading === 'Areas' ? 'red' : 'blue'} style={{ marginBottom: '8px' }}>
              {tag.name}
            </Tag>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Users;
