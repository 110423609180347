import dayjs from 'dayjs';

/* eslint-disable eqeqeq */
export const formatDate = (dateObj) => {
  // Ensure the dateObj has the necessary properties
  if (dateObj && dateObj.$y !== undefined && dateObj.$M !== undefined && dateObj.$D !== undefined) {
    const year = dateObj.$y;
    const month = dateObj.$M + 1;
    const day = dateObj.$D;
    return `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
  }
  return dateObj;
};

export const calculateAndAppendTotals = (data, columns) => {
  const totals = { so: 'Total' };
  columns.forEach((column) => {
    totals[column] = 0;
  });
  data.forEach((row) => {
    columns.forEach((column) => {
      totals[column] += row[column];
    });
  });
  return [...data, totals];
};

export function formatIndianCurrency(number) {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    minimumFractionDigits: 0,
  }).format(number);
}

// Custom validator function
export const checkboxFields = [
  'not_satisfied_with_features',
  'Not_satisfied_with_experience',
  'Finance_not_approved',
  'Out_of_budget',
  'High_discount_expected',
  'Long_waiting_period',
  'Postponement_of_purchase',
  'Not_responding',
];
export const atLeastOneCheckboxValidator = ({ getFieldValue }) => ({
  validator() {
    // List all your checkbox field names
    const checkboxFields = [
      'not_satisfied_with_features',
      'Not_satisfied_with_experience',
      'Finance_not_approved',
      'Out_of_budget',
      'High_discount_expected',
      'Long_waiting_period',
      'Postponement_of_purchase',
      'Not_responding',
    ];

    // Check if at least one checkbox is checked
    const isAtLeastOneChecked = checkboxFields.some((fieldName) => getFieldValue(fieldName));

    if (isAtLeastOneChecked) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please check at least one checkbox'));
  },
});

export const pricevalidationwithAsterisklength7 = (title) => {
  return [
    {
      required: true,
      message: '',
    },
    {
      validator: (_, value) => {
        if (!value && value !== 0) {
          // Adjusted to also allow 0 as a valid input
          return Promise.reject(new Error(`Please input a valid ${title} value`));
        } else if (!/^\d+(\d{1,2})?$/.test(value)) {
          // Updated regex here to ensure the format is correct (integer or decimal with up to 2 places)
          return Promise.reject(new Error(`Please input a valid ${title} value`));
        } else if (value == 0) {
          // If the value is exactly 0, it's accepted without further checks.
          return Promise.resolve();
        } else if (value.toString()?.length < 2) {
          // Adjusted to convert value to string for length check, ensuring it works for numbers
          return Promise.reject(new Error('The input must be at least 2 digits long'));
        } else if (value.toString()?.length > 7) {
          // Considering the decimal point and decimals, the total length check might need adjustment
          // If including decimals and the point, adjust the length check as needed
          return Promise.reject(new Error(`The ${title} must be up to 7 digits`));
        } else if (/\s/.test(value)) {
          return Promise.reject(new Error('The input must not contain whitespace'));
        }
        return Promise.resolve();
      },
    },
  ];
};

export const priceValidationFunctionWithAsterisk = (title) => {
  return [
    {
      required: true,
      message: '',
    },
    {
      validator: (_, value) => {
        if (!value && value !== 0) {
          // Adjusted to also allow 0 as a valid input
          return Promise.reject(new Error(`Please enter a valid ${title}`));
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          // Updated regex here to ensure the format is correct (integer or decimal with up to 2 places)
          return Promise.reject(new Error(`Please enter a valid ${title}`));
        } else if (value == 0) {
          // If the value is exactly 0, it's accepted without further checks.
          return Promise.resolve();
        } else if (value.toString()?.length < 2) {
          // Adjusted to convert value to string for length check, ensuring it works for numbers
          return Promise.reject(new Error('The input must be at least 2 digits long'));
        } else if (value.toString()?.length > 10) {
          // Considering the decimal point and decimals, the total length check might need adjustment
          // If including decimals and the point, adjust the length check as needed
          return Promise.reject(new Error('The input must not be more than 10 digits'));
        } else if (/\s/.test(value)) {
          return Promise.reject(new Error('The input must not contain whitespace'));
        }
        return Promise.resolve();
      },
    },
  ];
};

export const priceValidationFunction = (title) => {
  return [
    {
      validator: (_, value) => {
        if (!value && value !== 0) {
          // Adjusted to also allow 0 as a valid input
          return Promise.reject(new Error(`Please enter a valid ${title}`));
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
          // Updated regex here to ensure the format is correct (integer or decimal with up to 2 places)
          return Promise.reject(new Error(`Please enter a valid ${title}`));
        } else if (value == 0) {
          // If the value is exactly 0, it's accepted without further checks.
          return Promise.resolve();
        } else if (value.toString()?.length < 2) {
          // Adjusted to convert value to string for length check, ensuring it works for numbers
          return Promise.reject(new Error(`The ${title} must be at least 2 digits long`));
        } else if (value.toString()?.length > 10) {
          // Considering the decimal point and decimals, the total length check might need adjustment
          // If including decimals and the point, adjust the length check as needed
          return Promise.reject(new Error(`The ${title} must not be more than 10 digits`));
        } else if (/\s/.test(value)) {
          return Promise.reject(new Error(`The ${title} must not contain whitespace`));
        }
        return Promise.resolve();
      },
    },
  ];
};

export const percentageValidationFunction = (title) => {
  return [
    {
      required: true,
      message: `Please input valid ${title} value`,
    },
    {
      // Using a regular expression to enforce the rule for no symbols and allowing up to 5 decimal places.
      pattern: new RegExp(/^(100(?:\0{1,2})?|0?\d{0,2}(?:\d{1,2})?)$/),
      message: `Please input valid ${title} value`,
    },
  ];
};

export const numberValidationFunction = (title) => {
  return [
    {
      required: true,
      message: `Please input valid ${title} value`,
    },
    {
      // Using a regular expression to enforce the rule for no symbols and allowing up to 5 decimal places.
      pattern: new RegExp(/^\d{1,3}(\d{1,2})?$/),
      message: `The ${title} value must be up to 3 digits.`,
    },
  ];
};

export const formatKeyName = (key) => {
  return key
    ?.split('_')
    ?.map((word) => word.charAt(0).toUpperCase() + word?.slice(1))
    .join(' ');
};

export const notRequiredPriceValidationFunction = (title) => {
  return [
    {
      required: false,
      message: `Please input a valid ${title}`,
    },
    {
      validator: async (_, value) => {
        // Check if the value matches the expected numeric format.
        if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
          return Promise.reject(new Error(`Please input a valid ${title}`));
        }
        // If the value matches the format, then check the length.
        if (value && value?.length > 10) {
          return Promise.reject(new Error(`The ${title} must not be more than 10 digits`));
        }
        // If no issues, validation is successful.
        return Promise.resolve();
      },
    },
  ];
};

export function formatTimeToReadableString(time) {
  // Split the time into hours and minutes
  const [hours, minutes] = time?.split(':')?.map(Number); // Convert strings to numbers

  // Construct and return the formatted string
  return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} min${minutes !== 1 ? 's' : ''}`;
}

export function removeEmptyKeys(obj) {
  // Filter out key-value pairs where the value is null or undefined
  const filteredObj = Object.fromEntries(
    Object.entries(obj)?.filter(([, value]) => value !== null && value !== undefined && value !== '')
  );
  return filteredObj;
}
//common function to transform line chart data from api
export const transformData = (data, headers) => {
  const formattedData =
    Array.isArray(data) && data?.length > 0
      ? [headers, ...data?.map((item) => [item.interval, item.lead_count, item.lead_count.toString()])]
      : [headers];
  return formattedData;
};

export const ddmmyyFormatter = (date) => {
  const ddmmyyyy = dayjs(date).format('DD/MM/YYYY');
  return ddmmyyyy;
};

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  let years = [];
  for (let i = currentYear - 50; i <= currentYear + 50; i++) {
    years.push({ id: i, name: `${i}` });
  }
  // setYearsList(years);
  return years;
};

export const isValidNonZeroNumber = (value) => {
  // Check if the value is a number and not equal to zero
  return typeof value === 'number' && !isNaN(value) && value !== 0;
};
