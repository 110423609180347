// Server URL

//Yash IP
// export const API_BASE_URL = 'http://10.10.11.59/api/v1';
//
// Main IP
// export const API_BASE_URL = 'https://jd-dealership.siplsolutions.com/api/v1';

// QA Server URL
// export const API_BASE_URL = 'https://jd-dealership-qa.siplsolutions.com/api/v1';
export const API_BASE_URL = 'https://api.motorvault.in/api/v1'; 

//Staging IP
// export const API_BASE_URL =
//   "https://jd-dealership-staging.siplsolutions.com/api/v1";

// Role Ids for users
export const roleIds = {
  superAdmin: 1,
  exchangeEvaluationTeam: 9,
  salesOfficer: 10,
};

// API RESPONSE CODES
export const API_RESPONSE_CODES = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  NOT_MODIFIED: 304,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  PRECONDITION_FAILED: 412,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  TO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
  VALIDATION: 100,
  CONFLICT: 409,
};

export const Target_TYPE = {
  Enquiry: 1,
  Test: 2,
  Home: 3,
  Exchange: 4,
  Booking: 5,
  Retail: 6,
  Valuation: 7,
  Purchase: 8,
  Extended: 9,
  Body: 10,
  InHouse: 11,
  Accessories: 12,
};

export const lockingDate = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
  {
    id: 10,
  },
  {
    id: 11,
  },
  {
    id: 12,
  },
  {
    id: 13,
  },
  {
    id: 14,
  },
  {
    id: 15,
  },
  {
    id: 16,
  },
  {
    id: 17,
  },
  {
    id: 18,
  },
  {
    id: 19,
  },
  {
    id: 20,
  },
  {
    id: 21,
  },
  {
    id: 22,
  },
  {
    id: 23,
  },
  {
    id: 24,
  },
  {
    id: 25,
  },
  {
    id: 26,
  },
  {
    id: 27,
  },
  {
    id: 28,
  },
  {
    id: 29,
  },
  {
    id: 30,
  },
  {
    id: 31,

  },
]

export const monthData = [
  {
    id: 1,
    name: "January",
  },
  {
    id: 2,
    name: "February",
  },
  {
    id: 3,
    name: "March",
  },
  {
    id: 4,
    name: "April",
  },
  {
    id: 5,
    name: "May",
  },
  {
    id: 6,
    name: "June",
  },
  {
    id: 7,
    name: "July",
  },
  {
    id: 8,
    name: "August",
  },
  {
    id: 9,
    name: "September",
  },
  {
    id: 10,
    name: "October",
  },
  {
    id: 11,
    name: "November",
  },
  {
    id: 12,
    name: "December",
  },
];

export const leadsInitialState = {
  per_page: 10,
  page: 1,
  search: "",
  lead_status: [],
  lead_type: [],
  lead_stage: [],
  model_type: [],
  fuel_type: [],
  rto_locations: [],
  lead_sources: [],
  variant_type: [],
  state_id: [],
  territory_id: [],
  area_id: [],
  lead_owned: [],
  follow_up_date_id: [],
  start_date: null,
  end_date: null,
  graph_metrics: "",
  date_range: "",
  test_drive_completed: false,
  home_visit_completed: false,
  exchange_car: false,
  evaluation_completed: false,
  greater_than_one_day: false,
  greater_than_ten_day: false,
  greater_than_twenty_day: false,
  last_month: false,
  dashboard_redirect: false,
  live_lead: false,
};
