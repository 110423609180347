import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
import {
  resetPasswordService,
  validateTokenService,
} from "../../services/auth-service";
import { comparePasswords } from "../../utills/apiUrl";
import { API_RESPONSE_CODES } from "../../utills/constant";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [inValidToken, setInvalidToken] = useState(false);
  const [token, setToken] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state?.auth?.isAdmin);

  const validateToken = async (data) => {
    try {
      let res = await validateTokenService(data);
      if (res.data.status === API_RESPONSE_CODES.OK) {
        notification.success({
          message: res.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
      setInvalidToken(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    setToken(token);
    if (token) {
      validateToken(`${token}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = async (values) => {
    let checkPassword = comparePasswords(
      values.password,
      values.password_confirmation
    );
    values.token = token;
    if (checkPassword) {
      try {
        setIsLoading(true);
        const res = await resetPasswordService(values);
        if (res.data.status === API_RESPONSE_CODES.OK) {
          notification.success({
            message: res.data.message,
          });
          navigate(isAdmin ? "/admin" : "/");
        }
      } catch (error) {
        notification.error({
          description: error.response?.data?.message ?? error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      notification.error({
        message: "Both the passwords must be same.",
      });
    }
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="form-wrapper">
        <div className="form-main common-middile-section">
          <div className="form-head text-center">
            <img src={"/toyota-logo.png"} alt="#" className="logo" />
            <h4>Reset Password </h4>
          </div>

          {inValidToken ? (
            <>
              <Alert message="Invalid Link" type="error" showIcon />
            </>
          ) : (
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={handleReset}
            >
              <div className="login-input-field">
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password",
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password cannot be less than 8 characters.',
                    // },
                    {
                      pattern: /^\S+$/,
                      message: "Password cannot contain white spaces.",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                      message:
                        "Password should contain at least 8 characters,one uppercase letter, one lowercase letter, one number, and one special character.",
                    },
                  ]}
                >
                  <Input.Password
                    maxLength={20}
                    // prefix={<LockOutlined className="site-form-item-icon" />}
                    type=" new_password"
                    placeholder="New Password"
                    className="password"
                  />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label="Confirm New Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirm new password",
                    },
                    {
                      pattern: /^\S+$/,
                      message: "Password cannot contain white spaces.",
                    },
                    // {
                    //   min: 8,
                    //   message: 'Password cannot be less than 8 characters.',
                    // },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("The passwords do not match!")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    maxLength={20}
                    // prefix={<LockOutlined className="site-form-item-icon" />}
                    type="confirm_new_password"
                    placeholder="Confirm New Password"
                    className="password"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    defaultColor="rgba(0, 0, 0, 0.88)"
                  >
                    Reset
                  </Button>
                </Form.Item>
                <Form.Item className="login-form-forgot mx-3">
                  {/* <Navigate to={isAdmin ? '/admin' : '/'}> */}
                  <a href={isAdmin ? "/admin" : "/"}>Cancel</a>
                  {/* </Navigate> */}
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
