import React,{ useState } from 'react';
import Loader from '../../components/common/Loader';
import { API_RESPONSE_CODES } from '../../utills/constant';
import { Button, Form, Input, Alert, notification } from 'antd';
import { forgotPasswordService } from '../../services/auth-service';

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();


  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const res = await forgotPasswordService(values);
      if (res.data.status === API_RESPONSE_CODES.OK) {
        form.resetFields();
        notification.success({
          message: res?.data?.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loader loading={isLoading} />
      <div className="form-wrapper">
        <div className="form-main common-middile-section">
          <div className="form-head text-center">
            <img src={'/toyota-logo.png'} alt="#" className="logo" />
            <h4>Forgot Password </h4>
          </div>

          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            onFinish={handleSubmit}
          >
            <div className="login-input-field">
              <Form.Item
                name="email"
                label="Email ID"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email id',
                  },
                  {
                    type: 'email',
                    message: 'Please enter a valid email id',
                  },
                  {
                    validator: (_, value) => {
                      if (value && /@-/.test(value)) {
                        return Promise.reject('Please enter a valid email id');
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input placeholder="Email ID" className="form-control-ant" />
              </Form.Item>
              <Form.Item>
                <Alert
                  message="Reset password link will be shared on Email"
                  type="info"
                  showIcon
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  defaultColor="rgba(0, 0, 0, 0.88)"
                >
                  Submit
                </Button>
              </Form.Item>
              <Form.Item className="login-form-forgot mx-3">
                <a href="#" onClick={() => window.history.back()}>
                  Login
                </a>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordPage;
