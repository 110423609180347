import React from "react";
import { Chart } from "react-google-charts";

const PieChart = (props) => {
  const { data, chartColors,onSegmentClick  } = props;

  // Function to check if all values in data (excluding header) are zero
  const allZeroValues = data?.slice(1).every((row) => row[1] === 0);

  const options = {
    pieHole: 0.6,
    is3D: false,
    pieSliceText: "value",
    legend: {
      position: "right",
      labeledValueText: "both",
      textStyle: { fontSize: 13, marginTop: 10 },
      alignment: "center",
    },
    chartArea: { width: "100%", height: "92%" },
    tooltip: { isHtml: true, trigger: "focus" },
    colors: chartColors
      ? chartColors
      : ["#f9b740", "#dd3b40", "#b6d3b4", "#9eb8f5"],
  };

  const chartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection?.length > 0) {
          const rowIndex = selection[0].row;
          const selectedItem = data[rowIndex + 1]; // Adding 1 because data includes header row
          onSegmentClick && onSegmentClick(selectedItem);
        }
      },
    },
  ];
  
  return (
    <div>
      {allZeroValues ? (
        <div className="align-items-center">
        <p>No Data to display</p>
        </div>
      ) : (
        <Chart
          chartType="PieChart"
          width="100%"
          height="250px"
          data={data}
          options={options}
          chartEvents={chartEvents}
          className={`${onSegmentClick ? "graph-cursor-pointer" : ""}`}
        />
      )}
    </div>
  );
};
export default PieChart;
