import React from "react";
import { Chart } from "react-google-charts";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ColumnChart = (props) => {
  const { data, chartColors, showPercentageSign, heading } = props;

  const navigate = useNavigate();

  const isAdmin = useSelector((state) => state?.auth?.isAdmin);
  const route = isAdmin ? "/admin" : "";

  // Function to check if all values in data (excluding header) are zero
  const allZeroValues = data?.slice(1).every((row) => row[1] === 0);

  const vAxisTicks = [
    { v: 0, f: "0%" },
    { v: 20, f: "20%" },
    { v: 40, f: "40%" },
    { v: 60, f: "60%" },
    { v: 80, f: "80%" },
    // {v: 100, f: '100%'},
  ];

  // Conditionally format the vAxis labels to include a '%' sign
  const vAxisOptions = showPercentageSign
    ? {
        format: "short", // Keeps the axis numbers short and readable
        textStyle: {
          // Additional styling can go here
        },
        ticks: vAxisTicks,
      }
    : {};

  const options = {
    chartArea: { width: "90%", left: "10%" },
    legend: {
      position: "top",
      labeledValueText: "both",
      textStyle: { fontSize: 13 },
      alignment: "center",
    },
    hAxis: {
      // title: 'Total Population',
      minValue: 0,
      minorGridlines: {
        color: "transparent", // Optional: Hides minor vertical gridlines if present
      },
    },
    vAxis: {
      // title: 'Day',
      ...vAxisOptions, // Spread the conditional vAxis options
      minorGridlines: {
        color: "transparent", // Optional: Hides minor vertical gridlines if present
      },
    },
    colors: chartColors ? chartColors : ["#fbd898", "#76af74"],
    annotations: {
      alwaysOutside: true,
      textStyle: {
        fontSize: 12,
        auraColor: "none",
        color: "#555",
        fontWeight: "700",
      },
    },
  };
  const chartEvents = [
    {
      eventName: "select",
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (heading === "Vas booked" || heading === "Vas retailed") {
          return;
        } else {
          if (selection.length === 0) return;
          let item = selection[0];
          let str = "> 60 Days & < 120 Days";
          // Adjust the column index if it's outside the expected data range
          if (item.column > data[0].length - 1) {
            item.column -= 1; // Adjust to target the correct data column
          }
          if (item.row !== null && item.column !== null) {
            let selectedRowData;
            let selectedMetric;
            selectedRowData = data[item.row + 1];

            // Ensure that you're accessing a string before calling .split()
            if (typeof data[0][item.column] === "string") {
              selectedMetric = data[0][item.column]
                .split(" ")
                .join("_")
                .toLowerCase();
              const selectedValue = selectedRowData[item.column];
              const checkStr =
                selectedRowData.at(0)?.split(" ")?.at(0) === str ? true : false;
              const duration = checkStr
                ? "60-120"
                : selectedRowData.at(0)?.split(" ")?.at(0); // Get the selected value

              navigate(
                `${route}/leads?metrics=${selectedMetric}&graph_duration=${duration}&graph_value=${selectedValue}&key=hot_lead`
              );
            } else {
              // Handle cases where data[0][item.column] is not a string
              return;
            }
          } else {
            return;
          }
        }
      },
    },
  ];

  return (
    <div>
      {allZeroValues ? (
        <div className="align-items-center">
          <p>No Data to display</p>
        </div>
      ) : (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          loader={<div>Loading Chart</div>}
          data={data}
          chartEvents={chartEvents}
          options={options}
          className="column-chart-cursor-pointer"
        />
      )}
    </div>
  );
};
export default ColumnChart;
