import React from 'react';

const SalesModalMaster = () => {
  return (
    <div>
      <h1>Sales Modal Master</h1>
    </div>
  );
};

export default SalesModalMaster;
