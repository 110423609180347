/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  notification,
  Typography,
  Space,
} from "antd";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../utills/constant";
import InputSelect from "../../../components/common/InputSelect";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  addVariantService,
  getVehicleMakeService,
  getVehicleModelService,
} from "../../../services/master-services";
/**
 * handleSubmit function handles the form submission.
 * It sends the form data to the server and handles the response.
 * @param {Object} values - Form values
 */
const { Title } = Typography;

const AddVariant = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [form] = Form.useForm(); // Ant Design form instance

  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [filters, setFilters] = useState({
    make_id: [],
    model_id: [],
  });

  const isMakeFilterSelected = filters?.make_id?.length > 0;

  useEffect(() => {
    getMakeData();
  }, []);

  const getMakeData = async () => {
    setIsLoading(true);
    try {
      const modalDataArray = await getVehicleMakeService();
      setMakeData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getModelData = async (make_id) => {
    try {
      setIsLoading(true);
      const modalDataArray = await getVehicleModelService(make_id);
      setModelData(modalDataArray?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMakeChange = (ele) => {
    if (ele) {
      getModelData(ele);
      setFilters((state) => ({ ...state, make_id: [ele] }));
    } else {
      setFilters((state) => ({ ...state, make_id: [] }));
    }
    setModelData([]);
    form.setFieldsValue({
      model_id: null,
      variant: null,
    });
  };

  const handleModelChange = (ele) => {
    if (ele) {
      setFilters((state) => ({ ...state, model_id: [ele] }));
    } else {
      setFilters((state) => ({ ...state, model_id: [] }));
      // setModelData([]);
    }
    form.setFieldsValue({
      variant: null,
    });
  };
  
  const handleSubmit = async (values) => {
    const payload = {
      variant_code: values?.variant,
      model_id: values?.model_id,
    };
    try {
      setIsLoading(true); // Set loading state to true
      // Simulate a successful API response
      const response = await addVariantService(payload);
      if (
        response.data.status === API_RESPONSE_CODES.OK ||
        response.data.status === API_RESPONSE_CODES.CREATED
      ) {
        notification.success({
          message: response.data.message,
        });
        navigate("/admin/masters/variant"); // Navigate to the variants page
      }
    } catch (error) {
      // Handle any errors
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  return (
    <>
      <div className="user-data">
        {/* Loader component to show loading spinner */}
        <Loader loading={isLoading} />

        {/* Page header */}
        <PageContentHeader title="Add Variant" />

        <div className="personal-detail">
          <Card>
            <div className="user-left d-flex align-items-center">
              <Title level={4} className="pe-2 pb-2">
                Variant Details
              </Title>
            </div>
            <Form
              layout="vertical"
              name="add-user-form"
              initialValues={{
                remember: true,
              }}
              form={form}
              onFinish={handleSubmit}
            >
              <div className="detail-wrapper">
                <Row gutter={20} justify="start">
                  {/* Make Filter */}
                  <Col xl={6} md={8} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      name="make_id"
                      label="Make"
                      options={makeData}
                      placeholder="Make"
                      optionName={"title"}
                      onSelect={handleMakeChange}
                      className="form-control-ant"
                      rules={[
                        {
                          required: true,
                          message: "Please select your car make",
                        },
                      ]}
                    />
                    {/* <Form.Item name="make_id" label="Make">
                  <Select
                    allowClear
                    // mode="multiple"
                    // maxTagCount={2}
                    placeholder="Make"
                    onChange={handleMakeChange}
                    className="form-control-ant"
                    // disabled={!isExchange || isSentforEvaluation}
                  >
                    {makeData?.map((option) => (
                      <Select.Option key={option.id} value={option.id}>
                        {option?.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> */}
                  </Col>

                  {/* Model Filter */}
                  <Col xl={4} lg={6} md={6} sm={24} xs={24}>
                    <InputSelect
                      allowClear
                      label="Model"
                      name="model_id"
                      options={modelData}
                      placeholder="Model"
                      optionName={"model_name"}
                      onSelect={handleModelChange}
                      className="form-control-ant"
                      // disabled={!isMakeFilterSelected}
                      rules={[
                        {
                          required: true,
                          message: "Please select your car model",
                        },
                      ]}
                    />
                  </Col>

                  {/*  Variant */}
                  <Col xl={8} lg={8} md={12} sm={12} xs={24}>
                    <div className="login-input-field">
                      <Form.Item
                        name="variant"
                        label="Variant"
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject(
                                  "Please input your car variant"
                                );
                              }

                              if (/\s{2,}/.test(value)) {
                                return Promise.reject(
                                  "Variant cannot contain two consecutive spaces"
                                );
                              }
                              const trimmedValue = value.trim();
                              if (trimmedValue !== value) {
                                return Promise.reject(
                                  "Variant cannot start or end with spaces"
                                );
                              }
                              if (value?.length < 3 || value?.length > 30) {
                                return Promise.reject(
                                  "Variant must be between 3 and 30 characters"
                                );
                              }
                              if (!/^[a-zA-Z0-9\s()]+$/.test(value)) {
                                return Promise.reject(
                                  "Variant can only contain alphanumeric characters"
                                );
                              }
                               // Check if the string is numeric only
                               if (/^[\d()]+$/.test(value)) {
                                return Promise.reject(
                                  "Variant cannot be only numeric values"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Variant"
                          className="form-control-ant"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="user-right d-flex align-items-center justify-content-end mt-3">
                <Space size="middle">
                  {/* Cancel button */}
                  <Button
                    type="primary"
                    ghost
                    danger
                    className="primary-button"
                    onClick={() => navigate("/admin/masters/variant")}
                  >
                    Cancel
                  </Button>
                  {/* Save button */}
                  <Button
                    type="primary"
                    className="secondary-button"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddVariant;
