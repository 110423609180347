/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PageContentHeader from "../../../components/common/PageContentHeader";
import {
  Button,
  Card,
  Col,
  Form,
  Popconfirm,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
  notification,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import {
  deleteAreaService,
  getMasterAreaListService,
  getStateService,
  getTerritoriesService,
  updateAreaStatusService,
} from "../../../services/master-services";
import InputSelect from "../../../components/common/InputSelect";
import Loader from "../../../components/common/Loader";
import { API_RESPONSE_CODES } from "../../../utills/constant";

const Area = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [listCount, setListCount] = useState();
  const [stateData, setStateData] = useState([]); // State to store state master data
  const [isLoading, setIsLoading] = useState(false);
  const [territoryData, setTerritoryData] = useState([]);
  const [listData, setListData] = useState([]);
  const [filters, setFilters] = useState({
    per_page: 10,
    page: 1,
    search: "",
    state_id: [],
    area_id: [],
    territory_id: [],
  });

  const isStatePresent = filters?.state_id?.length > 0;

  useEffect(() => {
    getStateData();
  }, []);

  useEffect(() => {
    getAreaListData(filters);
  }, [filters]);

  //function to get state master data
  const getStateData = async () => {
    setIsLoading(true);
    try {
      const stateData = await getStateService();
      setStateData(stateData?.data?.data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to get territory master data
  const getTerritoryData = async (state_id) => {
    try {
      setIsLoading(true);
      const territoryData = await getTerritoriesService(state_id);
      setTerritoryData(territoryData?.data?.data);
    } catch (error) {
      setTerritoryData([]);
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to get area master data
  const getAreaListData = async (data) => {
    try {
      setIsLoading(true);
      const userList = await getMasterAreaListService(data);
      setListCount(userList?.data?.data?.total_records);
      setListData(userList?.data?.data?.list_data);
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to handle state change
  const handleStateChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        state_id: value,
      }));
      getTerritoryData(value);
    } else {
      setFilters((prev) => ({
        ...prev,
        state_id: [],
      }));
      setTerritoryData([]);
    }
    setFilters((prev) => ({
      ...prev,
      territory_id: [],
    }));
    form.setFieldsValue({
      territory_id: null,
    });
  };

  //function to handle terrtitory change
  const handleTerritoryChange = (value) => {
    if (value?.length > 0) {
      setFilters((prev) => ({
        ...prev,
        territory_id: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        territory_id: [],
      }));
    }
  };

  //function to handle detele
  const handleDelete = async (id) => {
    try {
      setIsLoading(true);
      const response = await deleteAreaService(id);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        getAreaListData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  //function to handle search change
  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      page: 1,
      search: value,
    }));
  };

  // Handler for changing the status
  const handleStatusChange = async (checked, record) => {
    try {
      setIsLoading(true);
      let payload = {
        status: checked ? 1 : 0,
      };
      const response = await updateAreaStatusService(record.id, payload);
      if (response.data.status === API_RESPONSE_CODES.OK) {
        const updatedData = listData.map((item) =>
          item.id === record.id ? { ...item, status: checked } : item
        );
        setListData(updatedData);
        getAreaListData(filters);
        notification.success({
          message: response.data.message,
        });
      }
    } catch (error) {
      notification.error({
        description: error.response?.data?.message ?? error?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Tables
  const columns = [
    {
      title: "State",
      dataIndex: "state_name",
      key: "state_name",
    },
    {
      title: "Territory",
      dataIndex: "territory_name",
      key: "territory_name",
    },
    {
      title: "Area",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "120px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Switch
          checked={record.status}
          onChange={(checked) => handleStatusChange(checked, record)}
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "100px",
      className: "action text-center no-wrap",
      render: (text, record) => (
        <Space size="small">
          <Popconfirm
            title="Are you sure you want to delete this area?"
            onConfirm={() => handleDelete(record.id)}
            okButtonProps={{
              loading: false,
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Area">
              <Button
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                // onClick={() => handleDelete(record)}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="user-data">
      <Loader loading={isLoading} />
      <PageContentHeader
        title="Area"
        headerRight={
          <>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="secondary-button"
              onClick={() => navigate("/admin/masters/area/add")}
            >
              New Area
            </Button>
          </>
        }
      />
      {/* Filter Option Card */}
      <Card className="filter-card mb-3">
        <Form
          layout="vertical"
          name="normal_search"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          form={form}
        >
          <div className="login-input-field">
            <Row gutter={[16, 16]}>
              <Col xl={8} lg={12} md={12} sm={24} xs={24}>
                <Form.Item name="search" label="Search (By Area)">
                  <Search
                    allowClear
                    onSearch={onSearch}
                    // onChange={handlePageChange}
                    placeholder="Search by area"
                  />
                </Form.Item>
              </Col>

              {/* State Filter */}
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  label="State"
                  name="state_id"
                  mode="multiple"
                  maxTagCount={2}
                  options={stateData}
                  placeholder="State"
                  optionName={"name"}
                  onSelect={handleStateChange}
                  showSearch={true}
                  className="form-control-ant"
                  rules={[
                    {
                      required: false,
                      message: "Please select your state",
                    },
                  ]}
                />
              </Col>

              {/* Territory Filter */}
              <Col xl={6} lg={6} md={6} sm={24} xs={24}>
                <InputSelect
                  allowClear
                  mode="multiple"
                  maxTagCount={2}
                  label="Territory"
                  name="territory_id"
                  optionName={"name"}
                  placeholder="Territory"
                  showSearch={true}
                  options={territoryData}
                  // disable={!isStatePresent}
                  className="form-control-ant"
                  onSelect={handleTerritoryChange}
                  rules={[
                    {
                      required: false,
                      message: "Please select your state",
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
        </Form>
      </Card>

      <div className="user-table-data">
        <Table
          columns={columns}
          dataSource={listData}
          scroll={{ x: "max-content" }}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSize: filters?.per_page,
            current: filters?.page,
            total: listCount, // Replace totalCount with the total count of your data
            onChange: (page, pageSize) => {
              setFilters((prevFilters) => ({
                ...prevFilters,
                page,
                per_page: pageSize,
              }));
            },
          }}
        />
      </div>
    </div>
  );
};

export default Area;
